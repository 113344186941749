import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props} viewBox="0 0 50 50">
    <g>
      <g>
        <polygon points="25.05035,23.8747 41.14025,14.0447 8.96025,14.0447   " />
        <path d="M43.05035,34.0447v-18.83l-17.48,10.68c-0.16,0.1-0.34,0.15-0.52,0.15s-0.36-0.05-0.52-0.15l-17.48-10.68v18.83    c0,1.1046,0.8954,2,2,2h32C42.15495,36.0447,43.05035,35.1493,43.05035,34.0447z" />
        <path d="M23.95395,9.4198c-0.3906,0.3906-0.3906,1.0235,0,1.4141c0.3906,0.3906,1.0234,0.3906,1.4141,0l4-4    c0.3906-0.3906,0.3906-1.0235,0-1.4141l-4-4c-0.3907-0.3906-1.0235-0.3906-1.4141,0c-0.3906,0.3906-0.3906,1.0235,0,1.4141    l2.293,2.293h-22.211c-1.6543,0-3,1.3457-3,3v10.7334c0,0.5522,0.4478,1,1,1c0.5523,0,1-0.4478,1-1V8.1269c0-0.5513,0.4488-1,1-1    h22.211L23.95395,9.4198z" />
        <path d="M47.96405,30.1397c-0.55219,0-1,0.4478-1,1v10.7334c0,0.5513-0.4487,1-1,1h-22.2109l2.293-2.2929    c0.3906-0.3907,0.3906-1.0235,0-1.4141c-0.3907-0.3906-1.0235-0.3906-1.4141,0l-4,4c-0.3906,0.3906-0.3906,1.0234,0,1.4141l4,4    c0.3906,0.3906,1.0234,0.3906,1.4141,0c0.3906-0.3907,0.3906-1.0235,0-1.4141l-2.293-2.293h22.2109c1.6543,0,3-1.3457,3-3V31.1397    C48.96405,30.5875,48.51635,30.1397,47.96405,30.1397z" />
      </g>
      <g>
        <polygon points="25.05035,23.8747 41.14025,14.0447 8.96025,14.0447   " />
        <path d="M43.05035,34.0447v-18.83l-17.48,10.68c-0.16,0.1-0.34,0.15-0.52,0.15s-0.36-0.05-0.52-0.15l-17.48-10.68v18.83    c0,1.1046,0.8954,2,2,2h32C42.15495,36.0447,43.05035,35.1493,43.05035,34.0447z" />
        <path d="M23.95395,9.4198c-0.3906,0.3906-0.3906,1.0235,0,1.4141c0.3906,0.3906,1.0234,0.3906,1.4141,0l4-4    c0.3906-0.3906,0.3906-1.0235,0-1.4141l-4-4c-0.3907-0.3906-1.0235-0.3906-1.4141,0c-0.3906,0.3906-0.3906,1.0235,0,1.4141    l2.293,2.293h-22.211c-1.6543,0-3,1.3457-3,3v10.7334c0,0.5522,0.4478,1,1,1c0.5523,0,1-0.4478,1-1V8.1269c0-0.5513,0.4488-1,1-1    h22.211L23.95395,9.4198z" />
        <path d="M47.96405,30.1397c-0.55219,0-1,0.4478-1,1v10.7334c0,0.5513-0.4487,1-1,1h-22.2109l2.293-2.2929    c0.3906-0.3907,0.3906-1.0235,0-1.4141c-0.3907-0.3906-1.0235-0.3906-1.4141,0l-4,4c-0.3906,0.3906-0.3906,1.0234,0,1.4141l4,4    c0.3906,0.3906,1.0234,0.3906,1.4141,0c0.3906-0.3907,0.3906-1.0235,0-1.4141l-2.293-2.293h22.2109c1.6543,0,3-1.3457,3-3V31.1397    C48.96405,30.5875,48.51635,30.1397,47.96405,30.1397z" />
      </g>
    </g>
  </Base>
);
