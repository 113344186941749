import api from 'shared/api';
import { get } from 'lodash';
import * as actionTypes from './actionTypes';

// company actions
const getCompanyInit = companyId => ({
  type: actionTypes.getCompanyInit,
  company: parseInt(companyId, 10)
});

export const getCompanySuccess = res => ({
  type: actionTypes.getCompanySuccess,
  company: res,
  products: get(res, 'products')
});

const getCompanyError = res => ({
  type: actionTypes.getCompanyError,
  payload: res
});

export const getCompany = companyId => dispatch => {
  if (companyId) {
    dispatch(getCompanyInit(companyId));
  }
  return api.get(`/api/v1/companies/${companyId}/`).then(
    res => dispatch(getCompanySuccess(get(res, 'data'))),
    res => dispatch(getCompanyError(get(res, 'data')))
  );
};

export const getCompanySilent = companyId => dispatch =>
  api.get(`/api/v1/companies/${companyId}/`).then(
    res => dispatch(getCompanySuccess(get(res, 'data'))),
    res => dispatch(getCompanyError(get(res, 'data')))
  );

export const getCompanyFeaturesSuccess = res => ({
  type: actionTypes.getCompanyFeaturesSuccess,
  features: get(res, 'results')
});

const getCompanyFeaturesError = () => ({
  type: actionTypes.getCompanyError
});

export const getCompanyFeatures = companyId => dispatch =>
  api.get(`/api/v1/features/?company=${companyId}`).then(
    res => dispatch(getCompanyFeaturesSuccess(get(res, 'data'))),
    res => dispatch(getCompanyFeaturesError(get(res, 'data')))
  );

export const clearActiveCompany = () => dispatch => {
  dispatch({ type: actionTypes.clearActiveCompany });
};

export const clearCompanyState = () => dispatch => {
  dispatch({ type: actionTypes.clearCompanyState });
};

const getUserPreferencesSuccess = userPrefs => ({
  type: actionTypes.getUserPreferencesSuccess,
  payload: userPrefs
});

const getUserPreferencesError = error => ({
  type: actionTypes.getUserPreferencesError,
  payload: error
});

export const getUserPreferences = (userId, companyId) => dispatch => {
  dispatch({ type: actionTypes.getUserPreferencesInit });
  return api.get(`/api/v1/users/${userId}?company=${companyId}`).then(
    res => dispatch(getUserPreferencesSuccess(get(res, 'data'))),
    err => dispatch(getUserPreferencesError(err))
  );
};

const getManagerPermissionsSuccess = managerPerm => ({
  type: actionTypes.getManagerPermissionsSuccess,
  payload: managerPerm
});

const getManagerPermissionsError = error => ({
  type: actionTypes.getManagerPermissionsError,
  payload: error
});

export const getManagerPermissions = companyId => dispatch => {
  dispatch({ type: actionTypes.getManagerPermissionsInit });
  return api
    .get(`/api/v1/license-manager-permissions/?company=${companyId}`)
    .then(
      res => dispatch(getManagerPermissionsSuccess(get(res, 'data.results'))),
      err => dispatch(getManagerPermissionsError(err))
    );
};

// CUSTOMER LABELS actions
const getCustomerLabelsInit = () => ({
  type: actionTypes.getCustomerLabelsInit
});

const getCustomerLabelsSuccess = res => ({
  type: actionTypes.getCustomerLabelsSuccess,
  payload: res
});

const getCustomerLabelsError = res => ({
  type: actionTypes.getCustomerLabelsError,
  payload: res
});

export const getCustomerLabels = companyID => dispatch => {
  dispatch(getCustomerLabelsInit());
  const url = `/api/v1/clabels/?company=${companyID}`;
  return api.get(url).then(
    res => dispatch(getCustomerLabelsSuccess(get(res, 'data.results') || [])),
    res => dispatch(getCustomerLabelsError(res.data))
  );
};

const updateCustomerLabelsList = details => ({
  type: actionTypes.addNewCustomerLabel,
  payload: details
});

const removeLabel = details => ({
  type: actionTypes.removeLabel,
  payload: details
});

const updateLabel = details => ({
  type: actionTypes.updateLabel,
  payload: details
});

export const addNewCustomerLabel = details => dispatch => {
  dispatch(updateCustomerLabelsList(details));
};

export const removeCustomerLabel = details => dispatch => {
  dispatch(removeLabel(details));
};

export const updateCustomerLabel = details => dispatch => {
  dispatch(updateLabel(details));
};

// USER LABELS actions
const getUserLabelsInit = () => ({
  type: actionTypes.getUserLabelsInit
});

const getUserLabelsSuccess = res => ({
  type: actionTypes.getUserLabelsSuccess,
  payload: res
});

const getUserLabelsError = res => ({
  type: actionTypes.getUserLabelsError,
  payload: res
});

export const getUserLabels = companyID => dispatch => {
  dispatch(getUserLabelsInit());
  const url = `/api/v1/user-roles/?company=${companyID}`;
  return api.get(url).then(
    res => dispatch(getUserLabelsSuccess(get(res, 'data.results') || [])),
    res => dispatch(getUserLabelsError(res.data))
  );
};

const updateUserLabelsList = details => ({
  type: actionTypes.addNewUserLabel,
  payload: details
});

const removeUserLabelAction = details => ({
  type: actionTypes.removeUserLabel,
  payload: details
});

const updateUserLabelAction = details => ({
  type: actionTypes.updateUserLabel,
  payload: details
});

export const addNewUserLabel = details => dispatch => {
  dispatch(updateUserLabelsList(details));
};

export const removeUserLabel = details => dispatch => {
  dispatch(removeUserLabelAction(details));
};

export const updateUserLabel = details => dispatch => {
  dispatch(updateUserLabelAction(details));
};

// permissions actions
const getPermissionsInit = () => ({
  type: actionTypes.getPermissionsInit
});

const getPermissionsSuccess = res => ({
  type: actionTypes.getPermissionsSuccess,
  payload: res
});

const getPermissionsError = res => ({
  type: actionTypes.getPermissionsError,
  payload: res
});

export const getPermissions = companyID => dispatch => {
  dispatch(getPermissionsInit());
  const url = `/api/v1/user-permissions/all_permissions/?company=${companyID}`;
  return api.get(url).then(
    res => dispatch(getPermissionsSuccess(get(res, 'data') || [])),
    res => dispatch(getPermissionsError(res.data))
  );
};

// user permissions actions
const getUserPermissionsInit = () => ({
  type: actionTypes.getUserPermissionsInit
});

const getUserPermissionsSuccess = res => ({
  type: actionTypes.getUserPermissionsSuccess,
  payload: res
});

const getUserPermissionsError = res => ({
  type: actionTypes.getUserPermissionsError,
  payload: res
});

export const getUserPermissions = (userID, companyID) => dispatch => {
  dispatch(getUserPermissionsInit());
  const url = `/api/v1/user-permissions/?company=${companyID}&user=${userID}`;
  return api.get(url).then(
    res => dispatch(getUserPermissionsSuccess(get(res, 'data') || [])),
    res => dispatch(getUserPermissionsError(res.data))
  );
};

export const getPlatformUserPermissions = (userID, companyID) =>
  api.get(`/api/v1/user-permissions/?company=${companyID}&user=${userID}`);

export const setUserPermissions = (userID, companyID, data) =>
  api.post(
    `/api/v1/user-permissions/?company=${companyID}&user=${userID}`,
    data
  );

// na postojece doda sve kaj je u templateu a vec nema na sebi
export const addUserPermissionsFromTemplate = (userID, companyID, data) =>
  api.post(
    `/api/v1/user-permissions/add_from_template/?company=${companyID}&user=${userID}`,
    data
  );

// overwritea postojece s tima koji su u templateu
export const setUserPermissionsFromTemplate = (userID, companyID, data) =>
  api.post(
    `/api/v1/user-permissions/set_from_template/?company=${companyID}&user=${userID}`,
    data
  );

// user permission templates actions
const getUserPermissionsTemplatesInit = () => ({
  type: actionTypes.getUserPermissionsTemplatesInit
});

const getUserPermissionsTemplatesSuccess = res => ({
  type: actionTypes.getUserPermissionsTemplatesSuccess,
  payload: res
});

const getUserPermissionsTemplatesError = res => ({
  type: actionTypes.getUserPermissionsTemplatesError,
  payload: res
});

export const getUserPermissionsTemplates = companyID => dispatch => {
  dispatch(getUserPermissionsTemplatesInit());
  const url = `/api/v1/user-permission-templates/?company=${companyID}`;
  return api.get(url).then(
    res =>
      dispatch(
        getUserPermissionsTemplatesSuccess(get(res, 'data.results') || [])
      ),
    res => dispatch(getUserPermissionsTemplatesError(res.data))
  );
};

export const addUserPermissionTemplate = (companyID, data) =>
  api.post(`/api/v1/user-permission-templates/?company=${companyID}`, data);

export const editUserPermissionTemplateName = (templateID, companyID, data) =>
  api.patch(
    `/api/v1/user-permission-templates/${templateID}/?company=${companyID}`,
    data
  );

export const deleteUserPermissionTemplate = (templateID, companyID) =>
  api.delete(
    `/api/v1/user-permission-templates/${templateID}/?company=${companyID}`
  );

export const setUserPermissionTemplate = (templateID, companyID, data) =>
  api.post(
    `/api/v1/user-permission-templates/${templateID}/set_permissions/?company=${companyID}`,
    data
  );

// ui options actions
export const updateUserPreferences = res => ({
  type: actionTypes.updateUserPreferences,
  payload: res
});

const refreshCompanyData = companyData => ({
  type: actionTypes.refreshCompanyData,
  company: companyData
});

const refreshCardData = cardData => ({
  type: actionTypes.refreshCardData,
  payment_last4: cardData.payment_last4,
  payment_expires: cardData.payment_expires,
  payment_card_type: cardData.payment_card_type,
  stripe_tax_id: cardData.stripe_tax_id,
  subscription_cycle: cardData.subscription_cycle
});

const refreshTrialDays = trialDays => ({
  type: actionTypes.refreshTrialDays,
  trial_days: trialDays
});

export const updateCompanyData = companyData => dispatch => {
  dispatch(refreshCompanyData(companyData));
};

export const changeCardData = cardData => dispatch => {
  dispatch(refreshCardData(cardData));
};

export const changeTrialDays = trialDays => dispatch => {
  dispatch(refreshTrialDays(trialDays));
};

// portal data
export const updatePortalData = res => ({
  type: actionTypes.updatePortalData,
  payload: res
});

// integrations

const getAccountIntegrationsInit = () => ({
  type: actionTypes.getAccountIntegrationsInit
});

const getAccountIntegrationsSuccess = res => ({
  type: actionTypes.getAccountIntegrationsSuccess,
  payload: res
});

const getAccountIntegrationsError = res => ({
  type: actionTypes.getAccountIntegrationsError,
  payload: res
});

export const getAccountIntegrations = companyID => dispatch => {
  dispatch(getAccountIntegrationsInit());
  const url = `/api/v1/integrations/?company=${companyID}`;
  return api.get(url).then(
    res =>
      dispatch(getAccountIntegrationsSuccess(get(res, 'data.results') || [])),
    res => dispatch(getAccountIntegrationsError(res.data))
  );
};

export const addIntegration = (companyID, data) =>
  api.post(`/api/v1/integrations/?company=${companyID}`, data);

export const updateIntegration = (integrationID, companyID, data) =>
  api.patch(
    `/api/v1/integrations/${integrationID}/?company=${companyID}`,
    data
  );

// ----------------------------
export const getInactiveCompanies = () =>
  api.get('/api/v1/companies/?slim=1&is_active=0&limit=100000');

export const reactivateCompany = (companyID, data) =>
  api.post(`/api/v1/companies/${companyID}/reactivate/`, data);

export const registerNewCompany = data =>
  api.post('/api/v1/auth/register/', data);

export const generateAccountsReport = data =>
  api.post('/api/v1/analyze-companies/', data);

export const getCompanyCustomerDetails = companyID =>
  api.get(`/api/v1/companies/${companyID}/customer_details`);
