import React, { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  DirtyFormAlert,
  Modal,
  Label,
  DateInput,
  Notice,
  Notification,
  RadioBtn
} from 'shared/components';
import { validateDate } from 'shared/validation';
import { validityPeriodTimeFormat, APIdateFormat } from 'shared/constants';
import { sendErrorReport } from 'shared/helpers';
import { borrowDevice } from 'src/license/actions';
import './styles.scss';

const BorrowLicenseForm = ({
  closeCb,
  confirmCb,
  companyID,
  device,
  license
}) => {
  const maxBorrowTime = get(license, 'max_borrow_time') || 0;
  const initialDate = get(device, 'borrowed_until');
  const initialBorrowTime = initialDate
    ? moment(initialDate).format(APIdateFormat)
    : null;
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(true);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);
  const [borrowUntil, setBorrowUntil] = useState(initialBorrowTime);
  const [borrowUntilError, setBorrowUntilError] = useState('');
  const [selectedBorrowType, setSelectedBorrowType] = useState('max');

  const validateDateField = async (val, cb, isOptional = false) => {
    if (selectedBorrowType === 'max') {
      return true;
    }
    if (!val && isOptional) {
      cb('');
      return true;
    }

    setLoading(true);
    let errors;

    try {
      errors = await validateDate(val);
      setLoading(false);
      cb(errors);
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate borrowed_until date', {
        value: val
      });
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const validateMaxBorrowTimeValid = () => {
    if (selectedBorrowType === 'max') {
      return true;
    }
    const hoursDiff = moment(borrowUntil).diff(moment(), 'hours', true);
    const isBiggerThanAllowed = hoursDiff > maxBorrowTime;
    if (hoursDiff <= 0) {
      Notification('error', __('Only future dates can be selected'));
      return false;
    }
    if (isBiggerThanAllowed) {
      Notification(
        'error',
        __('Selected time period is longer than license max borrow time.')
      );
      return false;
    }
    return true;
  };

  const isFormValid = async () => {
    const isValid = await validateDateField(borrowUntil, setBorrowUntilError);
    const isBorrowRangeValid = validateMaxBorrowTimeValid();
    return isValid && isBorrowRangeValid;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!isDirty) {
      closeCb();
      return false;
    }

    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setLoading(true);
    const deviceID = get(device, 'id');
    const datetime = moment(borrowUntil).format(validityPeriodTimeFormat);
    const data = {
      borrowed_until: selectedBorrowType === 'max' ? null : datetime
    };
    borrowDevice(deviceID, companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        confirmCb();
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot borrow license device');
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
    return true;
  };

  const handleSelectedBorrowType = val => {
    setDirty(true);
    setSelectedBorrowType(val);
    setBorrowUntil(initialBorrowTime);
    setBorrowUntilError('');
  };

  const handleClose = () => {
    if (!isDirty) {
      return closeCb();
    }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      title={__('Borrow license')}
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      size="sm"
    >
      <div className="BorrowLicenseForm">
        <Notice>{`${__('Max borrow time')}: ${maxBorrowTime} ${__(
          'hours'
        )}`}</Notice>
        <div className="row">
          <RadioBtn
            name="borrow/type/select"
            inputId="borrow/max"
            label={__('Borrow for max allowed period')}
            value="max"
            checked={selectedBorrowType === 'max'}
            handleChange={handleSelectedBorrowType}
          />
          <RadioBtn
            name="borrow/type/select"
            inputId="borrow-date"
            label={__('Input borrow end date')}
            value="date"
            checked={selectedBorrowType !== 'max'}
            handleChange={handleSelectedBorrowType}
          />
          {selectedBorrowType !== 'max' && (
            <div>
              <Label text={__('Borrow until')} inputId="borrow-until" />
              <DateInput
                handleChange={val => {
                  setDirty(true);
                  setBorrowUntil(val);
                }}
                value={borrowUntil}
                error={borrowUntilError}
                id="borrow-until"
              />
            </div>
          )}
        </div>
        {isDirtyFormDisplayed && (
          <DirtyFormAlert
            dirty={isDirty}
            closeAlert={() => setDirtyFormDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </Modal>
  );
};

BorrowLicenseForm.propTypes = {
  companyID: PropTypes.number.isRequired,
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  license: PropTypes.object.isRequired
};

export default BorrowLicenseForm;
