import React, { useEffect } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';
import {
  ContentLoader,
  GlobalError,
  NotFound,
  Page,
  Sidebar,
  IsAuthorized
} from 'shared/components';
import {
  getCompany,
  getUserPreferences,
  getCustomerLabels,
  getUserLabels,
  clearActiveCompany,
  getManagerPermissions,
  getPermissions,
  getUserPermissionsTemplates,
  getAccountIntegrations
} from 'src/company/actions';
import {
  getCoupons,
  getPricingPlans,
  getStandardPricingPlans,
  getTaxRates
} from 'src/billing/actions';
import { getAllProducts, getProductsInit } from 'src/product/actions';
import { getUsers, getInvoices } from 'src/account/actions';
import {
  getEvents,
  getAvailableWebhookEvents
} from 'src/notifications/actions';
import CustomersContainer from 'src/customer/CustomersContainer';
import OrdersContainer from 'src/order/OrdersContainer';
import ProductsContainer from 'src/product/ProductsContainer';
import AccountContainer from 'src/account/AccountContainer';
import Dashboard from 'src/dashboard/Dashboard';
import LicensesContainer from 'src/license/LicensesContainer';
import BillingConfiguration from 'src/billing/BillingConfiguration';
import AnalyticsContainer from 'src/analytics/AnalyticsContainer';
import { getUserFromStorage } from 'src/keycloak/authUser';
import CompanySidebar from '../components/CompanySidebar';

const CompanyContainer = () => {
  const isSelfHosted = process.env.REACT_APP_SELF_HOSTED === 'true';
  const { companyId } = useParams();
  const dispatch = useDispatch();
  // We are using active company from account store because there is initial company id based on which we fetch actual company details
  const activeCompanyId = useSelector(state =>
    get(state, 'account.activeCompany.id')
  );
  const invoicesLoading = useSelector(state =>
    get(state, 'account.invoicesLoading')
  );
  const company = useSelector(state => get(state, 'company.details'));
  const loading = useSelector(state => get(state, 'company.loading'));

  const user = getUserFromStorage();
  const userID = get(user, 'profile.platform_user_id');
  const isStaff = get(user, 'profile.platform_is_staff');

  const userPreferencesLoading = useSelector(state =>
    get(state, 'company.userPreferencesLoading')
  );
  const managerPermissionsLoading = useSelector(state =>
    get(state, 'company.managerPermissionsLoading')
  );
  const loadingCustomerLabels = useSelector(state =>
    get(state, 'company.loadingCustomerLabels')
  );
  const loadingUserLabels = useSelector(state =>
    get(state, 'company.loadingUserLabels')
  );
  const loadingProducts = useSelector(state => get(state, 'products.loading'));

  useEffect(() => {
    if (companyId) {
      dispatch(getCompany(companyId));
      dispatch(getProductsInit());
      dispatch(getAllProducts(companyId));
      dispatch(getUserPreferences(userID, companyId));
      dispatch(getEvents(companyId));
      dispatch(getCustomerLabels(companyId));
      dispatch(getUserLabels(companyId));
      dispatch(getManagerPermissions(companyId));
      dispatch(getPermissions(companyId));
      dispatch(getUserPermissionsTemplates(companyId));
      dispatch(getAccountIntegrations(companyId));
      dispatch(getAvailableWebhookEvents(companyId));
      dispatch(getUsers(companyId));
      dispatch(getInvoices(companyId));
      if (!isSelfHosted && isStaff) {
        dispatch(getCoupons(companyId));
        dispatch(getPricingPlans(companyId));
        dispatch(getStandardPricingPlans(companyId));
        dispatch(getTaxRates(companyId));
      }
    }
    return () => dispatch(clearActiveCompany());
  }, [companyId, userID, isSelfHosted]);

  const isLoading = isSelfHosted
    ? loading ||
      userPreferencesLoading ||
      managerPermissionsLoading ||
      loadingCustomerLabels ||
      loadingUserLabels ||
      loadingProducts
    : loading ||
      userPreferencesLoading ||
      managerPermissionsLoading ||
      invoicesLoading ||
      loadingCustomerLabels ||
      loadingUserLabels ||
      loadingProducts;

  if (isLoading) {
    return (
      <div style={{ paddingTop: '80px' }}>
        <ContentLoader text={__('Getting organization details')} />
      </div>
    );
  }

  if (!get(company, 'id')) {
    return <NotFound />;
  }
  if (!get(company, 'is_active')) {
    return <NotFound />;
  }
  if (!activeCompanyId) {
    return <GlobalError />;
  }

  return (
    <section className="CompanyContainer">
      <Sidebar>
        <CompanySidebar activeCompany={activeCompanyId} company={company} />
      </Sidebar>
      <Switch>
        <Route
          exact
          path="/:companyId"
          render={() => (
            <Dashboard
              company={company}
              activeCompany={activeCompanyId}
              name={get(user, 'profile.given_name')}
              user={user}
            />
          )}
        />
        <Route path="/:companyId/orders" component={OrdersContainer} />
        <Route path="/:companyId/licenses" component={LicensesContainer} />
        <Route path="/:companyId/customers" component={CustomersContainer} />
        <Route path="/:companyId/products" component={ProductsContainer} />
        <Route path="/:companyId/analytics" component={AnalyticsContainer} />
        <Route path="/:companyId/account" component={AccountContainer} />
        <Route
          path="/:companyId/billing-configuration"
          render={() => (
            <IsAuthorized useRole="staff">
              <BillingConfiguration />
            </IsAuthorized>
          )}
        />
        <Route
          render={() => (
            <Page>
              <NotFound />
            </Page>
          )}
        />
      </Switch>
    </section>
  );
};

export default CompanyContainer;
