import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Label,
  TextInput,
  NumberInput
} from 'shared/components';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
  validateProductName,
  debouncedValidateProductName,
  validateProductShortCode,
  debouncedValidateProductShortCode,
  validateLink,
  debouncedValidateLink,
  validateDate,
  debouncedValidateDate,
  validateRequiredNumber,
  debouncedValidateRequiredNumber
} from 'shared/validation';
import { environmentOptions, authMethods } from 'shared/constants';
import { sendErrorReport } from 'shared/helpers';
import { checkCompanyConfigField } from 'shared/companyConfig';
import {
  InstallationFileOptions,
  ProductAuthorizationMethodSelector,
  TrialOptions
} from './components';
import './styles.scss';

const ProductInformations = ({
  companyID,
  companyPlan,
  formState,
  handleSubmit,
  setDirty
}) => {
  const products = useSelector(state => get(state, 'products.list') || []);
  const archivedProducts = useSelector(
    state => get(state, 'products.archived') || []
  );
  const isEnterprise = companyPlan === 'enterprise';

  const [isLoading, setLoading] = useState(false);
  const [productName, setProductName] = useState(
    get(formState, 'product_name')
  );
  const [productNameError, setProductNameError] = useState('');
  const [productShortCode, setProductShortCode] = useState(
    get(formState, 'short_code')
  );
  const [productShortCodeError, setProductShortCodeError] = useState('');
  const [productShortCodeLoading, setProductShortCodeLoading] = useState(false);
  const [authorizationMethod, setAuthorizationMethod] = useState(
    get(formState, 'authorization_method') || authMethods.key
  );
  const [floatingTimeout, setFloatingTimeout] = useState(
    get(formState, 'floating_timeout') || 120
  );
  const [floatingTimeoutError, setFloatingTimeoutError] = useState('');
  // trial
  const [allowTrial, setAllowTrial] = useState(
    get(formState, 'allow_trial') || false
  );
  const [trialDuration, setTrialDuration] = useState(
    get(formState, 'trial_days') || 1
  );
  const [trialDurationError, setTrialDurationError] = useState('');
  // product file
  const [productHasFile, setProductHasFile] = useState(
    !!get(formState, 'installation_files[0]')
  );
  const [productEnvironment, setProductEnvironment] = useState(
    get(formState, 'installation_files[0].environment') || ''
  );
  const [productEnvironmentError, setProductEnvironmentError] = useState('');
  const [productVersion, setProductVersion] = useState(
    get(formState, 'installation_files[0].version') || ''
  );
  const [productVersionError, setProductVersionError] = useState('');
  const [productReleaseDate, setProductReleaseDate] = useState(
    get(formState, 'installation_files[0].release_date') || ''
  );
  const [productReleaseDateError, setProductReleaseDateError] = useState('');
  const [productLinkToBinary, setProductLinkToBinary] = useState(
    get(formState, 'installation_files[0].full_link') || ''
  );
  const [productLinkToBinaryError, setProductLinkToBinaryError] = useState('');
  const [releaseNotesLink, setReleaseNotesLink] = useState(
    get(formState, 'installation_files[0].release_notes_link') || ''
  );
  const [releaseNotesLinkError, setReleaseNotesLinkError] = useState('');
  const [eulaLink, setEulaLink] = useState(
    get(formState, 'installation_files[0].eula_link') || ''
  );
  const [eulaLinkError, setEulaLinkError] = useState('');
  const [channel, setChannel] = useState(
    get(formState, 'installation_files[0].channel') || ''
  );

  const validateValue = async (val, cb) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new product form value', {
        value: val
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validateNumberValue = async (val, cb, includeZero) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateRequiredNumber(val, includeZero);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new product form value', {
        value: val
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const handleProductNameValidation = async (val, cb) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateProductName(val, products, archivedProducts);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new product name', { value: val });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const handleProductCodeValidation = async (val, cb) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateProductShortCode(val, companyID);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new product code', { value: val });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const handleReleaseDateValidation = async (val, cb) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateDate(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new product release date', {
        value: val
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const handleLinkValidation = async (val, cb, required = true) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateLink(val, required);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new product link to binary', {
        value: val
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const handleInstallationFileValidation = async () => {
    if (!productHasFile) {
      return true;
    }
    const isEnvValid = await validateValue(
      productEnvironment,
      setProductEnvironmentError
    );
    const isVersionValid = await validateValue(
      productVersion,
      setProductVersionError
    );
    const isReleaseDateValid = await handleReleaseDateValidation(
      productReleaseDate,
      setProductReleaseDateError
    );
    const isFileLinkValid = await handleLinkValidation(
      productLinkToBinary,
      setProductLinkToBinaryError,
      false
    );
    const isReleaseNotesLinkValid = await handleLinkValidation(
      releaseNotesLink,
      setReleaseNotesLinkError,
      false
    );
    const isEulaLinkValid = await handleLinkValidation(
      eulaLink,
      setEulaLinkError,
      false
    );
    return (
      isEnvValid &&
      isVersionValid &&
      isReleaseDateValid &&
      isFileLinkValid &&
      isReleaseNotesLinkValid &&
      isEulaLinkValid
    );
  };

  const isFormValid = async () => {
    const isProductNameValid = await handleProductNameValidation(
      productName,
      setProductNameError
    );
    const isProductCodeValid = await handleProductCodeValidation(
      productShortCode,
      setProductShortCodeError
    );
    const isTrialDurationValid = allowTrial
      ? await validateNumberValue(trialDuration, setTrialDurationError)
      : true;
    const isFloatingTimeoutValid = await validateNumberValue(
      floatingTimeout,
      setFloatingTimeoutError
    );
    const isInstallationFileDataValid = await handleInstallationFileValidation();
    return (
      isProductNameValid &&
      isProductCodeValid &&
      isTrialDurationValid &&
      isFloatingTimeoutValid &&
      isInstallationFileDataValid
    );
  };

  const handleFormSubmit = async e => {
    e.preventDefault();

    const isDataValid = await isFormValid();
    if (!isDataValid || isLoading) {
      return false;
    }

    const fileData = {
      environment: productEnvironment,
      version: productVersion,
      release_date: productReleaseDate,
      full_link: productLinkToBinary,
      release_notes_link: releaseNotesLink,
      eula_link: eulaLink,
      channel: channel || ''
    };
    const instalationFile = !productHasFile ? undefined : [fileData];
    const data = {
      product_name: productName,
      short_code: productShortCode,
      allow_trial: allowTrial,
      trial_days: allowTrial ? Number(trialDuration) : 0,
      authorization_method: authorizationMethod,
      floating_timeout: Number(floatingTimeout),
      company: companyID,
      installation_files: instalationFile
    };
    handleSubmit(data);
    return true;
  };

  return (
    <div className="ProductInformations">
      <form className="ProductInformations-form" onSubmit={handleFormSubmit}>
        <div className="form-row">
          <div className="form-column product-name">
            <Label text={__('Product Name')} inputId="product-name" />
            <TextInput
              id="product-name"
              value={productName}
              error={productNameError}
              handleChange={val => {
                setDirty(true);
                setProductName(val);
                debouncedValidateProductName(
                  val,
                  products,
                  archivedProducts
                ).then(err => setProductNameError(err));
              }}
              placeholder={__('Enter product name')}
            />
          </div>
          <div className="form-column right-input">
            <Label
              text={__('Product code')}
              inputId="product-shortcode"
              description={__('Only letters and numbers are allowed')}
            />
            <TextInput
              id="product-shortcode"
              value={productShortCode}
              error={productShortCodeError}
              loading={productShortCodeLoading}
              handleChange={val => {
                const alphanumericValue = val.replace(/[^a-zA-Z0-9]/g, '');
                setDirty(true);
                setProductShortCodeLoading(true);
                setProductShortCode(alphanumericValue);
                debouncedValidateProductShortCode(
                  alphanumericValue,
                  companyID
                ).then(err => {
                  setProductShortCodeError(err);
                  setProductShortCodeLoading(false);
                });
              }}
              placeholder={__('Eg. QQ')}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-column">
            <Label
              text={__('Authorization method')}
              inputId="authorization-method"
            />
            <ProductAuthorizationMethodSelector
              selectedMethod={authorizationMethod}
              handleMethodChange={val => setAuthorizationMethod(val)}
              disabled={isLoading}
            />
          </div>
          {isEnterprise && (
            <div className="form-column right-input">
              <Label
                text={__('Floating timeout')}
                description={__(
                  'If the license issued is a floating license, the floating timeout is the time interval in minutes that the end users application will need to perform a license check in order to remain registered to the license.'
                )}
              />
              <NumberInput
                min="1"
                max="2147483647"
                handleChange={val => {
                  setFloatingTimeout(val);
                  debouncedValidateRequiredNumber(val).then(err =>
                    setFloatingTimeoutError(err)
                  );
                }}
                value={floatingTimeout}
                error={floatingTimeoutError}
              />
            </div>
          )}
        </div>
        {checkCompanyConfigField(companyID, 'isTrial') && (
          <div className="form-row file-checkbox">
            <Checkbox
              label={__('Product has trial period')}
              inputId="product-has-trial"
              checked={allowTrial}
              handleChange={val => {
                setAllowTrial(val);
                setTrialDuration(1);
                setTrialDurationError('');
              }}
            />
          </div>
        )}
        {allowTrial && (
          <TrialOptions
            trialDuration={trialDuration}
            trialDurationError={trialDurationError}
            handleTrialDuration={val => {
              setTrialDuration(val);
              debouncedValidateRequiredNumber(val).then(err =>
                setTrialDurationError(err)
              );
            }}
          />
        )}
        <div className="form-row file-checkbox">
          <div className="form-column">
            <Checkbox
              label={__('Product has file')}
              inputId="product-has-file"
              checked={productHasFile}
              handleChange={val => {
                setDirty(true);
                setProductHasFile(val);
                setProductEnvironment('');
                setProductEnvironmentError('');
                setProductVersion('');
                setProductVersionError('');
                setProductReleaseDate('');
                setProductReleaseDateError('');
                setProductLinkToBinary('');
                setProductLinkToBinaryError('');
                setReleaseNotesLink('');
                setReleaseNotesLinkError('');
                setEulaLink('');
                setEulaLinkError('');
              }}
            />
          </div>
        </div>
        {productHasFile && (
          <InstallationFileOptions
            environmentOptions={environmentOptions}
            productEnvironment={productEnvironment}
            productEnvironmentError={productEnvironmentError}
            handleProductEnvironmentChange={val => {
              setProductEnvironment(val);
              setProductEnvironmentError('');
            }}
            productVersion={productVersion}
            productVersionError={productVersionError}
            handleProductVersionChange={val => {
              setProductVersion(val);
              debouncedValidateRequiredValue(val).then(err =>
                setProductVersionError(err)
              );
            }}
            productReleaseDate={productReleaseDate}
            productReleaseDateError={productReleaseDateError}
            handleProductReleaseDateChange={val => {
              setProductReleaseDate(val);
              debouncedValidateDate(val).then(err =>
                setProductReleaseDateError(err)
              );
            }}
            productLinkToBinary={productLinkToBinary}
            productLinkToBinaryError={productLinkToBinaryError}
            handleProductLinkToBinaryChange={val => {
              setProductLinkToBinary(val);
              debouncedValidateLink(val, false).then(err =>
                setProductLinkToBinaryError(err)
              );
            }}
            releaseNotesLink={releaseNotesLink}
            releaseNotesLinkError={releaseNotesLinkError}
            handleRelaseNotesLinkChange={val => {
              setReleaseNotesLink(val);
              debouncedValidateLink(val, false).then(err =>
                setReleaseNotesLinkError(err)
              );
            }}
            eulaLink={eulaLink}
            eulaLinkError={eulaLinkError}
            handleEulaLinkChange={val => {
              setEulaLink(val);
              debouncedValidateLink(val, false).then(err =>
                setEulaLinkError(err)
              );
            }}
            channel={channel}
            handleChannelChange={val => {
              setChannel(val);
            }}
          />
        )}
        <div className="ProductInformations-actions">
          <Button
            theme="info"
            size="lg"
            onClick={handleFormSubmit}
            disabled={isLoading || productShortCodeLoading}
            type="submit"
          >
            {__('Next')}
          </Button>
        </div>
      </form>
    </div>
  );
};

ProductInformations.propTypes = {
  companyID: PropTypes.number.isRequired,
  companyPlan: PropTypes.string.isRequired,
  formState: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired
};

export default ProductInformations;
