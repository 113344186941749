import { get } from 'lodash';
import {
  formatDate,
  formatAmountValue,
  getSelectedPricingPlan
} from 'shared/helpers';
import { defaultDateFormat } from 'shared/constants';

export const mapCurrencySymbol = val => {
  if (val === 'usd') {
    return '$';
  }
  if (val === 'cad') {
    return 'CAD$';
  }
  if (val === 'eur') {
    return '€';
  }

  return '';
};

export const showUnitAmount = (
  company,
  enterprisePricingPlans,
  standardPricingPlans
) => {
  const companyPlanType = get(company, 'plan_type');
  let pricingPlan;

  if (companyPlanType === 'enterprise') {
    pricingPlan = getSelectedPricingPlan(
      get(company, 'upcoming_invoice.plan_id'),
      enterprisePricingPlans
    );
  }
  if (companyPlanType === 'standard') {
    const billingCycle = get(company, 'subscription_cycle');
    if (billingCycle === 'monthly') {
      pricingPlan = standardPricingPlans.find(sp => sp.interval === 'month');
    } else {
      pricingPlan = standardPricingPlans.find(sp => sp.interval === 'year');
    }
  }

  if (!pricingPlan) {
    return '-';
  }

  const totalAmount = get(pricingPlan, 'amount');
  const currency = get(company, 'upcoming_invoice.currency');

  if (!totalAmount && totalAmount !== 0) {
    return '-';
  }

  return `${formatAmountValue(totalAmount)} ${mapCurrencySymbol(currency)}`;
};

export const showBillingAmount = company => {
  const totalAmount = get(company, 'upcoming_invoice.total');
  const currency = get(company, 'upcoming_invoice.currency');

  if (!totalAmount && totalAmount !== 0) {
    return '-';
  }

  return `${formatAmountValue(totalAmount)} ${mapCurrencySymbol(currency)}`;
};

export const showTaxAmount = company => {
  const taxAmount = get(company, 'upcoming_invoice.tax');
  const currency = get(company, 'upcoming_invoice.currency');

  if (!taxAmount) {
    return '-';
  }

  return `${formatAmountValue(taxAmount)} ${mapCurrencySymbol(currency)}`;
};

export const showNextInvoiceDateValue = company => {
  const nextPaymentAttempt = get(
    company,
    'upcoming_invoice.next_payment_attempt'
  );
  const invoiceDate = get(company, 'upcoming_invoice.date');

  if (!invoiceDate && !nextPaymentAttempt) {
    return '-';
  }

  if (nextPaymentAttempt) {
    return formatDate(nextPaymentAttempt, defaultDateFormat);
  }

  return formatDate(invoiceDate, defaultDateFormat);
};
