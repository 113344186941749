import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const ContentLoader = ({ text }) => (
  <div className="ContentLoader">
    <div className="ContentLoader-spinner" />
    {text && <div className="ContentLoader-text">{text}</div>}
  </div>
);

ContentLoader.propTypes = {
  text: PropTypes.string
};

ContentLoader.defaultProps = {
  text: ''
};

export default ContentLoader;
