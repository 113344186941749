import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { sendErrorReport } from 'shared/helpers';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput
} from 'shared/components';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue
} from 'shared/validation';
import { customFieldNameMaxLength } from 'shared/constants';
import { addCustomField, editCustomField } from 'src/product/actions';

const CustomFieldForm = ({
  closeCb,
  refetchCustomFields,
  updateProducts,
  productId,
  fieldToEdit,
  companyID
}) => {
  const initialName = get(fieldToEdit, 'name') || '';
  const initialValue = get(fieldToEdit, 'default_value') || '';

  const [isLoading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [fieldName, setFieldName] = useState(initialName);
  const [fieldNameError, setFieldNameError] = useState('');
  const [fieldValue, setFieldValue] = useState(initialValue);
  const [fieldValueError, setFieldValueError] = useState('');

  const validateValue = async (val, cb, maxLength = undefined) => {
    let errors;
    try {
      errors = await validateRequiredValue(val, maxLength);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate version form value', {
        value: val
      });
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const isFormValid = async () => {
    const isNameValid = await validateValue(
      fieldName,
      setFieldNameError,
      customFieldNameMaxLength
    );
    const isValueValid = await validateValue(fieldValue, setFieldValueError);
    return isNameValid && isValueValid;
  };

  const handleAddField = () => {
    const data = {
      name: fieldName,
      default_value: fieldValue,
      product: productId
    };
    setLoading(true);

    addCustomField(data, companyID)
      .then(() => {
        Notification(
          'success',
          __('Changes saved successfully'),
          __('Custom field added')
        );
        refetchCustomFields();
        updateProducts();
        closeCb();
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot add custom field on product', data);
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const handleEditField = () => {
    const fieldID = get(fieldToEdit, 'id');
    const data = { default_value: fieldValue, name: fieldName };
    setLoading(true);

    editCustomField(fieldID, data, companyID)
      .then(() => {
        Notification(
          'success',
          __('Changes saved successfully'),
          __('Custom field edited')
        );
        refetchCustomFields();
        updateProducts();
        closeCb();
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot edit custom field on product', data);
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    if (fieldToEdit) {
      handleEditField();
    } else {
      handleAddField();
    }
    return true;
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return setDirtyFormAlertDisplay(true);
  };

  const title = fieldToEdit ? __('Edit custom field') : __('Add custom field');

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      title={title}
      size="sm"
    >
      <div className="CustomFieldForm">
        <form className="custom-field-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <Label text={__('Name')} inputId="name" />
            <TextInput
              id="name"
              value={fieldName}
              error={fieldNameError}
              handleChange={val => {
                setDirty(true);
                setFieldName(val);
                debouncedValidateRequiredValue(
                  val,
                  customFieldNameMaxLength
                ).then(err => setFieldNameError(err));
              }}
              disabled={isLoading}
            />
          </div>
          <div className="form-row">
            <Label text={__('Value')} inputId="value" />
            <TextInput
              id="value"
              value={fieldValue}
              error={fieldValueError}
              handleChange={val => {
                setDirty(true);
                setFieldValue(val);
                debouncedValidateRequiredValue(val).then(err =>
                  setFieldValueError(err)
                );
              }}
              disabled={isLoading}
            />
          </div>
        </form>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

CustomFieldForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  fieldToEdit: PropTypes.object,
  refetchCustomFields: PropTypes.func.isRequired,
  updateProducts: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};

CustomFieldForm.defaultProps = {
  fieldToEdit: null
};

export default CustomFieldForm;
