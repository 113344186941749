import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import Image from '@tiptap/extension-image';
import TextAlign from '@tiptap/extension-text-align';
import RichTextEditorToolbar from './RichTextEditorToolbar';
import './styles.scss';

const RichTextEditor = ({ id, content, onChange }) => {
  const editorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3]
        }
      }),
      Image.configure({
        inline: true,
        allowBase64: true
      }),
      Link,
      Placeholder.configure({
        placeholder: __('Add your own custom content')
      }),
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph']
      })
    ],
    content,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    }
  });

  useEffect(() => {
    if (editorConfig) {
      editorConfig.commands.setContent(content);
    }
  }, [id, editorConfig]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (editorConfig) {
        editorConfig.destroy();
      }
    };
  }, [editorConfig]);

  return (
    <div className="RichTextEditor">
      <RichTextEditorToolbar editor={editorConfig} />
      <EditorContent editor={editorConfig} />
    </div>
  );
};

RichTextEditor.propTypes = {
  content: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

RichTextEditor.defaultProps = {
  content: ''
};

export default RichTextEditor;
