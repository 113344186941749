import React, { useState, useEffect, useReducer } from 'react';
import { useParams, Prompt, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  ContentLoader,
  FormStepper,
  FormStepperItem,
  NotFound,
  Notification,
  Page
} from 'shared/components';
import {
  sendErrorReport,
  isFeatureAvailable,
  isFeatureEnabled,
  getErrMsg
} from 'shared/helpers';
import { platformFeatures } from 'shared/constants';
import { getOrder, createOrder } from 'src/order/actions';
import { initialState } from './constants';
import {
  newOrderReducer,
  prefillExistingOrderDetails,
  setCustomerDetails,
  setSelectedProducts
} from './helpers';
import { CustomerStep, ProductsStep, VerifyOrderStep } from './components';
import './styles.scss';

const CreateOrderForm = () => {
  const { orderId } = useParams();
  const history = useHistory();
  const companyID = useSelector(state => get(state, 'company.details.id'));

  const [formState, dispatch] = useReducer(newOrderReducer, initialState);
  const [orderFetch, setOrderFetch] = useState(false);
  const [orderCreating, setOrderCreating] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [formStep, setFormStep] = useState(1);

  const handleEmptyOrderId = () => {
    dispatch(
      prefillExistingOrderDetails({
        orderId: '',
        customerReference: '',
        customerFirstName: '',
        customerLastName: '',
        customerEmail: '',
        customerOrganization: '',
        customerAddress: '',
        customerCity: '',
        customerState: '',
        customerCountry: '',
        customerZipcode: '',
        customerPhoneNumber: ''
      })
    );
  };

  const fetchOrderDetails = async () => {
    setOrderFetch(true);
    let order;

    try {
      order = await getOrder(companyID, orderId);
    } catch (err) {
      setOrderFetch(false);
      sendErrorReport(err, 'Cannot fetch order details');
    }

    const orderData = get(order, 'data');

    if (orderData) {
      const orderCustomer = get(orderData, 'customer');
      const data = {
        orderId: get(orderData, 'store_id'),
        orderReference: get(orderData, 'customer_reference'),
        customerReference: get(orderCustomer, 'reference') || '',
        customerFirstName: get(orderCustomer, 'first_name') || '',
        customerLastName: get(orderCustomer, 'last_name') || '',
        customerEmail: get(orderCustomer, 'email') || '',
        customerOrganization: get(orderCustomer, 'company_name') || '',
        customerAddress: get(orderCustomer, 'address') || '',
        customerCity: get(orderCustomer, 'city') || '',
        customerState: get(orderCustomer, 'state') || '',
        customerCountry: get(orderCustomer, 'country') || '',
        customerZipcode: get(orderCustomer, 'postcode') || '',
        customerPhoneNumber: get(orderCustomer, 'phone') || ''
      };

      dispatch(prefillExistingOrderDetails(data));
      setOrderFetch(false);
      setDirty(true);
      // setFormStep(2);
    }
  };

  const handleBack = () => setFormStep(formStep - 1);
  const handleForward = () => setFormStep(formStep + 1);

  const handleBackAndSaveProducts = data => {
    dispatch(setSelectedProducts(data));
    setFormStep(formStep - 1);
  };

  const handleCustomerDetailsSubmit = data => {
    dispatch(setCustomerDetails(data));
    setFormStep(2);
  };

  const handleSelectedProductsSubmit = data => {
    dispatch(setSelectedProducts(data));
    setFormStep(3);
  };

  const handleOrderCreate = data => {
    setOrderCreating(true);
    setDirty(false);
    createOrder(companyID, data)
      .then(res => {
        Notification(
          'success',
          __('Order created'),
          `${__('Order ID')}: ${get(res, 'data.store_id')}`
        );
        history.push(`/${companyID}/orders/${get(res, 'data.id')}/`);
      })
      .catch(err => {
        const errMsg = getErrMsg(err);
        setOrderCreating(false);
        setDirty(true);
        setOrderCreating(false);
        sendErrorReport(err, 'Cannot create new order', data);
        Notification('error', __('Error occured'), errMsg);
      });
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails();
    } else {
      handleEmptyOrderId();
    }
  }, [orderId]);

  const isPageUnavailable = () => {
    const featureEnabled = isFeatureEnabled(
      platformFeatures.platform_add_license
    );
    const featureAvailable = isFeatureAvailable(
      platformFeatures.platform_add_license
    );

    return !featureEnabled || !featureAvailable;
  };

  if (isPageUnavailable()) {
    return (
      <Page>
        <NotFound />
      </Page>
    );
  }

  if (orderFetch) {
    return (
      <Page>
        <ContentLoader text={__('Getting order details')} />
      </Page>
    );
  }

  return (
    <Page title={__('Issue Licenses')}>
      <Prompt
        message={__(
          'By leaving this form you will lose all progress. Are you sure you want to leave?'
        )}
        when={isDirty}
      />
      <div className="CreateOrderForm">
        <FormStepper>
          <FormStepperItem
            title={__('Customer')}
            description={__('Enter customer information')}
            isActive={formStep === 1}
            isCompleted={formStep > 1}
            step={1}
          />
          <FormStepperItem
            title={__('Products')}
            description={__('Add products to order')}
            isActive={formStep === 2}
            isCompleted={formStep > 2}
            step={2}
          />
          <FormStepperItem
            title={__('Confirm order')}
            description={__('Verify order details')}
            isActive={formStep === 3}
            isCompleted={formStep > 3}
            step={3}
          />
        </FormStepper>
        {formStep === 1 && (
          <CustomerStep
            companyID={companyID}
            formState={formState}
            handleForward={handleForward}
            handleSubmit={handleCustomerDetailsSubmit}
            orderID={orderId}
            setDirty={setDirty}
          />
        )}
        {formStep === 2 && (
          <ProductsStep
            companyID={companyID}
            formState={formState}
            handleBack={handleBackAndSaveProducts}
            handleSubmit={handleSelectedProductsSubmit}
          />
        )}
        {formStep === 3 && (
          <VerifyOrderStep
            companyID={companyID}
            formState={formState}
            handleBack={handleBack}
            handleOrderCreate={handleOrderCreate}
            loading={orderCreating}
            orderID={orderId}
          />
        )}
      </div>
    </Page>
  );
};

export default CreateOrderForm;
