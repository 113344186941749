import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { IconInfo, Tooltip } from 'shared/components';
import { productFeatureTypes, defaultDateFormat } from 'shared/constants';
import { capitalizeFirstLetter, formatDate } from 'shared/helpers';
import './styles.scss';

const SelectedFeaturesTags = ({ features }) => {
  if (!Array.isArray(features) || !features.length) {
    return '-';
  }

  return (
    <div className="SelectedFeaturesTags">
      {features.map(f => {
        const name =
          get(f, 'name') || get(f, 'label') || get(f, 'product_feature.name');
        const type =
          get(f, 'feature_type') || get(f, 'product_feature.feature_type');
        const isConsumption = type === productFeatureTypes.consumption;
        const maxConsumption = get(f, 'max_consumption');
        const allowUnlimitedConsumptions = get(
          f,
          'allow_unlimited_consumptions'
        );
        const allowNegativeConsumptions = get(f, 'allow_negative_consumptions');
        const allowOverage = get(f, 'allow_overages');
        const resetConsumption = get(f, 'reset_consumption');
        const isFloating = get(f, 'is_floating_cloud') || get(f, 'is_floating');
        const isFloatingCloud = get(f, 'is_floating_cloud');
        const expiryDate = get(f, 'expiry_date');
        return (
          <div key={f.value || f.id} className="SFTag">
            <Tooltip
              overlayClassName="sf-tags-tooltip"
              active
              content={
                <div className="sf-tags-tooltip-inner">
                  <div className="type">
                    <span className="value">
                      {`${capitalizeFirstLetter(type)} ${__('type')}`}
                    </span>
                  </div>
                  {isConsumption && (
                    <div className="max_consumptions">
                      <span className="value">
                        {allowUnlimitedConsumptions
                          ? __('Unlimited consumptions')
                          : `${maxConsumption} ${__('max consumptions')}`}
                      </span>
                    </div>
                  )}
                  {isConsumption && allowNegativeConsumptions && (
                    <div className="negative_consumptions">
                      <span className="value">
                        {__('Allow negative consumptions')}
                      </span>
                    </div>
                  )}
                  {allowOverage && (
                    <div className="allowOverage">
                      <span className="value">
                        {`${get(f, 'max_overages')} ${__('max overages')}`}
                      </span>
                    </div>
                  )}
                  {resetConsumption && (
                    <div className="allowOverage">
                      <span className="value">
                        {`${capitalizeFirstLetter(
                          get(f, 'consumption_period')
                        )} ${__('consumptions reset')}`}
                      </span>
                    </div>
                  )}
                  {isFloating && (
                    <>
                      <div className="type">
                        <span className="value">
                          {isFloatingCloud
                            ? __('Is floating cloud')
                            : __('Offline floating feature')}
                        </span>
                      </div>
                      <div className="floating-users">
                        <span className="value">
                          {`${__('Max simultaneous users')}: ${get(
                            f,
                            'floating_users'
                          )}`}
                        </span>
                      </div>
                      <div className="floating-timeout">
                        <span className="value">
                          {`${__('Floating timeout')}: ${get(
                            f,
                            'floating_timeout'
                          )}`}
                        </span>
                      </div>
                    </>
                  )}
                  {expiryDate && (
                    <div className="expiry_date">
                      <span className="value">
                        {`${__('Expiry date')}: ${formatDate(
                          expiryDate,
                          defaultDateFormat
                        )}`}
                      </span>
                    </div>
                  )}
                </div>
              }
            >
              <div className="SFTag-inner">
                <span>{name}</span>
                <IconInfo height="10px" width="10px" />
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

SelectedFeaturesTags.propTypes = {
  features: PropTypes.array
};

SelectedFeaturesTags.defaultProps = {
  features: []
};

export default SelectedFeaturesTags;
