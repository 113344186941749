import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getPublicStripeApiKey } from 'shared/helpers';
import { userPermissions } from 'shared/constants';
import { PermissionCheck } from 'shared/components';
import PlatformUsers from '../PlatformUsers';
import UserContainer from '../PlatformUsers/UserContainer';
import SettingsContainer from '../SettingsContainer';
import BillingContainer from '../BillingContainer';
import PlanTypeContainer from '../PlanTypeContainer';
import StandardPlanUpgradeContainer from '../StandardPlanUpgradeContainer';
import PortalConfiguration from '../PortalConfiguration';
import Permissions from '../Permissions';
import AuditLog from '../AuditLog';
import NotificationsContainer from '../../notifications/NotificationsContainer';
import WebhookContainer from '../WebhookContainer';

const AccountContainer = () => {
  const isSelfHosted = process.env.REACT_APP_SELF_HOSTED === 'true';
  const stripePromise = loadStripe(getPublicStripeApiKey());

  return (
    <Elements stripe={stripePromise}>
      <Switch>
        <Route
          exact
          path="/:companyId/account"
          component={PermissionCheck(
            SettingsContainer,
            userPermissions.settings_read
          )}
        />
        <Route
          path="/:companyId/account/plan-type/upgrade"
          component={PermissionCheck(
            StandardPlanUpgradeContainer,
            userPermissions.billing_read
          )}
        />
        <Route
          path="/:companyId/account/users/:userId"
          component={PermissionCheck(
            UserContainer,
            userPermissions.platform_users_read
          )}
        />
        <Route
          path="/:companyId/account/users"
          component={PermissionCheck(
            PlatformUsers,
            userPermissions.platform_users_read
          )}
        />
        <Route
          path="/:companyId/account/permissions"
          component={PermissionCheck(
            Permissions,
            userPermissions.platform_users_read
          )}
        />
        <Route
          path="/:companyId/account/audit-log"
          component={PermissionCheck(
            AuditLog,
            userPermissions.platform_users_read
          )}
        />
        <Route
          path="/:companyId/account/user-portal-configuration"
          component={PermissionCheck(
            PortalConfiguration,
            userPermissions.settings_read
          )}
        />
        <Route
          path="/:companyId/account/notifications"
          component={PermissionCheck(
            NotificationsContainer,
            userPermissions.settings_read
          )}
        />
        <Route
          path="/:companyId/account/webhooks"
          component={PermissionCheck(
            WebhookContainer,
            userPermissions.settings_read
          )}
        />
        {!isSelfHosted && (
          <>
            <Route
              path="/:companyId/account/billing"
              component={PermissionCheck(
                BillingContainer,
                userPermissions.billing_read
              )}
            />
            <Route
              exact
              path="/:companyId/account/plan-type"
              component={PermissionCheck(
                PlanTypeContainer,
                userPermissions.billing_read
              )}
            />
          </>
        )}
      </Switch>
    </Elements>
  );
};

export default AccountContainer;
