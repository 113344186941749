import React from 'react';
import PropTypes from 'prop-types';
import {
  Label,
  TextInput,
  TextArea,
  IconClipboard,
  Notification
} from 'shared/components';
import { copyText } from 'shared/helpers';
import './styles.scss';

const ClipboardRow = ({ label, hideLabel, id, value, isTextArea }) => {
  const handleFieldCopy = (val, desc) => {
    copyText(val);
    Notification('success', `${desc} ${__('copied to clipboard')}`);
  };

  return (
    <div className="ClipboardRow row">
      {!hideLabel && <Label inputId={id} text={label} />}
      <div className="text-input-cont">
        <button
          type="button"
          className="clipboard-btn"
          onClick={() => handleFieldCopy(value, label)}
        >
          <IconClipboard width="21.5" height="20" viewBox="0 0 51.5 50" />
        </button>
        {isTextArea ? (
          <TextArea id={id} handleChange={() => {}} value={value} disabled />
        ) : (
          <TextInput id={id} handleChange={() => {}} value={value} disabled />
        )}
      </div>
    </div>
  );
};

ClipboardRow.propTypes = {
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isTextArea: PropTypes.bool
};

ClipboardRow.defaultProps = {
  isTextArea: false,
  hideLabel: false
};

export default ClipboardRow;
