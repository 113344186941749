import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  DirtyFormAlert,
  Label,
  Modal,
  TextInput,
  Notification,
  NumberInput,
  Selector,
  RadioBtn
} from 'shared/components';
import { currencyOptions } from 'shared/constants';
import { sendErrorReport } from 'shared/helpers';
import { createCoupon } from 'src/billing/actions';
import './styles.scss';

const CouponForm = ({ closeCb, confirmCb, fetchCoupons, title }) => {
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const stripeCouponDurationOptions = [
    { label: __('Forever'), value: 'forever' },
    { label: __('Once'), value: 'once' },
    { label: __('Multiple months'), value: 'repeating' }
  ];

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [selectedCouponType, setSelectedCouponType] = useState('percentage');
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [duration, setDuration] = useState(
    get(stripeCouponDurationOptions, '[0].value')
  );
  const [durationMonths, setDurationMonths] = useState('');
  const [durationMonthsError, setDurationMonthsError] = useState('');
  const [percentageOff, setPercentageOff] = useState('');
  const [percentageOffError, setPercentageOffError] = useState('');
  const [currency, setCurrency] = useState(get(currencyOptions, '[0].value'));
  const [amountOff, setAmountOff] = useState('');
  const [amountOffError, setAmountOffError] = useState('');

  const validateCouponName = val => {
    if (!val) {
      setNameError(__('This field is required'));
      return false;
    }
    setNameError('');
    return true;
  };

  const validateDurationMonths = val => {
    if (duration === 'repeating' && !val) {
      setDurationMonthsError(__('This field is required'));
      return false;
    }
    setDurationMonthsError('');
    return true;
  };

  const validatePercentageOff = val => {
    if (selectedCouponType === 'percentage' && !val) {
      setPercentageOffError(__('This field is required'));
      return false;
    }
    setPercentageOffError('');
    return true;
  };

  const validateAmountOff = val => {
    if (selectedCouponType === 'fixed' && !val) {
      setAmountOffError(__('This field is required'));
      return false;
    }
    setAmountOffError('');
    return true;
  };

  const handleNameChange = val => {
    setDirty(true);
    setName(val);
    validateCouponName(val);
  };

  const handleSelectedCouponType = val => {
    setDirty(true);
    setSelectedCouponType(val);
    setPercentageOff('');
    setPercentageOffError('');
    setAmountOff('');
    setAmountOffError('');
  };

  const handleDurationChange = val => {
    setDirty(true);
    setDuration(val);
    setDurationMonths('');
    setDurationMonthsError('');
  };

  const handleDurationMonthsChange = val => {
    setDirty(true);
    setDurationMonths(val);
    validateDurationMonths(val);
  };

  const handlePercentageOffChange = val => {
    setDirty(true);
    setPercentageOff(val);
    validatePercentageOff(val);
  };

  const handleCurrencyChange = val => {
    setDirty(true);
    setCurrency(val);
  };

  const handleAmountOffChange = val => {
    setDirty(true);
    setAmountOff(val);
    validateAmountOff(val);
  };

  const isFormValid = () => {
    const isNameValid = validateCouponName(name);
    const isDurationMonthsValid = validateDurationMonths(durationMonths);
    const isPercentageOffValid = validatePercentageOff(percentageOff);
    const isAmountOffValid = validateAmountOff(amountOff);
    return (
      isNameValid &&
      isDurationMonthsValid &&
      isPercentageOffValid &&
      isAmountOffValid
    );
  };

  const handleSubmit = () => {
    if (!isFormValid() || loading) {
      return false;
    }

    setLoading(true);
    const durationInMonthsValue =
      duration === 'repeating' ? Number(durationMonths) : undefined;
    const percentOffValue =
      selectedCouponType === 'percentage' ? Number(percentageOff) : undefined;
    const currencyValue = selectedCouponType === 'fixed' ? currency : undefined;
    const amountOffValue =
      selectedCouponType === 'fixed' ? Number(amountOff) * 100 : undefined;

    const data = {
      name,
      duration,
      duration_in_months: durationInMonthsValue,
      percent_off: percentOffValue,
      amount_off: amountOffValue,
      currency: currencyValue
    };

    createCoupon(data, companyID)
      .then(() => {
        fetchCoupons();
        Notification('success', __('Coupon created'));
        confirmCb();
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot create coupon', data);
        Notification(
          'error',
          __('Error occured'),
          __('We could not create the coupon.')
        );
        setLoading(false);
      });
    return true;
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return setDirtyFormAlertDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={title}
      disabled={loading}
    >
      <div className="CouponForm">
        <form className="CouponForm-form" onSubmit={handleSubmit}>
          <div className="form-inner">
            <div className="left">
              <div>
                <Label text={__('Name')} inputId="name" />
                <TextInput
                  id="name"
                  value={name}
                  error={nameError}
                  handleChange={handleNameChange}
                  disabled={loading}
                />
              </div>
              <div>
                <Label text={__('Duration')} inputId="duration" />
                <Selector
                  options={stripeCouponDurationOptions}
                  value={duration}
                  isDisabled={loading}
                  handleChange={handleDurationChange}
                />
              </div>
              {duration === 'repeating' && (
                <div>
                  <Label text={__('Number of months')} inputId="months-num" />
                  <TextInput
                    id="months-num"
                    value={durationMonths}
                    error={durationMonthsError}
                    handleChange={handleDurationMonthsChange}
                    disabled={loading}
                  />
                </div>
              )}
            </div>
            <div className="divider active" />
            <div className="right">
              <div>
                <Label text={__('Type')} inputId="type" />
                <RadioBtn
                  name="coupon-type-select"
                  inputId="coupon-type-perc"
                  label={__('Percentage discount')}
                  value="percentage"
                  checked={selectedCouponType === 'percentage'}
                  handleChange={handleSelectedCouponType}
                />
                <RadioBtn
                  name="coupon-type-select"
                  inputId="coupon-type-fixed"
                  label={__('Fixed amount discount')}
                  value="fixed"
                  checked={selectedCouponType === 'fixed'}
                  handleChange={handleSelectedCouponType}
                />
              </div>
              {selectedCouponType === 'percentage' && (
                <div>
                  <Label text={__('Percentage off')} inputId="perc-off" />
                  <NumberInput
                    id="perc-off"
                    min="1"
                    max="100"
                    value={percentageOff}
                    error={percentageOffError}
                    handleChange={handlePercentageOffChange}
                    disabled={loading}
                  />
                </div>
              )}
              {selectedCouponType === 'fixed' && (
                <div>
                  <div>
                    <Label text={__('Currency')} inputId="currency" />
                    <Selector
                      options={currencyOptions}
                      value={currency}
                      isDisabled={loading}
                      handleChange={handleCurrencyChange}
                    />
                  </div>
                  <div>
                    <Label text={__('Discount amount')} inputId="amount-off" />
                    <NumberInput
                      id="amount-off"
                      min="1"
                      max="100"
                      value={amountOff}
                      error={amountOffError}
                      handleChange={handleAmountOffChange}
                      disabled={loading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

CouponForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  fetchCoupons: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default CouponForm;
