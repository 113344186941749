import axios from 'axios';
import { get } from 'lodash';
import { getUserFromStorage } from '../keycloak/authUser';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'x-csrftoken';

// Request interceptor to attach the token
axios.interceptors.request.use(
  config => {
    const user = getUserFromStorage();
    const token = get(user, 'access_token');
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`; // Attach the token
    }
    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => {
    if (response.status === 401 || response.status === 403) {
      window.location.href = '/login'; // eslint-disable-line no-undef
    }
    return response;
  },
  error => {
    const errorResponseStatus = get(error, 'response.status');
    // const errorResponseData = get(error, 'response.data');
    if (errorResponseStatus === 403 || errorResponseStatus === 401) {
      // console.log(errorResponseData);
      if (
        window.location.pathname.includes('/login') &&
        window.location.pathname.includes('/sso')
      ) {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

const endpoint = '';

const getPath = url => `${endpoint}${url}`;

const api = {
  get: (url, config = undefined) => axios.get(getPath(url), config),
  post: (url, data = undefined, config = undefined) =>
    axios.post(getPath(url), data, config),
  patch: (url, data, config) => axios.patch(getPath(url), data, config),
  delete: (url, data = undefined, config = undefined) =>
    axios.delete(getPath(url), { data }, config) // { data: { params to be sent here } }
};

export default api;
