import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { userPermissions } from 'shared/constants';
import { PermissionCheck } from 'shared/components';
import LicenseContainer from 'src/license/LicenseContainer';
import OrderList from '../OrderList';
import OrderContainer from '../OrderContainer';
import LicenseManagers from '../LicenseManagers';
import LicenseManagerPage from '../LicenseManagerPage';

const OrdersContainer = () => (
  <Switch>
    <Route
      exact
      path="/:companyId/orders"
      component={PermissionCheck(OrderList, userPermissions.orders_read)}
    />
    <Route
      exact
      path="/:companyId/orders/license-managers"
      component={PermissionCheck(LicenseManagers, userPermissions.orders_read)}
    />
    <Route
      exact
      path="/:companyId/orders/license-managers/:managerId"
      component={PermissionCheck(
        LicenseManagerPage,
        userPermissions.orders_read
      )}
    />
    <Route
      path="/:companyId/orders/:orderId/:licenseId"
      component={PermissionCheck(
        LicenseContainer,
        userPermissions.licenses_read
      )}
    />
    <Route
      path="/:companyId/orders/:orderId"
      component={PermissionCheck(OrderContainer, userPermissions.orders_read)}
    />
  </Switch>
);

export default OrdersContainer;
