import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { abbreviateNumber } from 'js-abbreviation-number';
import {
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { ChartTooltip } from 'shared/components';

const LineChart = ({
  axisColor,
  color,
  data,
  dataKey,
  height,
  isDaily,
  label
}) => (
  <div className="LineChart">
    <ResponsiveContainer width="100%" height={height}>
      <RechartsLineChart
        data={data}
        margin={{
          top: 5,
          right: 20,
          bottom: 5,
          left: -20
        }}
      >
        <Line
          connectNulls
          name={label}
          type="linear"
          dataKey={dataKey}
          stroke={color}
          strokeWidth={2}
          dot={false}
        />
        <YAxis
          stroke={axisColor}
          tickFormatter={v => abbreviateNumber(v, 1)}
          tick={{ fontSize: 10 }}
        />
        <XAxis
          stroke={axisColor}
          dataKey="ts"
          type="number"
          domain={['dataMin', 'dataMax']}
          interval="preserveStartEnd"
          scale="time"
          tickFormatter={ts =>
            isDaily
              ? moment(ts).format('MM/DD/YYYY')
              : moment(ts).format('MM/DD/YYYY HH:mm')
          }
          tick={{ fontSize: 10 }}
        />
        <Tooltip
          content={<ChartTooltip isDaily={isDaily} tooltipLabel={label} />}
        />
      </RechartsLineChart>
    </ResponsiveContainer>
  </div>
);

LineChart.propTypes = {
  axisColor: PropTypes.string,
  color: PropTypes.string,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isDaily: PropTypes.bool,
  label: PropTypes.string
};

LineChart.defaultProps = {
  axisColor: '#07364c',
  color: '#14B0FC',
  dataKey: 'total_call_count',
  height: 200,
  isDaily: true,
  label: ''
};

export default LineChart;
