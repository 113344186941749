import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const InactiveAlert = ({ theme, company, isAdmin }) => (
  <div className={`PageNotice PageNotice-theme--${theme} InactiveAlert`}>
    {isAdmin ? (
      <div className="PageNotice-body">
        {__("Your payment didn't go through, please check your")}
        &nbsp;
        <NavLink to={`/${company}/account/billing`}>{`${__(
          'payment method'
        )}.`}</NavLink>
      </div>
    ) : (
      <div className="PageNotice-body">
        {`${__("Your payment didn't go through, please check your")} ${__(
          'payment method'
        )}.`}
        &nbsp;
        {__(
          'You must have admin rights in order to access this section. Please contact your account administrator to gain access.'
        )}
      </div>
    )}
  </div>
);

InactiveAlert.propTypes = {
  company: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(['success', 'warning', 'info', 'error'])
};

InactiveAlert.defaultProps = {
  theme: 'error'
};

export default InactiveAlert;
