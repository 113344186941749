import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { sendErrorReport } from 'shared/helpers';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput
} from 'shared/components';
import { patchUser } from 'src/account/actions';

const EditUserForm = ({ user, closeCb, confirmCb, companyID }) => {
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);
  const [firstName, setFirstName] = useState(get(user, 'first_name'));
  const [lastName, setLastName] = useState(get(user, 'last_name'));

  const handleSubmit = () => {
    if (loading) {
      return false;
    }
    setLoading(true);

    const userId = get(user, 'id');
    const data = {
      first_name: firstName || '',
      last_name: lastName || ''
    };

    patchUser(userId, data, companyID)
      .then(() => {
        confirmCb();
        Notification('success', __('Changes saved successfully'));
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot edit customer details', data);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
        setLoading(false);
      });
    return true;
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      title={`${__('Edit user')} - ${get(user, 'email')}`}
      size="sm"
    >
      <div className="EditUserForm">
        <div>
          <Label text={__('First Name')} inputId="user-firstname" />
          <TextInput
            id="user-firstname"
            value={firstName}
            handleChange={val => {
              setDirty(true);
              setFirstName(val);
            }}
          />
        </div>
        <div>
          <Label text={__('Last Name')} inputId="user-lastname" />
          <TextInput
            id="user-lastname"
            value={lastName}
            handleChange={val => {
              setDirty(true);
              setLastName(val);
            }}
          />
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

EditUserForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  companyID: PropTypes.number.isRequired
};

export default EditUserForm;
