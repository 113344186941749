import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { UnauthorizedNotice } from 'shared/components';
import { isStaffUser } from 'shared/helpers';
import CompanySettings from './CompanySettings';
import PricingPlanSettings from './PricingPlanSettings';
import DiscountSettings from './DiscountSettings';
import TaxRatesSettings from './TaxRatesSettings';
import './styles.scss';

const BillingConfiguration = () => {
  const isSelfHosted = process.env.REACT_APP_SELF_HOSTED === 'true';
  const companyDetails = useSelector(state => get(state, 'company.details'));

  const planType = get(companyDetails, 'plan_type');
  const isStaff = isStaffUser();

  if (!isStaff) {
    return <UnauthorizedNotice />;
  }

  return (
    <div className="BillingConfiguration">
      <Switch>
        <Route
          exact
          path="/:companyId/billing-configuration"
          render={() => (
            <CompanySettings
              planType={planType}
              companyDetails={companyDetails}
            />
          )}
        />
        {!isSelfHosted && (
          <>
            <Route
              exact
              path="/:companyId/billing-configuration/pricing-plans"
              render={() => (
                <PricingPlanSettings companyDetails={companyDetails} />
              )}
            />
            <Route
              exact
              path="/:companyId/billing-configuration/coupons"
              render={() => (
                <DiscountSettings companyDetails={companyDetails} />
              )}
            />
            <Route
              exact
              path="/:companyId/billing-configuration/tax-rates"
              render={() => (
                <TaxRatesSettings companyDetails={companyDetails} />
              )}
            />
          </>
        )}
      </Switch>
    </div>
  );
};

export default BillingConfiguration;
