import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Button,
  CustomFormTab,
  DescriptionTable,
  DirtyFormAlert,
  JsonView,
  Notice,
  SlidePane,
  SelectedFeaturesTags,
  SelectedCustomFieldsTags,
  Tab,
  Tabs,
  TabContent,
  TabsHeader
} from 'shared/components';
import {
  trialTypes,
  licenseTypes,
  overageTypes,
  errorMsg,
  defaultDateFormat,
  validityTypes
} from 'shared/constants';
import {
  displayValue,
  capitalizeFirstLetter,
  displayMaxOveragesValue,
  mapProductsToSelector,
  setDefaultOverageType,
  sendErrorReport,
  getDefaultLicensePolicy,
  formatPolicyFeatures,
  mapPolicyProductFeatures,
  mapPolicyCustomFields,
  formatPolicyCFields,
  getPolicyHasMaxTransfers,
  parseMetadata,
  formatDate
} from 'shared/helpers';
import { checkCompanyConfigField } from 'shared/companyConfig';
import {
  validateRequiredNumber,
  debouncedValidateRequiredNumber,
  validateDate,
  validateFloatingUsers,
  validateLicenseUsers,
  validateJSON
} from 'shared/validation';
import {
  getPolicyValue,
  getPolicyPeriodValue,
  getPolicyPeriodLabel,
  getPolicyResetConsumptionValue,
  getInitialProductValue,
  getInitialValue,
  getInitialPeriodValue,
  getInitialPeriodLabel,
  getResetConsumptionValue,
  getEditProductLicenseUsersEmails
} from './helpers';
import {
  CustomFieldsTab,
  LicenseOptionsSection,
  LicenseTypeSection,
  LicenseUsageSection,
  ProductFeaturesTab,
  ProductInformation
} from './components';
import './styles.scss';

const SelectProductForm = ({
  closeCb,
  companyID,
  handleSubmit,
  isOpen,
  productToEdit,
  title,
  width
}) => {
  const productsListState = useSelector(state => get(state, 'products.list'));
  const products = productsListState.filter(p => !p.is_bundle);

  const planType = useSelector(state =>
    get(state, 'company.details.plan_type')
  );
  const periodOptions = [
    { label: __('Years'), value: 'y' },
    { label: __('Months'), value: 'm' },
    { label: __('Days'), value: 'd' }
  ];
  const consumptionPeriodOptions = [
    { label: __('Daily'), value: 'daily' },
    { label: __('Weekly'), value: 'weekly' },
    { label: __('Monthly'), value: 'monthly' },
    { label: __('Annually'), value: 'annually' }
  ];
  const initialLicensePolicy = getDefaultLicensePolicy(products, productToEdit);

  const [loading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);

  // ------------- PRODUCT INFORMATION ----------------------------------------------------------------------------------------------------------------------------------
  const [productsForSelector, setProductsForSelector] = useState(
    mapProductsToSelector(products)
  );
  const [selectedProduct, setSelectedProduct] = useState({
    label: getInitialProductValue('product_name', products, productToEdit),
    value: getInitialProductValue('product_name', products, productToEdit),
    data: productToEdit || get(products, '[0]')
  });
  const [selectedPolicy, setSelectedPolicy] = useState(initialLicensePolicy);
  const [useCustomValues, setUseCustomValues] = useState(
    !!get(productToEdit, 'custom_values_used')
  );
  // ------------- LICENSE TYPE -----------------------------------------------------------------------------------------------------------------------------------------
  const [licenseType, setLicenseType] = useState(
    getInitialValue(
      'default_license_type',
      initialLicensePolicy,
      productToEdit,
      'perpetual'
    )
  );
  const [licenseTypeError, setLicenseTypeError] = useState('');
  // subscription
  const [subscriptionPeriodValue, setSubscriptionPeriodValue] = useState(
    getInitialPeriodValue(
      'subscription_duration',
      initialLicensePolicy,
      productToEdit,
      '1y'
    )
  );
  const [subscriptionPeriodLabel, setSubscriptionPeriodLabel] = useState(
    getInitialPeriodLabel(
      periodOptions,
      'subscription_duration',
      initialLicensePolicy,
      productToEdit,
      '1y'
    )
  );
  const [subscriptionPeriodError, setSubscriptionPeriodError] = useState('');
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(
    getInitialProductValue('validity_period', products, productToEdit, '')
  );
  const [subscriptionEndDateError, setSubscriptionEndDateError] = useState('');
  // grace period
  const [gracePeriod, setGracePeriod] = useState(
    getInitialValue('grace_period', initialLicensePolicy, productToEdit, 1)
  );
  const [gracePeriodError, setGracePeriodError] = useState('');
  const [allowGracePeriod, setAllowGracePeriod] = useState(
    getInitialValue(
      'allow_grace_period',
      initialLicensePolicy,
      productToEdit,
      false
    )
  );
  // time-limited
  const [selectedValidityType, setSelectedValidityType] = useState(
    productToEdit ? get(productToEdit, 'validity_type') : 'valid_from'
  );
  const [timeLimitedPeriodValue, setTimeLimitedPeriodValue] = useState(
    getInitialPeriodValue(
      'valid_duration',
      initialLicensePolicy,
      productToEdit,
      '1y'
    )
  );
  const [timeLimitedPeriodLabel, setTimeLimitedPeriodLabel] = useState(
    getInitialPeriodLabel(
      periodOptions,
      'valid_duration',
      initialLicensePolicy,
      productToEdit,
      '1y'
    )
  );
  const [timeLimitedPeriodError, setTimeLimitedPeriodError] = useState('');
  const [timeLimitedEndDate, setTimeLimitedEndDate] = useState(
    getInitialProductValue('validity_period', products, productToEdit, '')
  );
  const [timeLimitedEndDateError, setTimeLimitedEndDateError] = useState('');
  // consumption
  const [maxConsumptions, setMaxConsumptions] = useState(
    getInitialValue('max_consumptions', initialLicensePolicy, productToEdit, 1)
  );
  const [maxConsumptionsError, setMaxConsumptionsError] = useState('');
  const [allowUnlimitedConsumptions, setAllowUnlimitedConsumptions] = useState(
    getInitialValue(
      'allow_unlimited_consumptions',
      initialLicensePolicy,
      productToEdit,
      false
    )
  );
  const [allowNegativeConsumptions, setAllowNegativeConsumptions] = useState(
    getInitialValue(
      'allow_negative_consumptions',
      initialLicensePolicy,
      productToEdit,
      false
    )
  );
  const [allowOverages, setAllowOverages] = useState(
    getInitialValue(
      'allow_overages',
      initialLicensePolicy,
      productToEdit,
      false
    )
  );
  const [selectedOverageType, setSelectedOverageType] = useState(
    setDefaultOverageType(productToEdit || initialLicensePolicy)
  );
  const [maxOverages, setMaxOverages] = useState(
    getInitialValue('max_overages', initialLicensePolicy, productToEdit, 0)
  );
  const [maxOveragesError, setMaxOveragesError] = useState('');
  const [resetConsumption, setResetConsumption] = useState(
    getInitialValue(
      'reset_consumption',
      initialLicensePolicy,
      productToEdit,
      false
    )
  );
  const [resetConsumptionPeriod, setResetConsumptionPeriod] = useState(
    getResetConsumptionValue(
      consumptionPeriodOptions,
      initialLicensePolicy,
      productToEdit,
      'daily'
    )
  );
  // trial
  const [isTrial, setTrial] = useState(
    getInitialValue('allow_trial', initialLicensePolicy, productToEdit, false)
  );
  const [selectedTrialType, setSelectedTrialType] = useState(
    productToEdit ? get(productToEdit, 'trial_type') : trialTypes.days
  );
  const [trialDays, setTrialDays] = useState(
    getInitialValue('trial_days', initialLicensePolicy, productToEdit, 1)
  );
  const [trialDaysError, setTrialDaysError] = useState('');
  const [trialEndDate, setTrialEndDate] = useState(
    getInitialProductValue('start_date', products, productToEdit, '')
  );
  const [trialEndDateError, setTrialEndDateError] = useState('');
  // ------------- LICENSE OPTIONS ------------------------------------------------------------------------------------------------------------------------------------
  const [maxActivations, setMaxActivations] = useState(
    getInitialValue('max_activations', initialLicensePolicy, productToEdit, 1)
  );
  const [maxActivationsError, setMaxActivationsError] = useState('');
  const [allowUnlimitedActivations, setAllowUnlimitedActivations] = useState(
    getInitialValue(
      'allow_unlimited_activations',
      initialLicensePolicy,
      productToEdit,
      false
    )
  );
  const [licenseQuantity, setLicenseQuantity] = useState(
    getInitialProductValue('license_num', products, productToEdit, 1)
  );
  const [licenseQuantityError, setLicenseQuantityError] = useState('');
  const [licenseUsers, setLicenseUsers] = useState({
    emails: getEditProductLicenseUsersEmails(productToEdit),
    emailError: '',
    value: '',
    validationError: ''
  });
  const [preventVm, setPreventVm] = useState(
    getInitialValue('prevent_vm', initialLicensePolicy, productToEdit, false)
  );
  const [preventMaxTransfers, setPreventMaxTransfers] = useState(
    get(initialLicensePolicy, 'max_transfers') === -1
  );
  const [hasMaxTransfers, setHasMaxTransfers] = useState(
    getPolicyHasMaxTransfers(initialLicensePolicy)
  );
  const [maxTransfers, setMaxTransfers] = useState(
    getInitialValue('max_transfers', initialLicensePolicy, productToEdit, 0)
  );
  const [maxTransfersError, setMaxTransfersError] = useState('');
  // ------------- LICENSE USAGE ---------------------------------------------------------------------------------------------------------------------------------------
  const [isFloating, setIsFloating] = useState(
    getInitialValue('is_floating', initialLicensePolicy, productToEdit, false)
  );
  const [isFloatingCloud, setIsFloatingCloud] = useState(
    getInitialValue(
      'is_floating_cloud',
      initialLicensePolicy,
      productToEdit,
      false
    )
  );
  const [floatingUsers, setFloatingUsers] = useState(
    getInitialValue('floating_users', initialLicensePolicy, productToEdit, 1)
  );
  const [floatingTimeout, setFloatingTimeout] = useState(
    getInitialValue(
      'floating_timeout',
      initialLicensePolicy,
      productToEdit,
      120
    )
  );
  const [floatingTimeoutError, setFloatingTimeoutError] = useState('');
  const [floatingUsersError, setFloatingUsersError] = useState('');
  const [maxLicenseUsers, setMaxLicenseUsers] = useState(
    getInitialValue('max_license_users', initialLicensePolicy, productToEdit, 1)
  );
  const [maxLicenseUsersError, setMaxLicenseUsersError] = useState('');
  const [unlimitedMaxLicenseUsers, setUnlimitedMaxLicenseUsers] = useState(
    getInitialProductValue(
      'unlimited_max_license_users',
      products,
      productToEdit,
      false
    )
  );
  // maintenance
  const [enableMaintenancePeriod, setMaintenancePeriod] = useState(
    getInitialValue(
      'enable_maintenance_period',
      initialLicensePolicy,
      productToEdit,
      false
    )
  );
  // const [maintenancePeriodValue, setMaintenancePeriodValue] = useState(getInitialPeriodValue('maintenance_duration', initialLicensePolicy, productToEdit, '1y'));
  // const [maintenancePeriodLabel, setMaintenancePeriodLabel] = useState(getInitialPeriodLabel(periodOptions, 'maintenance_duration', initialLicensePolicy, productToEdit, '1y'));
  // const [maintenancePeriodError, setMaintenancePeriodError] = useState('');
  const [
    selectedMaintenanceDurationType,
    setSelectedMaintenanceDurationType
  ] = useState(validityTypes.duration);
  const [hasMaintenanceValue, setHasMaintenanceValue] = useState(
    getInitialPeriodValue(
      'maintenance_duration',
      initialLicensePolicy,
      productToEdit,
      '1y'
    )
  );
  const [hasMaintenancePeriod, setHasMaintenancePeriod] = useState(
    getInitialPeriodLabel(
      periodOptions,
      'maintenance_duration',
      initialLicensePolicy,
      productToEdit,
      '1y'
    )
  );
  const [hasMaintenanceError, setHasMaintenanceError] = useState('');
  const [hasMaintenanceEndDate, setHasMaintenanceEndDate] = useState(
    get(initialLicensePolicy, 'maintenance_period') || ''
  );
  const [hasMaintenanceEndDateError, setHasMaintenanceEndDateError] = useState(
    ''
  );
  // start_date
  const [startDate, setStartDate] = useState(
    getInitialProductValue('start_date', products, productToEdit, '')
  );
  const [startDateError, setStartDateError] = useState('');
  // product features
  const [productFeatures, setProductFeatures] = useState(
    mapPolicyProductFeatures(
      productToEdit || get(products, '[0]'),
      initialLicensePolicy
    )
  );
  const [selectedProductFeatures, setSelectedProductFeatures] = useState(
    productToEdit
      ? get(productToEdit, 'selected_features')
      : formatPolicyFeatures(
          get(initialLicensePolicy, 'license_product_feature_templates') || []
        )
  );
  const [productFeaturesErrors, setProductFeaturesError] = useState(false);
  // custom fields
  const [customFields, setCustomFields] = useState(
    mapPolicyCustomFields(
      productToEdit || get(products, '[0]'),
      initialLicensePolicy
    )
  );
  const [selectedCustomFields, setSelectedCustomFields] = useState(
    productToEdit
      ? get(productToEdit, 'selected_custom_fields')
      : formatPolicyCFields(
          get(initialLicensePolicy, 'license_custom_field_templates') || []
        )
  );
  const [customFieldsError, setCustomFieldsError] = useState('');
  // air-gapped
  const [isAirGapped, setAirGapped] = useState(
    getInitialProductValue('is_air_gapped', products, productToEdit, '')
  );
  // license borrowing
  const [canBorrow, setCanBorrow] = useState(
    getInitialProductValue('can_borrow', products, productToEdit, false)
  );
  const [maxBorrowTime, setMaxBorrowTime] = useState(
    getInitialProductValue('max_borrow_time', products, productToEdit, '')
  );
  const [maxBorrowTimeError, setMaxBorrowTimeError] = useState('');
  // hardware_key
  const [isHwKeyAuth, setHwKeyAuth] = useState(
    getInitialProductValue(
      'is_hardware_key_auth',
      products,
      productToEdit,
      false
    )
  );
  // license note
  const [licenseNote, setLicenseNote] = useState(
    getInitialProductValue('note', products, productToEdit, '')
  );
  // metadata
  const [metadata, setMetadata] = useState(
    parseMetadata(
      getInitialProductValue('start_date', products, productToEdit, '')
    )
  );
  const [metadataError, setMetadataError] = useState('');

  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------

  const productAuthMethod =
    get(selectedProduct, 'data.authorization_method') || '';
  const isUserBasedProduct = productAuthMethod === 'user';
  const trialValidity =
    selectedTrialType === trialTypes.days
      ? `${trialDays} ${'days'}`
      : `${__('Until date:')} ${trialEndDate}`;

  const getValidityPeriod = () => {
    const isTimeLimited = licenseType === licenseTypes.time_limited;
    const isSubscription = licenseType === licenseTypes.subscription;

    if (isTrial && selectedTrialType === trialTypes.endDate) {
      const val = trialEndDate === '' ? undefined : trialEndDate;
      return val;
    }
    if (isTimeLimited && timeLimitedEndDate) {
      const val = timeLimitedEndDate === '' ? undefined : timeLimitedEndDate;
      return val;
    }
    if (isSubscription && subscriptionEndDate) {
      const val = subscriptionEndDate === '' ? undefined : subscriptionEndDate;
      return val;
    }
    return undefined;
  };

  const getMaxTransfers = () => {
    if (preventMaxTransfers) {
      return -1;
    }
    if (hasMaxTransfers) {
      return Number(maxTransfers);
    }
    return 0;
  };

  const getOrderData = () => {
    const licenseUsersEmails = licenseUsers.emails.join(',');
    // const trialValidity = selectedTrialType === trialTypes.days ? `${trialDays} ${'days'}` : `${__('Until date:')} ${trialEndDate}`;
    const validityPeriod = getValidityPeriod();
    const maxUsers = unlimitedMaxLicenseUsers ? 0 : Number(maxLicenseUsers);
    const productPolicies =
      get(selectedProduct, 'data.license_templates') || [];
    const licensePolicy = productPolicies.find(
      pp => pp.code === selectedPolicy.code
    );

    const data = {
      id: get(selectedProduct, 'data.id'),
      short_code: get(selectedProduct, 'data.short_code'),
      product_name: get(selectedProduct, 'data.product_name'),
      authorization_method: get(selectedProduct, 'data.authorization_method'),
      license_templates: productPolicies,
      selected_license_policy: licensePolicy,
      max_activations: maxActivations,
      allow_unlimited_activations: allowUnlimitedActivations,
      license_num: isUserBasedProduct ? 1 : Number(licenseQuantity),
      license_users_emails: licenseUsersEmails,
      license_users: licenseUsers.emails,
      allow_trial: isTrial,
      trial_type: selectedTrialType,
      trial_days:
        selectedTrialType === trialTypes.endDate
          ? undefined
          : Number(trialDays),
      trial_end_date: trialEndDate,
      trial_validity: trialValidity,
      prevent_vm: preventVm,
      default_license_type: licenseType,
      subscription_duration: `${subscriptionPeriodValue}${subscriptionPeriodLabel}`,
      validity_type: selectedValidityType,
      valid_duration: `${timeLimitedPeriodValue}${timeLimitedPeriodLabel}`,
      allow_grace_period: allowGracePeriod,
      grace_period: gracePeriod,
      validity_period: validityPeriod,
      max_consumptions: Number(maxConsumptions),
      allow_unlimited_consumptions: allowUnlimitedConsumptions,
      allow_negative_consumptions: allowNegativeConsumptions,
      allow_overages: allowOverages,
      max_overages:
        selectedOverageType === overageTypes.unlimited ? 0 : maxOverages,
      reset_consumption: resetConsumption,
      consumption_period: resetConsumptionPeriod,
      is_floating: isFloating,
      is_floating_cloud: isFloatingCloud,
      floating_users: Number(floatingUsers),
      floating_timeout: Number(floatingTimeout),
      enable_maintenance_period: enableMaintenancePeriod,
      maintenance_duration: `${hasMaintenanceValue}${hasMaintenancePeriod}`,
      maintenance_period: hasMaintenanceEndDate,
      product_features: productFeatures.map(feature => feature.data),
      selected_features: selectedProductFeatures,
      selected_custom_fields: selectedCustomFields,
      custom_fields: customFields.map(cf => cf.data),
      note: licenseNote,
      max_license_users: isUserBasedProduct ? maxUsers : undefined,
      unlimited_max_license_users: unlimitedMaxLicenseUsers,
      max_transfers: getMaxTransfers(),
      custom_values_used: useCustomValues,
      start_date: startDate,
      is_air_gapped: isAirGapped,
      can_borrow: canBorrow,
      max_borrow_time: maxBorrowTime,
      is_hardware_key_auth: isHwKeyAuth,
      metadata: metadata ? JSON.parse(metadata) : {}
    };

    return data;
  };

  // ------------- VALIDATION --------------------------------------------------------------------------------------------------------------------------
  const validateValue = async (val, cb, includeZero = false) => {
    setLoading(true);
    let errors;

    try {
      errors = await validateRequiredNumber(val, includeZero);
      setLoading(false);
      cb(errors);
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate reguired product step field', {
        value: val
      });
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const validateLicenseQuantity = async val => {
    setLoading(true);
    let errors;

    if (Number(val) > 10000) {
      setLicenseQuantityError(`${errorMsg.maxQuantity} 10000`);
      return false;
    }

    try {
      errors = await validateRequiredNumber(val);
      setLoading(false);
      setLicenseQuantityError(errors);
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate reguired product step field', {
        value: val
      });
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const validateFeaturesConsumption = async values => {
    if (!values.length) {
      setProductFeaturesError(false);
      return true;
    }

    const hasError = values.some(
      f => f.max_consumption_error || f.max_overages_error
    );
    if (hasError || productFeaturesErrors) {
      setProductFeaturesError(true);
      return false;
    }
    setProductFeaturesError(false);
    return true;
  };

  const validateCustomFields = async values => {
    if (!values.length) {
      setCustomFieldsError(false);
      return true;
    }

    const hasError = values.some(f => f.cf_value_error);
    if (hasError || customFieldsError) {
      setCustomFieldsError(true);
      return false;
    }
    setCustomFieldsError(false);
    return true;
  };

  const validateDateField = async (val, cb, isOptional = false) => {
    if (!val && isOptional) {
      cb('');
      return true;
    }

    setLoading(true);
    let errors;

    try {
      errors = await validateDate(val);
      setLoading(false);
      cb(errors);
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate trial end date', { value: val });
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const handleValidateLicenseUsers = async licenseUsersNum => {
    if (!isUserBasedProduct) {
      return true;
    }

    const usersError = get(licenseUsers, 'emailError');
    if (usersError) {
      return false;
    }

    if (licenseUsers.value) {
      setLicenseUsers({
        ...licenseUsers,
        validationError: `${get(errorMsg, 'unsubmittedEmail')} (${get(
          licenseUsers,
          'value'
        )})`
      });
      return false;
    }

    setLoading(true);
    let errors;

    try {
      errors = await validateLicenseUsers(
        get(licenseUsers, 'emails'),
        licenseUsersNum
      );
      setLoading(false);
      setLicenseUsers({
        ...licenseUsers,
        validationError: errors
      });
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate license users', {
        emails: get(licenseUsers, 'emails'),
        max_license_users: licenseUsersNum
      });
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const handleValidateFloatingUsers = async val => {
    setLoading(true);
    let errors;

    try {
      errors = await validateFloatingUsers(
        val,
        maxActivations,
        allowUnlimitedActivations,
        isFloatingCloud
      );
      setLoading(false);
      setFloatingUsersError(errors);
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate floating users', { value: val });
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const validateMaxActivations = async val => {
    // TODO
    setLoading(true);
    let errors;

    try {
      errors = await validateRequiredNumber(val);
      setLoading(false);
      setMaxActivationsError(errors);

      if (Number(val) >= Number(floatingUsers)) {
        setFloatingUsersError('');
      }

      if (
        !errors &&
        isUserBasedProduct &&
        Number(val) < Number(maxLicenseUsers) &&
        !allowUnlimitedActivations
      ) {
        setMaxActivationsError(errorMsg.maxActivations);
        errors = true;
      }
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate max activations', { value: val });
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const validateTrialFields = () => {
    if (!isTrial) {
      return true;
    }
    if (selectedTrialType === trialTypes.days) {
      return validateValue(trialDays, setTrialDaysError);
    }
    if (selectedTrialType === trialTypes.endDate) {
      return validateDateField(trialEndDate, setTrialEndDateError);
    }
    return false;
  };

  const validateGracePeriod = async () => {
    if (!allowGracePeriod) {
      return true;
    }
    const isGracePeriodValid = await validateValue(
      gracePeriod,
      setGracePeriodError
    );
    return isGracePeriodValid;
  };

  const validateSubscriptionLicenseType = async () => {
    // const duration = await validateValue(subscriptionPeriodValue, setSubscriptionPeriodError);
    // const endDate = subscriptionEndDate ? await validateDateField(subscriptionEndDate, setSubscriptionEndDate) : true;
    // return duration && endDate;
    if (selectedValidityType === 'valid_from') {
      return validateValue(subscriptionPeriodValue, setSubscriptionPeriodError);
    }
    if (selectedValidityType === 'valid_until') {
      return validateDateField(
        subscriptionEndDate,
        setSubscriptionEndDateError
      );
    }
    return false;
  };

  const validateTimeLimitedLicenseType = () => {
    if (selectedValidityType === 'valid_from') {
      return validateValue(timeLimitedPeriodValue, setTimeLimitedPeriodError);
    }
    if (selectedValidityType === 'valid_until') {
      return validateDateField(timeLimitedEndDate, setTimeLimitedEndDateError);
    }
    return false;
  };

  const validateConsumptionLiceneType = async () => {
    let isMaxConsumptionValid;
    let isMaxOveragesValid;

    try {
      isMaxConsumptionValid = await validateValue(
        maxConsumptions,
        setMaxConsumptionsError
      );
      if (!allowOverages || selectedOverageType === overageTypes.unlimited) {
        isMaxOveragesValid = true;
      } else {
        isMaxOveragesValid = await validateValue(
          maxOverages,
          setMaxOveragesError,
          true
        );
      }
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate consumption license type', {
        value: maxConsumptions
      });
    }

    if (!isMaxConsumptionValid || !isMaxOveragesValid) {
      return false;
    }
    return true;
  };

  const validateLicenseType = () => {
    switch (licenseType) {
      case licenseTypes.perpetual:
        return true;
      case licenseTypes.subscription:
        return validateSubscriptionLicenseType();
      case licenseTypes.time_limited:
        return validateTimeLimitedLicenseType();
      case licenseTypes.consumption:
        return validateConsumptionLiceneType();
      default:
        return false;
    }
  };

  const validateFloating = async () => {
    if (!isFloating && !isFloatingCloud) {
      return true;
    }
    const areFloatingUsersValid = await handleValidateFloatingUsers(
      floatingUsers
    );
    const isFloatingTimeoutValid = await validateValue(
      floatingTimeout,
      setFloatingTimeoutError
    );
    return areFloatingUsersValid && isFloatingTimeoutValid;
  };

  const validateBorrowing = async () => {
    if (!canBorrow) {
      return true;
    }
    const isMaxBorrowTimeValid = await validateValue(
      maxBorrowTime,
      setMaxBorrowTimeError
    );
    return isMaxBorrowTimeValid;
  };

  const validateMetadata = async val => {
    setLoading(true);
    let errors;
    try {
      errors = await validateJSON(metadata);
      setMetadataError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate metadata', { value: val });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validateMaintenanceDuration = async () => {
    if (selectedMaintenanceDurationType === validityTypes.period) {
      return true;
    }
    if (!hasMaintenancePeriod) {
      setHasMaintenanceEndDateError(__(errorMsg.required));
      return false;
    }
    const validationErr = await validateRequiredNumber(hasMaintenanceValue);
    setHasMaintenanceError(validationErr);
    if (validationErr) {
      return false;
    }
    return true;
  };

  const validateMaintenanceEndDate = async () => {
    if (selectedMaintenanceDurationType === validityTypes.duration) {
      return true;
    }
    const validationErr = await validateDate(hasMaintenanceEndDate);
    setHasMaintenanceEndDateError(validationErr);
    if (validationErr) {
      return false;
    }
    return true;
  };

  const validateMaintenance = async () => {
    if (!enableMaintenancePeriod) {
      return true;
    }
    const isDurationValid = await validateMaintenanceDuration();
    const isEndDateValid = await validateMaintenanceEndDate();
    return isDurationValid && isEndDateValid;
  };

  const isFormValid = async () => {
    const isMaxActivationsValid = await validateMaxActivations(maxActivations);
    const isLicenseQuantityValid = isUserBasedProduct
      ? true
      : await validateLicenseQuantity(licenseQuantity);
    const areLicenseUsersValid = await handleValidateLicenseUsers(
      maxLicenseUsers
    );
    const areTrialFieldsValid = await validateTrialFields();
    const areLicenseTypeFieldsValid = await validateLicenseType();
    const isFloatingValid = await validateFloating();
    const isMaintenanceValid = await validateMaintenance();
    const areProductFeaturesValid = await validateFeaturesConsumption(
      selectedProductFeatures
    );
    const areCustomFieldsValid = await validateCustomFields(
      selectedCustomFields
    );
    const isMaxLicenseUsersValid = isUserBasedProduct
      ? await validateValue(
          maxLicenseUsers,
          setMaxLicenseUsersError,
          unlimitedMaxLicenseUsers
        )
      : true;
    const isDeviceLimitValid = hasMaxTransfers
      ? await validateValue(maxTransfers, setMaxTransfersError)
      : true;
    const isStartDateValid = await validateDateField(
      startDate,
      setStartDateError,
      true
    );
    const isBorrowingValid = await validateBorrowing();
    const isGracePeriodValid = await validateGracePeriod();
    const isMetadataValid = await validateMetadata();

    return (
      isMaxActivationsValid &&
      isLicenseQuantityValid &&
      areLicenseUsersValid &&
      areTrialFieldsValid &&
      areLicenseTypeFieldsValid &&
      isFloatingValid &&
      isMaintenanceValid &&
      areProductFeaturesValid &&
      areCustomFieldsValid &&
      isMaxLicenseUsersValid &&
      isDeviceLimitValid &&
      isStartDateValid &&
      isBorrowingValid &&
      isGracePeriodValid &&
      isMetadataValid
    );
  };

  // ------------- SET LICENSE POLICY VALUES ----------------------------------------------------------------------------------------------------------------------------

  const setLicensePolicyValues = (selectedPol, selectedProd) => {
    setLicenseType(
      getPolicyValue('default_license_type', selectedPol, 'perpetual')
    );
    setLicenseTypeError('');

    // subscripton type ( legacy? - since this is controlled via integrations )
    setSubscriptionPeriodValue(
      getPolicyPeriodValue('valid_duration', selectedPol, '1y')
    );
    setSubscriptionPeriodLabel(
      getPolicyPeriodLabel(periodOptions, 'valid_duration', selectedPol, '1y')
    );
    setSubscriptionPeriodError('');
    setSubscriptionEndDate('');
    setSubscriptionEndDateError('');
    setGracePeriod(getPolicyValue('grace_period', selectedPol, 1));
    setGracePeriodError('');
    setAllowGracePeriod(
      getPolicyValue('allow_grace_period', selectedPol, false)
    );

    // time-limited
    setSelectedValidityType(
      getPolicyValue('validity_period', selectedPol, '')
        ? 'valid_until'
        : 'valid_from'
    );
    setTimeLimitedPeriodValue(
      getPolicyPeriodValue('valid_duration', selectedPol, '1y')
    );
    setTimeLimitedPeriodLabel(
      getPolicyPeriodLabel(periodOptions, 'valid_duration', selectedPol, '1y')
    );
    setTimeLimitedPeriodError('');
    setTimeLimitedEndDate(getPolicyValue('validity_period', selectedPol, ''));
    setTimeLimitedEndDateError('');

    // consumption
    setMaxConsumptions(getPolicyValue('max_consumptions', selectedPol, 1));
    setMaxConsumptionsError('');
    setAllowUnlimitedConsumptions(
      getPolicyValue('allow_unlimited_consumptions', selectedPol, false)
    );
    setAllowNegativeConsumptions(
      getPolicyValue('allow_negative_consumptions', selectedPol, false)
    );
    setAllowOverages(getPolicyValue('allow_overages', selectedPol, false));
    setSelectedOverageType(setDefaultOverageType(selectedPol));
    setMaxOverages(getPolicyValue('max_overages', selectedPol, 0));
    setMaxOveragesError('');
    setResetConsumption(
      getPolicyValue('reset_consumption', selectedPol, false)
    );
    setResetConsumptionPeriod(
      getPolicyResetConsumptionValue(
        consumptionPeriodOptions,
        selectedPol,
        'daily'
      )
    );

    // trial
    setTrial(getPolicyValue('allow_trial', selectedPol, false));
    setTrialDays(getPolicyValue('trial_days', selectedPol, 1));
    setTrialDaysError('');
    setTrialEndDate(get(selectedPol, 'validity_period'));
    setTrialEndDateError('');
    setSelectedTrialType(
      get(selectedPol, 'validity_period') ? trialTypes.endDate : trialTypes.days
    );

    setMaxActivations(getPolicyValue('max_activations', selectedPol, 1));
    setMaxActivationsError('');
    setAllowUnlimitedActivations(
      getPolicyValue('allow_unlimited_activations', selectedPol, false)
    );
    setPreventMaxTransfers(get(selectedPol, 'max_transfers') === -1);
    setHasMaxTransfers(getPolicyHasMaxTransfers(selectedPol));
    setMaxTransfers(getPolicyValue('max_transfers', selectedPol, 0));
    setMaxTransfersError('');
    setLicenseQuantity(1);
    setLicenseQuantityError('');
    setMaxLicenseUsers(1);
    setMaxLicenseUsersError('');
    setUnlimitedMaxLicenseUsers(false);
    setLicenseUsers({
      emails: [],
      emailError: '',
      value: '',
      validationError: ''
    });
    setPreventVm(getPolicyValue('prevent_vm', selectedPol, false));

    // floating
    setIsFloating(getPolicyValue('is_floating', selectedPol, false));
    setIsFloatingCloud(getPolicyValue('is_floating_cloud', selectedPol, false));
    setFloatingTimeout(getPolicyValue('floating_timeout', selectedPol, 120));
    setFloatingTimeoutError('');
    setFloatingUsers(getPolicyValue('floating_users', selectedPol, 1));
    setFloatingUsersError('');

    // maintenance
    setMaintenancePeriod(
      getPolicyValue('enable_maintenance_period', selectedPol, false)
    );
    setHasMaintenanceValue(
      getPolicyPeriodValue('maintenance_duration', selectedPol, '1y')
    );
    setHasMaintenancePeriod(
      getPolicyPeriodLabel(
        periodOptions,
        'maintenance_duration',
        selectedPol,
        '1y'
      )
    );
    setHasMaintenanceError('');
    setHasMaintenanceEndDate(
      getPolicyValue('maintenance_period', selectedPol, '')
    );
    setHasMaintenanceEndDateError('');

    // start_date
    setStartDate('');
    setStartDateError('');

    // license_borrowing
    setCanBorrow(getPolicyValue('can_borrow', selectedPol, false));
    setMaxBorrowTime(getPolicyValue('max_borrow_time', selectedPol, 0));

    setHwKeyAuth(getPolicyValue('is_hardware_key_auth', selectedPol, false));

    setProductFeatures(
      mapPolicyProductFeatures(get(selectedProd, 'data'), selectedPol)
    );
    setSelectedProductFeatures(
      formatPolicyFeatures(
        get(selectedPol, 'license_product_feature_templates') || []
      )
    );
    setProductFeaturesError(false);

    setCustomFields(
      mapPolicyCustomFields(get(selectedProd, 'data'), selectedPol)
    );
    setSelectedCustomFields(
      formatPolicyCFields(
        get(selectedPol, 'license_custom_field_templates') || []
      )
    );
    setCustomFieldsError('');

    setLicenseNote('');
    setMetadata(parseMetadata(getPolicyValue('metadata', selectedPol, false)));
    setMetadataError('');
  };

  // ------------- PRODUCT INFORMATION METHODS --------------------------------------------------------------------------------------------------------------------------
  const handleProductSelect = val => {
    const selectedP = products.find(p => get(p, 'product_name') === val);
    const defaultPolicy = getDefaultLicensePolicy(products, selectedP);
    setDirty(true);
    // reset state values
    const newSelectedProduct = {
      label: get(selectedP, 'product_name'),
      value: get(selectedP, 'product_name'),
      data: selectedP
    };
    setSelectedProduct(newSelectedProduct);
    setSelectedPolicy(defaultPolicy);
    setLicensePolicyValues(defaultPolicy, newSelectedProduct);
  };

  const handlePolicySelect = val => {
    const productPolicies =
      get(selectedProduct, 'data.license_templates') || [];
    const selectedPol = productPolicies.find(pp => pp.code === val);
    setSelectedPolicy(selectedPol);
    setLicensePolicyValues(selectedPol, selectedProduct);
  };

  const handleCustomValuesChange = val => {
    setDirty(true);
    setUseCustomValues(val);
    // reset everything and delete errors -> use currently selected policy
    setLicensePolicyValues(selectedPolicy, selectedProduct);
  };

  const handleAirGappedChange = val => {
    setDirty(true);
    setAirGapped(val);
    setUseCustomValues(false);
    if (val) {
      const data = productsForSelector
        .filter(p => {
          const pPolicies = get(p, 'data.license_templates');
          const hasAirGappedPolicy = pPolicies.some(pol => pol.is_air_gapped);
          return hasAirGappedPolicy;
        })
        .map(p => {
          const pPolicies = get(p, 'data.license_templates') || [];
          const airGappedPolicies = pPolicies.filter(pol => pol.is_air_gapped);
          const newData = { ...p.data, license_templates: airGappedPolicies };
          return {
            ...p,
            data: newData
          };
        });
      setProductsForSelector(data);
      setSelectedProduct(data[0]);
      const newProduct = get(data, '[0].data');
      const newPolicy = getDefaultLicensePolicy(
        data,
        newProduct,
        'is_air_gapped'
      );
      setSelectedPolicy(newPolicy);
      // reset everything and delete errors -> use currently selected policy
      setLicensePolicyValues(newPolicy, newProduct);
    } else {
      const productsList = mapProductsToSelector(products);
      const newProduct = get(productsList, '[0]');
      const newPolicy = getDefaultLicensePolicy(productsList, newProduct.data);
      setProductsForSelector(productsList);
      setSelectedProduct(newProduct);
      setSelectedPolicy(newPolicy);
      // reset everything and delete errors -> use currently selected policy
      setLicensePolicyValues(newPolicy, newProduct.data);
    }
  };

  // ------------- LICENSE TYPE METHODS --------------------------------------------------------------------------------------------------------------------------
  const handleLicenseTypeChange = val => {
    setDirty(true);
    setLicenseType(val);
    // reset license type related values here
    setSubscriptionPeriodValue(
      getPolicyPeriodValue('subscription_duration', selectedPolicy, '1y')
    );
    setSubscriptionPeriodLabel(
      getPolicyPeriodLabel(
        periodOptions,
        'subscription_duration',
        selectedPolicy,
        '1y'
      )
    );
    setSubscriptionPeriodError('');
    setSubscriptionEndDate(
      getInitialProductValue('validity_period', products, productToEdit, '')
    );
    setSubscriptionEndDateError('');
    setGracePeriod(getPolicyValue('grace_period', selectedPolicy, 1));
    setGracePeriodError('');
    setAllowGracePeriod(
      getPolicyValue('allow_grace_period', selectedPolicy, false)
    );
    setSelectedValidityType('valid_from');
    setTimeLimitedPeriodValue(
      getPolicyPeriodValue('valid_duration', selectedPolicy, '1y')
    );
    setTimeLimitedPeriodLabel(
      getPolicyPeriodLabel(
        periodOptions,
        'valid_duration',
        selectedPolicy,
        '1y'
      )
    );
    setTimeLimitedPeriodError('');
    setTimeLimitedEndDate(
      getInitialProductValue('validity_period', products, productToEdit, '')
    );
    setTimeLimitedEndDateError('');
    setMaxConsumptions(getPolicyValue('max_consumptions', selectedPolicy, 1));
    setMaxConsumptionsError('');
    setAllowUnlimitedConsumptions(
      getPolicyValue('allow_unlimited_consumptions', selectedPolicy, false)
    );
    setAllowNegativeConsumptions(
      getPolicyValue('allow_negative_consumptions', selectedPolicy, false)
    );
    setAllowOverages(getPolicyValue('allow_overages', selectedPolicy, false));
    setSelectedOverageType(setDefaultOverageType(selectedPolicy));
    setMaxOverages(getPolicyValue('max_overages', selectedPolicy, 0));
    setMaxOveragesError('');
    setResetConsumption(
      getPolicyValue('reset_consumption', selectedPolicy, false)
    );
    setResetConsumptionPeriod(
      getPolicyResetConsumptionValue(
        consumptionPeriodOptions,
        selectedPolicy,
        'daily'
      )
    );
  };

  const handleSubscriptionPeriodValueChange = val => {
    setDirty(true);
    setSubscriptionPeriodValue(val);
    validateValue(val, setSubscriptionPeriodError);
  };

  const handleSubscriptionPeriodLabelChange = val => {
    setDirty(true);
    setSubscriptionPeriodLabel(val);
  };

  const handleSubscriptionEndDateChange = val => {
    setDirty(true);
    setSubscriptionEndDate(val);
    validateDateField(val, setSubscriptionEndDateError, true);
  };

  const handleAllowGracePeriod = val => {
    setDirty(true);
    setAllowGracePeriod(val);
    setGracePeriod(getPolicyValue('grace_period', selectedPolicy, 1));
    setGracePeriodError('');
  };

  const handleGracePeriodChange = val => {
    setDirty(true);
    setGracePeriod(val);
    setGracePeriodError('');
  };

  const handleValidityTypeChange = val => {
    setDirty(true);
    setSelectedValidityType(val);
    setTimeLimitedPeriodError('');
    setTimeLimitedEndDate('');
    setTimeLimitedEndDateError('');
    setSubscriptionPeriodError('');
    setSubscriptionEndDate('');
    setSubscriptionEndDateError('');
  };

  const handleTimeLimitedPeriodValueChange = val => {
    setDirty(true);
    setTimeLimitedPeriodValue(val);
    validateValue(val, setTimeLimitedPeriodError);
  };

  const handleTimeLimitedPeriodLabelChange = val => {
    setDirty(true);
    setTimeLimitedPeriodLabel(val);
  };

  const handleTimeLimitedEndDateChange = val => {
    setDirty(true);
    setTimeLimitedEndDate(val);
    validateDateField(val, setTimeLimitedEndDateError);
  };

  const handleMaxConsumptionsChange = val => {
    setDirty(true);
    setMaxConsumptions(val);
    validateValue(val, setMaxConsumptionsError);
  };

  const handleAllowUnlimitedConsumptions = val => {
    setDirty(true);
    setAllowUnlimitedConsumptions(val);
    setMaxConsumptions(1);
    setMaxConsumptionsError('');
  };

  const handleAllowNegativeConsumptions = val => {
    setDirty(true);
    setAllowNegativeConsumptions(val);
  };

  const handleAllowOveragesChange = val => {
    setDirty(true);
    setAllowOverages(val);
    setMaxOverages(getPolicyValue('max_overages', selectedPolicy, 0));
    setMaxOveragesError('');
  };

  const handleSelectedOverageTypeChange = val => {
    setDirty(true);
    setSelectedOverageType(val);
    setMaxOveragesError('');
  };

  const handleMaxOveragesChange = val => {
    setDirty(true);
    setMaxOverages(val);
    validateValue(val, setMaxOveragesError, true);
  };

  const handleResetConsumptionChange = val => {
    setDirty(true);
    setResetConsumption(val);
  };

  const handleResetConsumptionPeriodChange = val => {
    setDirty(true);
    setResetConsumptionPeriod(val);
  };

  const handleTrialChange = val => {
    setDirty(true);
    setTrial(val);
    setTrialDays(
      getInitialValue('trial_days', selectedPolicy, selectedProduct.data, 1)
    );
    setTrialDaysError('');
    setTrialEndDate(
      getInitialValue(
        'validity_period',
        selectedPolicy,
        selectedProduct.data,
        ''
      )
    );
    setTrialEndDateError('');
  };

  const handleTrialTypeChange = val => {
    setDirty(true);
    setSelectedTrialType(val);
    setTrialDays(
      getInitialValue('trial_days', selectedPolicy, selectedProduct.data, 1)
    );
    setTrialDaysError('');
    setTrialEndDate(
      getInitialValue(
        'validity_period',
        selectedPolicy,
        selectedProduct.data,
        ''
      )
    );
    setTrialEndDateError('');
  };

  const handleTrialDaysChange = val => {
    setDirty(true);
    setTrialDays(val);
    validateValue(val, setTrialDaysError);
  };

  const handleTrialEndDateChange = val => {
    setDirty(true);
    setTrialEndDate(val);
    validateDateField(val, setTrialEndDateError);
  };

  // ------------- LICENSE OPTIONS METHODS --------------------------------------------------------------------------------------------------------------------------
  const handleMaxActivationsChange = val => {
    setDirty(true);
    setMaxActivations(val);
    validateMaxActivations(val);
  };

  const handleAllowUnlimitedActivations = val => {
    setDirty(true);
    setAllowUnlimitedActivations(val);
    setMaxActivations(1);
    setMaxActivationsError('');
    if (floatingUsers && !!val) {
      setFloatingUsersError('');
    }
  };

  const handleLicenseQuantityChange = val => {
    setDirty(true);
    setLicenseQuantity(val);
    validateLicenseQuantity(val);
  };

  const handleMaxLicenseUsers = val => {
    setDirty(true);
    setMaxLicenseUsers(val);
    validateValue(val, setMaxLicenseUsersError, unlimitedMaxLicenseUsers); // include zero if unlimited checked
    handleValidateLicenseUsers(val);
  };

  const handleUnlimitedMaxLicenseUsers = val => {
    setDirty(true);
    setUnlimitedMaxLicenseUsers(val);
    if (val) {
      setMaxLicenseUsers(0);
      setMaxLicenseUsersError('');
    }
  };

  const handleLicenseUsersChange = val => {
    setDirty(true);
    setLicenseUsers(val);
  };

  const handlePreventVmChange = val => {
    setDirty(true);
    setPreventVm(val);
  };

  const handlePreventMaxTransfersChange = val => {
    setDirty(true);
    setPreventMaxTransfers(val);
    setMaxTransfers(-1);
    if (val) {
      setHasMaxTransfers(false);
    }
  };

  const handleHasMaxTransfersChange = val => {
    setDirty(true);
    setHasMaxTransfers(val);
    setMaxTransfers(getPolicyValue('max_transfers', selectedPolicy, 0));
    setMaxTransfersError('');
    if (val) {
      setPreventMaxTransfers(false);
    }
  };

  const handleMaxTransfersChange = val => {
    setDirty(true);
    setMaxTransfers(val);
    validateValue(val, setMaxTransfersError);
  };

  // ------------- LICENSE USAGE METHODS --------------------------------------------------------------------------------------------------------------------------
  const handleIsFloatingChange = val => {
    setDirty(true);
    setIsFloating(val);
    setIsFloatingCloud(false);
    setFloatingUsersError('');

    if (!val) {
      setFloatingUsers(getPolicyValue('floating_users', selectedPolicy, 1));
    }
  };

  const handleIsFloatingCloudChange = val => {
    setDirty(true);
    setIsFloatingCloud(val);
    setIsFloating(false);
    setFloatingUsersError('');

    if (!val) {
      setFloatingUsers(getPolicyValue('floating_users', selectedPolicy, 1));
      setUnlimitedMaxLicenseUsers(false);
    }
    if (val && maxLicenseUsers === 0) {
      setMaxLicenseUsersError('');
      setUnlimitedMaxLicenseUsers(true);
    }
  };

  const handleFloatingUsersChange = val => {
    setDirty(true);
    setFloatingUsers(val);
    handleValidateFloatingUsers(val);
  };

  const handleFloatingTimeoutChange = val => {
    setDirty(true);
    setFloatingTimeout(val);
    validateValue(val, setFloatingTimeoutError);
  };

  const handleSelectedMaintenanceDurationType = val => {
    setDirty(true);
    setSelectedMaintenanceDurationType(val);
    setHasMaintenanceValue(
      getPolicyPeriodValue('maintenance_duration', selectedPolicy, '1y')
    );
    setHasMaintenancePeriod(
      getPolicyPeriodLabel(
        periodOptions,
        'maintenance_duration',
        selectedPolicy,
        '1y'
      )
    );
    setHasMaintenanceError('');
    setHasMaintenanceEndDate(get(selectedPolicy, 'maintenance_period') || '');
    setHasMaintenanceEndDateError('');
  };

  const handleProductHasMaintenanceChange = val => {
    setDirty(true);
    setMaintenancePeriod(val);
    setSelectedMaintenanceDurationType(validityTypes.duration);
    setHasMaintenanceValue(
      getPolicyPeriodValue('maintenance_duration', selectedPolicy, '1y')
    );
    setHasMaintenancePeriod(
      getPolicyPeriodLabel(
        periodOptions,
        'maintenance_duration',
        selectedPolicy,
        '1y'
      )
    );
    setHasMaintenanceError('');
    setHasMaintenanceEndDate(get(selectedPolicy, 'maintenance_period') || '');
    setHasMaintenanceEndDateError('');
  };

  const handleMaintenancePeriodValueChange = val => {
    setDirty(true);
    setHasMaintenanceValue(val);
    debouncedValidateRequiredNumber(val).then(err =>
      setHasMaintenanceError(err)
    );
  };

  const handleMaintenancePeriodLabelChange = val => {
    const selectedLabel = periodOptions.find(plo => plo.value === val);
    setDirty(true);
    // setMaintenancePeriodLabel(get(selectedLabel, 'value') || 'y');
    setHasMaintenancePeriod(get(selectedLabel, 'value') || 'y');
  };

  const handleMaintenanceEndDateChange = async val => {
    setDirty(true);
    setHasMaintenanceEndDate(val);
    const validationErr = await debouncedValidateRequiredNumber(val);
    setHasMaintenanceEndDateError(validationErr);
  };

  const handleStartDateChange = val => {
    setDirty(true);
    setStartDate(val);
    validateDateField(val, setStartDateError, true);
  };

  const handleLicenseNoteChange = val => {
    setDirty(true);
    setLicenseNote(val);
  };

  const handleMetadataChange = val => {
    setDirty(true);
    setMetadata(val);
    validateMetadata(val);
  };

  const handleCanBorrowChange = val => {
    setDirty(true);
    setCanBorrow(val);
    setMaxBorrowTime(
      getPolicyPeriodValue('max_borrow_time', selectedPolicy, 0)
    );
    setMaxBorrowTimeError('');
  };

  const handleMaxBorrowTimeChange = val => {
    setDirty(true);
    setMaxBorrowTime(val);
    validateValue(val, setMaxBorrowTimeError);
  };

  const handleRequireHwKey = val => {
    setDirty(true);
    setHwKeyAuth(val);
  };

  // ------------- PRODUCT FEATURES METHODS --------------------------------------------------------------------------------------------------------------------------
  const handleProductFeatureSelect = values => {
    setDirty(true);
    if (!selectedProductFeatures.length) {
      setSelectedProductFeatures(values);
      validateFeaturesConsumption(values);
      return true;
    }
    const newSelectedList = values.map(v => {
      const isSelected = selectedProductFeatures.find(
        sf => sf.value === v.value
      );
      if (isSelected) {
        return isSelected;
      }
      return v;
    });
    setSelectedProductFeatures(newSelectedList);
    validateFeaturesConsumption(newSelectedList);
    return true;
  };

  const handleFeaturesEdit = features => {
    setDirty(true);
    setSelectedProductFeatures(features);
    validateFeaturesConsumption(features);
  };

  // ------------- CUSTOM FIELDS METHODS --------------------------------------------------------------------------------------------------------------------------

  const handleCustomFieldsSelect = values => {
    setDirty(true);
    if (!selectedCustomFields.length) {
      setSelectedCustomFields(values);
      validateCustomFields(values);
      return true;
    }
    const newSelectedList = values.map(v => {
      const isSelected = selectedCustomFields.find(sf => sf.value === v.value);
      if (isSelected) {
        return isSelected;
      }
      return v;
    });
    setSelectedCustomFields(newSelectedList);
    validateCustomFields(newSelectedList);
    return true;
  };

  const handleFieldsEdit = values => {
    setDirty(true);
    setSelectedCustomFields(values);
    validateCustomFields(values);
  };

  // ------------- SUBMIT METHODS --------------------------------------------------------------------------------------------------------------------------

  const handleStepSubmit = async () => {
    const isValid = await isFormValid();
    if (loading || !isValid) {
      return false;
    }

    const data = getOrderData();
    const isEditing = !!productToEdit || false;
    return handleSubmit(data, isEditing);
  };

  const handleClose = () => {
    if (isDirty) {
      setDirtyFormDisplay(true);
    } else {
      closeCb();
    }
  };

  const shouldShowFeaturesTab =
    productFeatures.length > 0 &&
    checkCompanyConfigField(companyID, 'productFeatures');
  const shouldShowCustomFieldsTab = customFields.length > 0;
  const isTimeLimited =
    get(selectedPolicy, 'default_license_type') === licenseTypes.time_limited;
  const isSubscription =
    get(selectedPolicy, 'default_license_type') === licenseTypes.subscription;
  const isConsumption =
    get(selectedPolicy, 'default_license_type') === licenseTypes.consumption;

  const getTitle = () => (
    <div className="SelectProductFormTitle">
      <div className="title">{title}</div>
      <div className="confirm-btn">
        <Button
          theme="success"
          onClick={handleStepSubmit}
          disabled={loading || !selectedProduct}
        >
          {__('Confirm')}
        </Button>
      </div>
    </div>
  );

  return (
    <SlidePane
      closeCb={handleClose}
      isOpen={isOpen}
      title={getTitle()}
      width={width}
    >
      <div className="SelectProductForm">
        <Tabs forceRenderTabPanel>
          <TabsHeader>
            <Tab>{__('Main')}</Tab>
            {shouldShowFeaturesTab && useCustomValues && (
              <Tab>
                <CustomFormTab
                  error={productFeaturesErrors}
                  text={__('Product features')}
                />
              </Tab>
            )}
            {shouldShowCustomFieldsTab && useCustomValues && (
              <Tab>
                <CustomFormTab
                  error={customFieldsError}
                  text={__('Custom fields')}
                />
              </Tab>
            )}
          </TabsHeader>
          <TabContent>
            {isAirGapped && !productsForSelector.length && (
              <Notice
                theme="error"
                title={__('Missing products with air-gap license policies.')}
              >
                {__(
                  'There needs to be at least on product with air-gap license policy defined.'
                )}
              </Notice>
            )}
            <div className="SelectProductForm-main">
              <div className="sections-wrapper">
                <ProductInformation
                  products={productsForSelector}
                  handleProductSelect={handleProductSelect}
                  selectedProduct={selectedProduct}
                  selectedPolicy={get(selectedPolicy, 'code')}
                  handlePolicySelect={handlePolicySelect}
                  useCustomValues={useCustomValues}
                  setUseCustomValues={handleCustomValuesChange}
                  isAirGapped={isAirGapped}
                  handleAirGappedChange={handleAirGappedChange}
                />
                {useCustomValues && (
                  <LicenseTypeSection
                    companyID={companyID}
                    handleLicenseTypeChange={handleLicenseTypeChange}
                    licenseType={licenseType}
                    licenseTypeError={licenseTypeError}
                    periodOptions={periodOptions}
                    // subscription type
                    handleSubscriptionPeriodValueChange={
                      handleSubscriptionPeriodValueChange
                    }
                    subscriptionPeriodValue={subscriptionPeriodValue}
                    subscriptionPeriodError={subscriptionPeriodError}
                    subscriptionPeriodLabel={subscriptionPeriodLabel}
                    handleSubscriptionPeriodLabelChange={
                      handleSubscriptionPeriodLabelChange
                    }
                    handleSubscriptionEndDateChange={
                      handleSubscriptionEndDateChange
                    }
                    subscriptionEndDate={subscriptionEndDate}
                    subscriptionEndDateError={subscriptionEndDateError}
                    allowGracePeriod={allowGracePeriod}
                    handleAllowGracePeriod={handleAllowGracePeriod}
                    gracePeriod={gracePeriod}
                    gracePeriodError={gracePeriodError}
                    handleGracePeriodChange={handleGracePeriodChange}
                    // time-limited type
                    selectedValidityType={selectedValidityType}
                    handleValidityTypeChange={handleValidityTypeChange}
                    handleTimeLimitedPeriodValueChange={
                      handleTimeLimitedPeriodValueChange
                    }
                    timeLimitedPeriodValue={timeLimitedPeriodValue}
                    timeLimitedPeriodLabel={timeLimitedPeriodLabel}
                    handleTimeLimitedPeriodLabelChange={
                      handleTimeLimitedPeriodLabelChange
                    }
                    timeLimitedPeriodError={timeLimitedPeriodError}
                    handleTimeLimitedEndDateChange={
                      handleTimeLimitedEndDateChange
                    }
                    timeLimitedEndDate={timeLimitedEndDate}
                    timeLimitedEndDateError={timeLimitedEndDateError}
                    // consumption
                    handleMaxConsumptionsChange={handleMaxConsumptionsChange}
                    maxConsumptions={maxConsumptions}
                    maxConsumptionsError={maxConsumptionsError}
                    allowUnlimitedConsumptions={allowUnlimitedConsumptions}
                    handleAllowUnlimitedConsumptions={
                      handleAllowUnlimitedConsumptions
                    }
                    allowNegativeConsumptions={allowNegativeConsumptions}
                    handleAllowNegativeConsumptions={
                      handleAllowNegativeConsumptions
                    }
                    allowOverages={allowOverages}
                    handleAllowOveragesChange={handleAllowOveragesChange}
                    selectedOverageType={selectedOverageType}
                    handleSelectedOverageTypeChange={
                      handleSelectedOverageTypeChange
                    }
                    maxOverages={maxOverages}
                    maxOveragesError={maxOveragesError}
                    handleMaxOveragesChange={handleMaxOveragesChange}
                    resetConsumption={resetConsumption}
                    handleResetConsumptionChange={handleResetConsumptionChange}
                    consumptionPeriodOptions={consumptionPeriodOptions}
                    resetConsumptionPeriod={resetConsumptionPeriod}
                    handleResetConsumptionPeriodChange={
                      handleResetConsumptionPeriodChange
                    }
                    // trial
                    isTrial={isTrial}
                    handleTrialChange={handleTrialChange}
                    selectedTrialType={selectedTrialType}
                    handleTrialTypeChange={handleTrialTypeChange}
                    handleTrialDaysChange={handleTrialDaysChange}
                    trialDays={trialDays}
                    trialDaysError={trialDaysError}
                    handleTrialEndDateChange={handleTrialEndDateChange}
                    trialEndDate={trialEndDate}
                    trialEndDateError={trialEndDateError}
                  />
                )}
              </div>
              {useCustomValues && (
                <div className="sections-wrapper">
                  <LicenseOptionsSection
                    companyID={companyID}
                    maxActivations={maxActivations}
                    maxActivationsError={maxActivationsError}
                    allowUnlimitedActivations={allowUnlimitedActivations}
                    handleAllowUnlimitedActivations={
                      handleAllowUnlimitedActivations
                    }
                    handleMaxActivationsChange={handleMaxActivationsChange}
                    isUserBasedProduct={isUserBasedProduct}
                    licenseQuantity={licenseQuantity}
                    licenseQuantityError={licenseQuantityError}
                    handleLicenseQuantityChange={handleLicenseQuantityChange}
                    licenseUsers={licenseUsers}
                    handleLicenseUsersChange={handleLicenseUsersChange}
                    preventVm={preventVm}
                    handlePreventVmChange={handlePreventVmChange}
                    preventMaxTransfers={preventMaxTransfers}
                    setPreventMaxTransfers={handlePreventMaxTransfersChange}
                    hasMaxTransfers={hasMaxTransfers}
                    handleHasMaxTransfersChange={handleHasMaxTransfersChange}
                    maxTransfers={maxTransfers}
                    maxTransfersError={maxTransfersError}
                    handleMaxTransfersChange={handleMaxTransfersChange}
                    isFloatingCloud={isFloatingCloud}
                    maxLicenseUsers={maxLicenseUsers}
                    maxLicenseUsersError={maxLicenseUsersError}
                    handleMaxLicenseUsers={handleMaxLicenseUsers}
                    unlimitedMaxLicenseUsers={unlimitedMaxLicenseUsers}
                    handleUnlimitedMaxLicenseUsers={
                      handleUnlimitedMaxLicenseUsers
                    }
                  />
                  <LicenseUsageSection
                    companyID={companyID}
                    isFloating={isFloating}
                    isFloatingCloud={isFloatingCloud}
                    handleIsFloatingChange={handleIsFloatingChange}
                    handleIsFloatingCloudChange={handleIsFloatingCloudChange}
                    floatingUsers={floatingUsers}
                    floatingUsersError={floatingUsersError}
                    handleFloatingUsersChange={handleFloatingUsersChange}
                    floatingTimeout={floatingTimeout}
                    floatingTimeoutError={floatingTimeoutError}
                    handleFloatingTimeoutChange={handleFloatingTimeoutChange}
                    enableMaintenancePeriod={enableMaintenancePeriod}
                    handleProductHasMaintenanceChange={
                      handleProductHasMaintenanceChange
                    }
                    periodOptions={periodOptions}
                    maintenancePeriodValue={hasMaintenanceValue}
                    maintenancePeriodLabel={hasMaintenancePeriod}
                    maintenancePeriodError={hasMaintenanceError}
                    handleMaintenancePeriodValueChange={
                      handleMaintenancePeriodValueChange
                    }
                    handleMaintenancePeriodLabelChange={
                      handleMaintenancePeriodLabelChange
                    }
                    maintenanceEndDateError={hasMaintenanceEndDateError}
                    maintenanceEndDate={hasMaintenanceEndDate}
                    handleMaintenanceEndDateChange={
                      handleMaintenanceEndDateChange
                    }
                    selectedMaintenanceDurationType={
                      selectedMaintenanceDurationType
                    }
                    handleSelectedMaintenanceDurationType={
                      handleSelectedMaintenanceDurationType
                    }
                    startDate={startDate}
                    startDateError={startDateError}
                    handleStartDateChange={handleStartDateChange}
                    licenseNote={licenseNote}
                    handleLicenseNoteChange={handleLicenseNoteChange}
                    canBorrow={canBorrow}
                    setCanBorrow={handleCanBorrowChange}
                    maxBorrowTime={maxBorrowTime}
                    maxBorrowTimeError={maxBorrowTimeError}
                    setMaxBorrowTime={handleMaxBorrowTimeChange}
                    requireHwKey={isHwKeyAuth}
                    setRequireHwKey={handleRequireHwKey}
                    metadata={metadata}
                    metadataError={metadataError}
                    setMetadata={handleMetadataChange}
                  />
                </div>
              )}
              {!useCustomValues && (
                <DescriptionTable
                  details={[
                    {
                      label: __('License type'),
                      value: displayValue(
                        get(selectedPolicy, 'default_license_type')
                      )
                    },
                    {
                      label: __('Max activations'),
                      value: get(selectedPolicy, 'allow_unlimited_activations')
                        ? __('Unlimited')
                        : displayValue(get(selectedPolicy, 'max_activations'))
                    },
                    {
                      label: isUserBasedProduct
                        ? __('Max license users')
                        : null,
                      value:
                        get(selectedPolicy, 'unlimited_max_license_users') ||
                        get(selectedPolicy, 'max_license_users') === 0
                          ? __('Unlimited')
                          : displayValue(
                              get(selectedPolicy, 'max_license_users')
                            )
                    },
                    {
                      label: __('Is trial'),
                      value: isTrial ? __('Yes') : __('No')
                    },
                    {
                      label:
                        isTrial && !get(selectedPolicy, 'validity_period')
                          ? __('Trial days')
                          : null,
                      value: trialValidity
                    },
                    {
                      label:
                        isTimeLimited || isSubscription
                          ? __('Valid duration')
                          : null,
                      value: displayValue(get(selectedPolicy, 'valid_duration'))
                    },
                    {
                      label: get(selectedPolicy, 'validity_period')
                        ? __('Expiration Date')
                        : null,
                      value: formatDate(
                        get(selectedPolicy, 'validity_period'),
                        defaultDateFormat
                      )
                    },
                    // show consumption values
                    {
                      label: isConsumption ? __('Max consumptions') : null,
                      value: get(selectedPolicy, 'allow_unlimited_consumptions')
                        ? __('Unlimited')
                        : displayValue(get(selectedPolicy, 'max_consumptions'))
                    },
                    {
                      label: isConsumption ? __('Allow overages') : null,
                      value: get(selectedPolicy, 'allow_overages')
                        ? __('Yes')
                        : __('No')
                    },
                    {
                      label: isConsumption ? __('Max overages') : null,
                      value: displayMaxOveragesValue(selectedPolicy)
                    },
                    {
                      label: isConsumption ? __('Reset consumption') : null,
                      value: get(selectedPolicy, 'reset_consumption')
                        ? __('Yes')
                        : __('No')
                    },
                    {
                      label: isConsumption ? __('Consumption period') : null,
                      value: displayValue(
                        capitalizeFirstLetter(
                          get(selectedPolicy, 'consumption_period')
                        )
                      )
                    },
                    // show everything else
                    {
                      label: get(selectedPolicy, 'enable_maintenance_period')
                        ? __('Maintenance duration')
                        : null,
                      value: displayValue(
                        get(selectedPolicy, 'maintenance_duration')
                      )
                    },
                    {
                      label: get(selectedPolicy, 'is_floating')
                        ? __('Offline floating license')
                        : null,
                      value: get(selectedPolicy, 'is_floating')
                        ? __('Yes')
                        : __('No')
                    },
                    {
                      label: get(selectedPolicy, 'is_floating_cloud')
                        ? __('Is floating cloud')
                        : null,
                      value: get(selectedPolicy, 'is_floating_cloud')
                        ? __('Yes')
                        : __('No')
                    },
                    {
                      label:
                        get(selectedPolicy, 'is_floating') ||
                        get(selectedPolicy, 'is_floating_cloud')
                          ? __('Max simultaneous license users')
                          : null,
                      value: displayValue(get(selectedPolicy, 'floating_users'))
                    },
                    {
                      label:
                        get(selectedPolicy, 'is_floating') ||
                        get(selectedPolicy, 'is_floating_cloud')
                          ? __('Floating timeout')
                          : null,
                      value: `${displayValue(
                        get(selectedPolicy, 'floating_timeout')
                      )} min`
                    },
                    {
                      label: get(selectedPolicy, 'can_borrow')
                        ? __('Can borrow')
                        : null,
                      value: get(selectedPolicy, 'can_borrow')
                        ? __('Yes')
                        : __('No')
                    },
                    {
                      label: get(selectedPolicy, 'can_borrow')
                        ? __('Max borrow time')
                        : null,
                      value: `${displayValue(
                        get(selectedPolicy, 'max_borrow_time')
                      )} ${__('hours')}`
                    },
                    {
                      label: get(selectedPolicy, 'max_transfers')
                        ? __('Device transfer limit')
                        : null,
                      value:
                        get(selectedPolicy, 'max_transfers') === -1
                          ? __('Device transfer not allowed')
                          : displayValue(get(selectedPolicy, 'max_transfers'))
                    },
                    {
                      label: __('Prevent virtual machine'),
                      value: get(selectedPolicy, 'prevent_vm')
                        ? __('Yes')
                        : __('No')
                    },
                    {
                      label: shouldShowFeaturesTab
                        ? __('Product features')
                        : null,
                      // value: <SelectedFeaturesTags features={get(selectedPolicy, 'license_product_feature_templates')} />,
                      value: (
                        <SelectedFeaturesTags
                          features={selectedProductFeatures}
                        />
                      )
                    },
                    {
                      label: shouldShowCustomFieldsTab
                        ? __('Custom fields')
                        : null,
                      // value: <SelectedCustomFieldsTags cFields={get(selectedPolicy, 'license_custom_field_templates')} />,
                      value: (
                        <SelectedCustomFieldsTags
                          cFields={selectedCustomFields}
                        />
                      )
                    },
                    {
                      label: get(selectedPolicy, 'metadata')
                        ? __('Metadata')
                        : null,
                      value: (
                        <JsonView
                          value={get(selectedPolicy, 'metadata')}
                          name="metadata"
                        />
                      )
                    }
                  ]}
                />
              )}
            </div>
          </TabContent>
          {shouldShowFeaturesTab && useCustomValues && (
            <TabContent>
              <ProductFeaturesTab
                productFeatures={productFeatures}
                handleProductFeatureSelect={handleProductFeatureSelect}
                selectedProductFeatures={selectedProductFeatures}
                handleFeaturesEdit={handleFeaturesEdit}
              />
            </TabContent>
          )}
          {shouldShowCustomFieldsTab && useCustomValues && (
            <TabContent>
              <CustomFieldsTab
                planType={planType}
                customFields={customFields}
                selectedCustomFields={selectedCustomFields}
                handleCustomFieldsSelect={handleCustomFieldsSelect}
                setCustomFieldsTabError={val => setCustomFieldsError(val)}
                handleFieldsEdit={handleFieldsEdit}
              />
            </TabContent>
          )}
        </Tabs>
        {isDirtyFormDisplayed && (
          <DirtyFormAlert
            dirty={isDirty}
            closeAlert={() => setDirtyFormDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </SlidePane>
  );
};

SelectProductForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  productToEdit: PropTypes.object,
  title: PropTypes.string.isRequired,
  width: PropTypes.string
};

SelectProductForm.defaultProps = {
  productToEdit: null,
  width: '95%'
};

export default SelectProductForm;
