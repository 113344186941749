import { get } from 'lodash';

export const mapUserLabelsToSelector = labels =>
  labels.map(l => ({
    value: l.id,
    label: l.name,
    data: l
  }));

export const getAvailableLabels = (labels, user) => {
  const userLabels = get(user, 'roles') || [];
  const result = labels.filter(l => {
    const isAlreadyUsed = userLabels.find(u => u.id === l.id);
    if (!isAlreadyUsed) {
      return true;
    }
    return false;
  });
  return result;
};
