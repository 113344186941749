import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { generatePass, sendErrorReport } from 'shared/helpers';
import {
  Checkbox,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  NumberInput,
  TextInput,
  PhoneNumberInput
} from 'shared/components';
import { errorMsg } from 'shared/constants';
import {
  validateEmail,
  debouncedValidateEmail,
  validateRequiredValue,
  debouncedValidateRequiredValue,
  validateRequiredNumber
} from 'shared/validation';
import { addLicenseUser, checkExistingUser } from 'src/license/actions';
import { addOrderManager } from 'src/order/actions';
import ExistingUserForm from '../ExistingUserForm';
import './styles.scss';

const AssignLicenseUser = ({
  closeCb,
  companyID,
  licenseID,
  orderID,
  refetchLicense,
  licenseUsers
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);
  // existing user
  const [existingUser, setExistingUser] = useState(null);
  const [isExistingUserFormDisplayed, setExistingUserFormDisplay] = useState(
    null
  );
  // form state
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [maxActivations, setMaxActivations] = useState(1);
  const [maxActivationsError, setMaxActivationsError] = useState('');
  const [isLicenseManager, setLicenseManager] = useState(false);
  const [displayPassword, setPasswordDisplay] = useState(true);
  const [password, setPassword] = useState(generatePass());
  const [passwordError, setPasswordError] = useState('');

  const checkUsersList = val => {
    const licenseUsersEmailList = licenseUsers.map(u =>
      u.true_email.toLowerCase()
    );
    const doesEmailExist = licenseUsersEmailList.includes(val.toLowerCase());
    return doesEmailExist;
  };

  const handleEmailChange = val => {
    const isUserAlreadyAdded = checkUsersList(val);

    if (isUserAlreadyAdded) {
      setDirty(true);
      setEmail(val);
      setEmailError(__('User with this email is already added to the list'));
      return false;
    }

    setDirty(true);
    setEmail(val);
    debouncedValidateEmail(val).then(err => setEmailError(err));
    return true;
  };

  const validateUserEmail = async () => {
    const isUserAlreadyAdded = checkUsersList(email);
    if (isUserAlreadyAdded) {
      setEmailError(__('User with this email is already added to the list'));
      return false;
    }

    setLoading(true);
    let errors;
    try {
      errors = await validateEmail(email);
      setEmailError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate license user form email value', {
        value: email
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validatePassword = async () => {
    if (!displayPassword) {
      return true;
    }

    setLoading(true);
    let errors;
    try {
      errors = await validateRequiredValue(password);
      if (password.length < 8) {
        errors = errorMsg.passCharNum;
      }
      setPasswordError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate license user form password value', {
        value: password
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validateMaxActivations = async val => {
    setLoading(true);
    let errors;

    try {
      errors = await validateRequiredNumber(val);
      setLoading(false);
      setMaxActivationsError(errors);
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate max activations', { value: val });
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const handleMaxActivationsChange = val => {
    setDirty(true);
    setMaxActivations(val);
    validateMaxActivations(val);
  };

  const isFormValid = async () => {
    const isEmailValid = await validateUserEmail();
    const isPasswordValid = await validatePassword();
    const isMaxActivationsValid = await validateMaxActivations(maxActivations);
    return isEmailValid && isPasswordValid && isMaxActivationsValid;
  };

  const handleSuccess = msg => {
    refetchLicense();
    closeCb();
    Notification('success', __('Changes saved successfully'), msg);
  };

  const setAsManager = successMsg => {
    addOrderManager(orderID, companyID, { email })
      .then(() => handleSuccess(successMsg))
      .catch(err => {
        sendErrorReport(err, 'Cannot set license user as manager', {
          value: email
        });
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const changeManagerStatusIfNeeded = successMsg => {
    if (isLicenseManager) {
      setAsManager(successMsg);
    } else {
      handleSuccess(successMsg);
    }
    return true;
  };

  const addUser = () => {
    const succesMsg = __('License user added');
    const pass = displayPassword ? password : undefined;
    const data = {
      email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      password: pass,
      max_activations: Number(maxActivations) || 1
    };

    addLicenseUser(licenseID, data, companyID)
      .then(res => {
        const newUserData = get(res, 'data');
        const newUserID = get(newUserData, 'id');
        changeManagerStatusIfNeeded(succesMsg, newUserID);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot add license user', data);
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const checkIfUserExisting = () => {
    checkExistingUser(email, companyID)
      .then(res => {
        const count = get(res, 'data.count');
        if (count < 1) {
          addUser();
          return true;
        }

        const existingUserData = get(res, 'data.results[0]');
        setExistingUser(existingUserData);
        setExistingUserFormDisplay(true);
        return true;
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot check for existing license user', {
          value: email
        });
        setLoading(false);
        Notification(
          'error',
          __('Error occured'),
          __('Your changes were not saved')
        );
      });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setDirty(false);
    setLoading(true);
    checkIfUserExisting();
    return true;
  };

  const handleClose = () => {
    if (!isDirty) {
      return closeCb();
    }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      title={__('Assign license user')}
    >
      <form className="AssignLicenseUser" onSubmit={handleSubmit}>
        <div className="form-inner">
          <div className="left">
            <div className="form-row">
              <Label text={__('Email')} inputId="email" />
              <TextInput
                id="email"
                value={email}
                error={emailError}
                handleChange={handleEmailChange}
              />
            </div>
            <div className="form-row">
              <Label text={__('First Name')} inputId="firstname" />
              <TextInput
                id="firstname"
                value={firstName}
                handleChange={val => {
                  setDirty(true);
                  setFirstName(val);
                }}
              />
            </div>
            <div className="form-row">
              <Label text={__('Last Name')} inputId="lastname" />
              <TextInput
                id="lastname"
                value={lastName}
                handleChange={val => {
                  setDirty(true);
                  setLastName(val);
                }}
              />
            </div>
            <div className="form-row">
              <Label text={__('Phone Number')} inputId="phone" />
              <PhoneNumberInput
                value={phoneNumber}
                handleChange={val => {
                  setDirty(true);
                  setPhoneNumber(val);
                }}
              />
            </div>
          </div>
          <div className="divider active" />
          <div className="right">
            <Label
              text={__('License management')}
              inputId="license-management"
            />
            <div className="section-row">
              <Label
                text={__('Max Activations')}
                inputId="max-activations-input"
              />
              <NumberInput
                handleChange={handleMaxActivationsChange}
                value={maxActivations}
                error={maxActivationsError}
                min="1"
                max="2147483647"
                id="max-activations-input"
              />
            </div>
            <div className="license-manager-input">
              <Checkbox
                label={__('Is license manager')}
                inputId="license-manager"
                checked={isLicenseManager}
                handleChange={val => {
                  setDirty(true);
                  setLicenseManager(val);
                }}
              />
            </div>
            <div className="password-input">
              <Checkbox
                label={__('Set password')}
                inputId="password-manager"
                disabled
                checked={displayPassword}
                handleChange={val => {
                  setDirty(true);
                  setPasswordDisplay(val);
                  setPassword(generatePass());
                  setPasswordError('');
                }}
              />
              {displayPassword && (
                <div className=" form-row password-container">
                  <Label text={__('Password')} inputId="password" />
                  <TextInput
                    id="password"
                    value={password}
                    error={passwordError}
                    handleChange={val => {
                      setDirty(true);
                      setPassword(val);
                      debouncedValidateRequiredValue(val).then(err =>
                        setPasswordError(err)
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <button type="submit" style={{ visibility: 'hidden' }} />
      </form>
      {isDirtyFormDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
      {isExistingUserFormDisplayed && (
        <ExistingUserForm
          existingUser={existingUser}
          closeCb={() => {
            setLoading(false);
            setExistingUserFormDisplay(false);
            setExistingUser(null);
          }}
          confirmCb={() => handleSuccess(__('License user added'))}
          licenseID={licenseID}
          companyID={companyID}
          orderID={orderID}
          initialMaxActivations={maxActivations}
          initalIsLicenseManager={isLicenseManager}
        />
      )}
    </Modal>
  );
};

AssignLicenseUser.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  licenseID: PropTypes.number.isRequired,
  orderID: PropTypes.number.isRequired,
  refetchLicense: PropTypes.func.isRequired,
  licenseUsers: PropTypes.array
};

AssignLicenseUser.defaultProps = {
  licenseUsers: []
};

export default AssignLicenseUser;
