import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  Button,
  ClipboardRow,
  Notice,
  DescriptionTable,
  Notification
} from 'shared/components';
import { updateIntegration } from 'src/company/actions';
import { maskString, sendErrorReport } from 'shared/helpers';
import StripeForm from './StripeForm';
import { ReactComponent as StripeLogo } from './stripe-logo.svg';

const Stripe = ({ integrations, refreshIntegration }) => {
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const companyID = get(companyDetails, 'id');
  const stripeIntegration = integrations.find(i => i.code === 'stripe');
  const integrationID = get(stripeIntegration, 'id');
  const isActive = get(stripeIntegration, 'is_active');

  const [isFormDisplayed, setFormDisplay] = useState(false);
  const [isDeactivating, setDeactivating] = useState(false);

  const handleDeactivate = () => {
    setDeactivating(true);

    const data = {
      ...stripeIntegration,
      is_active: false
    };
    updateIntegration(integrationID, companyID, data)
      .then(() => {
        refreshIntegration();
      })
      .catch(err => {
        setDeactivating(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
        sendErrorReport(err);
      });
  };

  if (!isActive) {
    return (
      <div className="Integrations">
        <div className="row">
          <StripeLogo width="120px" fill="#635AFF" />
          <div className="docs-link">
            <p>{__('Find integration documentation')}</p>
            <a
              href="https://docs.licensespring.com/integrations/stripe"
              target="_blank"
              rel="noopener noreferrer"
            >
              {__('here')}
            </a>
          </div>
          <Notice
            size="sm"
            theme="default"
            title={__('Integration has not been activated.')}
          />
          <Button theme="info" size="sm" onClick={() => setFormDisplay(true)}>
            {__('Activate')}
          </Button>
        </div>
        {isFormDisplayed && (
          <StripeForm
            closeCb={() => setFormDisplay(false)}
            stripeIntegration={stripeIntegration}
            onConfirm={() => {
              setFormDisplay(false);
              refreshIntegration();
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div className="Integrations">
      <div className="row">
        <StripeLogo width="120px" fill="#635AFF" />
        <div className="docs-link">
          <p>{__('Find integration documentation')}</p>
          <a
            href="https://docs.licensespring.com/integrations/stripe"
            target="_blank"
            rel="noopener noreferrer"
          >
            {__('here')}
          </a>
        </div>
        <Notice
          size="sm"
          theme="success"
          title={__('Integration has been activated.')}
        />
        <DescriptionTable
          details={[
            {
              label: __('Secret Key'),
              value: maskString(
                get(stripeIntegration, 'data.stripe_secret_key'),
                12
              )
            },
            {
              label: __('Management API key'),
              value: (
                <ClipboardRow
                  value={get(stripeIntegration, 'data.management_api_key')}
                  hideLabel
                  label={__('Management API key')}
                  id="management-api-key"
                />
              )
            },
            {
              label: __('License API key'),
              value: (
                <ClipboardRow
                  value={get(stripeIntegration, 'data.license_api_key')}
                  hideLabel
                  label={__('License API key')}
                  id="license-api-key"
                />
              )
            }
          ]}
        />
        <div className="actions">
          <Button
            theme="error"
            size="sm"
            loading={isDeactivating}
            disabled={isDeactivating}
            onClick={handleDeactivate}
          >
            {__('Deactivate')}
          </Button>
          <Button
            theme="default"
            size="sm"
            onClick={() => setFormDisplay(true)}
          >
            {__('Edit')}
          </Button>
        </div>
      </div>
      {isFormDisplayed && (
        <StripeForm
          closeCb={() => setFormDisplay(false)}
          stripeIntegration={stripeIntegration}
          onConfirm={() => {
            setFormDisplay(false);
            refreshIntegration();
          }}
        />
      )}
    </div>
  );
};

Stripe.propTypes = {
  refreshIntegration: PropTypes.func.isRequired,
  integrations: PropTypes.array
};

Stripe.defaultProps = {
  integrations: []
};

export default Stripe;
