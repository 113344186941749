import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { sendErrorReport, formatDate } from 'shared/helpers';
import { eventsConfig } from 'shared/constants';
import {
  Button,
  DescriptionTable,
  EventStatus,
  List,
  IconResendMail,
  Notification
} from 'shared/components';
import { getNotificationsHistory } from 'src/notifications/actions';
import ResendForm from './ResendForm';

const mapRecipients = (recipientsList = []) => {
  if (!Array.isArray(recipientsList) || !recipientsList.length) return '-';
  return recipientsList.map(r => r.email).join(', ');
};

const NotificationHistory = () => {
  const notificationPolicies = useSelector(state =>
    get(state, 'notifications.notification_policies')
  );
  const companyID = useSelector(state => get(state, 'company.details.id'));
  // table state
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentSort, setSort] = useState([
    {
      desc: true,
      id: 'created_at'
    }
  ]);
  const [tableExpanded, setTableExpanded] = useState({});

  const [showResendPopup, setResendPopupDisplay] = useState(false);
  const [notificationToResend, setNotificationToResend] = useState(null);

  const fetchData = useCallback(
    (tablePage = 0, sort = currentSort, rows = rowsPerPage) => {
      setLoading(true);
      getNotificationsHistory(companyID, tablePage, sort, rows)
        .then(res => {
          setData(get(res, 'data.results') || []);
          setDataCount(get(res, 'data.count'));
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          sendErrorReport(err, 'Cannot fetch notification history list');
        });
    },
    [companyID]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const doesEventHasNotificationPolicy = notificationData => {
    const evtCode = get(notificationData, 'event.code');
    const doesExist = notificationPolicies.find(
      np => get(np, 'event.code') === evtCode
    );
    return !!doesExist;
  };

  const handlePageChange = newPage => {
    setPage(newPage);
    fetchData(newPage, currentSort, rowsPerPage);
  };

  const handleSortChange = newSorted => {
    setSort(newSorted);
    fetchData(0, newSorted, rowsPerPage);
  };

  const handlePageSizeChange = (newPageSize, newPage) => {
    setPage(newPage);
    setRowsPerPage(newPageSize);
    fetchData(newPage, currentSort, newPageSize);
  };

  return (
    <div className="NotificationHistory">
      <List
        columns={[
          {
            expander: true,
            Header: __('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none'
            }
          },
          {
            accessor: 'event.code',
            Header: __('Event'),
            Cell: cellInfo => {
              const { value } = cellInfo;
              const label = eventsConfig[value]
                ? get(eventsConfig, `${value}.label`)
                : '-';
              return label;
            }
          },
          {
            accessor: 'created_at',
            Header: __('Date'),
            Cell: cellInfo => formatDate(cellInfo.value),
            width: 150
          },
          {
            accessor: 'result.data.order_id',
            Header: __('Order ID'),
            Cell: cellInfo => {
              const { value } = cellInfo;
              // if (!value) { return '-'; }
              // return (
              //   <a href={`${companyID}/orders/${value}`}>{value}</a>
              // );
              return value || '-';
            }
          },
          {
            accessor: 'result.data.license_id',
            Header: __('License ID'),
            Cell: cellInfo => {
              const { value } = cellInfo;
              return value || '-';
            }
          },
          {
            accessor: 'result.data.product_id',
            Header: __('Product ID'),
            Cell: cellInfo => {
              const { value } = cellInfo;
              return value || '-';
            }
          },
          {
            accessor: 'result.status',
            Header: __('Status'),
            Cell: cellInfo => <EventStatus status={cellInfo.value} />,
            width: 100
          },
          {
            Header: __('Resend'),
            headerClassName: 'text-center',
            className: 'text-center',
            id: 'edit',
            width: 80,
            sortable: false,
            Cell: rowData => (
              <Button
                className="table-button"
                onClick={() => {
                  const row = get(rowData, 'original');
                  const hasPolicy = doesEventHasNotificationPolicy(row);
                  if (!hasPolicy) {
                    Notification(
                      'error',
                      __('Notification policy for this event does not exist')
                    );
                  } else {
                    setNotificationToResend(row);
                    setResendPopupDisplay(true);
                  }
                }}
                type="button"
              >
                <IconResendMail height={24} width={24} />
              </Button>
            )
          }
        ]}
        data={data}
        page={page}
        pages={Math.ceil(dataCount / rowsPerPage)}
        loading={isLoading}
        manual
        minRows={get(data, 'length') || 10}
        showPagination={dataCount > 5}
        onPageChange={handlePageChange}
        onSortedChange={handleSortChange}
        defaultSorted={currentSort}
        clickable={false}
        // handleClick={rowData => redirectToLicensePage(rowData)}
        showPageSizeOptions
        pageSize={rowsPerPage}
        onPageSizeChange={(pageSize, pageIndex) =>
          handlePageSizeChange(pageSize, pageIndex)
        }
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={row => (
          <div className="SubComponent">
            <DescriptionTable
              details={[
                {
                  label: __('Recipients'),
                  value: mapRecipients(
                    get(row, 'original.result.recipients_data')
                  )
                }
              ]}
            />
          </div>
        )}
      />
      {showResendPopup && (
        <ResendForm
          closeCb={() => {
            setResendPopupDisplay(false);
            setNotificationToResend(null);
          }}
          confirmCb={() => {
            setResendPopupDisplay(false);
            setNotificationToResend(null);
            fetchData(page, currentSort, rowsPerPage);
          }}
          notification={notificationToResend}
          companyID={companyID}
        />
      )}
    </div>
  );
};

export default NotificationHistory;
