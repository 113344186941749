import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  CheckboxSelector,
  IconCheckmark,
  IconDisabled,
  IconExport,
  IconFilter,
  IconSearch,
  Label,
  List,
  Notification,
  Page,
  Selector,
  SimpleFilterTag,
  TextInput
} from 'shared/components';
import {
  orderListColumns,
  platformFeatures,
  initialOrderListSize,
  initialOrdersListFilter,
  searchType
} from 'shared/constants';
import {
  capitalizeFirstLetter,
  displayValue,
  formatDate,
  getDisabledMessage,
  getTableColumnObjects,
  getTableColumnValues,
  getTableCustomerName,
  isFeatureAvailable,
  isFeatureEnabled,
  mapCustomerLabelsToSelector,
  sendErrorReport
} from 'shared/helpers';
import { checkCompanyConfigField } from 'shared/companyConfig';
import { updateUserPreferences } from 'src/company/actions';
import ExportOrderForm from '../../license/ExportOrderForm';
import { getOrders, updateUIOptions } from '../actions';
import { transformSort } from './helpers';
import './styles.scss';

const OrderList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const products = useSelector(state => get(state, 'products.list'));
  const uiOptions = useSelector(state =>
    get(state, 'company.userPreferences.ui_options')
  );
  const userPrefsID = useSelector(state =>
    get(state, 'company.userPreferences.id')
  );
  const companyCustomerLabels = useSelector(state =>
    get(state, 'company.customerLabels')
  );

  const [isLoading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [exportModalDisplayed, setExportModalDisplay] = useState(false);
  // table state
  const [ordersCount, setOrdersCount] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    get(uiOptions, 'list_size.orders') || initialOrderListSize
  );
  const [currentSort, setSort] = useState(get(uiOptions, 'list_sort.orders'));
  const [columnsToShow, setColumnsToShow] = useState(
    getTableColumnObjects(
      get(uiOptions, 'list_columns.orders'),
      orderListColumns
    )
  );
  const [changeColumnsDirty, setChangeColumnsDirty] = useState(false);
  // search state
  const [query, setQuery] = useState('');
  const [activeQuery, setActiveQuery] = useState('');
  const [searchBy, setSearchBy] = useState(get(searchType, 'all'));
  // filters
  const [showFilters, setFiltersDisplay] = useState(false);
  const [
    selectedCustomerLabelFilters,
    setSelectedCustomerLabelFilters
  ] = useState(
    get(uiOptions, 'list_filters.orders.clabels') ||
      initialOrdersListFilter.clabels
  );

  const fetchOrders = useCallback(
    (
      tablePage = 0,
      activeQ = undefined,
      searchTypeSelect = undefined,
      sort = currentSort,
      rows = rowsPerPage,
      filters = selectedCustomerLabelFilters
    ) => {
      setLoading(true);
      getOrders(
        companyID,
        tablePage,
        activeQ,
        searchTypeSelect,
        transformSort(sort),
        rows,
        filters
      )
        .then(res => {
          setOrders(get(res, 'data.results') || []);
          setOrdersCount(get(res, 'data.count'));
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          sendErrorReport(err, 'Cannot fetch orders list');
        });
    },
    [companyID]
  );

  const patchUiFeatures = (
    newSorted = undefined,
    newPageSize = undefined,
    newFilters = undefined
  ) => {
    const sortObject = newSorted || currentSort;
    const listPageSize = newPageSize || rowsPerPage;
    const clabelsFilters =
      newFilters || get(uiOptions, 'list_filters.orders') || {};

    if (
      (!changeColumnsDirty && !newSorted && !newPageSize && !newFilters) ||
      !userPrefsID
    ) {
      return false;
    }

    const listColumns = get(uiOptions, 'list_columns') || {};
    const listSort = get(uiOptions, 'list_sort') || {};
    const listSize = get(uiOptions, 'list_size') || {};
    const listFilter = get(uiOptions, 'list_filters') || {};

    listColumns.orders = getTableColumnValues(columnsToShow);
    listSort.orders = sortObject;
    listSize.orders = listPageSize;
    listFilter.orders = clabelsFilters;

    const ui = {
      ...uiOptions,
      list_columns: listColumns,
      list_sort: listSort,
      list_size: listSize,
      list_filters: listFilter
    };
    const data = { ui_options: JSON.stringify(ui) };

    updateUIOptions(userPrefsID, companyID, data)
      .then(res => {
        dispatch(updateUserPreferences(res.data));
        setChangeColumnsDirty(false);
      })
      .catch(err => sendErrorReport(err, 'Ui options update failed', data));
    return true;
  };

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handleSearchSubmit = e => {
    e.preventDefault();
    setActiveQuery(query);
    setPage(0);
    fetchOrders(
      0,
      query,
      searchBy,
      currentSort,
      rowsPerPage,
      selectedCustomerLabelFilters
    );
  };

  const handleSearchClear = () => {
    setQuery('');
    setActiveQuery('');
    setPage(0);
    fetchOrders(
      0,
      undefined,
      undefined,
      currentSort,
      rowsPerPage,
      selectedCustomerLabelFilters
    );
  };

  const redirectToNewOrderView = () => {
    if (Array.isArray(products) && !products.length) {
      Notification(
        'error',
        __('Company has no products'),
        __('Add products to company before creating new order')
      );
      return;
    }
    history.push(`/${companyID}/licenses/issue-licenses`);
  };

  const handleClabelsSelect = (newClabelFilters = undefined) => {
    if (!selectedCustomerLabelFilters || !selectedCustomerLabelFilters.length) {
      return false;
    }
    fetchOrders(
      0,
      activeQuery,
      searchBy,
      currentSort,
      rowsPerPage,
      newClabelFilters || selectedCustomerLabelFilters
    );
    patchUiFeatures(undefined, undefined, {
      clabels: newClabelFilters || selectedCustomerLabelFilters
    });
    return true;
  };

  const handleClearFilters = () => {
    if (!selectedCustomerLabelFilters || !selectedCustomerLabelFilters.length) {
      return false;
    }
    setSelectedCustomerLabelFilters([]);
    fetchOrders(0, activeQuery, searchBy, currentSort, rowsPerPage, []);
    patchUiFeatures(undefined, undefined, { clabels: [] });
    return true;
  };

  const shouldShowColumn = name => {
    const values = columnsToShow.map(column => column.value);
    return !!values.includes(name);
  };

  const redirectToOrderPage = rowData => {
    const orderId = get(rowData, 'original.id');
    history.push(`/${companyID}/orders/${orderId}`);
  };

  const handlePageChange = newPage => {
    setPage(newPage);
    fetchOrders(
      newPage,
      activeQuery,
      searchBy,
      currentSort,
      rowsPerPage,
      selectedCustomerLabelFilters
    );
  };

  const handleSortChange = newSorted => {
    setSort(newSorted);
    fetchOrders(
      0,
      activeQuery,
      searchBy,
      newSorted,
      rowsPerPage,
      selectedCustomerLabelFilters
    );
    patchUiFeatures(newSorted);
  };

  const handlePageSizeChange = (newPageSize, newPage) => {
    setPage(newPage);
    setRowsPerPage(newPageSize);
    fetchOrders(
      newPage,
      activeQuery,
      searchBy,
      currentSort,
      newPageSize,
      selectedCustomerLabelFilters
    );
    patchUiFeatures(undefined, newPageSize);
  };

  return (
    <div className="OrderList">
      <Page title={__('All Orders')}>
        <div className="list-header">
          <div>
            <div className="search-selector">
              <Selector
                handleChange={val => setSearchBy(val)}
                options={[
                  { label: __('Search by all'), value: searchType.all },
                  { label: __('Search by order'), value: searchType.order },
                  {
                    label: __('Search by customer email'),
                    value: searchType.user
                  },
                  {
                    label: __('Search by customer name'),
                    value: searchType.customerName
                  },
                  {
                    label: __('Search by customer company'),
                    value: searchType.customerCompany
                  },
                  {
                    label: __('Search by customer reference'),
                    value: searchType.customerReference
                  },
                  {
                    label: __('Search by order reference'),
                    value: searchType.orderReference
                  },
                  {
                    label: __('Search by account name'),
                    value: searchType.customerAccountName
                  },
                  {
                    label: __('Search by account code'),
                    value: searchType.customerAccountCode
                  },
                  {
                    label: __('Search by account reference'),
                    value: searchType.customerAccountReference
                  }
                ]}
                value={searchBy}
              />
            </div>
            <form onSubmit={handleSearchSubmit} style={{ height: '34px' }}>
              <TextInput handleChange={val => setQuery(val)} value={query} />
              {activeQuery && (
                <button
                  type="button"
                  onClick={handleSearchClear}
                  disabled={isLoading}
                >
                  &times;
                </button>
              )}
              <Button type="submit" theme="info" disabled={isLoading}>
                <IconSearch fill="#fff" />
              </Button>
            </form>
            <div className="columns-display-select">
              <CheckboxSelector
                text={__('Table columns')}
                options={orderListColumns}
                value={columnsToShow}
                onChangeCallback={data => {
                  setChangeColumnsDirty(true);
                  setColumnsToShow(data);
                }}
                onMenuClose={patchUiFeatures}
              />
            </div>
          </div>
          <div>
            <Button
              featureEnabled={isFeatureEnabled(
                platformFeatures.reporting_data_export
              )}
              featureAvailable={isFeatureAvailable(
                platformFeatures.reporting_data_export
              )}
              notAvailableMessage={__(
                'Data export is not available in your plan type.'
              )}
              ctaText={__('Upgrade to download your data exports.')}
              notEnabledMessage={getDisabledMessage()}
              style={{ width: '50px', padding: '0' }}
              theme="default"
              title={__('Export licenses')}
              onClick={() => setExportModalDisplay(true)}
            >
              <IconExport height="14px" color="#777" />
            </Button>
            <Button
              featureEnabled={isFeatureEnabled(
                platformFeatures.platform_add_license
              )}
              featureAvailable={isFeatureAvailable(
                platformFeatures.platform_add_license
              )}
              notEnabledMessage={getDisabledMessage()}
              onClick={redirectToNewOrderView}
              theme="info"
            >
              {__('Create new order')}
            </Button>
          </div>
        </div>
        <div className="OrderList-filters">
          <button
            type="button"
            className="AdvancedSearch-btn"
            onClick={() => setFiltersDisplay(!showFilters)}
          >
            <IconFilter
              stroke="#2e86de"
              strokeWidth={2}
              fill="none"
              viewBox="0 0 24 24"
            />
            {__('Search filters')}
          </button>
          <div
            className={`AdvancedSearch-wrapper ${showFilters ? '' : 'hide'}`}
          >
            <div className={`AdvancedSearch ${showFilters ? '' : 'hide'}`}>
              <div className="AdvancedSearch-fields">
                <div className="filter-section">
                  <Label
                    inputId="customer-labels-filter"
                    text={__('Customer labels')}
                  />
                  <CheckboxSelector
                    text={__('Selected labels')}
                    options={mapCustomerLabelsToSelector(companyCustomerLabels)}
                    value={selectedCustomerLabelFilters}
                    onChangeCallback={data =>
                      setSelectedCustomerLabelFilters(data)
                    }
                    onMenuClose={handleClabelsSelect}
                  />
                </div>
                <div className="AdvancedSearch-actions">
                  <Button
                    theme="error"
                    size="sm"
                    disabled={isLoading}
                    onClick={handleClearFilters}
                  >
                    {__('Clear filters')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="Filters-list">
            {selectedCustomerLabelFilters.map(slt => (
              <SimpleFilterTag
                key={slt.value}
                value={slt.value}
                label={capitalizeFirstLetter(slt.label)}
                customFilterSetter={() => {
                  const newCustomerLabelsFilter = selectedCustomerLabelFilters.filter(
                    s => s.value !== slt.value
                  );
                  setSelectedCustomerLabelFilters(newCustomerLabelsFilter);
                  handleClabelsSelect(newCustomerLabelsFilter);
                }}
              />
            ))}
          </div>
        </div>
        <List
          clickable
          columns={[
            {
              accessor: 'created_at',
              Header: __('Created on'),
              Cell: cellInfo => formatDate(cellInfo.value),
              show: shouldShowColumn('created_at'),
              width: 180
            },
            {
              accessor: 'customer.email',
              Header: __('Customer email'),
              Cell: cellData => displayValue(cellData.value),
              show: shouldShowColumn('customer.email'),
              maxWidth: 400
            },
            {
              accessor: 'customer.last_name',
              Header: __('Customer name'),
              Cell: rowData => getTableCustomerName(rowData.original),
              show: shouldShowColumn('customer.last_name')
            },
            {
              accessor: 'customer.company_name',
              Header: __('Customer company'),
              Cell: cellInfo => displayValue(cellInfo.value),
              minWidth: 150,
              show: shouldShowColumn('customer.company_name')
            },
            {
              accessor: 'customer.customer_account.name',
              Header: __('Customer account'),
              Cell: cellData => displayValue(cellData.value),
              show: shouldShowColumn('customer.customer_account.name')
            },
            {
              accessor: 'customer.reference',
              Header: __('Customer reference'),
              Cell: cellInfo => displayValue(cellInfo.value),
              minWidth: 150,
              show: shouldShowColumn('customer.reference')
            },
            {
              accessor: 'customer.labels',
              Header: __('Customer labels'),
              className: 'customer-label',
              Cell: cellInfo => {
                const labels = get(cellInfo, 'value') || [];
                const labelTitles = labels
                  .map(l => l.label)
                  .sort()
                  .join(', ');
                return (
                  <div className="customerLabels">
                    <span className="label-titles">{labelTitles}</span>
                    <div className="label-colors-wrapper">
                      {labels.map(l => (
                        <div
                          className="label-color"
                          key={l.id}
                          style={{ backgroundColor: l.color || '#949494' }}
                        />
                      ))}
                    </div>
                  </div>
                );
              },
              show: shouldShowColumn('customer.labels')
            },
            {
              accessor: 'store_id',
              Header: __('Order ID'),
              Cell: cellData => displayValue(cellData.value),
              show: shouldShowColumn('store_id')
            },
            {
              accessor: 'customer_reference',
              Header: __('Order reference'),
              Cell: cellData => displayValue(cellData.value),
              show: shouldShowColumn('customer_reference')
            },
            {
              accessor: 'updated_at',
              Header: __('Last updated'),
              Cell: cellInfo => formatDate(cellInfo.value),
              show: shouldShowColumn('updated_at')
            },
            {
              accessor: 'type',
              Header: __('Type'),
              Cell: cellData => displayValue(cellData.value),
              show: shouldShowColumn('type')
            },
            {
              accessor: 'campaign_params',
              Header: __('Campaign parameters'),
              headerClassName: 'text-center',
              Cell: cellData => displayValue(cellData.value),
              show: shouldShowColumn('campaign_params')
            },
            {
              accessor: 'is_trial',
              Header: __('Is trial'),
              headerClassName: 'text-center',
              className: 'text-center',
              Cell: cellData =>
                cellData.value ? (
                  <IconCheckmark color="#10ac84" height="14px" />
                ) : (
                  <IconDisabled color="#aaa" height="14px" />
                ),
              width: 80,
              show:
                shouldShowColumn('is_trial') &&
                checkCompanyConfigField(companyID, 'isTrial')
            },
            {
              accessor: 'language',
              Header: __('Language'),
              Cell: cellData => displayValue(cellData.value),
              show: shouldShowColumn('language')
            },
            {
              accessor: 'id',
              Header: __('ID'),
              Cell: cellData => displayValue(cellData.value),
              show: shouldShowColumn('id')
            }
          ]}
          data={orders}
          defaultSorted={currentSort}
          handleClick={rowData => redirectToOrderPage(rowData)}
          loading={isLoading}
          manual
          minRows={get(orders, 'length') || 10}
          page={page}
          onPageChange={handlePageChange}
          onSortedChange={handleSortChange}
          pages={Math.ceil(ordersCount / rowsPerPage)}
          showPagination={ordersCount > 5}
          showPageSizeOptions
          pageSize={rowsPerPage}
          onPageSizeChange={(pageSize, pageIndex) =>
            handlePageSizeChange(pageSize, pageIndex)
          }
        />
        {exportModalDisplayed && (
          <ExportOrderForm
            closeCb={() => setExportModalDisplay(false)}
            filters={{
              selectedCustomerLabelFilters,
              searchBy,
              query
            }}
            handleClearFilters={handleClearFilters}
          />
        )}
      </Page>
    </div>
  );
};

export default OrderList;
