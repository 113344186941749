import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { abbreviateNumber } from 'js-abbreviation-number';
import {
  AreaChart as RechartsAreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { ChartTooltip } from 'shared/components';

const AreaChart = ({
  axisColor,
  color,
  chartID,
  data,
  dataKey,
  height,
  isDaily,
  label
}) => (
  <div className="AreaChart">
    <ResponsiveContainer width="100%" height={height}>
      <RechartsAreaChart
        data={data}
        margin={{
          top: 5,
          right: 20,
          bottom: 5,
          left: -20
        }}
      >
        <defs>
          <linearGradient
            id={`color-fill-${chartID}`}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop offset="5%" stopColor={color} stopOpacity={0.6} />
            <stop offset="95%" stopColor={color} stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <Area
          connectNulls
          name={label}
          type="linear"
          dataKey={dataKey}
          fillOpacity={1}
          fill={`url(#color-fill-${chartID})`}
          stroke={color}
        />
        <YAxis
          stroke={axisColor}
          tickFormatter={v => abbreviateNumber(v, 1)}
          tick={{ fontSize: 10 }}
        />
        <XAxis
          stroke={axisColor}
          dataKey="ts"
          type="number"
          domain={['dataMin', 'dataMax']}
          interval="preserveStartEnd"
          scale="time"
          tickFormatter={ts =>
            isDaily
              ? moment(ts).format('MM/DD/YYYY')
              : moment(ts).format('MM/DD/YYYY HH:mm')
          }
          tick={{ fontSize: 10 }}
        />
        <Tooltip
          content={<ChartTooltip isDaily={isDaily} tooltipLabel={label} />}
        />
      </RechartsAreaChart>
    </ResponsiveContainer>
  </div>
);

AreaChart.propTypes = {
  axisColor: PropTypes.string,
  color: PropTypes.string,
  chartID: PropTypes.string,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isDaily: PropTypes.bool,
  label: PropTypes.string
};

AreaChart.defaultProps = {
  axisColor: '#07364c',
  color: '#14B0FC',
  dataKey: 'total_call_count',
  height: 200,
  isDaily: true,
  label: '',
  chartID: 'chart-id'
};

export default AreaChart;
