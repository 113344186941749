import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Checkbox,
  CheckboxSelector,
  ConfirmationPopup,
  DateInput,
  FilterSelect,
  IconCheckmark,
  IconDisabled,
  IconFilter,
  IconSearch,
  Label,
  List,
  Notification,
  Page,
  PermissionMissingNotificationTitle,
  Selector,
  SelectedFeaturesTags,
  SimpleFilterTag,
  StatusCard,
  TextInput,
  IconExport,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  TooltipHeader
} from 'shared/components';
import {
  defaultDateFormat,
  errorMsg,
  licenseListColumns,
  platformFeatures,
  initialLicenseListSize,
  initialLicenseListFilter,
  searchType,
  enabledFilterFields,
  userPermissions
} from 'shared/constants';
import {
  capitalizeFirstLetter,
  displayValue,
  formatDate,
  getDisabledMessage,
  getTableColumnObjects,
  getTableColumnValues,
  getTableCustomerName,
  isFeatureAvailable,
  isFeatureEnabled,
  mapCustomerLabelsToSelector,
  sendErrorReport,
  mapProductsToSelector,
  checkUserPermission
} from 'shared/helpers';
import { checkCompanyConfigField } from 'shared/companyConfig';
import { updateUserPreferences } from 'src/company/actions';
import {
  getLicenses,
  disableBulkLicenses,
  enableBulkLicenses,
  deleteBulkLicenses,
  updateUIOptions
} from 'src/license/actions';
import {
  transformSort,
  transformSearchFilter,
  getProductFilter
} from './helpers';
import ExportOrderForm from '../ExportOrderForm';
import LicensesImport from '../LicensesImport';
import LicensesFeaturesImport from '../LicensesFeaturesImport';
import BulkActionsDropdown from './BulkActionsDropdown';
import './styles.scss';

const LicenseList = () => {
  const canManageLicenses = checkUserPermission(userPermissions.licenses_write);
  const history = useHistory();
  const dispatch = useDispatch();
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const products = useSelector(state => get(state, 'products.list'));
  const uiOptions = useSelector(state =>
    get(state, 'company.userPreferences.ui_options')
  );
  const userPrefsID = useSelector(state =>
    get(state, 'company.userPreferences.id')
  );
  const companyCustomerLabels = useSelector(state =>
    get(state, 'company.customerLabels')
  );
  const productsForSelector = mapProductsToSelector(products, 'id');
  const productFilterOptions = [
    { label: __('All'), value: 'all' },
    ...productsForSelector
  ];

  const [isLoading, setLoading] = useState(true);
  const [licenses, setLicenses] = useState([]);
  const [exportModalDisplayed, setExportModalDisplay] = useState(false);
  const [selectedLicenses, setSelectedLicenses] = useState([]);
  const [currentBulkAction, setCurrentBulkAction] = useState(null);
  const [confirmationPopupDisplayed, setConfirmationPopupDisplay] = useState(
    false
  );
  const [processingBulkAction, setProcessingBulkAction] = useState(false);
  // table state
  const [licenseCount, setLicenseCount] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    get(uiOptions, 'list_size.licenses') || initialLicenseListSize
  );
  const [currentSort, setSort] = useState(get(uiOptions, 'list_sort.licenses'));
  const [columnsToShow, setColumnsToShow] = useState(
    getTableColumnObjects(
      get(uiOptions, 'list_columns.licenses'),
      licenseListColumns
    )
  );
  const [changeColumnsDirty, setChangeColumnsDirty] = useState(false);
  // search state
  const [query, setQuery] = useState('');
  const [activeQuery, setActiveQuery] = useState('');
  const [searchBy, setSearchBy] = useState(get(searchType, 'all'));
  // filters
  const [isFiltersDirty, setFiltersDirty] = useState(false);
  const [showFilters, setFiltersDisplay] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(
    get(uiOptions, 'list_filters.licenses') || initialLicenseListFilter
  );
  const [
    selectedCustomerLabelFilters,
    setSelectedCustomerLabelFilters
  ] = useState(
    get(uiOptions, 'list_filters.licenses.clabels') ||
      initialLicenseListFilter.clabels
  );
  const [selectedEnabledFilter, setSelectedEnabledFilter] = useState(
    get(uiOptions, 'list_filters.licenses.enabled') ||
      initialLicenseListFilter.enabled
  );
  const [validityPeriodLte, setValidityPeriodLte] = useState(
    get(uiOptions, 'list_filters.licenses.validity_period_lte') ||
      initialLicenseListFilter.validity_period_lte
  );
  const [selectedProductFilter, setSelectedProductFilter] = useState(
    getProductFilter(
      productFilterOptions,
      get(uiOptions, 'list_filters.licenses.product')
    )
  );

  const fetchLicenses = useCallback(
    (
      tablePage = 0,
      activeQ = undefined,
      searchTypeSelect = undefined,
      sort = currentSort,
      rows = rowsPerPage,
      filters = selectedFilters
    ) => {
      setLoading(true);
      getLicenses(
        companyID,
        tablePage,
        activeQ,
        transformSearchFilter(searchTypeSelect),
        transformSort(sort),
        rows,
        filters
      )
        .then(res => {
          setLicenses(get(res, 'data.results') || []);
          setLicenseCount(get(res, 'data.count'));
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          sendErrorReport(err, 'Cannot fetch licenses list');
        });
    },
    [companyID]
  );

  useEffect(() => {
    fetchLicenses();
  }, [fetchLicenses]);

  const patchUiFeatures = (
    newSorted = undefined,
    newPageSize = undefined,
    newFilters = undefined
  ) => {
    const sortObject = newSorted || currentSort;
    const listPageSize = newPageSize || rowsPerPage;
    const filters = newFilters || get(uiOptions, 'list_filters.licenses') || {};

    if (
      (!changeColumnsDirty && !newSorted && !newPageSize && !newFilters) ||
      !userPrefsID
    ) {
      return false;
    }

    const listColumns = get(uiOptions, 'list_columns') || {};
    const listSort = get(uiOptions, 'list_sort') || {};
    const listSize = get(uiOptions, 'list_size') || {};
    const listFilter = get(uiOptions, 'list_filters') || {};

    listColumns.licenses = getTableColumnValues(columnsToShow);
    listSort.licenses = sortObject;
    listSize.licenses = listPageSize;
    listFilter.licenses = filters;

    const ui = {
      ...uiOptions,
      list_columns: listColumns,
      list_sort: listSort,
      list_size: listSize,
      list_filters: listFilter
    };
    const data = { ui_options: JSON.stringify(ui) };

    updateUIOptions(userPrefsID, companyID, data)
      .then(res => {
        dispatch(updateUserPreferences(res.data));
        setChangeColumnsDirty(false);
      })
      .catch(err => sendErrorReport(err, 'Ui options update failed', data));
    return true;
  };

  const handleBulkLicenseOperation = async operation => {
    setProcessingBulkAction(true);

    // Map operation names to their corresponding action functions
    const actionMap = {
      disable: disableBulkLicenses,
      enable: enableBulkLicenses,
      delete: deleteBulkLicenses
    };

    try {
      await actionMap[operation](companyID, selectedLicenses);

      const selectedCount = selectedLicenses.length;
      const successMessages = {
        disable: __('Licenses successfully disabled'),
        enable: __('Licenses successfully enabled'),
        delete: __('Licenses successfully deleted')
      };
      const singleSuccessMessages = {
        disable: __('License successfully disabled'),
        enable: __('License successfully enabled'),
        delete: __('License successfully deleted')
      };

      // Refresh licenses list
      fetchLicenses(
        page,
        activeQuery,
        searchBy,
        currentSort,
        rowsPerPage,
        selectedFilters
      );

      // Reset states
      setSelectedLicenses([]);
      setProcessingBulkAction(false);
      setConfirmationPopupDisplay(false);

      // Show success notification
      Notification(
        'success',
        selectedCount > 1
          ? successMessages[operation]
          : singleSuccessMessages[operation]
      );
    } catch (err) {
      sendErrorReport(
        err,
        `${capitalizeFirstLetter(operation)}ing licenses failed`,
        selectedLicenses
      );
      setProcessingBulkAction(false);
      Notification(
        'error',
        __('Error occurred'),
        __('Your changes were not saved')
      );
    }
  };

  const disableSelectedLicenses = () => handleBulkLicenseOperation('disable');
  const enableSelectedLicenses = () => handleBulkLicenseOperation('enable');
  const deleteSelectedLicenses = () => handleBulkLicenseOperation('delete');

  const handleBulkAction = actionId => {
    if (!canManageLicenses) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle
          permission={userPermissions.licenses_write}
        />,
        __('Contact your account admin for support.')
      );
      return;
    }
    setCurrentBulkAction(actionId);
    setConfirmationPopupDisplay(true);
  };

  const executeBulkAction = () => {
    switch (currentBulkAction) {
      case 'disable':
        return disableSelectedLicenses();
      case 'enable':
        return enableSelectedLicenses();
      case 'delete':
        return deleteSelectedLicenses();
      default:
        return null;
    }
  };

  const handleSearchSubmit = e => {
    e.preventDefault();
    setActiveQuery(query);
    setPage(0);
    fetchLicenses(
      0,
      query,
      searchBy,
      currentSort,
      rowsPerPage,
      selectedFilters
    );
  };

  const handleSearchClear = () => {
    setQuery('');
    setActiveQuery('');
    setPage(0);
    fetchLicenses(
      0,
      undefined,
      undefined,
      currentSort,
      rowsPerPage,
      selectedFilters
    );
  };

  const handleClabelsSelect = (newClabelFilters = undefined) => {
    if (!selectedCustomerLabelFilters || !isFiltersDirty) {
      return false;
    }
    const newFilters = {
      ...selectedFilters,
      clabels: newClabelFilters || selectedCustomerLabelFilters
    };
    setSelectedFilters(newFilters);
    fetchLicenses(
      0,
      activeQuery,
      searchBy,
      currentSort,
      rowsPerPage,
      newFilters
    );
    patchUiFeatures(undefined, undefined, newFilters);
    return true;
  };

  const handleClearFilters = () => {
    const newFilters = {
      enabled: enabledFilterFields[0],
      validity_period_lte: '',
      clabels: [],
      product: get(productFilterOptions, '[0].value')
    };
    setSelectedFilters(newFilters);
    setSelectedCustomerLabelFilters([]);
    setSelectedEnabledFilter(enabledFilterFields[0]);
    setValidityPeriodLte('');
    setSelectedProductFilter(productFilterOptions[0]);
    fetchLicenses(
      0,
      activeQuery,
      searchBy,
      currentSort,
      rowsPerPage,
      newFilters
    );
    patchUiFeatures(undefined, undefined, newFilters);
    return true;
  };

  const handleEnabledFilterSelect = val => {
    setSelectedEnabledFilter(val);
    const newFilters = {
      ...selectedFilters,
      enabled: val
    };
    setSelectedFilters(newFilters);
    fetchLicenses(
      0,
      activeQuery,
      searchBy,
      currentSort,
      rowsPerPage,
      newFilters
    );
    patchUiFeatures(undefined, undefined, newFilters);
  };

  const handleProductFilterSelect = val => {
    setSelectedProductFilter(val);
    const newFilters = {
      ...selectedFilters,
      product: get(val, 'value')
    };
    setSelectedFilters(newFilters);
    fetchLicenses(
      0,
      activeQuery,
      searchBy,
      currentSort,
      rowsPerPage,
      newFilters
    );
    patchUiFeatures(undefined, undefined, newFilters);
  };

  const handleValidityPeriodLteChange = val => {
    setValidityPeriodLte(val);
    const newFilters = {
      ...selectedFilters,
      validity_period_lte: val
    };
    setSelectedFilters(newFilters);
    fetchLicenses(
      0,
      activeQuery,
      searchBy,
      currentSort,
      rowsPerPage,
      newFilters
    );
    patchUiFeatures(undefined, undefined, newFilters);
  };

  const handlePageChange = newPage => {
    setPage(newPage);
    fetchLicenses(
      newPage,
      activeQuery,
      searchBy,
      currentSort,
      rowsPerPage,
      selectedFilters
    );
  };

  const handleSortChange = newSorted => {
    setSort(newSorted);
    fetchLicenses(
      0,
      activeQuery,
      searchBy,
      newSorted,
      rowsPerPage,
      selectedFilters
    );
    patchUiFeatures(newSorted);
  };

  const handlePageSizeChange = (newPageSize, newPage) => {
    setPage(newPage);
    setRowsPerPage(newPageSize);
    fetchLicenses(
      newPage,
      activeQuery,
      searchBy,
      currentSort,
      newPageSize,
      selectedFilters
    );
    patchUiFeatures(undefined, newPageSize);
  };

  const shouldShowColumn = name => {
    const values = columnsToShow.map(column => column.value);
    return !!values.includes(name);
  };

  const redirectToNewOrderView = () => {
    if (Array.isArray(products) && !products.length) {
      Notification(
        'error',
        __('Company has no products'),
        __('Add products to company before creating new order')
      );
      return false;
    }
    history.push(`/${companyID}/licenses/issue-licenses`);
    return true;
  };

  const redirectToLicensePage = rowData => {
    const orderId = get(rowData, 'original.order');
    const licenseId = get(rowData, 'original.id');
    history.push(`/${companyID}/orders/${orderId}/${licenseId}`);
  };

  const allowsAirGapped =
    isFeatureEnabled(platformFeatures.extra_air_gapped) &&
    isFeatureAvailable(platformFeatures.extra_air_gapped);

  return (
    <Page title={__('Licenses')}>
      {/* <Helmet>
        <title>{`${__('Licenses')} - LicenseSpring`}</title>
      </Helmet> */}
      <Tabs>
        <TabsHeader>
          <Tab>{__('All licenses')}</Tab>
          <Tab>{__('Licenses import')}</Tab>
          <Tab>{__('License features import')}</Tab>
        </TabsHeader>
        <TabContent>
          <div className="LicenseList">
            <div className="list-header">
              <div>
                <div className="search-selector">
                  <Selector
                    handleChange={val => setSearchBy(val)}
                    options={[
                      { label: __('Search by all'), value: searchType.all },
                      {
                        label: __('Search by license key'),
                        value: searchType.licenseNumber
                      },
                      {
                        label: __('Search by license user email'),
                        value: searchType.licenseUserEmail
                      },
                      {
                        label: __('Search by license note'),
                        value: searchType.note
                      },
                      {
                        label: __('Search by device hardware_id'),
                        value: searchType.hwid
                      },
                      {
                        label: __('Search by device hostname'),
                        value: searchType.hostname
                      },
                      {
                        label: __('Search by device OS'),
                        value: searchType.os
                      },
                      {
                        label: __('Search by product name'),
                        value: searchType.productName
                      },
                      {
                        label: __('Search by order id'),
                        value: searchType.order
                      },
                      {
                        label: __('Search by customer email'),
                        value: searchType.customerEmail
                      },
                      {
                        label: __('Search by customer reference'),
                        value: searchType.customerReference
                      },
                      {
                        label: __('Search by customer name'),
                        value: searchType.customerName
                      },
                      {
                        label: __('Search by customer company'),
                        value: searchType.customerCompany
                      },
                      {
                        label: __('Search by account name'),
                        value: searchType.customerAccountName
                      },
                      {
                        label: __('Search by account code'),
                        value: searchType.customerAccountCode
                      },
                      {
                        label: __('Search by account reference'),
                        value: searchType.customerAccountReference
                      }
                    ]}
                    value={searchBy}
                  />
                </div>
                <form onSubmit={handleSearchSubmit} style={{ height: '34px' }}>
                  <TextInput
                    handleChange={val => setQuery(val)}
                    value={query}
                  />
                  {activeQuery && (
                    <button
                      type="button"
                      onClick={handleSearchClear}
                      disabled={isLoading}
                    >
                      &times;
                    </button>
                  )}
                  <Button type="submit" theme="info" disabled={isLoading}>
                    <IconSearch fill="#fff" />
                  </Button>
                </form>
                <div className="columns-display-select">
                  <CheckboxSelector
                    text={__('Table columns')}
                    options={licenseListColumns}
                    value={columnsToShow}
                    onChangeCallback={data => {
                      setChangeColumnsDirty(true);
                      setColumnsToShow(data);
                    }}
                    onMenuClose={patchUiFeatures}
                  />
                </div>
              </div>
              <div>
                {/* permission licenses_read -> if this permission is missing user can't get to the UI to click on this */}
                <Button
                  featureEnabled={isFeatureEnabled(
                    platformFeatures.reporting_data_export
                  )}
                  featureAvailable={isFeatureAvailable(
                    platformFeatures.reporting_data_export
                  )}
                  notAvailableMessage={__(
                    'Data export is not available in your plan type.'
                  )}
                  ctaText={__('Upgrade to download your data exports.')}
                  notEnabledMessage={getDisabledMessage()}
                  style={{ padding: '0', width: '50px' }}
                  theme="default"
                  title={__('Export licenses')}
                  onClick={() => setExportModalDisplay(true)}
                >
                  <IconExport height="14px" color="#555" />
                </Button>
                {/* permission licenses_write -> this will redirect user to the /issue-licenses route where this permission is checked */}
                <Button
                  featureAvailable={isFeatureAvailable(
                    platformFeatures.platform_add_license
                  )}
                  featureEnabled={isFeatureEnabled(
                    platformFeatures.platform_add_license
                  )}
                  notEnabledMessage={getDisabledMessage()}
                  onClick={redirectToNewOrderView}
                  theme="info"
                >
                  {__('Create new order')}
                </Button>
              </div>
            </div>
            <div className="LicenseList-filters">
              <button
                type="button"
                className="AdvancedSearch-btn"
                onClick={() => setFiltersDisplay(!showFilters)}
              >
                <IconFilter
                  stroke="#2e86de"
                  strokeWidth={2}
                  fill="none"
                  viewBox="0 0 24 24"
                />
                {__('Search filters')}
              </button>
              <div
                className={`AdvancedSearch-wrapper ${
                  showFilters ? '' : 'hide'
                }`}
              >
                <div className={`AdvancedSearch ${showFilters ? '' : 'hide'}`}>
                  <div className="AdvancedSearch-fields">
                    <div className="filter-section">
                      <Label
                        inputId="customer-labels-filter"
                        text={__('Customer labels')}
                      />
                      <CheckboxSelector
                        text={__('Selected labels')}
                        options={mapCustomerLabelsToSelector(
                          companyCustomerLabels
                        )}
                        value={selectedCustomerLabelFilters}
                        onChangeCallback={d => {
                          if (
                            d.length !== selectedCustomerLabelFilters.length
                          ) {
                            setFiltersDirty(true);
                          }
                          setSelectedCustomerLabelFilters(d);
                        }}
                        onMenuClose={handleClabelsSelect}
                        isDisabled={isLoading}
                      />
                    </div>
                    <div className="filter-section">
                      <div className="validity-period-lte">
                        <Label
                          inputId="validity-period-lte"
                          text={__('Expires before date:')}
                        />
                        <DateInput
                          handleChange={handleValidityPeriodLteChange}
                          value={validityPeriodLte}
                          id="validity-period-lte"
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                    <FilterSelect
                      filter={selectedEnabledFilter}
                      filterArray={enabledFilterFields}
                      filterSetter={handleEnabledFilterSelect}
                      label={__('Is disabled')}
                      labelID="license-usage-filter"
                      isDisabled={isLoading}
                    />
                    <FilterSelect
                      filter={selectedProductFilter}
                      filterArray={productFilterOptions}
                      filterSetter={handleProductFilterSelect}
                      label={__('Product')}
                      labelID="license-product-filter"
                      isDisabled={isLoading}
                    />
                    <div className="AdvancedSearch-actions">
                      <Button
                        theme="error"
                        size="sm"
                        disabled={isLoading}
                        onClick={handleClearFilters}
                      >
                        {__('Clear filters')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Filters-list">
                {selectedCustomerLabelFilters.map(slt => (
                  <SimpleFilterTag
                    key={slt.value}
                    value={slt.value}
                    label={capitalizeFirstLetter(slt.label)}
                    customFilterSetter={() => {
                      setFiltersDirty(true);
                      const newCustomerLabelsFilter = selectedCustomerLabelFilters.filter(
                        s => s.value !== slt.value
                      );
                      setSelectedCustomerLabelFilters(newCustomerLabelsFilter);
                      handleClabelsSelect(newCustomerLabelsFilter);
                    }}
                    isDisabled={isLoading}
                  />
                ))}
                <SimpleFilterTag
                  label={selectedEnabledFilter.label}
                  value={selectedEnabledFilter.value}
                  filtersArray={enabledFilterFields}
                  filterSetter={handleEnabledFilterSelect}
                  isDisabled={isLoading}
                />
                <SimpleFilterTag
                  label={`${__('Expires before')} ${validityPeriodLte}`}
                  value={validityPeriodLte}
                  filtersArray={enabledFilterFields}
                  customFilterSetter={() => handleValidityPeriodLteChange('')}
                  isDisabled={isLoading}
                />
                <SimpleFilterTag
                  // label="all"
                  // value="All"
                  label={selectedProductFilter.label}
                  value={selectedProductFilter.value}
                  filtersArray={enabledFilterFields}
                  filterSetter={handleProductFilterSelect}
                  isDisabled={isLoading}
                />
              </div>
            </div>
            <BulkActionsDropdown
              disabled={!selectedLicenses.length}
              onActionSelect={handleBulkAction}
              style={{ marginBottom: '1rem' }}
            />
            <List
              clickable
              data={licenses}
              columns={[
                {
                  id: 'select',
                  className: 'select-container',
                  Cell: rowData => (
                    <button
                      className="table-select"
                      aria-label="Select license"
                      onClick={e => {
                        e.stopPropagation();
                        let newState;
                        const isSelected = selectedLicenses.includes(
                          rowData.original.id
                        );
                        if (isSelected) {
                          newState = selectedLicenses.filter(
                            l => l !== rowData.original.id
                          );
                        } else {
                          newState = selectedLicenses.concat(
                            rowData.original.id
                          );
                        }
                        setSelectedLicenses(newState);
                      }}
                      type="button"
                    >
                      <Checkbox
                        inputId={`${rowData.original.id}-select`}
                        handleChange={(val, e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        checked={selectedLicenses.includes(rowData.original.id)}
                      />
                    </button>
                  ),
                  width: 60,
                  show: true,
                  sortable: false
                },
                {
                  accessor: 'created_at',
                  Header: __('Created on'),
                  Cell: rowData => formatDate(rowData.value),
                  width: 160,
                  show: shouldShowColumn('created_at')
                },
                {
                  id: 'license_key',
                  Header: `${__('License Key')}/${__('User')}`,
                  width: 250,
                  sortable: false,
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.bundle_license');
                    if (isBundle) return '-';

                    const licenseProduct = products.find(
                      p => p.id === get(rowData, 'original.product.id')
                    );
                    const isUserAuth =
                      get(licenseProduct, 'authorization_method') === 'user';
                    if (isUserAuth) {
                      const licenseUsers =
                        get(rowData, 'original.license_users') || [];
                      const usersNum = licenseUsers.length;
                      if (usersNum > 0) {
                        const firstUser = get(licenseUsers, '[0].true_email');
                        const isSingleUser = usersNum === 1;
                        if (isSingleUser) {
                          return `${firstUser}`;
                        }
                        return `${firstUser} (+${usersNum - 1})`;
                      }
                      return __(errorMsg.notAssigned);
                    }

                    const licenseKey = get(rowData, 'original.license_key');
                    if (licenseKey) {
                      return displayValue(licenseKey);
                    }
                    return '-';
                  },
                  show: shouldShowColumn('license_key')
                },
                {
                  accessor: 'product.product_name',
                  Header: __('Product'),
                  Cell: rowData => displayValue(rowData.value),
                  maxWidth: 250,
                  show: shouldShowColumn('product')
                },
                {
                  accessor: 'license_type',
                  Header: __('License Type'),
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return displayValue(rowData.value);
                  },
                  maxWidth: 200,
                  show: shouldShowColumn('license_type')
                },
                {
                  accessor: 'validity_period',
                  Header: () => TooltipHeader(__('Expiration Date')),
                  maxWidth: 200,
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    const isEnabled = get(rowData, 'original.enabled');
                    if (!isEnabled) {
                      return '-';
                    }
                    return formatDate(rowData.value, defaultDateFormat);
                  },
                  show: shouldShowColumn('validity_period')
                },
                {
                  accessor: 'is_trial',
                  className: 'text-center',
                  Header: __('Is trial'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return rowData.value ? (
                      <IconCheckmark color="#10ac84" height="14px" />
                    ) : (
                      <IconDisabled color="#aaa" height="14px" />
                    );
                  },
                  width: 80,
                  show:
                    shouldShowColumn('is_trial') &&
                    checkCompanyConfigField(companyID, 'isTrial')
                },
                {
                  accessor: 'status',
                  className: 'text-center',
                  Header: __('Status'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return (
                      <StatusCard
                        status={
                          rowData.value === 'Active' ? 'success' : 'error'
                        }
                        text={__(rowData.value)}
                      />
                    );
                  },
                  minWidth: 100,
                  show: shouldShowColumn('enabled')
                },
                {
                  accessor: 'times_activated',
                  Header: __('Times activated'),
                  maxWidth: 150,
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return displayValue(rowData.value);
                  },
                  show: shouldShowColumn('times_activated')
                },
                {
                  accessor: 'max_activations',
                  Header: __('Max activations'),
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    const isUnlimited = get(
                      rowData,
                      'original.allow_unlimited_activations'
                    );
                    if (isUnlimited) {
                      return __('Unlimited');
                    }
                    return displayValue(rowData.value);
                  },
                  show:
                    shouldShowColumn('max_activations') &&
                    checkCompanyConfigField(companyID, 'maxActivations')
                },
                {
                  accessor: 'prevent_vm',
                  className: 'text-center',
                  Header: __('Prevent virtual machine'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return rowData.value ? (
                      <IconCheckmark color="#10ac84" height="14px" />
                    ) : (
                      <IconDisabled color="#aaa" height="14px" />
                    );
                  },
                  minWidth: 100,
                  show: shouldShowColumn('prevent_vm')
                },
                {
                  accessor: 'customer.email',
                  Header: __('Customer email'),
                  Cell: rowData => displayValue(rowData.value),
                  minWidth: 180,
                  show: shouldShowColumn('customer.email')
                },
                {
                  accessor: 'customer.last_name',
                  Header: __('Customer name'),
                  Cell: rowData => getTableCustomerName(rowData.original),
                  minWidth: 180,
                  show: shouldShowColumn('customer.last_name')
                },
                {
                  accessor: 'customer.company_name',
                  Header: __('Customer company'),
                  Cell: cellInfo => displayValue(cellInfo.value),
                  minWidth: 150,
                  show: shouldShowColumn('customer.company_name')
                },
                {
                  accessor: 'customer.customer_account.name',
                  Header: __('Customer account'),
                  Cell: rowData => displayValue(rowData.value),
                  show: shouldShowColumn('customer.customer_account.name')
                },
                {
                  accessor: 'customer.reference',
                  Header: __('Customer reference'),
                  Cell: rowData => displayValue(rowData.value),
                  minWidth: 150,
                  show: shouldShowColumn('customer.reference')
                },
                {
                  accessor: 'customer.labels',
                  Header: __('Customer labels'),
                  className: 'customer-label',
                  Cell: rowData => {
                    const labels = get(rowData, 'value') || [];
                    const labelTitles = labels
                      .map(l => l.label)
                      .sort()
                      .join(', ');
                    return (
                      <div className="customerLabels">
                        <span className="label-titles">{labelTitles}</span>
                        <div className="label-colors-wrapper">
                          {labels.map(l => (
                            <div
                              className="label-color"
                              key={l.id}
                              style={{ backgroundColor: l.color || '#949494' }}
                            />
                          ))}
                        </div>
                      </div>
                    );
                  },
                  show: shouldShowColumn('customer.labels')
                },
                {
                  accessor: 'active',
                  className: 'text-center',
                  Header: __('Active'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return rowData.value ? (
                      <IconCheckmark color="#10ac84" height="14px" />
                    ) : (
                      <IconDisabled color="#aaa" height="14px" />
                    );
                  },
                  width: 80,
                  show: shouldShowColumn('active')
                },
                {
                  accessor: 'start_date',
                  Header: () =>
                    TooltipHeader(
                      __('Start Date'),
                      __('License cannot be activated before this date')
                    ),
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return formatDate(rowData.value, defaultDateFormat);
                  },
                  maxWidth: 200,
                  show: shouldShowColumn('start_date')
                },
                {
                  accessor: 'is_bundle',
                  className: 'text-center',
                  Header: __('Is bundle'),
                  headerClassName: 'text-center',
                  Cell: rowData =>
                    rowData.value ? (
                      <IconCheckmark color="#10ac84" height="14px" />
                    ) : (
                      <IconDisabled color="#aaa" height="14px" />
                    ),
                  width: 80,
                  show: shouldShowColumn('is_bundle')
                },
                {
                  accessor: 'is_hardware_key_auth',
                  className: 'text-center',
                  Header: __('Is hardware key'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return rowData.value ? (
                      <IconCheckmark color="#10ac84" height="14px" />
                    ) : (
                      <IconDisabled color="#aaa" height="14px" />
                    );
                  },
                  width: 120,
                  show: shouldShowColumn('is_hardware_key_auth')
                },
                {
                  accessor: 'is_air_gapped',
                  className: 'text-center',
                  Header: __('Is air-gapped'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return rowData.value ? (
                      <IconCheckmark color="#10ac84" height="14px" />
                    ) : (
                      <IconDisabled color="#aaa" height="14px" />
                    );
                  },
                  width: 120,
                  show: shouldShowColumn('is_air_gapped') && allowsAirGapped
                },
                {
                  accessor: 'is_floating',
                  className: 'text-center',
                  Header: __('Is floating'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return rowData.value ? (
                      <IconCheckmark color="#10ac84" height="14px" />
                    ) : (
                      <IconDisabled color="#aaa" height="14px" />
                    );
                  },
                  width: 80,
                  show: shouldShowColumn('is_floating')
                },
                {
                  accessor: 'is_floating_cloud',
                  className: 'text-center',
                  Header: __('Is floating cloud'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return rowData.value ? (
                      <IconCheckmark color="#10ac84" height="14px" />
                    ) : (
                      <IconDisabled color="#aaa" height="14px" />
                    );
                  },
                  width: 80,
                  show: shouldShowColumn('is_floating_cloud')
                },
                {
                  accessor: 'floating_users',
                  className: 'text-center',
                  Header: __('Max simultaneous license users'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    const isFloating =
                      get(rowData, 'original.is_floating') ||
                      get(rowData, 'original.is_floating_cloud');
                    if (!isFloating) {
                      return __('N/A');
                    }
                    return displayValue(rowData.value);
                  },
                  width: 120,
                  show: shouldShowColumn('floating_users')
                },
                {
                  accessor: 'can_borrow',
                  className: 'text-center',
                  Header: __('Can borrow'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return rowData.value ? (
                      <IconCheckmark color="#10ac84" height="14px" />
                    ) : (
                      <IconDisabled color="#aaa" height="14px" />
                    );
                  },
                  width: 80,
                  show: shouldShowColumn('can_borrow')
                },
                {
                  accessor: 'max_borrow_time',
                  className: 'text-center',
                  Header: __('Max borrow time'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    const canBorrow = get(rowData, 'original.can_borrow');
                    if (!canBorrow) {
                      return __('N/A');
                    }
                    return `${displayValue(rowData.value)} ${__('hours')}`;
                  },
                  width: 120,
                  show: shouldShowColumn('max_borrow_time')
                },
                {
                  accessor: 'max_license_users',
                  className: 'text-center',
                  Header: __('Max license users'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return displayValue(rowData.value);
                  },
                  width: 80,
                  show: shouldShowColumn('max_license_users')
                },
                {
                  accessor: 'enable_maintenance_period',
                  className: 'text-center',
                  Header: __('Enable maintenance period'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return rowData.value ? (
                      <IconCheckmark color="#10ac84" height="14px" />
                    ) : (
                      <IconDisabled color="#aaa" height="14px" />
                    );
                  },
                  maxWidth: 200,
                  show:
                    shouldShowColumn('enable_maintenance_period') &&
                    checkCompanyConfigField(companyID, 'maintenancePeriod')
                },
                {
                  accessor: 'maintenance_duration',
                  Header: __('Maintenance duration'),
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return displayValue(rowData.value);
                  },
                  show:
                    shouldShowColumn('maintenance_duration') &&
                    checkCompanyConfigField(companyID, 'maintenancePeriod'),
                  sortable: false
                },
                {
                  accessor: 'maintenance_period',
                  Header: __('Maintenance End Date'),
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return displayValue(rowData.value);
                  },
                  show:
                    shouldShowColumn('maintenance_period') &&
                    checkCompanyConfigField(companyID, 'maintenancePeriod')
                },
                {
                  accessor: 'max_consumptions',
                  Header: __('Max consumptions'),
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    const isUnlimited = get(
                      rowData,
                      'original.allow_unlimited_consumptions'
                    );
                    if (isUnlimited) {
                      return __('Unlimited');
                    }
                    return displayValue(rowData.value, '0');
                  },
                  show: shouldShowColumn('max_consumptions')
                },
                {
                  accessor: 'total_consumptions',
                  Header: __('Total consumptions'),
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return displayValue(rowData.value, '0');
                  },
                  show: shouldShowColumn('total_consumptions')
                },
                {
                  accessor: 'product_features',
                  Header: __('Product features'),
                  headerClassName: 'text-center',
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return (
                      <SelectedFeaturesTags
                        features={get(rowData, 'value') || []}
                      />
                    );
                  },
                  show:
                    shouldShowColumn('product_features') &&
                    checkCompanyConfigField(companyID, 'productFeatures'),
                  sortable: false
                },
                {
                  accessor: 'time_disabled',
                  Header: __('Disabled on'),
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return formatDate(rowData.value);
                  },
                  show: shouldShowColumn('time_disabled')
                },
                {
                  accessor: 'time_activated',
                  Header: __('Activated on'),
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return formatDate(rowData.value);
                  },
                  show: shouldShowColumn('time_activated')
                },
                {
                  accessor: 'updated_at',
                  Header: __('Last updated'),
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return formatDate(rowData.value);
                  },
                  show: shouldShowColumn('updated_at')
                },
                {
                  accessor: 'last_check',
                  Header: __('Last checked'),
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return formatDate(rowData.value);
                  },
                  show: shouldShowColumn('last_check')
                },
                {
                  accessor: 'valid_duration',
                  Header: __('Valid duration'),
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    return displayValue(rowData.value);
                  },
                  show: shouldShowColumn('valid_duration'),
                  sortable: false
                },
                {
                  accessor: 'order',
                  Header: __('Order'),
                  Cell: rowData => displayValue(rowData.value),
                  show: shouldShowColumn('order')
                },
                {
                  accessor: 'active_up_to_date_devices',
                  Header: __('Active Devices Up to Date'),
                  Cell: rowData => {
                    const isBundle = get(rowData, 'original.is_bundle');
                    if (isBundle) return '-';
                    const activeDevices =
                      get(rowData, 'original.active_up_to_date_devices') ||
                      null;
                    const totalDevices =
                      get(rowData, 'original.total_active_devices') || 0;
                    if (!activeDevices) {
                      return __('N/A');
                    }
                    return `${activeDevices} / ${totalDevices}`;
                  },
                  sortable: false,
                  show: shouldShowColumn('active_up_to_date_devices')
                },
                {
                  accessor: 'note',
                  Header: __('Note'),
                  Cell: rowData => displayValue(rowData.value),
                  show: shouldShowColumn('note')
                }
              ]}
              page={page}
              pages={Math.ceil(licenseCount / rowsPerPage)}
              loading={isLoading}
              manual
              minRows={get(licenses, 'length') || 10}
              showPagination={licenseCount > 5}
              onPageChange={handlePageChange}
              onSortedChange={handleSortChange}
              defaultSorted={currentSort}
              handleClick={rowData => redirectToLicensePage(rowData)}
              showPageSizeOptions
              pageSize={rowsPerPage}
              onPageSizeChange={(pageSize, pageIndex) =>
                handlePageSizeChange(pageSize, pageIndex)
              }
            />
          </div>
        </TabContent>
        <TabContent>
          <LicensesImport companyID={companyID} />
        </TabContent>
        <TabContent>
          <LicensesFeaturesImport companyID={companyID} />
        </TabContent>
      </Tabs>
      {exportModalDisplayed && (
        <ExportOrderForm
          closeCb={() => setExportModalDisplay(false)}
          filters={{
            selectedFilters,
            selectedCustomerLabelFilters,
            selectedEnabledFilter,
            validityPeriodLte,
            selectedProductFilter,
            searchBy,
            query
          }}
          handleClearFilters={handleClearFilters}
        />
      )}
      {confirmationPopupDisplayed && (
        <ConfirmationPopup
          closeCb={() => setConfirmationPopupDisplay(false)}
          confirmCb={executeBulkAction}
          confirmText={capitalizeFirstLetter(currentBulkAction)}
          disabled={processingBulkAction}
          theme="error"
          title={__(
            `Are you sure you want to ${currentBulkAction} selected licenses?`
          )}
        />
      )}
    </Page>
  );
};

export default LicenseList;
