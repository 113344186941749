import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './styles.scss';

const CustomBreadcrumbs = ({ companyId, order }) => (
  <ul className="CustomBreadcrumbs">
    <Fragment>
      <NavLink to={`/${companyId}`}>Home</NavLink>
      &nbsp;
    </Fragment>
    <Fragment>
      /&nbsp;
      <NavLink to={`/${companyId}/orders`}>Orders</NavLink>
      &nbsp;
    </Fragment>
    <Fragment>
      /&nbsp;
      <NavLink to={`/${companyId}/orders/${order.id}`}>
        {`Order: ${order.store_id || order.id}`}
      </NavLink>
      &nbsp;
    </Fragment>
  </ul>
);

CustomBreadcrumbs.propTypes = {
  companyId: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired
};

export default CustomBreadcrumbs;
