import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendErrorReport,
  displayValue,
  checkUserPermission
} from 'shared/helpers';
import {
  Button,
  ConfirmationPopup,
  IconDelete,
  IconEdit,
  List,
  Notification,
  PermissionMissingNotificationTitle
} from 'shared/components';
import { userPermissions } from 'shared/constants';
import {
  removeCustomerLabel,
  addNewCustomerLabel,
  updateCustomerLabel
} from 'src/company/actions';
import { deleteCustomerLabel } from 'src/customer/actions';
import CustomerLabelsForm from '../CustomerLabelsForm';
import EditLabelForm from '../EditLabelForm';

const CustomerLabels = ({ refetchCustomers }) => {
  const canManageCustomers = checkUserPermission(
    userPermissions.customers_write
  );
  const dispatch = useDispatch();
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const companyCustomerLabels = useSelector(state =>
    get(state, 'company.customerLabels')
  );

  const [labelToDelete, setLabelToDelete] = useState(null);
  const [isLabelDeletePopupDisplayed, setLabelDeletePopupDisplay] = useState(
    false
  );
  const [deletingLabel, setDeletingLabel] = useState(false);
  const [labelToEdit, setLabelToEdit] = useState(null);
  const [isLabelEditPopupDisplayed, setLabelEditPopupDisplay] = useState(false);
  const [isLabelsFormDisplayed, setLabelsFormDisplay] = useState(false);

  const handleLabelDelete = label => {
    const labelID = get(label, 'id');
    setDeletingLabel(true);

    deleteCustomerLabel(labelID, companyID)
      .then(() => {
        setDeletingLabel(false);
        setLabelDeletePopupDisplay(false);
        setLabelToDelete(null);
        dispatch(removeCustomerLabel(label));
        refetchCustomers();
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete customer label');
        setDeletingLabel(false);
      });
  };

  const handleManageLabelsClick = cb => {
    if (!canManageCustomers) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle
          permission={userPermissions.customers_write}
        />,
        __('Contact you account admin for support.')
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <div className="CustomerLabels CustomersList-labels">
      <div className="list-header">
        <div>
          <Button
            theme="info"
            size="sm"
            onClick={() => handleManageLabelsClick(setLabelsFormDisplay)}
          >
            {__('Add new label')}
          </Button>
        </div>
      </div>
      <List
        data={companyCustomerLabels}
        columns={[
          {
            accessor: 'label',
            Header: __('Name'),
            Cell: cellInfo => displayValue(cellInfo.value)
          },
          {
            accessor: 'color',
            Header: __('Color'),
            Cell: cellInfo => (
              <div className="color-cell">
                <div
                  className="color-container"
                  style={{ backgroundColor: cellInfo.value || '#949494' }}
                />
                <div className="color-name">{cellInfo.value || '#949494'}</div>
              </div>
            )
          },
          {
            Cell: rowData => (
              <Button
                className="edit-button"
                onClick={() =>
                  handleManageLabelsClick(() => {
                    const isCompanyLabel = get(rowData, 'original.company');
                    if (!isCompanyLabel) {
                      Notification(
                        'error',
                        __('This is global label'),
                        __('Only custom labels can be edited')
                      );
                    } else {
                      setLabelToEdit(rowData.original);
                      setLabelEditPopupDisplay(true);
                    }
                  })
                }
                type="button"
              >
                <IconEdit height="16px" width="16px" />
              </Button>
            ),
            maxWidth: 50
          },
          {
            Cell: rowData => (
              <Button
                className="edit-button"
                onClick={() =>
                  handleManageLabelsClick(() => {
                    const isCompanyLabel = get(rowData, 'original.company');
                    if (!isCompanyLabel) {
                      Notification(
                        'error',
                        __('This is global label'),
                        __('Only custom labels can be deleted')
                      );
                    } else {
                      setLabelToDelete(rowData.original);
                      setLabelDeletePopupDisplay(true);
                    }
                  })
                }
                type="button"
              >
                <IconDelete height="16px" width="16px" color="#ee5253" />
              </Button>
            ),
            maxWidth: 50
          }
        ]}
        minRows={2}
        clickable={false}
      />
      {isLabelDeletePopupDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setLabelDeletePopupDisplay(false);
            setLabelToDelete(null);
          }}
          confirmCb={() => handleLabelDelete(labelToDelete)}
          title={`${__(
            'Are you sure you want to delete this customer label'
          )}?`}
          confirmText={__('Delete')}
          theme="error"
          disabled={deletingLabel}
        >
          <div>
            <div>
              {__(
                'This will also remove the label from all the customers who have it.'
              )}
            </div>
            <strong>{get(labelToDelete, 'label')}</strong>
          </div>
        </ConfirmationPopup>
      )}
      {isLabelEditPopupDisplayed && (
        <EditLabelForm
          labelToEdit={labelToEdit}
          closeCb={() => {
            setLabelEditPopupDisplay(false);
            setLabelToEdit(null);
          }}
          companyID={companyID}
          updateCustomerLabel={data => dispatch(updateCustomerLabel(data))}
          refetchCustomers={refetchCustomers}
        />
      )}
      {isLabelsFormDisplayed && (
        <CustomerLabelsForm
          closeCb={() => setLabelsFormDisplay(false)}
          customer={{}}
          companyLabels={companyCustomerLabels}
          companyID={companyID}
          refetchCustomer={() => {}}
          updateCompanyLabels={data => dispatch(addNewCustomerLabel(data))}
          isLabelCreateOnly
        />
      )}
    </div>
  );
};

CustomerLabels.propTypes = {
  refetchCustomers: PropTypes.func.isRequired
};

export default CustomerLabels;
