import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { sendErrorReport } from 'shared/helpers';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  DateInput
} from 'shared/components';
import { validateDate, debouncedValidateDate } from 'shared/validation';
import { patchCompany } from 'src/billing/actions';
import './styles.scss';

const DisableDateForm = ({ closeCb, company, refetchCompany }) => {
  const companyID = get(company, 'id');
  const initialDate = get(company, 'payment_skip_check_until');
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);
  // form state
  const [disableDate, setDisableDate] = useState(
    initialDate ? moment(initialDate).format('YYYY-MM-DD') : null
  );
  const [disableDateError, setDisableDateError] = useState('');

  const validateDisableDate = async val => {
    setLoading(true);
    let errors;

    try {
      errors = await validateDate(val);
      setLoading(false);
      setDisableDateError(errors);
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate max activations', { value: val });
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const handleDisableDateChange = val => {
    setDirty(true);
    setDisableDate(val);
    debouncedValidateDate(val).then(err => setDisableDateError(err));
  };

  const isFormValid = async () => {
    const isDisabledDateValid = await validateDisableDate(disableDate);
    return isDisabledDateValid;
  };

  const changeDisabledDate = () => {
    setLoading(true);
    const data = {
      payment_skip_check_until: moment(disableDate).format('YYYY-MM-DDThh:mm')
    };
    patchCompany(companyID, data)
      .then(() => {
        refetchCompany();
        closeCb();
        Notification('success', __('Changes saved successfully'), '');
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot set company disabled date');
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setDirty(false);
    setLoading(true);
    changeDisabledDate();
    return true;
  };

  const handleClose = () => {
    if (!isDirty) {
      return closeCb();
    }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      size="sm"
      title={__('Change disable date')}
    >
      <form className="DisableDateForm" onSubmit={handleSubmit}>
        <div className="form-inner">
          <div className="form-row">
            <Label text={__('Script disabled until:')} inputId="disable-date" />
            <DateInput
              handleChange={val => handleDisableDateChange(val)}
              value={disableDate}
              error={disableDateError}
              id="disable-date"
            />
          </div>
        </div>
        <button type="submit" style={{ visibility: 'hidden' }} />
      </form>
      {isDirtyFormDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

DisableDateForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  refetchCompany: PropTypes.func.isRequired
};

export default DisableDateForm;
