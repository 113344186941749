import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  Checkbox,
  DirtyFormAlert,
  Label,
  NumberInput,
  Notification,
  TextInput,
  Selector,
  Modal
} from 'shared/components';
import { sendErrorReport } from 'shared/helpers';
import { defaultFeaturesList } from 'shared/constants';
import { getCompanyFeatures } from 'src/company/actions';
import { addFeature } from 'src/billing/actions';
import { getReaminingFeatures, mapFeaturesToSelector } from './helpers';
import './styles.scss';

const AddCompanyFeatureForm = ({ closeCb, companyId, features }) => {
  const dispatch = useDispatch();
  const remainingFeatures = getReaminingFeatures(features, defaultFeaturesList);
  const featureOptions = mapFeaturesToSelector(remainingFeatures);

  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setIsDirtyFormAlertDisplayed] = useState(
    false
  );
  const [loading, setLoading] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(
    get(featureOptions, '[0]') || []
  );
  const [isUnlimited, setUnlimited] = useState(
    get(featureOptions, '[0].data.value') === -1
  );
  const [featureValue, setFeatureValue] = useState(
    get(featureOptions, '[0].data.value')
  );
  const [featureValueError, setFeatureValueError] = useState('');
  const [featureStatus, setFeatureStatus] = useState(
    get(featureOptions, '[0].data.is_active')
  );

  const validateValue = val => {
    const defaultValue = get(selectedFeature, 'data.value');
    if (isUnlimited || !defaultValue) {
      return true;
    }
    if (!val || Number(val) <= 0) {
      setFeatureValueError(__('This field is required'));
      return false;
    }
    setFeatureValueError('');
    return true;
  };

  const isFormValid = () => {
    const isValueValid = validateValue(featureValue);
    return isValueValid;
  };

  const handleSubmit = () => {
    if (!isFormValid() || loading) {
      return false;
    }

    setLoading(true);
    const fValue = isUnlimited ? -1 : featureValue;
    const data = {
      key: get(selectedFeature, 'data.key'),
      value: fValue || null,
      is_active: featureStatus,
      company: companyId
    };

    addFeature(data, companyId)
      .then(() => {
        dispatch(getCompanyFeatures(companyId));
        closeCb();
        Notification(
          'success',
          __('Feature successfully added to the company')
        );
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot add feature to the company', data);
        setLoading(false);
        Notification(
          'error',
          __('Error occured'),
          __('We could not add the feature to the company.')
        );
      });
    return true;
  };

  if (!featureOptions.length) {
    return (
      <Modal
        closeCb={() => closeCb()}
        confirmCb={handleSubmit}
        title={__('Add feature')}
        size="sm"
        disabled={loading}
        hideFooter
      >
        {__('All company features have been added.')}
      </Modal>
    );
  }

  return (
    <Modal
      closeCb={() => setIsDirtyFormAlertDisplayed(true)}
      confirmCb={handleSubmit}
      title={__('Add feature')}
      size="sm"
      disabled={loading}
    >
      <div className="AddCompanyFeatureForm">
        <div>
          <Label text={__('Feature')} inputId="feature-select" />
          <Selector
            options={featureOptions}
            value={selectedFeature.value}
            handleChange={val => {
              setDirty(true);
              const selected = featureOptions.find(
                option => option.value === val
              );
              setSelectedFeature(selected);
              setUnlimited(get(selected, 'data.value') === -1);
              setFeatureValue(get(selected, 'data.value'));
              setFeatureValueError('');
              setFeatureStatus(get(selected, 'data.is_active'));
            }}
          />
        </div>
        <div>
          <Label text={__('Key')} inputId="feature-key" />
          <TextInput
            value={get(selectedFeature, 'data.key')}
            handleChange={() => {}}
            disabled
            id="feature-key"
          />
        </div>
        <div>
          <Label text={__('Default plan types')} inputId="feature-plans" />
          <TextInput
            value={get(selectedFeature, 'data.plans').join(', ')}
            handleChange={() => {}}
            disabled
            id="feature-plans"
          />
        </div>
        {get(selectedFeature, 'data.value') && (
          <div>
            <Label text={__('Value')} inputId="feature-value" />
            <Checkbox
              label={__('Is unlimited')}
              handleChange={val => {
                setDirty(true);
                setUnlimited(val);
              }}
              checked={isUnlimited}
              inputId="feature-value-unlimited"
            />
            {!isUnlimited && (
              <NumberInput
                min="1"
                max="2147483647"
                id="feature-value"
                handleChange={val => {
                  setDirty(true);
                  setFeatureValue(val);
                  validateValue(val);
                }}
                value={featureValue}
                error={featureValueError}
              />
            )}
          </div>
        )}
        <div>
          <Label text={__('Status')} inputId="feature-active" />
          <Checkbox
            label={__('Is active')}
            handleChange={val => {
              setDirty(true);
              setFeatureStatus(val);
            }}
            checked={featureStatus}
            inputId="feature-active"
          />
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setIsDirtyFormAlertDisplayed(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

AddCompanyFeatureForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  features: PropTypes.array.isRequired
};

export default AddCompanyFeatureForm;
