export const transformSort = (sortObject = []) => {
  const formattedSortObject = sortObject.map(sortObj => {
    if (sortObj.id === 'labels') {
      return {
        id: 'clabels_all',
        desc: sortObj.desc
      };
    }
    if (sortObj.id === 'customer_account.name') {
      return {
        id: 'customer_account__name',
        desc: sortObj.desc
      };
    }
    return sortObj;
  });

  const fieldsToTransform = [
    'customer.email',
    'customer.company_name',
    'customer.last_name',
    'customer.reference',
    'product.id'
  ];
  return formattedSortObject.map(s => {
    const field = s.id;
    if (fieldsToTransform.indexOf(field) >= 0) {
      return {
        id: field.split('.').join('__'),
        desc: s.desc
      };
    }
    return s;
  });
};
