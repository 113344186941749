import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  defaultDisabledFeatureMessage,
  licenseTypes,
  platformFeatures
} from 'shared/constants';
import { isFeatureAvailable, isFeatureEnabled } from 'shared/helpers';
import {
  // checkCompanyConfigField,
  getCompanyConfigFieldValue
} from 'shared/companyConfig';
import { Selector } from 'shared/components';
import { filterLicenseTypes } from './helpers';
import LicenseNotAvailableMessage from './components/LicenseNotAvailableMessage';

const LicenseTypeSelector = ({
  currentCompany,
  handleLicenseChange,
  selectedLicense
}) => {
  const licenseTypeOptions = [
    {
      label: __(licenseTypes.perpetual),
      value: licenseTypes.perpetual,
      isOptionAvailable: isFeatureAvailable(platformFeatures.model_perpetual),
      isOptionEnabled: isFeatureEnabled(platformFeatures.model_perpetual),
      isDisabled: !(
        isFeatureAvailable(platformFeatures.model_perpetual) &&
        isFeatureEnabled(platformFeatures.model_perpetual)
      ),
      disabledMessage: !isFeatureAvailable(platformFeatures.model_perpetual) ? (
        <LicenseNotAvailableMessage />
      ) : (
        __(defaultDisabledFeatureMessage)
      )
    },
    {
      label: __(licenseTypes.time_limited),
      value: licenseTypes.time_limited,
      isOptionAvailable: isFeatureAvailable(
        platformFeatures.model_time_limited
      ),
      isOptionEnabled: isFeatureEnabled(platformFeatures.model_time_limited),
      isDisabled: !(
        isFeatureAvailable(platformFeatures.model_time_limited) &&
        isFeatureEnabled(platformFeatures.model_time_limited)
      ),
      disabledMessage: !isFeatureAvailable(
        platformFeatures.model_time_limited
      ) ? (
        <LicenseNotAvailableMessage />
      ) : (
        __(defaultDisabledFeatureMessage)
      )
    },
    {
      label: __(licenseTypes.subscription),
      value: licenseTypes.subscription,
      isOptionAvailable: isFeatureAvailable(
        platformFeatures.model_subscription
      ),
      isOptionEnabled: isFeatureEnabled(platformFeatures.model_subscription),
      isDisabled: !(
        isFeatureAvailable(platformFeatures.model_subscription) &&
        isFeatureEnabled(platformFeatures.model_subscription)
      ),
      disabledMessage: !isFeatureAvailable(
        platformFeatures.model_subscription
      ) ? (
        <LicenseNotAvailableMessage
          type={platformFeatures.model_subscription}
        />
      ) : (
        __(defaultDisabledFeatureMessage)
      )
    },
    {
      label: __(licenseTypes.consumption),
      value: licenseTypes.consumption,
      isOptionAvailable: isFeatureAvailable(platformFeatures.model_consumption),
      isOptionEnabled: isFeatureEnabled(platformFeatures.model_consumption),
      isDisabled: !(
        isFeatureAvailable(platformFeatures.model_consumption) &&
        isFeatureEnabled(platformFeatures.model_consumption)
      ),
      disabledMessage: !isFeatureAvailable(
        platformFeatures.model_consumption
      ) ? (
        <LicenseNotAvailableMessage type={platformFeatures.model_consumption} />
      ) : (
        __(defaultDisabledFeatureMessage)
      )
    }
  ];

  const typesFromOverride =
    getCompanyConfigFieldValue(currentCompany, 'licenseTypes') || [];
  const filteredTypesOptions = filterLicenseTypes(
    typesFromOverride,
    licenseTypeOptions
  );

  return (
    <Selector
      options={filteredTypesOptions}
      value={selectedLicense}
      handleChange={handleLicenseChange}
    />
  );
};

LicenseTypeSelector.propTypes = {
  currentCompany: PropTypes.number.isRequired,
  handleLicenseChange: PropTypes.func.isRequired,
  selectedLicense: PropTypes.string
};

LicenseTypeSelector.defaultProps = {
  selectedLicense: null
};

const mapStateToProps = state => ({
  currentCompany: get(state, 'company.details.id')
});

export default connect(mapStateToProps)(LicenseTypeSelector);
