import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { HelpNavigation, ProfileNavigation } from 'shared/components';
import logo from './logo.png';
import './styles.scss';

const TopHeader = ({ children }) => (
  <header className="TopHeader">
    <div className="TopHeader-primary">
      <div className="logo">
        <NavLink to="/">
          <img src={logo} alt="Logo" height="24" />
        </NavLink>
      </div>
      {children}
    </div>
    <div className="TopHeader-secondary">
      <div className="docs-btn">
        <a
          href="https://docs.licensespring.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Open documentation
        </a>
      </div>
      <HelpNavigation />
      <ProfileNavigation />
    </div>
  </header>
);

TopHeader.propTypes = {
  children: PropTypes.element
};

TopHeader.defaultProps = {
  children: <div />
};

export default TopHeader;
