import { get } from 'lodash';
import api from 'shared/api';
import { formatOrderBy } from 'shared/helpers';
import * as actionTypes from './actionTypes';

// notifications
// events actions
const getEventsInit = () => ({
  type: actionTypes.getEventsInit
});

const getEventsSuccess = res => ({
  type: actionTypes.getEventsSuccess,
  payload: res
});

const getEventsError = res => ({
  type: actionTypes.getEventsError,
  payload: res
});

export const getEvents = () => dispatch => {
  dispatch(getEventsInit());
  const url = '/api/v1/events/?limit=100';
  return api.get(url).then(
    res => dispatch(getEventsSuccess(get(res, 'data.results') || [])),
    res => dispatch(getEventsError(res.data))
  );
};

// notifications actions
export const getNotificationsInit = () => ({
  type: actionTypes.getNotificationsInit
});

export const getNotificationsSuccess = res => ({
  type: actionTypes.getNotificationsSuccess,
  payload: res
});

export const getNotificationsError = res => ({
  type: actionTypes.getNotificationsError,
  payload: res
});

export const getNotificationPolicies = companyID => dispatch => {
  dispatch(getNotificationsInit());
  return api.get(`/api/v1/notification-policies/?company=${companyID}`).then(
    res => dispatch(getNotificationsSuccess(get(res, 'data.results') || [])),
    res => dispatch(getNotificationsError(res.data))
  );
};

export const getEvent = (eventID, companyID) =>
  api.get(`/api/v1/events/${eventID}/?company=${companyID}`);

export const deleteNotificationPolicy = (notificationPolicyID, companyID) =>
  api.delete(
    `/api/v1/notification-policies/${notificationPolicyID}/?company=${companyID}`
  );

export const createNotificationPolicy = (companyID, data) =>
  api.post(`/api/v1/notification-policies/?company=${companyID}`, data);

export const getNotificationPolicy = (notificationPolicyID, companyID) =>
  api.get(
    `/api/v1/notification-policies/${notificationPolicyID}/?company=${companyID}`
  );

export const updateNotificationPolicy = (
  notificationPolicyID,
  companyID,
  data
) =>
  api.patch(
    `/api/v1/notification-policies/${notificationPolicyID}/?company=${companyID}`,
    data
  );

export const resetNotificationPolicy = (notificationPolicyID, companyID) =>
  api.post(
    `/api/v1/notification-policies/${notificationPolicyID}/default_email_options/?company=${companyID}`
  );

export const testSendNotificationPolicy = (
  notificationPolicyID,
  companyID,
  data
) =>
  api.post(
    `/api/v1/notification-policies/${notificationPolicyID}/test?company=${companyID}`,
    data
  );

export const getNotificationsHistory = (
  companyID,
  page,
  orderBy,
  pageSize = 20
) => {
  const offsetUrl = page ? page * pageSize : 0;
  const orderUrl = formatOrderBy(orderBy);
  const url = `/api/v1/notification-histories/?company=${companyID}&limit=${pageSize}&offset=${offsetUrl}${orderUrl}`;
  return api.get(url);
};

export const getNotificationHistoryRecord = (notificationID, companyID) =>
  api.get(
    `/api/v1/notification-histories/${notificationID}/?company=${companyID}`
  );

export const resendNotification = (notificationID, companyID, data) =>
  api.post(
    `/api/v1/notification-histories/${notificationID}/resend/?company=${companyID}`,
    data
  );

// webhook events actions
const getWebhookEventsInit = () => ({
  type: actionTypes.getWebhookEventsInit
});

const getWebhookEventsSuccess = res => ({
  type: actionTypes.getWebhookEventsSuccess,
  payload: res
});

const getWebhookEventsError = res => ({
  type: actionTypes.getWebhookEventsError,
  payload: res
});

export const getAvailableWebhookEvents = () => dispatch => {
  dispatch(getWebhookEventsInit());
  const url = '/api/v1/services/?code=webhooks';
  return api.get(url).then(
    res =>
      dispatch(
        getWebhookEventsSuccess(get(res, 'data.results[0].events') || [])
      ),
    res => dispatch(getWebhookEventsError(res.data))
  );
};
