import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Selector } from 'shared/components';
import { sendErrorReport } from 'shared/helpers';

const AsyncSelector = ({
  fetchInitialOptions,
  fetchOptions,
  dataQuery,
  disabled,
  labelKey,
  multi,
  value,
  valueKey,
  isClearable,
  handleChange,
  ...rest
}) => {
  const mounted = useRef(false);
  const fetchIdRef = useRef(0);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getInitialOptions = useCallback(() => {
    fetchInitialOptions()
      .then(res => {
        if (mounted.current) {
          setOptions(get(res, `${dataQuery}`) || []);
        }
      })
      .catch(err => {
        sendErrorReport(err, 'Async selector options could not be fetched');
      });
  }, [fetchInitialOptions, dataQuery, mounted.current]);

  useEffect(() => {
    getInitialOptions();
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [getInitialOptions]);

  const handleSearch = query => {
    if (!query) {
      return;
    }
    setLoading(true);
    // Give this fetch an ID
    // eslint-disable-next-line no-plusplus
    const fetchId = ++fetchIdRef.current;

    fetchOptions(query)
      .then(res => {
        if (fetchId === fetchIdRef.current) {
          setLoading(false);
          setOptions(get(res, `${dataQuery}`) || []);
        }
      })
      .catch(err => {
        sendErrorReport(err, 'Async selector options could not be fetched');
        setLoading(false);
      });
  };

  return (
    <div className="Selector">
      <Selector
        getOptionLabel={o => o[labelKey]}
        getOptionValue={o => o[valueKey]}
        onChange={option => handleChange(option)}
        isLoading={loading}
        isDisabled={disabled}
        onInputChange={handleSearch}
        options={options}
        value={value}
        isClearable={isClearable}
        valueKey={valueKey}
        {...rest}
      />
    </div>
  );
};

AsyncSelector.propTypes = {
  dataQuery: PropTypes.string,
  disabled: PropTypes.bool,
  fetchOptions: PropTypes.func.isRequired,
  fetchInitialOptions: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  multi: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  valueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isClearable: PropTypes.bool
};

AsyncSelector.defaultProps = {
  dataQuery: 'data.results',
  disabled: false,
  handleChange: () => {},
  multi: false,
  value: undefined,
  isClearable: false
};

export default AsyncSelector;
