import { get } from 'lodash';
import moment from 'moment';
import { formatAmountValue } from 'shared/helpers';

export const getTotal = (selectedPlan, selectedDiscount, selectedTax) => {
  let unitPlanPrice = 0;
  let unitDiscount = 0;
  let unitTax = 0;

  if (!selectedPlan) {
    return 0;
  }

  unitPlanPrice = formatAmountValue(selectedPlan.amount);

  if (selectedDiscount) {
    unitDiscount =
      Number(selectedDiscount.amount_off) ||
      (Number(unitPlanPrice) *
        Number(get(selectedDiscount, 'percent_off') || 0)) /
        100;
  }

  if (selectedTax) {
    unitTax =
      ((Number(unitPlanPrice) - unitDiscount) *
        Number(get(selectedTax, 'percentage') || 0)) /
      100;
  }

  const total = Number(unitPlanPrice) - unitDiscount + unitTax;
  return total.toFixed(2);
};

export const getBillingStartDate = (
  trial,
  startBillingOnTrialEnd,
  customBillingValue
) => {
  if (startBillingOnTrialEnd) {
    return moment(trial.trialEnd).format('YYYY-MM-DD');
  }

  return customBillingValue;
};
