import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { get } from 'lodash';
import { List } from 'shared/components';
import { APIdateFormat } from 'shared/constants';
import { formatDate, displayValue } from 'shared/helpers';
import { getAnalyticsLicenseTopCallCount } from 'src/analytics/actions';

const LicensesTopCallCount = ({ product, action }) => {
  const history = useHistory();
  const defaultFrom = moment().startOf('month');
  const defaultTo = moment().endOf('month');
  const productID = get(product, 'data.id') || undefined;
  const actionValue =
    get(action, 'value') === 'all' ? undefined : get(action, 'value');

  const from = useSelector(state =>
    get(state, 'analytics.dateRange.date_from')
  );
  const to = useSelector(state => get(state, 'analytics.dateRange.date_to'));

  const companyID = useSelector(state => get(state, 'company.details.id'));

  const dateFrom = from ? moment(from) : defaultFrom;
  const dateTo = to ? moment(to) : defaultTo;

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const getData = useCallback(
    (tablePage = 0, rows = rowsPerPage) => {
      const fromDate = dateFrom.format(APIdateFormat);
      const toDate = dateTo.format(APIdateFormat);

      const gteParam = `&ts__date__gte=${fromDate}`;
      const lteParam = `&ts__date__lte=${toDate}`;

      setLoading(true);

      getAnalyticsLicenseTopCallCount(
        companyID,
        gteParam,
        lteParam,
        tablePage,
        rows,
        productID,
        actionValue
      )
        .then(res => {
          setData(get(res, 'data.results') || []);
          setDataCount(get(res, 'data.count'));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [companyID, from, to, productID, actionValue]
  );

  const handlePageChange = newPage => {
    setPage(newPage);
    getData(newPage, rowsPerPage);
  };

  const handlePageSizeChange = (newPageSize, newPage) => {
    setPage(newPage);
    setRowsPerPage(newPageSize);
    getData(newPage, newPageSize);
  };

  const redirectToItemPage = rowData => {
    const orderID = get(rowData, 'original.order.id');
    const licenseID = get(rowData, 'original.id');
    history.push(`/${companyID}/orders/${orderID}/${licenseID}`);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="LicensesTopCallCount">
      <List
        columns={[
          {
            accessor: 'created_at',
            Header: __('Created on'),
            Cell: rowData => formatDate(rowData.value),
            sortable: false
          },
          {
            accessor: 'key_or_users',
            Header: `${__('License Key')}/${__('User')}`,
            Cell: rowData => displayValue(rowData.value),
            sortable: false
          },
          {
            accessor: 'product.product_name',
            Header: __('Product'),
            Cell: rowData => displayValue(rowData.value),
            sortable: false
          },
          {
            accessor: 'total_call_count',
            Header: __('Total'),
            Cell: rowData => displayValue(rowData.value),
            sortable: false
          }
        ]}
        data={data}
        clickable
        page={page}
        pages={Math.ceil(dataCount / rowsPerPage)}
        loading={isLoading}
        manual
        minRows={get(data, 'length') || 10}
        showPagination={dataCount > 5}
        onPageChange={handlePageChange}
        // onSortedChange={handleSortChange}
        // defaultSorted={currentSort}
        handleClick={rowData => redirectToItemPage(rowData)}
        showPageSizeOptions
        pageSize={rowsPerPage}
        onPageSizeChange={(pageSize, pageIndex) =>
          handlePageSizeChange(pageSize, pageIndex)
        }
      />
    </div>
  );
};

LicensesTopCallCount.propTypes = {
  product: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired
};

export default LicensesTopCallCount;
