import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { IconExport, InvoiceStatusIcon, List } from 'shared/components';
import {
  displayValue,
  formatDate,
  formatAmountValue,
  mapCurrencySymbol,
  sendErrorReport
} from 'shared/helpers';
import { fetchInvoices, getInvoicesSuccess } from 'src/account/actions';
import './styles.scss';

const InvoicesList = () => {
  const dispatch = useDispatch();
  const companyID = useSelector(state => get(state, 'company.details.id'));

  const [invoicesLoading, setInvoicesLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  const showAmountData = cellInfo => {
    const value = formatAmountValue(cellInfo.value, 0);
    const currency = displayValue(
      mapCurrencySymbol(cellInfo.original.currency),
      ''
    );
    return `${value} ${currency}`;
  };

  const getInvoices = useCallback(() => {
    fetchInvoices(companyID)
      .then(res => {
        const data = get(res, 'data') || [];
        dispatch(getInvoicesSuccess(data));
        setInvoices(data);
        setInvoicesLoading(false);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot fetch invoices list');
        setInvoices([]);
        setInvoicesLoading(false);
      });
  }, [companyID]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  return (
    <div className="InvoicesList BillingContainer-invoices">
      <List
        data={invoices}
        columns={[
          {
            accessor: 'number',
            Header: __('Invoice number'),
            Cell: cellInfo => displayValue(cellInfo.value),
            maxWidth: 150
          },
          {
            accessor: 'status',
            Header: __('Status'),
            maxWidth: 100,
            Cell: cellInfo => {
              const isDisputed = get(cellInfo, 'original.charge.disputed');
              const isRefunded = get(cellInfo, 'original.charge.refunded');
              let status = displayValue(cellInfo.value);
              if (isDisputed) {
                status = 'disputed';
              }
              if (isRefunded) {
                status = 'refunded';
              }
              return (
                <div className="invoice-status">
                  <InvoiceStatusIcon status={status} />
                  <span>{status}</span>
                </div>
              );
            }
          },
          {
            accessor: 'status_transitions.paid_at',
            Header: __('Payment date'),
            Cell: cellInfo => formatDate(cellInfo.value),
            maxWidth: 140
          },
          {
            accessor: 'customer_email',
            Header: __('Billing account'),
            Cell: cellInfo => displayValue(cellInfo.value)
          },
          {
            accessor: 'subtotal',
            Header: __('Amount'),
            Cell: cellInfo => showAmountData(cellInfo),
            maxWidth: 90
          },
          {
            accessor: 'tax',
            Header: __('Tax'),
            Cell: cellInfo => {
              if (!get(cellInfo, 'value')) {
                return '-';
              }
              return showAmountData(cellInfo);
            },
            maxWidth: 70
          },
          {
            accessor: 'discounts',
            Header: __('Discount'),
            Cell: cellInfo => {
              const discounts = get(cellInfo, 'value') || [];
              if (Array.isArray(discounts) && !discounts.length) {
                return '-';
              }
              if (discounts.length === 1) {
                const percent = get(discounts, '[0].coupon.percent_off', '');
                if (!percent) {
                  return '-';
                }
                return `${percent} %`;
              }
              const discountValues = discounts.map(c =>
                get(c, 'coupon.percent_off')
              );
              const joinedValues = discountValues.join(' %, ');
              return `${joinedValues}%`;
            },
            maxWidth: 100
          },
          {
            accessor: 'total',
            Header: __('Total'),
            Cell: cellInfo => showAmountData(cellInfo),
            maxWidth: 90
          },
          {
            accessor: 'invoice_pdf',
            Cell: rowData => (
              <a
                href={rowData.value}
                disabled={!rowData.value}
                className="edit-button"
              >
                <IconExport height="16px" width="16px" color="#777" />
              </a>
            ),
            maxWidth: 50
          }
        ]}
        loading={invoicesLoading}
        minRows={2}
        pageSize={20}
      />
    </div>
  );
};

export default InvoicesList;
