import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  ConfirmationPopup,
  Notification,
  IconDelete,
  Page,
  List
} from 'shared/components';
import {
  sendErrorReport,
  displayValue,
  capitalizeFirstLetter
} from 'shared/helpers';
import { deleteTaxRate, getTaxRates } from 'src/billing/actions';
import TaxRateForm from './TaxRateForm';
import './styles.scss';

const TaxRatesSettings = () => {
  const dispatch = useDispatch();
  const loadingTaxRates = useSelector(state =>
    get(state, 'billing.loadingTaxRates')
  );
  const taxRates = useSelector(state => get(state, 'billing.taxRates'));

  const [isFormDisplayed, setFormDisplay] = useState(false);
  const [archivingTaxRate, setArchivingTaxRate] = useState(false);
  const [taxRateToArchive, setTaxRateToArchive] = useState(null);
  const [
    isTaxRateArchiveConfirmationDisplayed,
    setTaxRateArchiveConfirmationDisplayed
  ] = useState(false);

  const handleTaxRateArchive = tax => {
    const taxId = get(tax, 'id');
    setArchivingTaxRate(true);

    deleteTaxRate(taxId)
      .then(() => {
        dispatch(getTaxRates());
        Notification('success', __('Tax rate archived'));
        setTaxRateArchiveConfirmationDisplayed(false);
        setTaxRateToArchive(null);
        setArchivingTaxRate(false);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete tax rate');
        Notification(
          'error',
          __('Error occured'),
          __('We could not delete the tax rate.')
        );
        setArchivingTaxRate(false);
      });
  };

  return (
    <Page title={__('Tax rates')}>
      <div className="TaxRatesSettings">
        <div className="TaxRatesSettings-actions">
          <Button size="sm" theme="info" onClick={() => setFormDisplay(true)}>
            {__('Create new tax rate')}
          </Button>
        </div>
        <List
          columns={[
            {
              accessor: 'display_name',
              Header: __('Type'),
              Cell: cellInfo => displayValue(cellInfo.value)
            },
            {
              accessor: 'jurisdiction',
              Header: __('Region'),
              Cell: cellInfo =>
                displayValue(capitalizeFirstLetter(cellInfo.value))
            },
            {
              accessor: 'percentage',
              Header: __('Percentage'),
              Cell: cellInfo => {
                const percent = get(cellInfo, 'value', '');
                if (!percent) {
                  return '-';
                }
                return `${percent} %`;
              }
            },
            {
              accessor: 'inclusive',
              Header: __('Is inclusive'),
              Cell: cellInfo => {
                const value = get(cellInfo, 'value', '');
                return value ? __('Yes') : __('No');
              }
            },
            {
              Cell: rowData => (
                <Button
                  className="edit-button"
                  onClick={() => {
                    setTaxRateToArchive(rowData.original);
                    setTaxRateArchiveConfirmationDisplayed(true);
                  }}
                  type="button"
                >
                  <IconDelete height="16px" width="16px" color="#ee5253" />
                </Button>
              ),
              maxWidth: 50
            }
          ]}
          data={taxRates}
          loading={loadingTaxRates}
          minRows={2}
          pageSize={20}
          defaultPageSize={10}
        />
      </div>
      {isFormDisplayed && (
        <TaxRateForm
          title={__('Create new tax rate')}
          closeCb={() => setFormDisplay(false)}
          confirmCb={() => setFormDisplay(false)}
          fetchTaxes={() => dispatch(getTaxRates())}
        />
      )}
      {isTaxRateArchiveConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setTaxRateToArchive(null);
            setTaxRateArchiveConfirmationDisplayed(false);
          }}
          confirmCb={() => handleTaxRateArchive(taxRateToArchive)}
          title={`${__('Are you sure you want to archive this tax rate:')} ${
            taxRateToArchive.display_name
          } ${taxRateToArchive.jurisdiction}?`}
          confirmText={__('Archive')}
          theme="error"
          warning
          disabled={archivingTaxRate}
        />
      )}
    </Page>
  );
};

export default TaxRatesSettings;
