export default {
  orders: 'Orders',
  'all-orders': 'All orders',
  'license-managers': 'License managers',
  licenses: 'Licenses',
  'hardware-keys': 'Hardware keys',
  customers: 'Customers',
  products: 'Products',
  'new-product': 'New product',
  account: 'Settings',
  users: 'Users',
  'plan-type': 'Plan type',
  ':orderId': 'Order',
  ':productId': 'Product',
  ':licenseId': 'License',
  ':webhookId': 'Webhook endpoint',
  ':userId': 'User',
  ':customerId': 'Customer',
  accounts: 'Customer accounts',
  ':accountId': 'Customer company',
  'billing-configuration': 'Company settings',
  'pricing-plans': 'Pricing plans',
  webhooks: 'Webhooks',
  coupons: 'Coupons',
  'tax-rates': 'Tax rates',
  features: 'Features',
  analytics: 'API Usage'
};
