import React, { useState } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendErrorReport,
  displayValue,
  checkUserPermission
} from 'shared/helpers';
import { userPermissions } from 'shared/constants';
import {
  Button,
  ConfirmationPopup,
  IconDelete,
  IconEdit,
  List,
  Notification,
  PermissionMissingNotificationTitle
} from 'shared/components';
import {
  addNewUserLabel,
  removeUserLabel,
  updateUserLabel
} from 'src/company/actions';
import { deleteUserLabel } from 'src/account/actions';
import { UserLabelsForm, EditUserLabel } from '../components';

const UserLabels = () => {
  const canManagePlatformUsers = checkUserPermission(
    userPermissions.platform_users_write
  );
  const dispatch = useDispatch();
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const companyUserLabels = useSelector(state =>
    get(state, 'company.userLabels')
  );

  const [labelToDelete, setLabelToDelete] = useState(null);
  const [isLabelDeletePopupDisplayed, setLabelDeletePopupDisplay] = useState(
    false
  );
  const [deletingLabel, setDeletingLabel] = useState(false);
  const [labelToEdit, setLabelToEdit] = useState(null);
  const [isLabelEditPopupDisplayed, setLabelEditPopupDisplay] = useState(false);
  const [isLabelsFormDisplayed, setLabelsFormDisplay] = useState(false);

  const handleLabelDelete = label => {
    const labelID = get(label, 'id');
    setDeletingLabel(true);

    deleteUserLabel(labelID, companyID)
      .then(() => {
        setDeletingLabel(false);
        setLabelDeletePopupDisplay(false);
        setLabelToDelete(null);
        dispatch(removeUserLabel(label));
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete user label');
        setDeletingLabel(false);
      });
  };

  const handleManagePlatformUsersClick = cb => {
    if (!canManagePlatformUsers) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle
          permission={userPermissions.platform_users_write}
        />,
        __('Contact you account admin for support.')
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <div className="UserLabels UserList-labels">
      <div className="list-header">
        <div>
          <Button
            theme="info"
            size="sm"
            onClick={() => handleManagePlatformUsersClick(setLabelsFormDisplay)}
          >
            {__('Add new label')}
          </Button>
        </div>
      </div>
      <List
        data={companyUserLabels}
        columns={[
          {
            accessor: 'name',
            Header: __('Name'),
            Cell: cellInfo => displayValue(cellInfo.value)
          },
          {
            accessor: 'color',
            Header: __('Color'),
            Cell: cellInfo => (
              <div className="color-cell">
                <div
                  className="color-container"
                  style={{ backgroundColor: cellInfo.value || '#949494' }}
                />
                <div className="color-name">{cellInfo.value || '#949494'}</div>
              </div>
            )
          },
          {
            Cell: rowData => (
              <Button
                className="edit-button"
                onClick={() =>
                  handleManagePlatformUsersClick(() => {
                    setLabelToEdit(rowData.original);
                    setLabelEditPopupDisplay(true);
                  })
                }
                type="button"
              >
                <IconEdit height="16px" width="16px" />
              </Button>
            ),
            maxWidth: 50
          },
          {
            Cell: rowData => (
              <Button
                className="edit-button"
                onClick={() =>
                  handleManagePlatformUsersClick(() => {
                    setLabelToDelete(rowData.original);
                    setLabelDeletePopupDisplay(true);
                  })
                }
                type="button"
              >
                <IconDelete height="16px" width="16px" color="#ee5253" />
              </Button>
            ),
            maxWidth: 50
          }
        ]}
        minRows={2}
        clickable={false}
      />
      {isLabelDeletePopupDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setLabelDeletePopupDisplay(false);
            setLabelToDelete(null);
          }}
          confirmCb={() => handleLabelDelete(labelToDelete)}
          title={`${__('Are you sure you want to delete this user label')}?`}
          confirmText={__('Delete')}
          theme="error"
          disabled={deletingLabel}
        >
          <div>
            <div>
              {__(
                'This will also remove the label from all the users who have it.'
              )}
            </div>
            <strong>{get(labelToDelete, 'label')}</strong>
          </div>
        </ConfirmationPopup>
      )}
      {isLabelEditPopupDisplayed && (
        <EditUserLabel
          labelToEdit={labelToEdit}
          closeCb={() => {
            setLabelEditPopupDisplay(false);
            setLabelToEdit(null);
          }}
          companyID={companyID}
          updateUserLabel={data => dispatch(updateUserLabel(data))}
        />
      )}
      {isLabelsFormDisplayed && (
        <UserLabelsForm
          closeCb={() => setLabelsFormDisplay(false)}
          user={{}}
          companyLabels={companyUserLabels}
          companyID={companyID}
          updateCompanyLabels={data => dispatch(addNewUserLabel(data))}
          isLabelCreateOnly
        />
      )}
    </div>
  );
};

export default UserLabels;
