import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { getUserFromStorage } from 'src/keycloak/authUser';
import { UnauthorizedNotice } from 'shared/components';
import { isStaffUser } from 'shared/helpers';

const IsAuthorized = ({ children, userRole }) => {
  const user = getUserFromStorage();
  const companyID = useSelector(state => get(state, 'company.details.id'));

  const userTennants = get(user, 'profile.all_tenants') || [];
  const tennantAdmin = userTennants.filter(t =>
    t.roles.includes('tenant-admin')
  );
  const isAdmin = tennantAdmin.some(t => Number(t.tenant_name) === companyID);
  const isStaff = isStaffUser();

  if (userRole === 'admin' && !isStaff && !isAdmin) {
    return <UnauthorizedNotice />;
  }
  if (userRole === 'staff' && !isStaff) {
    return <UnauthorizedNotice />;
  }

  return children;
};

IsAuthorized.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  userRole: PropTypes.string
};

IsAuthorized.defaultProps = {
  userRole: 'user'
};

export default IsAuthorized;
