import React from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { changeDisableStaff } from 'src/account/actions';
import { Label, Switcher } from 'shared/components';
import { isStaffUser } from 'shared/helpers';
import './styles.scss';

const DebugSwitcher = () => {
  const dispatch = useDispatch();
  const account = useSelector(state => get(state, 'account'));
  const isStaffDisabled = get(account, 'disableStaff');

  const isStaff = isStaffUser();

  if (!isStaff) {
    return null;
  }

  const handleSwitcherChange = () => {
    const debugState = !isStaffDisabled;
    dispatch(changeDisableStaff(debugState));
    window.location.reload();
  };

  return (
    <div className="DebugSwitcher">
      <Label
        text={__('Staff disabled')}
        inputId="debug-switch"
        description="This will disable staff overrides regarding pricing plan features"
      />
      <Switcher
        isFeatureEnabled
        checked={isStaffDisabled}
        handleChange={handleSwitcherChange}
        size="sm"
      />
    </div>
  );
};

export default DebugSwitcher;
