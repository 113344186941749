import React from 'react';
import { get } from 'lodash';
import { getUserFromStorage } from 'src/keycloak/authUser';
import ActiveCompaniesList from 'src/company/components/ActiveCompaniesList';
import { DescriptionTable, Page } from 'shared/components';
import { displayValue } from 'shared/helpers';

const Welcome = () => {
  const user = getUserFromStorage();

  return (
    <Page title={__('Profile')}>
      <div className="Welcome">
        <DescriptionTable
          details={[
            {
              label: __('Email'),
              value: displayValue(get(user, 'profile.email'))
            },
            {
              label: __('Name'),
              value: displayValue(get(user, 'profile.name'))
            }
          ]}
        />
      </div>
      <ActiveCompaniesList />
    </Page>
  );
};

export default Welcome;
