import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { get } from 'lodash';
import {
  Button,
  Forbidden,
  Page,
  Notification,
  List,
  StatusCard,
  IconCheckmark,
  IconDisabled
} from 'shared/components';
import {
  sendErrorReport,
  displayValue,
  formatDate,
  isFeatureEnabled,
  isFeatureAvailable
} from 'shared/helpers';
import {
  initialDeviceCertificatesListSort,
  platformFeatures
} from 'shared/constants';
import {
  getDeviceCertificatesList,
  updateUIOptions
} from 'src/license/actions';
import { updateUserPreferences } from 'src/company/actions';
import HardwareKeyProvision from './HardwareKeyProvision';
import './styles.scss';

const HardwareKeys = () => {
  const dispatch = useDispatch();
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const userPrefsId = useSelector(state =>
    get(state, 'company.userPreferences.id')
  );
  const uiOptions = useSelector(state =>
    get(state, 'company.userPreferences.ui_options')
  );

  const areHardwareKeysEnabled =
    isFeatureEnabled(platformFeatures.extra_hardware_token) &&
    isFeatureAvailable(platformFeatures.extra_hardware_token);

  const [isLoading, setLoading] = useState(true);
  const [deviceCertificates, setDeviceCertificates] = useState({});
  const [currentSort, setCurrentSort] = useState(
    get(uiOptions, 'list_sort.deviceCertificates') ||
      initialDeviceCertificatesListSort
  );
  const [page, setPage] = useState(0);
  // const [tableExpanded, setTableExpanded] = useState({});
  const [isHwKeyProvisioning, setHwKeyProvisioning] = useState(false);

  const getDeviceCertificates = useCallback(
    (selectedPage, sort) => {
      setLoading(true);
      getDeviceCertificatesList(companyID, selectedPage, sort)
        .then(res => {
          setDeviceCertificates(get(res, 'data'));
          setLoading(false);
        })
        .catch(err => {
          sendErrorReport(err, 'Cannot fetch device certificates');
          Notification(
            'error',
            __('There was an error while getting your data')
          );
          setLoading(false);
        });
    },
    [companyID]
  );

  useEffect(() => {
    getDeviceCertificates(page, currentSort);
  }, [page, currentSort]);

  const patchUiFeatures = (newSorted = undefined) => {
    if (!userPrefsId) {
      return false;
    }

    const listSort = get(uiOptions, 'list_sort') || {};
    listSort.deviceCertificates = newSorted;
    const ui = { ...uiOptions, list_sort: listSort };
    const data = { ui_options: JSON.stringify(ui) };

    updateUIOptions(userPrefsId, companyID, data)
      .then(res => dispatch(updateUserPreferences(res.data)))
      .catch(err => sendErrorReport(err, 'Cannot update UI options', data));
    return true;
  };

  if (!areHardwareKeysEnabled) {
    return (
      <Page title={__('Hardware keys')}>
        <div className="HardwareKeys">
          <Forbidden
            text={__('This feature is not available in your current plan type')}
            description={__('Contact sales if you wish to change this policy.')}
          />
        </div>
      </Page>
    );
  }

  return (
    <Page title={__('Hardware keys')}>
      <div className="HardwareKeys">
        <div className="list-header">
          <div className="main-action">
            <Button theme="info" onClick={() => setHwKeyProvisioning(true)}>
              {__('Provision the key certificate')}
            </Button>
            <div className="read-instruction">Read the instructions here.</div>
          </div>
        </div>
        <div className="HardwareKeys-list">
          <List
            columns={[
              {
                accessor: 'hardware_id',
                Header: __('Hardware ID'),
                Cell: cellInfo => displayValue(cellInfo.value)
              },
              {
                accessor: 'not_valid_after',
                Header: __('Expiration Date'),
                Cell: cellInfo => formatDate(cellInfo.value)
              },
              {
                accessor: 'is_revoked',
                Header: __('Revoked'),
                headerClassName: 'text-center',
                className: 'text-center',
                Cell: cellInfo =>
                  cellInfo.value ? (
                    <IconCheckmark color="#10ac84" height="14px" />
                  ) : (
                    <IconDisabled color="#aaa" height="14px" />
                  )
              },
              {
                accessor: 'is_active',
                Header: __('Status'),
                headerClassName: 'text-center',
                className: 'text-center',
                Cell: cellInfo => (
                  <StatusCard
                    status={cellInfo.value ? 'success' : 'error'}
                    text={
                      cellInfo.value ? `${__('Active')}` : `${__('Inactive')}`
                    }
                  />
                )
              },
              {
                accessor: 'device',
                Header: __('License'),
                headerClassName: 'text-center',
                className: 'text-center',
                Cell: cellInfo => {
                  if (!cellInfo.value) {
                    return __('Not assigned');
                  }

                  const orderID = get(
                    cellInfo,
                    'original.device.license.order'
                  );
                  const licenseID = get(cellInfo, 'original.device.license.id');
                  const url = `/${companyID}/orders/${orderID}/${licenseID}`;

                  return (
                    <NavLink to={url} className="HardwareKeys-list-link">
                      {__('View license')}
                    </NavLink>
                  );
                }
              }
            ]}
            data={get(deviceCertificates, 'results', [])}
            loading={isLoading}
            clickable={false}
            manual
            page={page}
            pages={Math.ceil(deviceCertificates.count / 20)}
            minRows={2}
            showPagination
            onPageChange={newPage => setPage(newPage)}
            onSortedChange={newSorted => {
              setCurrentSort(newSorted);
              patchUiFeatures(newSorted);
            }}
            defaultSorted={currentSort}
          />
        </div>
      </div>
      {isHwKeyProvisioning && (
        <HardwareKeyProvision
          closeCb={() => setHwKeyProvisioning(false)}
          confirmCb={() => {
            setHwKeyProvisioning(false);
            getDeviceCertificates(0, currentSort);
          }}
          // licenseID={licenseId}
          companyID={companyID}
        />
      )}
    </Page>
  );
};

export default HardwareKeys;
