import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import {
  Button,
  ConfirmationPopup,
  IconCheckmark,
  IconDelete,
  IconDisabled,
  IconEdit,
  List,
  Notification
} from 'shared/components';
import { defaultFeaturesList } from 'shared/constants';
import { sendErrorReport } from 'shared/helpers';
import { getCompanyFeatures } from 'src/company/actions';
import { deleteFeature, reactivateCompanyFeatures } from 'src/billing/actions';
import { mapCompanyFeatures } from './helpers';
import EditCompanyFeatureForm from '../EditCompanyFeatureForm';
import AddCompanyFeatureForm from '../AddCompanyFeatureForm';
import './styles.scss';

const CompanyFeatures = ({ companyDetails, features }) => {
  const dispatch = useDispatch();
  const companyID = get(companyDetails, 'id');
  const featuresList = mapCompanyFeatures(features, defaultFeaturesList);

  const [isEditFormDisplayed, setEditFormDisplay] = useState(false);
  const [featureToEdit, setFeatureToEdit] = useState(null);
  const [deletingFeature, setDeletingFeature] = useState(false);
  const [featureToDelete, setFeatureToDelete] = useState(null);
  const [
    isFeatureDeleteConfirmationDisplayed,
    setFeatureDeleteConfirmationDisplayed
  ] = useState(false);
  const [isAddFeatureFormDisplayed, setAddFeatureFormDisplay] = useState(false);
  const [isActivatingAll, setActivatingAll] = useState(false);

  const handleFeatureDelete = feature => {
    const featureId = get(feature, 'id');
    setDeletingFeature(true);

    deleteFeature(featureId, companyID)
      .then(() => {
        dispatch(getCompanyFeatures(companyID));
        Notification('success', __('Feature removed from this company.'));
        setFeatureDeleteConfirmationDisplayed(false);
        setFeatureToDelete(null);
        setDeletingFeature(false);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete company feature');
        Notification(
          'error',
          __('Error occured'),
          __('We could not remove the feature from this company.')
        );
        setDeletingFeature(false);
      });
  };

  const handleActivateAll = () => {
    setActivatingAll(true);

    setTimeout(() => {
      setActivatingAll(false);
    }, 1000);
    reactivateCompanyFeatures(companyID)
      .then(() => {
        dispatch(getCompanyFeatures(companyID));
        Notification('success', __('Company features reactivated.'));
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot reactivate company features');
        Notification('error', __('Error occured'));
      });
  };

  return (
    <div className="CompanyFeatures">
      <div className="list-header">
        <div>
          <Button
            size="sm"
            theme="info"
            onClick={() => setAddFeatureFormDisplay(true)}
          >
            {__('Add feature')}
          </Button>
          <Button
            size="sm"
            theme="default"
            disabled={isActivatingAll}
            loading={isActivatingAll}
            onClick={handleActivateAll}
          >
            {__('Activate all features')}
          </Button>
        </div>
      </div>
      <div className="CompanyFeatures-list">
        <List
          data={featuresList}
          minRows={2}
          pageSize={20}
          columns={[
            {
              accessor: 'label',
              Header: __('Name')
            },
            {
              accessor: 'key',
              Header: __('Key')
            },
            {
              accessor: 'value',
              Header: __('Value'),
              Cell: cellData => {
                if (cellData.value === -1) {
                  return __('Unlimited');
                }
                if (cellData.value === null || !cellData.value) {
                  return '-';
                }
                return cellData.value;
              },
              width: 150
            },
            {
              accessor: 'is_active',
              className: 'text-center',
              Header: __('Active'),
              headerClassName: 'text-center',
              Cell: cellData =>
                cellData.value ? (
                  <IconCheckmark color="#10ac84" height="14px" />
                ) : (
                  <IconDisabled color="red" height="14px" />
                ),
              width: 100
            },
            {
              accessor: 'plans',
              Header: __('Default plan types'),
              Cell: cellData => cellData.value.join(', ')
            },
            {
              Cell: rowData => (
                <Button
                  className="edit-button"
                  onClick={() => {
                    setFeatureToEdit(rowData.original);
                    setEditFormDisplay(true);
                  }}
                  type="button"
                >
                  <IconEdit height="16px" width="16px" />
                </Button>
              ),
              maxWidth: 50
            },
            {
              Cell: rowData => (
                <Button
                  className="edit-button"
                  onClick={() => {
                    setFeatureToDelete(rowData.original);
                    setFeatureDeleteConfirmationDisplayed(true);
                  }}
                  type="button"
                >
                  <IconDelete height="16px" width="16px" color="#ee5253" />
                </Button>
              ),
              maxWidth: 50
            }
          ]}
          defaultSorted={[
            {
              desc: true,
              id: 'key'
            }
          ]}
        />
      </div>
      {isAddFeatureFormDisplayed && (
        <AddCompanyFeatureForm
          features={features}
          closeCb={() => setAddFeatureFormDisplay(false)}
          companyId={get(companyDetails, 'id')}
        />
      )}
      {isEditFormDisplayed && (
        <EditCompanyFeatureForm
          feature={featureToEdit}
          closeCb={() => setEditFormDisplay(false)}
          companyId={get(companyDetails, 'id')}
        />
      )}
      {isFeatureDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setFeatureToDelete(null);
            setFeatureDeleteConfirmationDisplayed(false);
          }}
          confirmCb={() => handleFeatureDelete(featureToDelete)}
          title={`${__(
            'Are you sure you want to delete this feature from the company'
          )}?`}
          confirmText={__('Delete')}
          theme="error"
          disabled={deletingFeature}
        >
          <strong>{get(featureToDelete, 'label')}</strong>
        </ConfirmationPopup>
      )}
    </div>
  );
};

CompanyFeatures.propTypes = {
  companyDetails: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired
};

export default CompanyFeatures;
