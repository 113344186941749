import React from 'react';
import PropTypes from 'prop-types';
import {
  defaultDisabledFeatureMessage,
  platformFeatures,
  productFeatureTypes
} from 'shared/constants';
import { isFeatureAvailable, isFeatureEnabled } from 'shared/helpers';
import { Selector } from 'shared/components';
import ProductFeatureNotAvailableMessage from './ProductFeatureNotAvailableMessage';

const ProductFeaturesTypeSelector = ({
  availableFeatures,
  handleFeatureChange,
  selectedFeature
}) => {
  const featureTypeOptions = {
    activation: {
      label: __('Activation'),
      value: productFeatureTypes.activation
    },
    consumption: {
      label: __('Consumption'),
      value: productFeatureTypes.consumption
    }
  };

  const getMessageType = featureType => {
    if (featureType === 'consumption') {
      if (availableFeatures.consumption_max === __('Not available')) {
        return platformFeatures.extra_feature_licensing_consumption;
      }
      if (availableFeatures.consumption_limit_reached) {
        return 'limit_reached';
      }
    }
    if (featureType === 'activation') {
      if (availableFeatures.activation_max === __('Not available')) {
        return 'not_available';
      }
      if (availableFeatures.activation_limit_reached) {
        return 'limit_reached';
      }
    }

    return null;
  };

  return (
    <Selector
      options={[
        {
          label: __(featureTypeOptions.activation.label),
          value: featureTypeOptions.activation.value,
          isOptionAvailable: isFeatureAvailable(
            platformFeatures.extra_feature_licensing_activation,
            availableFeatures.activation_used
          ),
          isOptionEnabled: isFeatureEnabled(
            platformFeatures.extra_feature_licensing_activation
          ),
          isDisabled: !(
            isFeatureAvailable(
              platformFeatures.extra_feature_licensing_activation,
              availableFeatures.activation_used
            ) &&
            isFeatureEnabled(
              platformFeatures.extra_feature_licensing_activation
            )
          ),
          disabledMessage: !isFeatureAvailable(
            platformFeatures.extra_feature_licensing_activation,
            availableFeatures.activation_used
          ) ? (
            <ProductFeatureNotAvailableMessage
              type={getMessageType('activation')}
            />
          ) : (
            __(defaultDisabledFeatureMessage)
          )
        },
        {
          label: __(featureTypeOptions.consumption.label),
          value: featureTypeOptions.consumption.value,
          isOptionAvailable: isFeatureAvailable(
            platformFeatures.extra_feature_licensing_consumption,
            availableFeatures.consumption_used
          ),
          isOptionEnabled: isFeatureEnabled(
            platformFeatures.extra_feature_licensing_consumption
          ),
          isDisabled: !(
            isFeatureAvailable(
              platformFeatures.extra_feature_licensing_consumption,
              availableFeatures.consumption_used
            ) &&
            isFeatureEnabled(
              platformFeatures.extra_feature_licensing_consumption
            )
          ),
          disabledMessage: !isFeatureAvailable(
            platformFeatures.extra_feature_licensing_consumption,
            availableFeatures.consumption_used
          ) ? (
            <ProductFeatureNotAvailableMessage
              type={getMessageType('consumption')}
            />
          ) : (
            __(defaultDisabledFeatureMessage)
          )
        }
      ]}
      value={selectedFeature}
      handleChange={handleFeatureChange}
    />
  );
};

ProductFeaturesTypeSelector.propTypes = {
  availableFeatures: PropTypes.object.isRequired,
  handleFeatureChange: PropTypes.func.isRequired,
  selectedFeature: PropTypes.string
};

ProductFeaturesTypeSelector.defaultProps = {
  selectedFeature: null
};

export default ProductFeaturesTypeSelector;
