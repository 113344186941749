import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  sendErrorReport,
  capitalizeFirstLetter,
  displayValue,
  mapCustomerLabelsToSelector,
  isFeatureEnabled,
  isFeatureAvailable,
  getDisabledMessage,
  checkUserPermission
} from 'shared/helpers';
import {
  searchType,
  initialCustomersListSort,
  initialCustomersListSize,
  initialCustomersListFilter,
  platformFeatures,
  userPermissions
} from 'shared/constants';
import {
  Button,
  CheckboxSelector,
  IconSearch,
  IconFilter,
  IconExport,
  Label,
  List,
  Notification,
  Page,
  PermissionMissingNotificationTitle,
  Selector,
  SimpleFilterTag,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  TextInput
} from 'shared/components';
import { updateUserPreferences } from 'src/company/actions';
import { fetchCustomers, updateUIOptions } from 'src/customer/actions';
import CustomerLabels from '../components/CustomerLabels';
import CustomersImport from '../components/CustomersImport';
import CustomerForm from '../components/CustomerForm';
import ExportCustomersForm from '../components/ExportCustomersForm';
import { transformSort } from './helpers';
import './styles.scss';

const CustomersList = () => {
  const canManageCustomers = checkUserPermission(
    userPermissions.customers_write
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const uiOptions = useSelector(state =>
    get(state, 'company.userPreferences.ui_options')
  );
  const userPrefsID = useSelector(state =>
    get(state, 'company.userPreferences.id')
  );
  const companyCustomerLabels = useSelector(state =>
    get(state, 'company.customerLabels')
  );

  const [isLoading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [exportModalDisplayed, setExportModalDisplay] = useState(false);
  // table state
  const [customersCount, setCustomersCount] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    get(uiOptions, 'list_size.customers') || initialCustomersListSize
  );
  const [currentSort, setSort] = useState(
    get(uiOptions, 'list_sort.customers') || initialCustomersListSort
  );
  // search state
  const [query, setQuery] = useState('');
  const [activeQuery, setActiveQuery] = useState('');
  const [searchBy, setSearchBy] = useState(get(searchType, 'all'));
  // filters
  const [showFilters, setFiltersDisplay] = useState(false);
  const [
    selectedCustomerLabelFilters,
    setSelectedCustomerLabelFilters
  ] = useState(
    get(uiOptions, 'list_filters.customers.clabels') ||
      initialCustomersListFilter.clabels
  );
  // forms state
  const [isFormDisplayed, setFormDisplay] = useState(false);

  const getCustomers = useCallback(
    (
      tablePage = 0,
      activeQ = undefined,
      searchTypeSelect = undefined,
      sort = currentSort,
      rows = rowsPerPage,
      filters = selectedCustomerLabelFilters
    ) => {
      setLoading(true);
      fetchCustomers(
        companyID,
        tablePage,
        activeQ,
        searchTypeSelect,
        transformSort(sort),
        rows,
        filters
      )
        .then(res => {
          setCustomers(get(res, 'data.results') || []);
          setCustomersCount(get(res, 'data.count'));
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          sendErrorReport(err, 'Cannot fetch customers list');
        });
    },
    [companyID]
  );

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  const patchUiFeatures = (
    newSorted = undefined,
    newPageSize = undefined,
    newFilters = undefined
  ) => {
    const sortObject = newSorted || currentSort;
    const listPageSize = newPageSize || rowsPerPage;
    const clabelsFilters =
      newFilters || get(uiOptions, 'list_filters.customers') || {};

    if ((!newSorted && !newPageSize && !newFilters) || !userPrefsID) {
      return false;
    }

    const listSort = get(uiOptions, 'list_sort') || {};
    const listSize = get(uiOptions, 'list_size') || {};
    const listFilter = get(uiOptions, 'list_filters') || {};

    listSort.customers = sortObject;
    listSize.customers = listPageSize;
    listFilter.customers = clabelsFilters;

    const ui = {
      ...uiOptions,
      list_sort: listSort,
      list_size: listSize,
      list_filters: listFilter
    };
    const data = { ui_options: JSON.stringify(ui) };

    updateUIOptions(userPrefsID, companyID, data)
      .then(res => {
        dispatch(updateUserPreferences(res.data));
      })
      .catch(err => sendErrorReport(err, 'Ui options update failed', data));
    return true;
  };

  const handleSearchSubmit = e => {
    e.preventDefault();
    setActiveQuery(query);
    setPage(0);
    getCustomers(
      0,
      query,
      searchBy,
      currentSort,
      rowsPerPage,
      selectedCustomerLabelFilters
    );
  };

  const handleSearchClear = () => {
    setQuery('');
    setActiveQuery('');
    setPage(0);
    getCustomers(
      0,
      undefined,
      undefined,
      currentSort,
      rowsPerPage,
      selectedCustomerLabelFilters
    );
  };

  const handlePageChange = newPage => {
    setPage(newPage);
    getCustomers(
      newPage,
      activeQuery,
      searchBy,
      currentSort,
      rowsPerPage,
      selectedCustomerLabelFilters
    );
  };

  const handleSortChange = newSorted => {
    setSort(newSorted);
    getCustomers(
      0,
      activeQuery,
      searchBy,
      newSorted,
      rowsPerPage,
      selectedCustomerLabelFilters
    );
    patchUiFeatures(newSorted);
  };

  const handlePageSizeChange = (newPageSize, newPage) => {
    setPage(newPage);
    setRowsPerPage(newPageSize);
    getCustomers(
      newPage,
      activeQuery,
      searchBy,
      currentSort,
      newPageSize,
      selectedCustomerLabelFilters
    );
    patchUiFeatures(undefined, newPageSize);
  };

  const redirectToCustomerPage = rowData => {
    const customerId = get(rowData, 'original.id');
    history.push(`/${companyID}/customers/${customerId}`);
  };

  const handleClabelsSelect = (newClabelFilters = undefined) => {
    if (!selectedCustomerLabelFilters || !selectedCustomerLabelFilters.length) {
      return false;
    }
    getCustomers(
      0,
      activeQuery,
      searchBy,
      currentSort,
      rowsPerPage,
      newClabelFilters || selectedCustomerLabelFilters
    );
    patchUiFeatures(undefined, undefined, {
      clabels: newClabelFilters || selectedCustomerLabelFilters
    });
    return true;
  };

  const handleClearFilters = () => {
    if (!selectedCustomerLabelFilters || !selectedCustomerLabelFilters.length) {
      return false;
    }
    setSelectedCustomerLabelFilters([]);
    getCustomers(0, activeQuery, searchBy, currentSort, rowsPerPage, []);
    patchUiFeatures(undefined, undefined, { clabels: [] });
    return true;
  };

  const handleManageCustomersClick = cb => {
    if (!canManageCustomers) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle
          permission={userPermissions.customers_write}
        />,
        __('Contact you account admin for support.')
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <Page title={__('All Customers')}>
      <div className="CustomersList">
        <Tabs>
          <TabsHeader>
            <Tab>{__('Customers')}</Tab>
            <Tab>{__('Customer labels')}</Tab>
            <Tab>{__('Customers imports')}</Tab>
          </TabsHeader>
          <TabContent>
            <div className="CustomersList-public">
              <div className="list-header">
                <div>
                  <div className="search-selector">
                    <Selector
                      handleChange={val => setSearchBy(val)}
                      options={[
                        { label: __('Search by all'), value: searchType.all },
                        {
                          label: __('Search by email'),
                          value: searchType.email
                        },
                        {
                          label: __('Search by account name'),
                          value: searchType.accountName
                        },
                        {
                          label: __('Search by first name'),
                          value: searchType.firstName
                        },
                        {
                          label: __('Search by last name'),
                          value: searchType.lastName
                        },
                        {
                          label: __('Search by phone number'),
                          value: searchType.phoneNumber
                        },
                        {
                          label: __('Search by company name'),
                          value: searchType.companyName
                        },
                        {
                          label: __('Search by reference'),
                          value: searchType.reference
                        }
                      ]}
                      value={searchBy}
                    />
                  </div>
                  <form onSubmit={handleSearchSubmit}>
                    <TextInput
                      handleChange={val => setQuery(val)}
                      value={query}
                    />
                    {activeQuery && (
                      <button
                        type="button"
                        onClick={handleSearchClear}
                        disabled={isLoading}
                      >
                        &times;
                      </button>
                    )}
                    <Button type="submit" theme="info" disabled={isLoading}>
                      <IconSearch fill="#fff" />
                    </Button>
                  </form>
                </div>
                <div>
                  <Button
                    featureEnabled={isFeatureEnabled(
                      platformFeatures.reporting_data_export
                    )}
                    featureAvailable={isFeatureAvailable(
                      platformFeatures.reporting_data_export
                    )}
                    notAvailableMessage={__(
                      'Data export is not available in your plan type.'
                    )}
                    ctaText={__('Upgrade to download your data exports.')}
                    notEnabledMessage={getDisabledMessage()}
                    style={{ padding: '0', width: '50px' }}
                    theme="default"
                    title={__('Export licenses')}
                    onClick={() => setExportModalDisplay(true)}
                  >
                    <IconExport height="14px" color="#555" />
                  </Button>
                  <Button
                    theme="info"
                    onClick={() => handleManageCustomersClick(setFormDisplay)}
                  >
                    {__('Add customer')}
                  </Button>
                </div>
              </div>
              <div className="CustomersList-filters">
                <button
                  type="button"
                  className="AdvancedSearch-btn"
                  onClick={() => setFiltersDisplay(!showFilters)}
                >
                  <IconFilter
                    stroke="#2e86de"
                    strokeWidth={2}
                    fill="none"
                    viewBox="0 0 24 24"
                  />
                  {__('Search filters')}
                </button>
                <div
                  className={`AdvancedSearch-wrapper ${
                    showFilters ? '' : 'hide'
                  }`}
                >
                  <div
                    className={`AdvancedSearch ${showFilters ? '' : 'hide'}`}
                  >
                    <div className="AdvancedSearch-fields">
                      <div className="filter-section">
                        <Label
                          inputId="customer-labels-filter"
                          text={__('Customer labels')}
                        />
                        <CheckboxSelector
                          text={__('Selected labels')}
                          options={mapCustomerLabelsToSelector(
                            companyCustomerLabels
                          )}
                          value={selectedCustomerLabelFilters}
                          onChangeCallback={data =>
                            setSelectedCustomerLabelFilters(data)
                          }
                          onMenuClose={handleClabelsSelect}
                        />
                      </div>
                      <div className="AdvancedSearch-actions">
                        <Button
                          theme="error"
                          size="sm"
                          disabled={isLoading}
                          onClick={handleClearFilters}
                        >
                          {__('Clear filters')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Filters-list">
                  {selectedCustomerLabelFilters.map(slt => (
                    <SimpleFilterTag
                      key={slt.value}
                      value={slt.value}
                      label={capitalizeFirstLetter(slt.label)}
                      customFilterSetter={() => {
                        const newCustomerLabelsFilter = selectedCustomerLabelFilters.filter(
                          s => s.value !== slt.value
                        );
                        setSelectedCustomerLabelFilters(
                          newCustomerLabelsFilter
                        );
                        handleClabelsSelect(newCustomerLabelsFilter);
                      }}
                    />
                  ))}
                </div>
              </div>
              <List
                clickable
                columns={[
                  {
                    accessor: 'email',
                    Header: __('Email'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                    maxWidth: 300
                  },
                  {
                    accessor: 'first_name',
                    Header: __('First Name'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                    maxWidth: 200
                  },
                  {
                    accessor: 'last_name',
                    Header: __('Last Name'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                    maxWidth: 300
                  },
                  {
                    accessor: 'customer_account.name',
                    Header: __('Account'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                    maxWidth: 200
                  },
                  {
                    accessor: 'phone',
                    Header: __('Phone'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                    maxWidth: 150
                  },
                  {
                    accessor: 'company_name',
                    Header: __('Company Name'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                    maxWidth: 200
                  },
                  {
                    accessor: 'reference',
                    Header: __('Reference'),
                    Cell: cellInfo => displayValue(cellInfo.value)
                  },
                  {
                    accessor: 'labels',
                    Header: __('Labels'),
                    className: 'customer-label',
                    Cell: cellInfo => {
                      const labels = get(cellInfo, 'value') || [];
                      const labelTitles = labels
                        .map(l => l.label)
                        .sort()
                        .join(', ');
                      return (
                        <div className="customerLabels">
                          <span className="label-titles">{labelTitles}</span>
                          <div className="label-colors-wrapper">
                            {labels.map(l => (
                              <div
                                className="label-color"
                                key={l.id}
                                style={{
                                  backgroundColor: l.color || '#949494'
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      );
                    }
                  }
                ]}
                data={customers}
                defaultSorted={currentSort}
                loading={isLoading}
                manual
                minRows={Math.ceil(get(customers, 'length')) || 10}
                page={page}
                pages={Math.ceil(customersCount / rowsPerPage)}
                onPageChange={handlePageChange}
                onSortedChange={handleSortChange}
                showPagination
                handleClick={rowData => redirectToCustomerPage(rowData)}
                showPageSizeOptions
                pageSize={rowsPerPage}
                onPageSizeChange={(pageSize, pageIndex) =>
                  handlePageSizeChange(pageSize, pageIndex)
                }
              />
            </div>
          </TabContent>
          <TabContent>
            <CustomerLabels
              refetchCustomers={() =>
                getCustomers(
                  page,
                  activeQuery,
                  searchBy,
                  currentSort,
                  rowsPerPage,
                  selectedCustomerLabelFilters
                )
              }
            />
          </TabContent>
          <TabContent>
            <CustomersImport companyID={companyID} />
          </TabContent>
        </Tabs>
        {isFormDisplayed && (
          <CustomerForm
            company={companyID}
            customer={null}
            closeForm={() => {
              setFormDisplay(false);
            }}
            updateCustomer={customerID => {
              setFormDisplay(false);
              history.push(`/${companyID}/customers/${customerID}`);
            }}
          />
        )}
        {exportModalDisplayed && (
          <ExportCustomersForm closeCb={() => setExportModalDisplay(false)} />
        )}
      </div>
    </Page>
  );
};

export default CustomersList;
