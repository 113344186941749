import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { sendErrorReport } from 'shared/helpers';
import {
  ContentLoader,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
  Selector
} from 'shared/components';
import {
  fetchMgmgtApiKeys,
  initSalesforceIntegration
} from 'src/account/actions';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue
} from 'shared/validation';
import { addIntegration, updateIntegration } from 'src/company/actions';
import './styles.scss';

const SalesforceInit = ({ closeCb, salesforceIntegration }) => {
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const companyID = get(companyDetails, 'id');
  const companyCode = get(companyDetails, 'code');

  const [isLoading, setLoading] = useState(false);
  const [isMgmtKeysLoading, setMgmtKeysLoading] = useState(true);
  const [mgmtKeys, setMgmtKeys] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);
  const [clientID, setClientID] = useState('');
  const [clientIDError, setClientIDError] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [clientSecretError, setClientSecretError] = useState('');
  const [salesforceDomain, setSalesforceDomain] = useState('');
  const [salesforceDomainError, setSalesforceDomainError] = useState('');
  const [selectedKey, setSelectedKey] = useState(null);

  const validateValue = async (val, cb) => {
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate salesforce integration value', {
        value: val
      });
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const getMgmtKeys = useCallback(() => {
    fetchMgmgtApiKeys(companyID)
      .then(res => {
        const data = get(res, 'data') || [];
        const keysList = data
          .filter(k => !k.read_only && !k.revoked)
          .filter(k => k.management_api_key);
        setMgmtKeys(keysList);
        setMgmtKeysLoading(false);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot get mgmt api keys');
        setMgmtKeysLoading(false);
      });
  }, [companyID]);

  useEffect(() => {
    getMgmtKeys();
  }, [getMgmtKeys]);

  const isFormValid = async () => {
    const isIDValid = await validateValue(clientID, setClientIDError);
    const isSecretValid = await validateValue(
      clientSecret,
      setClientSecretError
    );
    const isDomainValid = await validateValue(
      salesforceDomain,
      setSalesforceDomainError
    );

    return isIDValid && isSecretValid && isDomainValid;
  };

  const createIntegration = data => {
    const baseUrl = window.appConfig.integrationServiceBaseUrl;
    initSalesforceIntegration(baseUrl, data)
      .then(res => {
        const redirectLink = get(res, 'data.link');
        window.location.replace(redirectLink);
      })
      .catch(err => {
        sendErrorReport(err);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
        setLoading(false);
      });
  };

  const editIntegrationInLS = (integrationData, data) => {
    const integrationID = get(salesforceIntegration, 'id');
    const patchData = {
      ...integrationData,
      is_active: true,
      data
    };
    updateIntegration(integrationID, companyID, patchData)
      .then(() => {
        createIntegration(data);
      })
      .catch(err => {
        sendErrorReport(err);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
        setLoading(false);
      });
  };

  const saveIntegrationInLS = (integrationData, data) => {
    addIntegration(companyID, integrationData)
      .then(() => {
        createIntegration(data);
      })
      .catch(err => {
        sendErrorReport(err);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
        setLoading(false);
      });
  };

  const handleSubmit = async () => {
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    if (!selectedKey) {
      Notification('error', __('Management API key is required'));
      return false;
    }

    setLoading(true);

    const data = {
      salesForceClientID: clientID,
      salesForceClientSecret: clientSecret,
      salesForceBaseUrl: salesforceDomain,
      licenseSpringApiKey: selectedKey,
      licensespringCompanyCode: companyCode
    };

    const integrationData = {
      code: 'salesforce',
      is_active: false,
      is_verified: false,
      data: {}
    };

    if (!salesforceIntegration) {
      saveIntegrationInLS(integrationData, data);
    } else {
      editIntegrationInLS(integrationData, data);
    }
    return true;
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return setDirtyFormDisplay(true);
  };

  if (isMgmtKeysLoading) {
    return (
      <Modal
        closeCb={handleClose}
        confirmCb={() => {}}
        disabled={isMgmtKeysLoading}
        title={__('Activate Salesforce integration')}
      >
        <div className="SalesforceInit">
          <ContentLoader text="" />
        </div>
      </Modal>
    );
  }

  const mgtmApiKeyOptions = mgmtKeys.map(k => ({
    value: k.management_api_key,
    label: k.management_api_key
  }));

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title={__('Activate Salesforce integration')}
    >
      <div className="SalesforceInit">
        <div>
          <Label text={__('Consumer Key')} inputId="client-id" />
          <TextInput
            id="client-id"
            value={clientID}
            error={clientIDError}
            handleChange={val => {
              setDirty(true);
              setClientID(val);
              debouncedValidateRequiredValue(val).then(err =>
                setClientIDError(err)
              );
            }}
            disabled={isLoading}
          />
        </div>
        <div>
          <Label text={__('Consumer Secret')} inputId="client-secret" />
          <TextInput
            id="client-secret"
            value={clientSecret}
            error={clientSecretError}
            handleChange={val => {
              setDirty(true);
              setClientSecret(val);
              debouncedValidateRequiredValue(val).then(err =>
                setClientSecretError(err)
              );
            }}
            disabled={isLoading}
          />
        </div>
        <div>
          <Label text={__('Salesforce Domain')} inputId="salesforce-domain" />
          <TextInput
            id="salesforce-domain"
            value={salesforceDomain}
            error={salesforceDomainError}
            handleChange={val => {
              setDirty(true);
              setSalesforceDomain(val);
              debouncedValidateRequiredValue(val).then(err =>
                setSalesforceDomainError(err)
              );
            }}
            disabled={isLoading}
          />
        </div>
        <div>
          <Label
            inputId="mgmt-api-key-select"
            text={__('Management API key')}
            description={__('Key has to be active and not read_only')}
          />
          <Selector
            options={mgtmApiKeyOptions}
            value={selectedKey}
            handleChange={val => {
              setSelectedKey(val);
            }}
            blurInputOnSelect
            isDisabled={isLoading}
          />
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

SalesforceInit.propTypes = {
  closeCb: PropTypes.func.isRequired,
  salesforceIntegration: PropTypes.object
};

SalesforceInit.defaultProps = {
  salesforceIntegration: null
};

export default SalesforceInit;
