import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  DirtyFormAlert,
  AsyncSelector,
  Selector,
  InputErrorMessage,
  Label,
  Notification,
  RadioBtn,
  TextInput,
  PhoneNumberInput,
  TextArea,
  Modal
} from 'shared/components';
import { sendErrorReport, parseMetadata } from 'shared/helpers';
// import { errorMsg } from 'shared/constants';
import api from 'shared/api';
import {
  validateRequiredValue,
  validateCustomerEmail,
  debouncedValidateCustomerEmail,
  validateJSON,
  debouncedValidateJSON
} from 'shared/validation';
import { createCustomer } from 'src/customer/actions';
import { updateOrder } from 'src/order/actions';
import './styles.scss';

// eslint-disable-next-line no-unused-vars
const ChangeCustomerOrderForm = ({ closeCb, confirmCb, orderID }) => {
  // todo_metadata replace this check with company feature
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const companyID = get(companyDetails, 'id');
  const isEnterprisePlan = get(companyDetails, 'plan_type') === 'enterprise';

  const [isLoading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setIsDirtyFormAlertDisplayed] = useState(
    false
  );
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailLoading, setEmailLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState('');
  const [stateProvince, setStateProvince] = useState('');
  const [stateProvinceError, setStateProvinceError] = useState('');
  const [country, setCountry] = useState('');
  const [countryError, setCountryError] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [organization, setOrganization] = useState('');
  const [organizationError, setOrganizationError] = useState('');
  const [reference, setReference] = useState(7);
  const [referenceError, setReferenceError] = useState('');
  const [metadata, setMetadata] = useState(parseMetadata(''));
  const [metadataError, setMetadataError] = useState('');
  const [selectedCustomerType, setSelectedCustomerType] = useState(
    'customer-existing'
  );
  const [existingCustomer, setExistingCustomer] = useState(null);
  const [existingCustomerError, setExistingCustomerError] = useState('');

  const [searchBy, setSearchBy] = useState('email__icontains');

  const handleEmailValidation = async val => {
    setEmailLoading(true);
    let errors;

    try {
      errors = await validateCustomerEmail(val, companyID);
      setEmailLoading(false);
      setEmailError(errors);
    } catch (err) {
      setEmailLoading(false);
      sendErrorReport(err, 'Cannot validate customer email', { value: val });
    }

    if (errors) {
      return false;
    }
    return true;
  };

  // eslint-disable-next-line no-unused-vars
  const validateValue = async (val, cb) => {
    setLoading(true);
    let errors;

    try {
      errors = await validateRequiredValue(val);
      setLoading(false);
      cb(errors);
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate customer field', { value: val });
    }

    if (errors) {
      return false;
    }

    return true;
  };

  const validateMetadata = async () => {
    setLoading(true);
    let errors;
    try {
      errors = await validateJSON(metadata);
      setMetadataError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit customer form value', {
        value: metadata
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const resetCustomerState = () => {
    // clean customer inner state
    setExistingCustomer(null);
    setExistingCustomerError('');
    setEmail('');
    setEmailLoading(false);
    setEmailError('');
    setFirstName('');
    setFirstNameError('');
    setLastName('');
    setLastNameError('');
    setLastName('');
    setLastNameError('');
    setAddress('');
    setAddressError('');
    setCity('');
    setCityError('');
    setStateProvince('');
    setStateProvinceError('');
    setCountry('');
    setCountryError('');
    setZipcode('');
    setZipcodeError('');
    setPhone('');
    setPhoneError('');
    setOrganization('');
    setOrganizationError('');
    setReference('');
    setReferenceError('');
    setMetadata('');
    setMetadataError('');
  };

  const handleExistingCustomerChange = val => {
    setDirty(true);
    setExistingCustomer(val);
    setExistingCustomerError('');
  };

  const handleCustomerTypeChange = val => {
    setDirty(true);
    setSelectedCustomerType(val);
    resetCustomerState();
  };

  const handleEmailChange = val => {
    setDirty(true);
    setEmail(val);
    debouncedValidateCustomerEmail(val);
  };

  const validateFormData = async () => {
    const emailValid = existingCustomer
      ? true
      : await handleEmailValidation(email);
    const metadataValid = existingCustomer ? true : await validateMetadata();

    return !!emailValid && !!metadataValid;
  };

  const isFormValid = async () => {
    const isFormDataValid = await validateFormData();
    return isFormDataValid;
  };

  const changeOrderCustomer = async () => {
    const existingCustomerID = get(existingCustomer, 'id');
    const data = { customer: existingCustomerID };

    try {
      await updateOrder(orderID, companyID, data);
      confirmCb();
    } catch (error) {
      sendErrorReport(error, 'Cannot add new order customer', data);
      setLoading(false);
      Notification(
        'error',
        __('Your changes were not saved'),
        __('There was an error while saving your changes')
      );
    }
  };

  const createAndAddCustomerToOrder = async () => {
    const data = {
      email,
      first_name: firstName,
      last_name: lastName,
      address,
      city,
      state: stateProvince,
      country,
      postcode: zipcode,
      phone,
      company_name: organization,
      reference,
      metadata: metadata ? JSON.parse(metadata) : {},
      company: companyID
    };

    try {
      const newCustomer = await createCustomer(companyID, data);
      const newCustomerID = get(newCustomer, 'data.id');

      await updateOrder(orderID, companyID, { customer: newCustomerID });
      confirmCb();
    } catch (error) {
      sendErrorReport(error, 'Cannot add new order customer', data);
      setLoading(false);
      Notification(
        'error',
        __('Your changes were not saved'),
        __('There was an error while saving your changes')
      );
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (isLoading) {
      return false;
    }

    const isValid = await isFormValid();
    if (!isValid) {
      Notification(
        'error',
        __('Please enter correct information and try again')
      );
      return false;
    }

    setLoading(true);

    if (existingCustomer) {
      await changeOrderCustomer();
      return true;
    }

    await createAndAddCustomerToOrder();
    return true;
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return setIsDirtyFormAlertDisplayed(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading || email.loading}
      title={__('Add customer')}
      size="lg"
    >
      <div className="ChangeCustomerOrderForm">
        <form
          className="ChangeCustomerOrderForm-form"
          id="changeCustomerOrderForm"
          onSubmit={handleSubmit}
        >
          <div className="customer-details">
            <div className="form-row buttons-container">
              <div className="form-column radio-btns">
                <RadioBtn
                  name="customer-select"
                  inputId="customer-existing"
                  label={__('Existing customer')}
                  value="customer-existing"
                  checked={selectedCustomerType === 'customer-existing'}
                  handleChange={val => handleCustomerTypeChange(val)}
                />
                <RadioBtn
                  name="customer-select"
                  inputId="customer-new"
                  label={__('New customer')}
                  value="customer-new"
                  checked={selectedCustomerType === 'customer-new'}
                  handleChange={val => handleCustomerTypeChange(val)}
                />
              </div>
            </div>
            <div className="customer-details-input-fields">
              {selectedCustomerType === 'customer-existing' && (
                <div className="existing-customer-selector">
                  <div className="existing-customer">
                    <div className="search-selector">
                      <Selector
                        handleChange={val => setSearchBy(val)}
                        options={[
                          {
                            label: __('Search by email'),
                            value: 'email__icontains'
                          },
                          { label: __('Search by name'), value: 'name' }
                        ]}
                        value={searchBy}
                      />
                    </div>
                    <div className="customer-selector">
                      <AsyncSelector
                        isClearable
                        valueKey="id"
                        labelKey="email"
                        getOptionLabel={o => {
                          if (searchBy === 'name') {
                            let label;
                            if (o.first_name && o.last_name) {
                              label = `${o.first_name} ${o.last_name}`;
                            } else if (o.email) {
                              label = o.email;
                            } else if (o.company_name) {
                              label = o.company_name;
                            } else if (o.reference) {
                              label = o.reference;
                            } else {
                              label = o.id;
                            }
                            return label;
                          }

                          let label;
                          if (o.email) {
                            label = o.email;
                          } else if (o.first_name && o.last_name) {
                            label = `${o.first_name} ${o.last_name}`;
                          } else if (o.company_name) {
                            label = o.company_name;
                          } else if (o.reference) {
                            label = o.reference;
                          } else {
                            label = o.id;
                          }
                          return label;
                        }}
                        id="customer-existing-select"
                        placeholder={__('Select existing customer')}
                        fetchOptions={val =>
                          api.get(
                            `/api/v1/customers/?company=${companyID}&limit=50&order_by=email&${searchBy}=${val}`
                          )
                        }
                        fetchInitialOptions={() =>
                          api.get(
                            `/api/v1/customers/?company=${companyID}&limit=50&order_by=email`
                          )
                        }
                        handleChange={val => handleExistingCustomerChange(val)}
                        value={get(existingCustomer, 'id') || ''}
                      />
                    </div>
                  </div>
                  <InputErrorMessage text={existingCustomerError} />
                </div>
              )}
              {selectedCustomerType === 'customer-new' && (
                <div className="new-customer-form">
                  <div className="form-row">
                    <div className="form-column wide">
                      <Label
                        text={__('Email')}
                        inputId="customer-order-email"
                      />
                      <TextInput
                        id="customer-order-email"
                        handleChange={handleEmailChange}
                        value={email}
                        error={emailError}
                        loading={emailLoading}
                        type="email"
                      />
                    </div>
                    <div className="form-column wide">
                      <Label
                        text={__('Reference')}
                        inputId="customer-reference"
                      />
                      <TextInput
                        id="customer-reference"
                        handleChange={val => {
                          setDirty(true);
                          setReference(val);
                        }}
                        value={reference}
                        error={referenceError}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-column">
                      <Label
                        text={__('First name')}
                        inputId="customer-order-firstname"
                      />
                      <TextInput
                        id="customer-order-firstname"
                        handleChange={val => {
                          setDirty(true);
                          setFirstName(val);
                          setFirstNameError('');
                        }}
                        value={firstName}
                        error={firstNameError}
                      />
                    </div>
                    <div className="form-column">
                      <Label
                        text={__('Last name')}
                        inputId="customer-order-lastname"
                      />
                      <TextInput
                        id="customer-order-lastname"
                        handleChange={val => {
                          setDirty(true);
                          setLastName(val);
                          setLastNameError('');
                        }}
                        value={lastName}
                        error={lastNameError}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-column">
                      <Label
                        text={__('Address')}
                        inputId="customer-order-address"
                      />
                      <TextInput
                        id="customer-order-address"
                        handleChange={val => {
                          setDirty(true);
                          setAddress(val);
                          setAddressError('');
                        }}
                        value={address}
                        error={addressError}
                      />
                    </div>
                    <div className="form-column">
                      <Label text={__('City')} inputId="customer-order-city" />
                      <TextInput
                        id="customer-order-city"
                        handleChange={val => {
                          setDirty(true);
                          setCity(val);
                          setCityError('');
                        }}
                        value={city}
                        error={cityError}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-column">
                      <Label
                        text={__('State / Province')}
                        inputId="customer-order-state"
                      />
                      <TextInput
                        id="customer-order-state"
                        handleChange={val => {
                          setDirty(true);
                          setStateProvince(val);
                          setStateProvinceError('');
                        }}
                        value={stateProvince}
                        error={stateProvinceError}
                      />
                    </div>
                    <div className="form-column">
                      <Label
                        text={__('Country')}
                        inputId="customer-order-country"
                      />
                      <TextInput
                        id="customer-order-country"
                        handleChange={val => {
                          setDirty(true);
                          setCountry(val);
                          setCountryError('');
                        }}
                        value={country}
                        error={countryError}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-column">
                      <Label
                        text={__('Zipcode / Postcode')}
                        inputId="customer-order-zipcode"
                      />
                      <TextInput
                        id="customer-order-zipcode"
                        handleChange={val => {
                          setDirty(true);
                          setZipcode(val);
                          setZipcodeError('');
                        }}
                        value={zipcode}
                        error={zipcodeError}
                      />
                    </div>
                    <div className="form-column">
                      <Label
                        text={__('Phone number')}
                        inputId="customer-order-phone"
                      />
                      <PhoneNumberInput
                        handleChange={val => {
                          setDirty(true);
                          setPhone(val);
                          setPhoneError('');
                        }}
                        value={phone}
                        error={phoneError}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-column">
                      <Label
                        text={__('Company Name')}
                        inputId="customer-order-organization"
                      />
                      <TextInput
                        id="customer-order-organization"
                        handleChange={val => {
                          setDirty(true);
                          setOrganization(val);
                          setOrganizationError('');
                        }}
                        value={organization}
                        error={organizationError}
                      />
                    </div>
                    <div className="form-column">
                      <Label
                        inputId="metadata-input"
                        text={__('Metadata JSON')}
                      />
                      <TextArea
                        handleChange={val => {
                          setDirty(true);
                          setMetadata(val);
                          debouncedValidateJSON(val).then(err =>
                            setMetadataError(err)
                          );
                        }}
                        id="metadata-input"
                        type="metadata"
                        value={metadata}
                        error={metadataError}
                        rows="4"
                        disabled={!isEnterprisePlan}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setIsDirtyFormAlertDisplayed(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </Modal>
  );
};

ChangeCustomerOrderForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  orderID: PropTypes.number.isRequired
};

export default ChangeCustomerOrderForm;
