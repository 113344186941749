import React from 'react';
import PropTypes from 'prop-types';
import { userPermissionLabels } from 'shared/constants';
import './styles.scss';

const PermissionMissingNotificationTitle = ({ permission }) => (
  <div className="PermissionMissingNotificationTitle">
    <div className="label">{`${__('Missing permission')}:`}</div>
    <div className="value">{userPermissionLabels[permission]}</div>
  </div>
);

PermissionMissingNotificationTitle.propTypes = {
  permission: PropTypes.string.isRequired
};

export default PermissionMissingNotificationTitle;
