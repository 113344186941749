import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { IconDisabled } from 'shared/components';
import './styles.scss';

const getLabelTagBackground = color => {
  if (!color) {
    return '#949494';
  }
  return color;
};

const LabelTag = ({ label, handleLabelDelete }) => (
  <div
    className="LabelTag"
    style={{ background: getLabelTagBackground(get(label, 'color')) }}
  >
    <span>{get(label, 'label') || get(label, 'name')}</span>
    <button
      onClick={handleLabelDelete}
      type="button"
      className="label-delete-btn"
    >
      <IconDisabled height="8px" fill="#f7f7f7" />
    </button>
  </div>
);

LabelTag.propTypes = {
  label: PropTypes.object.isRequired,
  handleLabelDelete: PropTypes.func.isRequired
};

export default LabelTag;
