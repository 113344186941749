import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Modal, Label, DateRangePicker, Notification } from 'shared/components';
import {
  sendErrorReport,
  filenameFromHeadersData,
  downloadResource
} from 'shared/helpers';
import { exportCustomers } from 'src/customer/actions';
import './styles.scss';

const APIdateFormat = 'YYYY-MM-DD';
const defaultFrom = moment().subtract(30, 'days');
const defaultTo = moment();

const ExportCustomersForm = ({ closeCb }) => {
  const companyID = useSelector(state => get(state, 'company.details.id'));

  const [isLoading, setLoading] = useState(false);
  const [from, setFrom] = useState(defaultFrom);
  const [to, setTo] = useState(defaultTo);

  const exportData = () => {
    setLoading(true);
    const data = {
      company: companyID,
      from: from.format(APIdateFormat),
      to: to.format(APIdateFormat)
    };

    exportCustomers(data, companyID)
      .then(response => {
        const filename = filenameFromHeadersData(response.headers);
        downloadResource(response, filename);
        closeCb();
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot export data', data);
        Notification('error', __('Export data failed'));
        setLoading(false);
      });
  };

  const isDateRangeValid = !!from && !!to && from.isSameOrBefore(to);
  const isFormSubmittable = isDateRangeValid && !isLoading;

  return (
    <div className="ExportCustomersForm">
      <Modal
        title={__('Export customers')}
        size="lg"
        closeCb={closeCb}
        confirmCb={exportData}
        disabled={!isFormSubmittable}
      >
        <div className="form-horizontal">
          <Label text={__('Select date range for the data export')} />
          <DateRangePicker
            handleChange={dates => {
              setFrom(dates[0]);
              setTo(dates[1]);
            }}
            initialStartDate={defaultFrom}
            initialEndDate={defaultTo}
          />
        </div>
      </Modal>
    </div>
  );
};

ExportCustomersForm.propTypes = {
  closeCb: PropTypes.func.isRequired
};

export default ExportCustomersForm;
