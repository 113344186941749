import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconInvisible,
  IconVisible,
  InputErrorMessage,
  Spinner
} from 'shared/components';
import './styles.scss';

const PasswordInput = ({
  autoComplete,
  error,
  disabled,
  loading,
  handleChange,
  ...rest
}) => {
  const [passVisible, setPassVisible] = useState(false);
  return (
    <div className="PasswordInput">
      <input
        className={error ? 'has-error' : ''}
        type={passVisible ? 'text' : 'password'}
        disabled={disabled}
        onChange={e => handleChange(e.target.value, e)}
        autoComplete={autoComplete ? 'on' : 'off'}
        {...rest}
      />
      {loading && <Spinner />}
      <button type="button" onClick={() => setPassVisible(!passVisible)}>
        {!passVisible && <IconVisible color="#777" />}
        {passVisible && <IconInvisible color="#777" />}
      </button>
      <InputErrorMessage text={error} />
    </div>
  );
};

PasswordInput.propTypes = {
  autoComplete: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool
};

PasswordInput.defaultProps = {
  autoComplete: true,
  disabled: false,
  error: '',
  loading: false
};

export default PasswordInput;
