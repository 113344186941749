import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  sendErrorReport,
  mapProductsToSelector,
  isFeatureAvailable,
  isFeatureEnabled,
  getDisabledMessage
} from 'shared/helpers';
import {
  CheckboxSelector,
  DirtyFormAlert,
  InputErrorMessage,
  Label,
  Modal,
  Notification,
  Notice,
  TextInput
} from 'shared/components';
import { authMethods, platformFeatures, errorMsg } from 'shared/constants';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
  validateProductShortCode,
  debouncedValidateProductShortCode
} from 'shared/validation';
import {
  createProduct,
  setProductsInBundle,
  patchProduct
} from 'src/product/actions';
import { ProductAuthorizationMethodSelector } from '../../CreateProductForm/components/ProductInformations/components';
import './styles.scss';

const ProductBundleForm = ({ closeCb, bundle, companyID, refreshState }) => {
  const history = useHistory();
  const products = useSelector(state => get(state, 'products.list') || []);
  const nonBundleProducts = products.filter(p => !p.is_bundle);
  const mappedProducts = mapProductsToSelector(nonBundleProducts);
  const initialName = get(bundle, 'product_name') || '';
  const initialCode = get(bundle, 'short_code') || '';
  const initialAuthMethod =
    get(bundle, 'authorization_method') || authMethods.key;
  const initialProducts = mappedProducts.filter(
    p => get(p, 'data.authorization_method') === initialAuthMethod
  );

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);

  const [bundleName, setBundleName] = useState(initialName);
  const [bundleNameError, setBundleNameError] = useState('');
  const [bundleCode, setBundleCode] = useState(initialCode);
  const [bundleCodeLoading, setBundleCodeLoading] = useState(false);
  const [bundleCodeError, setBundleCodeError] = useState('');
  const [authorizationMethod, setAuthorizationMethod] = useState(
    initialAuthMethod
  );
  const [productsForSelector, setProductsForSelector] = useState(
    initialProducts
  );
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsError, setSelectedProductsError] = useState('');

  const handleProductSelect = val => {
    setDirty(true);
    setSelectedProducts(val);
    setSelectedProductsError('');
  };

  const handleAuthMethodChange = val => {
    setAuthorizationMethod(val);
    setProductsForSelector(
      mappedProducts.filter(p => get(p, 'data.authorization_method') === val)
    );
    setSelectedProducts([]);
    setSelectedProductsError('');
  };

  const validateValue = async (val, cb) => {
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate bundle form value', { value: val });
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const handleProductCodeValidation = async (val, cb) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateProductShortCode(val, companyID);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new bundle code', { value: val });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validateProducts = () => {
    if (!selectedProducts.length) {
      setSelectedProductsError(errorMsg.selectErr);
      return false;
    }
    return true;
  };

  const isFormValid = async () => {
    const isNameValid = await validateValue(bundleName, setBundleNameError);
    const isCodeValid = bundle
      ? true
      : await handleProductCodeValidation(bundleCode, setBundleCodeError);
    const areProductValid = bundle ? true : validateProducts();
    return isNameValid && isCodeValid && areProductValid;
  };

  const createBundle = async data => {
    try {
      const newBundle = await createProduct(data, companyID);
      const newBundleID = get(newBundle, 'data.id');
      await setProductsInBundle(
        newBundleID,
        { products_in_bundle: selectedProducts.map(p => get(p, 'data.id')) },
        companyID
      );
      refreshState();
      Notification(
        'success',
        __('Product bundle created succesfully'),
        `${__('Bundle name')}: ${bundleName}`
      );
      history.push(`/${companyID}/products/${newBundleID}/`);
    } catch (err) {
      setLoading(false);
      setDirty(true);
      sendErrorReport(err, 'Cannot create new bundle', data);
      Notification('error', __('Error occured'));
    }
  };

  const editBundle = async () => {
    const data = { product_name: bundleName };
    const bundleID = get(bundle, 'id');
    try {
      await patchProduct(bundleID, data, companyID);
      refreshState();
      Notification(
        'success',
        __('Product bundle updated succesfully'),
        `${__('Bundle name')}: ${bundleName}`
      );
      closeCb();
    } catch (err) {
      setLoading(false);
      setDirty(true);
      sendErrorReport(err, 'Cannot update the bundle', data);
      Notification('error', __('Error occured'));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    const data = {
      active: true,
      product_name: bundleName,
      short_code: bundleCode,
      authorization_method: authorizationMethod,
      company: companyID,
      is_bundle: true,
      upgrade_from: []
    };

    setLoading(true);
    if (!bundle) {
      createBundle(data);
    } else {
      editBundle();
    }
    return true;
  };

  const handleClose = () => {
    if (!isDirty) {
      return closeCb();
    }
    return setDirtyFormAlertDisplay(true);
  };

  const title = bundle ? __('Edit product bundle') : __('Add product bundle');

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      title={title}
      size="sm"
    >
      <div className="ProductBundleForm">
        <form className="custom-field-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <Label text={__('Bundle name')} inputId="bundle-name" />
            <TextInput
              id="bundle-name"
              value={bundleName}
              error={bundleNameError}
              handleChange={val => {
                setDirty(true);
                setBundleName(val);
                debouncedValidateRequiredValue(val).then(err =>
                  setBundleNameError(err)
                );
              }}
              disabled={isLoading}
            />
          </div>
          {!bundle && (
            <>
              <div className="form-row">
                <Label text={__('Bundle code')} inputId="bundle-code" />
                <TextInput
                  id="name"
                  value={bundleCode}
                  error={bundleCodeError}
                  loading={bundleCodeLoading}
                  disabled={!!initialCode || isLoading}
                  handleChange={val => {
                    setDirty(true);
                    setBundleCode(val);
                    setBundleCodeLoading(true);
                    debouncedValidateProductShortCode(val, companyID).then(
                      err => {
                        setBundleCodeError(err);
                        setBundleCodeLoading(false);
                      }
                    );
                  }}
                />
              </div>
              <div className="form-row">
                <Label
                  text={__('Authorization method')}
                  inputId="authorization-method"
                />
                <ProductAuthorizationMethodSelector
                  selectedMethod={authorizationMethod}
                  handleMethodChange={handleAuthMethodChange}
                  disabled={isLoading}
                />
              </div>
              <div className="form-row checkbox-selector">
                <Label text={__('Select products')} />
                <CheckboxSelector
                  featureEnabled={isFeatureEnabled(
                    platformFeatures.extra_product_bundle
                  )}
                  featureAvailable={isFeatureAvailable(
                    platformFeatures.extra_product_bundle
                  )}
                  notEnabledMessage={getDisabledMessage()}
                  text={__('Products in a bundle')}
                  options={productsForSelector}
                  value={selectedProducts}
                  onChangeCallback={handleProductSelect}
                  onMenuClose={() => {}}
                  disabled={isLoading}
                />
                <InputErrorMessage text={selectedProductsError} />
              </div>
              <div className="form-row">
                <div className="products-list">
                  <ul>
                    {selectedProducts.map(p => (
                      <li>
                        <span>{get(p, 'label')}</span>
                        <span className="code">{`(${get(
                          p,
                          'data.short_code'
                        )})`}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="form-row">
                <Notice size="sm" theme="warning">
                  {__(
                    'Code, authorization method and selected products cannot be edited after bundle is created.'
                  )}
                </Notice>
              </div>
            </>
          )}
        </form>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

ProductBundleForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  bundle: PropTypes.object,
  refreshState: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired
};

ProductBundleForm.defaultProps = {
  bundle: null
};

export default ProductBundleForm;
