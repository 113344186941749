import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Page,
  Notice,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  Notification
} from 'shared/components';
import Emails from '../Emails';
import NotificationHistory from '../NotificationHistory';
import NotificationPolicies from '../NotificationPolicies';

const NotificationsContainer = () => {
  const [disableTabsChange, setDisableTabsChange] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const queryParams = get(location, 'search');
    if (!queryParams) {
      setSelectedTab(0);
    }
    if (queryParams.indexOf('st=0') >= 0) {
      setSelectedTab(0);
    }
    if (queryParams.indexOf('st=1') >= 0) {
      setSelectedTab(1);
    }
    if (queryParams.indexOf('st=2') >= 0) {
      setSelectedTab(2);
    }
  }, [location]);

  const companyDetails = useSelector(state => get(state, 'company.details'));
  const planType = get(companyDetails, 'plan_type');

  if (planType === 'free') {
    return (
      <Page title={__('Notifications')}>
        <Notice
          size="sm"
          title={__('Notifications are not available in your plan type.')}
          theme="error"
        >
          <div>{__('Upgrade your plan to use this feature.')}</div>
        </Notice>
      </Page>
    );
  }

  const handleTabChange = tabIndex => {
    if (disableTabsChange) {
      Notification(
        'warning',
        __(
          'You have unsaved changes. Please save the changes before leaving the tab.'
        )
      );
      return;
    }
    history.push({
      search: `?st=${tabIndex}`
    });
    setSelectedTab(tabIndex);
  };

  return (
    <Page title={__('Notifications')}>
      <Tabs defaultFocus selectedIndex={selectedTab} onSelect={handleTabChange}>
        <TabsHeader>
          <Tab>{__('Notification policies')}</Tab>
          <Tab>{__('Notification history')}</Tab>
          <Tab>{__('Emails')}</Tab>
        </TabsHeader>
        <TabContent>
          <NotificationPolicies />
        </TabContent>
        <TabContent>
          <NotificationHistory />
        </TabContent>
        <TabContent>
          <Emails setDisableTabsChange={setDisableTabsChange} />
        </TabContent>
      </Tabs>
    </Page>
  );
};

export default NotificationsContainer;
