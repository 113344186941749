import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { sendErrorReport } from 'shared/helpers';
import api from 'shared/api';
import { errorMsg } from 'shared/constants';
import {
  AsyncSelector,
  DirtyFormAlert,
  Label,
  InputErrorMessage,
  Modal,
  Notification,
  TextInput,
  PhoneNumberInput,
  RadioBtn,
  TextArea
} from 'shared/components';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
  validateCustomerAccountCode,
  debouncedValidateCustomerAccountCode,
  validateEmail
} from 'shared/validation';
import { createCustomerAccount, patchCustomer } from 'src/customer/actions';
import './styles.scss';

const SelectCustomerAccount = ({
  closeForm,
  company,
  customerID,
  hasAccount,
  updateCustomer
}) => {
  const [isLoading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [name, setName] = useState({ value: '', error: '' });
  const [code, setCode] = useState({ value: '', error: '', loading: false });
  const [description, setDescription] = useState({ value: '', error: '' });
  const [email, setEmail] = useState({ value: '', error: '', loading: false });
  const [phoneNumber, setPhoneNumber] = useState({ value: '', error: '' });
  const [address, setAddress] = useState({ value: '', error: '' });
  const [selectedAccountType, setSelectedAccountType] = useState(
    'account-existing'
  );
  const [existingAccount, setExistingAccount] = useState(null);
  const [existingAccountError, setExistingAccountError] = useState('');

  const handleAccountTypeChange = val => {
    setDirty(true);
    setSelectedAccountType(val);
    // clean company inner state
    setExistingAccount(null);
    setExistingAccountError('');
    setCode({ value: '', error: '', loading: false });
    setName({ value: '', error: '' });
    setDescription({ value: '', error: '' });
    setEmail({ value: '', error: '', loading: false });
    setPhoneNumber({ value: '', error: '' });
    setAddress({ value: '', error: '' });
  };

  const handleExistingAccountChange = val => {
    setDirty(true);
    setExistingAccount(val);
    setExistingAccountError('');
  };

  const validateValue = async (val, cb) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(prev => ({ ...prev, error: errors }));
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit customer account form value', {
        value: val
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validateEmailValue = async (val, cb) => {
    // email is not required
    if (!val) {
      return true;
    }
    setLoading(true);
    let errors;
    try {
      errors = await validateEmail(val);
      cb(prev => ({ ...prev, error: errors }));
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit customer form value', {
        value: val
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validateCode = async (val, cb) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateCustomerAccountCode(val, company);
      cb(prev => ({ ...prev, error: errors }));
    } catch (err) {
      sendErrorReport(err, 'Cannot validate customer account code', {
        value: val
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validateFormData = async () => {
    const isNameValid = await validateValue(name.value, setName);
    const isCodeValid = await validateCode(code.value, setCode);
    const isEmailValid = await validateEmailValue(email.value, setEmail);

    return isNameValid && isCodeValid && isEmailValid;
  };

  const isFormValid = async () => {
    const isExistingSelected = selectedAccountType === 'account-existing';
    if (isExistingSelected) {
      if (!existingAccount) {
        setExistingAccountError(errorMsg.selectCustomer);
        return false;
      }
      return true;
    }
    const isFormDataValid = await validateFormData();
    return isFormDataValid;
  };

  const addCustomerToAccount = (newAccount = undefined) => {
    const id = newAccount || get(existingAccount, 'id');

    if (!id) {
      setLoading(false);
      Notification(
        'error',
        __('Your changes were not saved'),
        __('There was an error while saving your changes')
      );
      return false;
    }

    patchCustomer(customerID, company, { customer_account: id })
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        updateCustomer(id);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot add customer to account');
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
    return true;
  };

  const addCustomerAccount = data => {
    const dataWithCompany = { ...data, company };
    createCustomerAccount(company, dataWithCompany)
      .then(res => {
        const id = get(res, 'data.id');
        addCustomerToAccount(id);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot add customer account', data);
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const handleSubmit = async () => {
    if (!dirty) {
      closeForm();
      return false;
    }
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setLoading(true);
    const data = {
      name: get(name, 'value'),
      code: get(code, 'value'),
      description: get(description, 'value'),
      email: get(email, 'value'),
      phone: get(phoneNumber, 'value'),
      address: get(address, 'value')
    };

    const isExistingSelected =
      selectedAccountType === 'account-existing' && !!existingAccount;
    if (isExistingSelected) {
      await addCustomerToAccount();
    } else {
      await addCustomerAccount(data);
    }
    return true;
  };

  const handleClose = () => {
    if (!dirty) {
      return closeForm();
    }
    return setDirtyFormAlertDisplay(true);
  };

  const title = hasAccount
    ? __('Change customer account')
    : __('Add customer to account');

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading || email.loading}
      title={title}
      size="md"
    >
      <div className="SelectCustomerAccount">
        <form onSubmit={handleSubmit}>
          <div className="form-column radio-btns">
            <RadioBtn
              name="account-select"
              inputId="account-existing"
              label={__('Existing account')}
              value="account-existing"
              checked={selectedAccountType === 'account-existing'}
              handleChange={val => handleAccountTypeChange(val)}
            />
            <RadioBtn
              name="account-select"
              inputId="account-new"
              label={__('New account')}
              value="account-new"
              checked={selectedAccountType === 'account-new'}
              handleChange={val => handleAccountTypeChange(val)}
            />
          </div>
          {selectedAccountType === 'account-new' ? (
            <div className="form-inner">
              <div className="left">
                <div>
                  <Label text={__('Name')} inputId="customer-acc-name" />
                  <TextInput
                    id="customer-acc-firstname"
                    value={get(name, 'value')}
                    error={get(name, 'error')}
                    handleChange={val => {
                      setDirty(true);
                      setName(prev => ({ ...prev, value: val }));
                      debouncedValidateRequiredValue(val).then(err =>
                        setName(prev => ({ ...prev, error: err }))
                      );
                    }}
                  />
                </div>
                <div className="form-column right-input">
                  <Label text={__('Code')} inputId="code" />
                  <TextInput
                    id="code"
                    value={get(code, 'value')}
                    error={get(code, 'error')}
                    loading={get(code, 'loading')}
                    handleChange={val => {
                      setDirty(true);
                      setCode(prev => ({ ...prev, value: val, loading: true }));
                      debouncedValidateCustomerAccountCode(val, company).then(
                        err =>
                          setCode(prev => ({
                            ...prev,
                            error: err,
                            loading: false
                          }))
                      );
                    }}
                    placeholder={__('Eg. QQ')}
                  />
                </div>
                <div>
                  <Label text={__('Email')} inputId="customer-acc-email" />
                  <TextInput
                    id="customer-acc-email"
                    value={get(email, 'value')}
                    error={get(email, 'error')}
                    loading={get(email, 'loading')}
                    handleChange={val => {
                      setDirty(true);
                      setEmail(prev => ({
                        ...prev,
                        value: val,
                        loading: false
                      }));
                    }}
                  />
                </div>
                <div>
                  <Label
                    text={__('Phone Number')}
                    inputId="customer-acc-phonenumber"
                  />
                  <PhoneNumberInput
                    id="customer-acc-phonenumber"
                    value={get(phoneNumber, 'value')}
                    error={get(phoneNumber, 'error')}
                    handleChange={val => {
                      setDirty(true);
                      setPhoneNumber(prev => ({ ...prev, value: val }));
                    }}
                  />
                </div>
              </div>
              <div className="divider active" />
              <div className="right">
                <div>
                  <Label text={__('Address')} inputId="customer-acc-address" />
                  <TextInput
                    id="customer-acc-address"
                    value={get(address, 'value')}
                    error={get(address, 'error')}
                    handleChange={val => {
                      setDirty(true);
                      setAddress(prev => ({ ...prev, value: val }));
                    }}
                  />
                </div>
                <div>
                  <Label
                    text={__('Description')}
                    inputId="customer-acc-description"
                  />
                  <TextArea
                    id="customer-acc-description"
                    rows="4"
                    value={get(description, 'value')}
                    error={get(description, 'error')}
                    handleChange={val => {
                      setDirty(true);
                      setDescription(prev => ({ ...prev, value: val }));
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="existing-account-select">
              <AsyncSelector
                isClearable
                valueKey="name"
                labelKey="name"
                id="account-existing-select"
                placeholder={__('Select existing account')}
                fetchOptions={val =>
                  api.get(
                    `/api/v1/customer-accounts/?company=${company}&limit=50&order_by=name&name__icontains=${val}`
                  )
                }
                fetchInitialOptions={() =>
                  api.get(
                    `/api/v1/customer-accounts/?company=${company}&limit=50&order_by=name`
                  )
                }
                handleChange={val => handleExistingAccountChange(val)}
                value={get(existingAccount, 'name') || ''}
              />
              <InputErrorMessage text={existingAccountError} />
            </div>
          )}
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={closeForm}
          />
        )}
      </div>
    </Modal>
  );
};

SelectCustomerAccount.propTypes = {
  closeForm: PropTypes.func.isRequired,
  company: PropTypes.number.isRequired,
  customerID: PropTypes.number.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  hasAccount: PropTypes.bool
};

SelectCustomerAccount.defaultProps = {
  hasAccount: false
};

export default SelectCustomerAccount;
