import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  CardType,
  ConfirmationPopup,
  Notice,
  Notification,
  PermissionMissingNotificationTitle
} from 'shared/components';
import { userPermissions } from 'shared/constants';
import { sendErrorReport, checkUserPermission } from 'shared/helpers';
import { getCompanySuccess } from 'src/company/actions';
import { attemptPayment, deleteCard, getInvoices } from 'src/account/actions';
import './styles.scss';

const PaymentMethod = () => {
  const canManageBilling = checkUserPermission(userPermissions.billing_write);
  const dispatch = useDispatch();
  const cardType = useSelector(state =>
    get(state, 'company.details.payment_card_type')
  );
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const invoices = useSelector(state => get(state, 'account.invoices') || []);
  const hasUnpaidInvoices = invoices.some(
    i => i.status === 'open' || i.status === 'uncollectible'
  );
  const expires = useSelector(state =>
    get(state, 'company.details.payment_expires')
  );
  const last4 = useSelector(state =>
    get(state, 'company.details.payment_last4')
  );

  const [isLoading, setLoading] = useState(false);
  const [
    isPaymentMethodDeletePopupDisplayed,
    setPaymentMethodDeletePopupDisplay
  ] = useState(false);

  const handlePaymentAttempt = () => {
    setLoading(true);

    attemptPayment(companyID)
      .then(res => {
        dispatch(getCompanySuccess(res.data));
        dispatch(getInvoices(companyID, true));
        Notification('success', __('Payment successful'));
        setLoading(false);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot attempt payment');
        setLoading(false);
        Notification(
          'error',
          __('Error occured'),
          __('Payment attempt has failed, try again later.')
        );
      });
  };

  const handleCardDelete = () => {
    setLoading(true);

    deleteCard(companyID)
      .then(res => {
        dispatch(getCompanySuccess(res.data));
        setLoading(false);
        setPaymentMethodDeletePopupDisplay(false);
        Notification('success', __('Payment method removed'));
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot remove payment method');
        Notification('error', __('Error occured'));
        setLoading(false);
      });
  };

  const handleManageBillingClick = cb => {
    if (!canManageBilling) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle
          permission={userPermissions.billing_write}
        />,
        __('Contact you account admin for support.')
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <div className="PaymentMethod payment-method-wrapper">
      {cardType ? (
        <div className="payment-method-card">
          <div className="card-details">
            <CardType type={cardType} />
            <div className="card-last4">
              <span>****</span>
              <span>****</span>
              <span>****</span>
              <span>{last4}</span>
            </div>
            <div className="card-expiry">
              <span>{__('expires')}</span>
              <div className="card-expiration-date">
                <span className="month">{expires}</span>
              </div>
            </div>
          </div>
          <div className="payment-action">
            {hasUnpaidInvoices && // We have to enable payment attempt only if company is in inactive state and has payment method attached
              cardType && (
                <Button
                  theme="success"
                  onClick={() => handleManageBillingClick(handlePaymentAttempt)}
                  disabled={isLoading}
                >
                  {__('Attempt payment')}
                </Button>
              )}
            {cardType && (
              <Button
                onClick={() =>
                  handleManageBillingClick(setPaymentMethodDeletePopupDisplay)
                }
              >
                {__('Delete payment method')}
              </Button>
            )}
          </div>
        </div>
      ) : (
        <Notice theme="warning" title={__('No payment method')}>
          {__('Payment method has not been added to your account')}
        </Notice>
      )}
      {isPaymentMethodDeletePopupDisplayed && (
        <ConfirmationPopup
          closeCb={() => setPaymentMethodDeletePopupDisplay(false)}
          confirmCb={handleCardDelete}
          confirmText={__('Delete')}
          disabled={isLoading}
          theme="error"
          title={__('Are you sure you want to delete your payment method?')}
        >
          {__(
            'This will remove your payment method from your organization account'
          )}
        </ConfirmationPopup>
      )}
    </div>
  );
};

export default PaymentMethod;
