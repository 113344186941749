import React from 'react';
import PropTypes from 'prop-types';
import { platformFeatures } from 'shared/constants';

const getMessage = type => {
  switch (type) {
    case platformFeatures.extra_feature_licensing_consumption:
      return (
        <>
          {__('Consumption licensing is not offered in your current plan.')}
          &nbsp;
          {__('Contact sales to support consumption licensing.')}
        </>
      );
    case 'limit_reached':
      return (
        <>
          {__('You have used all available features of this type.')}
          &nbsp;
          {__('Contact sales to expand your feature licensing limits.')}
        </>
      );
    case 'not_available':
      return (
        <>
          {__('This feature type is not available in your current plan.')}
          &nbsp;
          {__('Contact sales to support this feature type licensing.')}
        </>
      );
    default:
      return (
        <>
          {__('This feature is not available in your current plan.')}
          &nbsp;
          {__('To compare different plan types click')}
          <a
            style={{ marginLeft: '3px' }}
            href="https://licensespring.com/pricing"
            target="_blank"
            rel="noopener noreferrer"
          >
            {__('here')}
          </a>
        </>
      );
  }
};

const ProductFeatureNotAvailableMessage = ({ type }) => (
  <div className="feature-unavailable-tooltip">{getMessage(type)}</div>
);

ProductFeatureNotAvailableMessage.propTypes = {
  type: PropTypes.string.isRequired
};

export default ProductFeatureNotAvailableMessage;
