import React from 'react';
import PropTypes from 'prop-types';
import {
  defaultDisabledFeatureMessage,
  platformFeatures
} from 'shared/constants';
import { isFeatureAvailable, isFeatureEnabled } from 'shared/helpers';
import { Selector } from 'shared/components';
import ProductAuthMethodNotAvailableMessage from '../ProductAuthMethodNotAvailableMessage';

const ProductAuthorizationMethodSelector = ({
  handleMethodChange,
  selectedMethod
}) => {
  // eslint-disable-next-line no-unused-vars
  const authMethodOptions = {
    license: {
      label: __('License key'),
      value: 'license-key'
    },
    user: {
      label: __('User'),
      value: 'user'
    }
  };

  return (
    <Selector
      options={[
        {
          label: __(authMethodOptions.license.label),
          value: authMethodOptions.license.value,
          isOptionAvailable: isFeatureAvailable(
            platformFeatures.platform_add_product
          ),
          isOptionEnabled: isFeatureEnabled(
            platformFeatures.platform_add_product
          ),
          isDisabled: !(
            isFeatureAvailable(platformFeatures.platform_add_product) &&
            isFeatureEnabled(platformFeatures.platform_add_product)
          ),
          disabledMessage: !isFeatureAvailable(
            platformFeatures.platform_add_product
          ) ? (
            <ProductAuthMethodNotAvailableMessage />
          ) : (
            __(defaultDisabledFeatureMessage)
          )
        },
        {
          label: __(authMethodOptions.user.label),
          value: authMethodOptions.user.value,
          isOptionAvailable: isFeatureAvailable(
            platformFeatures.assignment_user
          ),
          isOptionEnabled: isFeatureEnabled(platformFeatures.assignment_user),
          isDisabled: !(
            isFeatureAvailable(platformFeatures.assignment_user) &&
            isFeatureEnabled(platformFeatures.assignment_user)
          ),
          disabledMessage: !isFeatureAvailable(
            platformFeatures.assignment_user
          ) ? (
            <ProductAuthMethodNotAvailableMessage
              type={platformFeatures.assignment_user}
            />
          ) : (
            __(defaultDisabledFeatureMessage)
          )
        }
      ]}
      value={selectedMethod}
      handleChange={handleMethodChange}
    />
  );
};

ProductAuthorizationMethodSelector.propTypes = {
  handleMethodChange: PropTypes.func.isRequired,
  selectedMethod: PropTypes.string
};

ProductAuthorizationMethodSelector.defaultProps = {
  selectedMethod: null
};

export default ProductAuthorizationMethodSelector;
