import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { sendErrorReport, mapProductsToSelector } from 'shared/helpers';
import {
  Checkbox,
  CheckboxSelector,
  DirtyFormAlert,
  DescriptionTable,
  InputErrorMessage,
  Label,
  Modal,
  Notification,
  TextInput
} from 'shared/components';
import { updateLicenseApiKey } from 'src/account/actions';
import './styles.scss';

const EditLicenseAPIKeyForm = ({ apiKey, companyID, closeCb, refetchKeys }) => {
  const keyID = get(apiKey, 'id');
  const uuid = get(apiKey, 'uuid');
  const sharedKey = get(apiKey, 'shared_key');
  const keyProducts = get(apiKey, 'products') || [];

  const products = useSelector(state => get(state, 'products.list') || []);
  const productsForSelector = mapProductsToSelector(products, 'id');
  const initialProducts = productsForSelector.filter(p => {
    const isInitial = keyProducts.includes(p.value);
    return isInitial;
  });
  const shouldAllowAll = keyProducts.length === 0;

  const [isLoading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);
  const [label, setLabel] = useState(get(apiKey, 'label'));
  const [isReadOnly, setReadOnly] = useState(get(apiKey, 'read_only'));
  const [allowAllProducts, setAllowAllProducts] = useState(shouldAllowAll);
  const [selectedProducts, setSelectedProducts] = useState(initialProducts);
  const [selectedProductsError, setSelectedProductsError] = useState('');

  const handleProductSelect = val => {
    setDirty(true);
    setSelectedProducts(val);
    setSelectedProductsError('');
  };

  const handleSubmit = () => {
    if (isLoading) {
      return false;
    }
    setLoading(true);

    const data = {
      label,
      read_only: isReadOnly,
      products: allowAllProducts
        ? []
        : selectedProducts.map(sp => get(sp, 'value'))
    };

    updateLicenseApiKey(keyID, companyID, data)
      .then(() => {
        closeCb();
        refetchKeys();
        Notification('success', __('Changes saved successfully'));
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot edit license api key', data);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
        setLoading(false);
      });
    return true;
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title={__('Edit License API key')}
    >
      <div className="EditLicenseAPIKeyForm">
        <div className="license-api-key">
          <DescriptionTable
            details={[
              {
                label: __('API key (UUID)'),
                value: uuid
              },
              {
                label: __('Shared key'),
                value: sharedKey
              }
            ]}
          />
        </div>
        <div>
          <Label text={__('Label')} inputId="label" />
          <TextInput
            id="label"
            value={label}
            handleChange={val => {
              setDirty(true);
              setLabel(val);
            }}
            disabled={isLoading}
          />
        </div>
        <div className="readonly-checkbox">
          <Checkbox
            label={__('Is read only')}
            description={__(
              'If read only is enabled then only GET requests are allowed with this key.'
            )}
            checked={isReadOnly}
            inputId="is-read-only-checkbox"
            disabled={isLoading}
            handleChange={val => {
              setDirty(true);
              setReadOnly(val);
            }}
          />
        </div>
        <div className="select-products">
          <Label text={__('Select products')} />
          <CheckboxSelector
            text={__('Allowed products')}
            options={productsForSelector}
            value={selectedProducts}
            onChangeCallback={handleProductSelect}
            onMenuClose={() => {}}
            disabled={allowAllProducts || isLoading}
          />
          <InputErrorMessage text={selectedProductsError} />
        </div>
        <div className="readonly-checkbox">
          <Checkbox
            label={__('Allow all products')}
            checked={allowAllProducts}
            inputId="allow-all-checkbox"
            disabled={isLoading}
            handleChange={val => {
              setDirty(true);
              setAllowAllProducts(val);
              if (val) {
                setSelectedProducts(initialProducts);
                setSelectedProductsError('');
              }
            }}
          />
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

EditLicenseAPIKeyForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  refetchKeys: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  apiKey: PropTypes.object.isRequired
};

export default EditLicenseAPIKeyForm;
