import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Button,
  ConfirmationPopup,
  HiddenPasswordField,
  IconDelete,
  IconEdit,
  IconPassword,
  List,
  Notification,
  PermissionMissingNotificationTitle
} from 'shared/components';
import {
  displayValue,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  sendErrorReport,
  checkUserPermission
} from 'shared/helpers';
import { platformFeatures, userPermissions } from 'shared/constants';
import { getOrderManagers, removeOrderManager } from 'src/order/actions';
import { OrderManagerForm, OrderManagerPasswordForm } from './components';

const OrderManagers = ({ order, companyID }) => {
  const canManageOrders = checkUserPermission(userPermissions.orders_write);
  const orderID = get(order, 'id');
  // const customerEmail = get(order, 'customer.email');

  const [isLoading, setLoading] = useState(true);
  const [orderManagers, setOrderManagers] = useState([]);
  // add/edit manager form
  const [orderManagerToEdit, setOrderManagerToEdit] = useState(null);
  const [showOrderManagerForm, setOrderManagerFormDisplay] = useState(false);
  // remove manager
  const [orderManagerToRemove, setOrderManagerToRemove] = useState(null);
  const [showManagerRemovePopup, setManagerRemovePopupDisplay] = useState(null);
  const [isRemoveManagerLoading, setRemoveManagerLoading] = useState(false);
  // change manager pass
  const [showOrderManagerPasswordForm, setOrderManagerPasswordForm] = useState(
    false
  );
  const [
    orderManagerToPasswordChange,
    setOrderManagerToPasswordChange
  ] = useState(null);

  const fetchManagers = useCallback(() => {
    getOrderManagers(orderID, companyID)
      .then(res => {
        const data = get(res, 'data') || [];
        setOrderManagers(data);
        setLoading(false);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot fetch order license managers');
        setLoading(false);
      });
  }, [orderID, companyID]);

  useEffect(() => {
    fetchManagers();
  }, [fetchManagers]);

  const handleRemoveOrderManager = () => {
    const managerToRemoveID = get(orderManagerToRemove, 'id');
    setRemoveManagerLoading(true);

    removeOrderManager(orderID, companyID, managerToRemoveID)
      .then(() => {
        const newList = orderManagers.filter(
          om => get(om, 'id') !== managerToRemoveID
        );
        setOrderManagers(newList);
        setRemoveManagerLoading(false);
        setManagerRemovePopupDisplay(false);
        setOrderManagerToRemove(null);
        Notification('success', __('Changes saved successfully'));
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot remove order manager');
        setRemoveManagerLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const handleManageOrderClick = cb => {
    if (!canManageOrders) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle
          permission={userPermissions.orders_write}
        />,
        __('Contact you account admin for support.')
      );
      return false;
    }
    cb(true);
    return true;
  };

  // const isCustomer = (manager) => {
  //   const managerEmail = get(manager, 'original.true_email');
  //   return managerEmail === customerEmail;
  // };

  return (
    <div className="OrderManagers order-managers-tab">
      <div className="order-managers-tab-actions">
        <Button
          featureEnabled={isFeatureEnabled(
            platformFeatures.platform_edit_license
          )}
          featureAvailable={isFeatureAvailable(
            platformFeatures.platform_edit_license
          )}
          notEnabledMessage={getDisabledMessage()}
          onClick={() => handleManageOrderClick(setOrderManagerFormDisplay)}
          theme="info"
          size="sm"
        >
          {__('Add license manager')}
        </Button>
      </div>
      <List
        columns={[
          {
            accessor: 'true_email',
            Header: __('Email'),
            Cell: cellData => displayValue(cellData.value),
            minWidth: 200
          },
          {
            accessor: 'first_name',
            Header: __('First name'),
            Cell: cellData => displayValue(cellData.value)
          },
          {
            accessor: 'last_name',
            Header: __('Last name'),
            Cell: cellData => displayValue(cellData.value)
          },
          {
            accessor: 'phone_number',
            Header: __('Phone number'),
            Cell: cellData => displayValue(cellData.value)
          },
          {
            accessor: 'is_initial_password',
            Header: __('Initial password'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData => {
              if (!cellData.value) {
                return __('N/A');
              }
              const initialPass = get(cellData, 'original.initial_password');
              return (
                <HiddenPasswordField value={initialPass} fallback={__('N/A')} />
              );
            }
          },
          {
            Header: __('Change password'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: rowData => (
              <Button
                className="edit-button"
                onClick={() => {
                  handleManageOrderClick(() => {
                    setOrderManagerToPasswordChange(rowData.original);
                    setOrderManagerPasswordForm(true);
                  });
                }}
                type="button"
              >
                <IconPassword height="24px" width="24px" viewBox="0 0 65 60" />
              </Button>
            )
          },
          {
            Cell: rowData => (
              <Button
                featureEnabled={isFeatureEnabled(
                  platformFeatures.platform_edit_license
                )}
                featureAvailable={isFeatureAvailable(
                  platformFeatures.platform_edit_license
                )}
                notEnabledMessage={getDisabledMessage()}
                className="edit-button"
                onClick={() => {
                  handleManageOrderClick(() => {
                    setOrderManagerToEdit(rowData.original);
                    setOrderManagerFormDisplay(true);
                  });
                }}
                type="button"
              >
                <IconEdit height="16px" width="16px" />
              </Button>
            ),
            maxWidth: 50
          },
          {
            Cell: rowData => (
              <Button
                className="edit-button"
                onClick={() => {
                  handleManageOrderClick(() => {
                    setOrderManagerToRemove(rowData.original);
                    setManagerRemovePopupDisplay(true);
                  });
                }}
                type="button"
              >
                <IconDelete height="16px" width="16px" color="#ee5253" />
              </Button>
            ),
            maxWidth: 50
          }
        ]}
        data={orderManagers}
        loading={isLoading}
        minRows={2}
        pageSize={20}
      />
      {showOrderManagerForm && (
        <OrderManagerForm
          orderID={orderID}
          companyID={companyID}
          closeCb={() => {
            setOrderManagerToEdit(null);
            setOrderManagerFormDisplay(false);
          }}
          confirmCb={data => {
            setOrderManagers(data);
            setOrderManagerToEdit(null);
            setOrderManagerFormDisplay(false);
          }}
          orderManagers={orderManagers}
          manager={orderManagerToEdit}
        />
      )}
      {showOrderManagerPasswordForm && (
        <OrderManagerPasswordForm
          companyID={companyID}
          closeCb={() => {
            setOrderManagerPasswordForm(false);
            setOrderManagerToPasswordChange(null);
          }}
          refetchManagers={fetchManagers}
          manager={orderManagerToPasswordChange}
        />
      )}
      {showManagerRemovePopup && (
        <ConfirmationPopup
          closeCb={() => {
            setOrderManagerToRemove(null);
            setManagerRemovePopupDisplay(false);
          }}
          confirmCb={handleRemoveOrderManager}
          title={__('Are you sure you want to remove this license manager?')}
          confirmText={__('Remove')}
          theme="error"
          disabled={isRemoveManagerLoading}
        >
          {get(orderManagerToRemove, 'true_email')}
        </ConfirmationPopup>
      )}
    </div>
  );
};

OrderManagers.propTypes = {
  companyID: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired
};

export default OrderManagers;
