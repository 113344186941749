import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import {
  Button,
  DescriptionTable,
  Notification,
  PermissionMissingNotificationTitle
} from 'shared/components';
import {
  displayValue,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  checkUserPermission
} from 'shared/helpers';
import { platformFeatures, userPermissions } from 'shared/constants';
import CustomerForm from 'src/customer/components/CustomerForm';
import CustomerLabelsForm from 'src/customer/components/CustomerLabelsForm';
import { addNewCustomerLabel } from 'src/company/actions';
import ChangeCustomerOrderForm from '../ChangeOrderCustomerForm';

const CustomerDetails = ({ order, companyID, refetchOrder }) => {
  const canManageOrders = checkUserPermission(userPermissions.orders_write);
  const canManageCustomers = checkUserPermission(
    userPermissions.customers_write
  );
  const dispatch = useDispatch();
  const companyLabels = useSelector(state =>
    get(state, 'company.customerLabels')
  );

  const [showCustomerAddForm, setCustomerAddFormDisplay] = useState(false);
  const [showCustomerEditForm, setCustomerEditFormDisplay] = useState(false);
  const [showLabelForm, setLabelFormDisplay] = useState(false);

  const customerLabels = get(order, 'customer.labels') || [];
  const customerLabelTitles = customerLabels
    .map(l => l.label)
    .sort()
    .join(', ');
  const { customer } = order;
  const orderID = get(order, 'id');

  const displayCustomerAccountLink = () => {
    const customerAccount = get(customer, 'customer_account');
    if (!customerAccount) {
      return '-';
    }

    return (
      <Link
        to={`/${companyID}/customers/accounts/${get(customerAccount, 'id')}`}
      >
        {get(customerAccount, 'name')}
      </Link>
    );
  };

  const handleManageOrderClick = cb => {
    if (!canManageOrders) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle
          permission={userPermissions.orders_write}
        />,
        __('Contact you account admin for support.')
      );
      return false;
    }
    cb(true);
    return true;
  };

  const handleManageCustomerClick = cb => {
    if (!canManageCustomers) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle
          permission={userPermissions.customers_write}
        />,
        __('Contact you account admin for support.')
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <div className="CustomerDetails CustomerContainer">
      <div className="list-header">
        <div>
          <Button
            featureEnabled={isFeatureEnabled(
              platformFeatures.platform_edit_customer
            )}
            featureAvailable={isFeatureAvailable(
              platformFeatures.platform_edit_customer
            )}
            notEnabledMessage={getDisabledMessage()}
            onClick={() => handleManageOrderClick(setCustomerAddFormDisplay)}
            theme="info"
            size="sm"
          >
            {customer ? __('Add new customer') : __('Add customer')}
          </Button>
          {customer && (
            <>
              <Button
                featureEnabled={isFeatureEnabled(
                  platformFeatures.platform_edit_customer
                )}
                featureAvailable={isFeatureAvailable(
                  platformFeatures.platform_edit_customer
                )}
                notEnabledMessage={getDisabledMessage()}
                onClick={() =>
                  handleManageCustomerClick(setCustomerEditFormDisplay)
                }
                theme="default"
                size="sm"
              >
                {__('Edit customer')}
              </Button>
              <Button
                featureEnabled={isFeatureEnabled(
                  platformFeatures.platform_edit_customer
                )}
                featureAvailable={isFeatureAvailable(
                  platformFeatures.platform_edit_customer
                )}
                notEnabledMessage={getDisabledMessage()}
                onClick={() => handleManageCustomerClick(setLabelFormDisplay)}
                theme="default"
                size="sm"
              >
                {__('Add label')}
              </Button>
            </>
          )}
        </div>
        <div />
      </div>
      <DescriptionTable
        details={[
          { label: __('Email'), value: displayValue(get(customer, 'email')) },
          {
            label: __('First Name'),
            value: displayValue(get(customer, 'first_name'))
          },
          {
            label: __('Last Name'),
            value: displayValue(get(customer, 'last_name'))
          },
          { label: __('Account'), value: displayCustomerAccountLink() },
          {
            label: __('Address'),
            value: displayValue(get(customer, 'address'))
          },
          { label: __('City'), value: displayValue(get(customer, 'city')) },
          {
            label: __('State / Province'),
            value: displayValue(get(customer, 'state'))
          },
          {
            label: __('Country'),
            value: displayValue(get(customer, 'country'))
          },
          {
            label: __('Zipcode / Postcode'),
            value: displayValue(get(customer, 'postcode'))
          },
          {
            label: __('Phone Number'),
            value: displayValue(get(customer, 'phone'))
          },
          {
            label: __('Company Name'),
            value: displayValue(get(customer, 'company_name'))
          },
          {
            label: __('Reference'),
            value: displayValue(get(customer, 'reference'))
          },
          { label: __('Labels'), value: customerLabelTitles }
        ]}
      />
      {showCustomerEditForm && (
        <CustomerForm
          closeForm={() => setCustomerEditFormDisplay(false)}
          company={companyID}
          customer={get(order, 'customer')}
          updateCustomer={refetchOrder}
        />
      )}
      {showCustomerAddForm && (
        <ChangeCustomerOrderForm
          closeCb={() => setCustomerAddFormDisplay(false)}
          confirmCb={() => {
            setCustomerAddFormDisplay(false);
            refetchOrder();
          }}
          orderID={orderID}
        />
      )}
      {showLabelForm && (
        <CustomerLabelsForm
          closeCb={() => setLabelFormDisplay(false)}
          customer={get(order, 'customer')}
          companyLabels={companyLabels}
          companyID={companyID}
          refetchCustomer={refetchOrder}
          updateCompanyLabels={data => dispatch(addNewCustomerLabel(data))}
        />
      )}
    </div>
  );
};

CustomerDetails.propTypes = {
  order: PropTypes.object.isRequired,
  companyID: PropTypes.number.isRequired,
  refetchOrder: PropTypes.func.isRequired
};

export default CustomerDetails;
