import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Label,
  ColorPicker,
  ConfirmationPopup,
  DescriptionTable,
  Notification,
  PermissionMissingNotificationTitle,
  TextArea
} from 'shared/components';
import {
  sendErrorReport,
  getData,
  checkUserPermission,
  getLink,
  getAirgapPortalURL,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled
} from 'shared/helpers';
import {
  initialAirgapPortalData,
  userPermissions,
  platformFeatures
} from 'shared/constants';
import { updatePortalData } from 'src/company/actions';
import { updateCompany } from 'src/account/actions';
import './styles.scss';

const AirgapPortalData = () => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write);
  const dispatch = useDispatch();
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const portalDomain = useSelector(state =>
    get(state, 'company.details.airgap_portal_domain')
  );
  const userPortalPreferences = useSelector(state =>
    get(state, 'company.userPortalPreferences')
  );
  const portalData = get(userPortalPreferences, 'airgap_portal_data');

  const [isReseting, setReseting] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isResetToDefaultDisplayed, setResetToDefaultDisplay] = useState(false);
  const [background, setBackground] = useState(
    getData(portalData, 'background', initialAirgapPortalData)
  );
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState(
    getData(portalData, 'button.background', initialAirgapPortalData)
  );
  const [buttonTextColor, setButtonTextColor] = useState(
    getData(portalData, 'button.textColor', initialAirgapPortalData)
  );
  const [textColorPrimary, setTextColorPrimary] = useState(
    getData(portalData, 'text.colorPrimary', initialAirgapPortalData)
  );
  const [textColorSecondary, setTextColorSecondary] = useState(
    getData(portalData, 'text.colorSecondary', initialAirgapPortalData)
  );
  const [accentSuccess, setAccentSuccess] = useState(
    getData(portalData, 'accentColors.success', initialAirgapPortalData)
  );
  const [accentError, setAccentError] = useState(
    getData(portalData, 'accentColors.error', initialAirgapPortalData)
  );
  const [accentInfo, setAccentInfo] = useState(
    getData(portalData, 'accentColors.info', initialAirgapPortalData)
  );
  const [titleText, setTitleText] = useState(
    getData(portalData, 'title', initialAirgapPortalData, '')
  );
  const [messageText, setMessageText] = useState(
    getData(portalData, 'message', initialAirgapPortalData, '')
  );

  const createPortalData = () => {
    const data = {
      ...initialAirgapPortalData,
      background,
      button: {
        background: buttonBackgroundColor,
        textColor: buttonTextColor
      },
      text: {
        colorPrimary: textColorPrimary,
        colorSecondary: textColorSecondary
      },
      accentColors: {
        success: accentSuccess,
        error: accentError,
        info: accentInfo
      },
      title: titleText,
      message: messageText
    };
    return data;
  };

  const handlePortalDataChange = () => {
    const changedData = createPortalData();
    const data = { airgap_portal_data: JSON.stringify(changedData) };
    setLoading(true);

    updateCompany(companyID, data)
      .then(() => {
        setLoading(false);
        dispatch(
          updatePortalData({
            ...userPortalPreferences,
            airgap_portal_data: changedData
          })
        );
        Notification('success', __('Changes saved successfully'));
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot update user portal preferences', data);
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const handleResetToDefault = () => {
    const data = {
      airgap_portal_data: JSON.stringify(initialAirgapPortalData)
    };
    setReseting(true);

    updateCompany(companyID, data)
      .then(() => {
        setReseting(false);
        setBackground(initialAirgapPortalData.background);
        setButtonBackgroundColor(initialAirgapPortalData.button.background);
        setButtonTextColor(initialAirgapPortalData.button.textColor);
        setTextColorPrimary(initialAirgapPortalData.text.colorPrimary);
        setTextColorSecondary(initialAirgapPortalData.text.colorSecondary);
        setAccentSuccess(initialAirgapPortalData.accentColors.success);
        setAccentError(initialAirgapPortalData.accentColors.error);
        setAccentInfo(initialAirgapPortalData.accentColors.info);
        setTitleText(initialAirgapPortalData.title);
        setMessageText(initialAirgapPortalData.message);
        setResetToDefaultDisplay(false);
        dispatch(
          updatePortalData({
            ...userPortalPreferences,
            airgap_portal_data: initialAirgapPortalData
          })
        );
        Notification('success', __('Changes saved successfully'));
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot update user portal preferences', data);
        setReseting(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const handleManageSettingsClick = cb => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle
          permission={userPermissions.settings_write}
        />,
        __('Contact you account admin for support.')
      );
      return false;
    }
    cb(true);
    return true;
  };

  const getDomainData = () => {
    if (portalDomain) {
      return {
        link: getLink(portalDomain),
        domain: portalDomain
      };
    }

    const url = getAirgapPortalURL();
    const { hostname } = new URL(url);
    return {
      link: url,
      domain: hostname
    };
  };
  const airgapPortal = getDomainData();

  return (
    <div className="AirgapPortalData">
      <div className="AirgapPortalData-domain">
        <Label text={__('Portal domain')} inputId="portal-domain" />
        <a
          href={airgapPortal.link}
          target="_blank"
          rel="noopener noreferrer"
          className="domain"
        >
          {airgapPortal.domain}
        </a>
      </div>
      <div className="config-section">
        <div className="section">
          <Label
            inputId="title-text-input"
            text={__('Portal title')}
            description={__(
              'Enter a personalized title for your user portal to be displayed on main view and in the browser tab.'
            )}
          />
          <TextArea
            disabled={isLoading}
            handleChange={val => {
              setTitleText(val);
            }}
            id="title-text-input"
            type="title-text"
            value={titleText}
            placeholder={__('Air-gap Licensing')}
            rows="1"
          />
        </div>
        <div className="section">
          <Label
            inputId="message-text-input"
            text={__('Portal message')}
            description={__(
              'Enter a personalized welcome message for users using your whitelabeled user portal. This message can include instructions, greetings, or any information you deem important for your users to know before they log in.'
            )}
          />
          <TextArea
            disabled={isLoading}
            handleChange={val => {
              setMessageText(val);
            }}
            id="message-text-input"
            type="message-text"
            value={messageText}
            placeholder={__(
              'Personalized welcome message to be displayed on main view.'
            )}
            rows="4"
          />
        </div>
      </div>
      <div className="style-config config-section">
        <div className="section-header">{__('Colors')}</div>
        <DescriptionTable
          details={[
            {
              label: __('Background'),
              value: (
                <ColorPicker
                  initialColor={background}
                  onColorSelect={val => setBackground(val)}
                  label={background}
                  featureEnabled={isFeatureEnabled(
                    platformFeatures.extra_whitelabeling
                  )}
                  featureAvailable={isFeatureAvailable(
                    platformFeatures.extra_whitelabeling
                  )}
                  notEnabledMessage={getDisabledMessage()}
                />
              )
            },
            {
              label: __('Button background'),
              value: (
                <ColorPicker
                  initialColor={buttonBackgroundColor}
                  onColorSelect={val => setButtonBackgroundColor(val)}
                  label={buttonBackgroundColor}
                  featureEnabled={isFeatureEnabled(
                    platformFeatures.extra_whitelabeling
                  )}
                  featureAvailable={isFeatureAvailable(
                    platformFeatures.extra_whitelabeling
                  )}
                  notEnabledMessage={getDisabledMessage()}
                />
              )
            },
            {
              label: __('Button text'),
              value: (
                <ColorPicker
                  initialColor={buttonTextColor}
                  onColorSelect={val => setButtonTextColor(val)}
                  label={buttonTextColor}
                  featureEnabled={isFeatureEnabled(
                    platformFeatures.extra_whitelabeling
                  )}
                  featureAvailable={isFeatureAvailable(
                    platformFeatures.extra_whitelabeling
                  )}
                  notEnabledMessage={getDisabledMessage()}
                />
              )
            },
            {
              label: __('Primary text color'),
              value: (
                <ColorPicker
                  initialColor={textColorPrimary}
                  onColorSelect={val => setTextColorPrimary(val)}
                  label={textColorPrimary}
                  featureEnabled={isFeatureEnabled(
                    platformFeatures.extra_whitelabeling
                  )}
                  featureAvailable={isFeatureAvailable(
                    platformFeatures.extra_whitelabeling
                  )}
                  notEnabledMessage={getDisabledMessage()}
                />
              )
            },
            {
              label: __('Secondary text color'),
              value: (
                <ColorPicker
                  initialColor={textColorSecondary}
                  onColorSelect={val => setTextColorSecondary(val)}
                  label={textColorSecondary}
                  featureEnabled={isFeatureEnabled(
                    platformFeatures.extra_whitelabeling
                  )}
                  featureAvailable={isFeatureAvailable(
                    platformFeatures.extra_whitelabeling
                  )}
                  notEnabledMessage={getDisabledMessage()}
                />
              )
            },
            {
              label: __('Accent color success'),
              value: (
                <ColorPicker
                  initialColor={accentSuccess}
                  onColorSelect={val => setAccentSuccess(val)}
                  label={accentSuccess}
                  featureEnabled={isFeatureEnabled(
                    platformFeatures.extra_whitelabeling
                  )}
                  featureAvailable={isFeatureAvailable(
                    platformFeatures.extra_whitelabeling
                  )}
                  notEnabledMessage={getDisabledMessage()}
                />
              )
            },
            {
              label: __('Accent color error'),
              value: (
                <ColorPicker
                  initialColor={accentError}
                  onColorSelect={val => setAccentError(val)}
                  label={accentError}
                  featureEnabled={isFeatureEnabled(
                    platformFeatures.extra_whitelabeling
                  )}
                  featureAvailable={isFeatureAvailable(
                    platformFeatures.extra_whitelabeling
                  )}
                  notEnabledMessage={getDisabledMessage()}
                />
              )
            },
            {
              label: __('Accent color info'),
              value: (
                <ColorPicker
                  initialColor={accentInfo}
                  onColorSelect={val => setAccentInfo(val)}
                  label={accentInfo}
                  featureEnabled={isFeatureEnabled(
                    platformFeatures.extra_whitelabeling
                  )}
                  featureAvailable={isFeatureAvailable(
                    platformFeatures.extra_whitelabeling
                  )}
                  notEnabledMessage={getDisabledMessage()}
                />
              )
            }
          ]}
        />
      </div>
      <div className="AirgapPortalData-actions">
        <Button
          theme="success"
          onClick={() => handleManageSettingsClick(handlePortalDataChange)}
          disabled={isLoading}
        >
          {__('Save changes')}
        </Button>
        <Button
          theme="default"
          onClick={() => handleManageSettingsClick(setResetToDefaultDisplay)}
          disabled={isLoading}
        >
          {__('Reset to default')}
        </Button>
      </div>
      {isResetToDefaultDisplayed && (
        <ConfirmationPopup
          closeCb={() => setResetToDefaultDisplay(false)}
          confirmCb={handleResetToDefault}
          title={__(
            'Are you sure you want to reset airgap portal customizations to default values?'
          )}
          confirmText={__('Reset to default')}
          theme="error"
          disabled={isReseting}
        />
      )}
    </div>
  );
};

export default AirgapPortalData;
