import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { userPermissions } from 'shared/constants';
import { PermissionCheck } from 'shared/components';
import LicenseList from '../LicenseList';
import HardwareKeys from '../HardwareKeys';
import CreateOrderForm from '../CreateOrderForm';

const LicensesContainer = () => (
  <Switch>
    <Route
      path="/:companyId/licenses"
      exact
      component={PermissionCheck(LicenseList, userPermissions.licenses_read)}
    />
    <Route
      path="/:companyId/licenses/hardware-keys"
      exact
      component={PermissionCheck(HardwareKeys, userPermissions.licenses_read)}
    />
    <Route
      path="/:companyId/licenses/issue-licenses"
      exact
      component={PermissionCheck(CreateOrderForm, userPermissions.orders_write)}
    />
    <Route
      path="/:companyId/licenses/issue-licenses/:orderId"
      exact
      component={PermissionCheck(CreateOrderForm, userPermissions.orders_write)}
    />
  </Switch>
);

export default LicensesContainer;
