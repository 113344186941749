import React from 'react';
import PropTypes from 'prop-types';
import {
  IconStatusInfo,
  IconStatusError,
  IconStatusSuccess
} from 'shared/components';

const InvoiceStatusIcon = ({ status }) => {
  switch (status) {
    case 'draft':
    case 'open':
      return <IconStatusInfo fill="#1d71c5" />;
    case 'paid':
      return <IconStatusSuccess fill="#0e7359" />;
    case 'uncollectible':
    case 'void':
    case 'disputed':
    case 'refunded':
      return <IconStatusError fill="#AE0721" />;
    default:
      return <IconStatusInfo fill="#1d71c5" />;
  }
};

InvoiceStatusIcon.propTypes = {
  status: PropTypes.string
};

InvoiceStatusIcon.defaultProps = {
  status: null
};

export default InvoiceStatusIcon;
