import { get, sortBy } from 'lodash';

export const formatFeaturesToTable = (features = []) => {
  if (!Array.isArray(features)) {
    return [];
  }
  const mapedFeatures = features.map(f => {
    const newF = {
      ...f,
      name: get(f, 'label') || get(f, 'name'),
      max_consumption_error: '',
      max_overages_error: '',
      expiry_date_error: '',
      metadata_error: ''
    };
    return newF;
  });
  const sortedArr = sortBy(mapedFeatures, 'value');
  return sortedArr;
};
