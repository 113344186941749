import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  Selector
} from 'shared/components';
import {
  sendErrorReport,
  mapPermissionTemplatesToSelector
} from 'shared/helpers';
import { setUserPermissionsFromTemplate } from 'src/company/actions';

const SetUserPermissionTemplate = ({ closeCb, confirmCb, user }) => {
  const userID = get(user, 'id');
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const userPermissionsTemplates = useSelector(state =>
    get(state, 'company.userPermissionsTemplates')
  );
  const templatesOptions = mapPermissionTemplatesToSelector(
    userPermissionsTemplates
  );

  const [isLoading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(templatesOptions[0]);

  const handleSubmit = async () => {
    setLoading(true);
    const selectedTemplateID = get(selectedTemplate, 'value');
    const data = { template_id: selectedTemplateID };

    setUserPermissionsFromTemplate(userID, companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        confirmCb();
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot set permissions from template', data);
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={__('Set permissions from template')}
      disabled={isLoading}
      size="sm"
    >
      <div className="SetUserPermissionTemplate">
        <Label inputId="template-select" text={__('Select template')} />
        <Selector
          options={templatesOptions}
          isDisabled={isLoading}
          value={get(selectedTemplate, 'value')}
          id="template-select"
          handleChange={val => {
            const selected = templatesOptions.find(
              option => option.value === val
            );
            setDirty(true);
            setSelectedTemplate(selected);
          }}
        />
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

SetUserPermissionTemplate.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default SetUserPermissionTemplate;
