import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import {
  Checkbox,
  InputErrorMessage,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  Selector
} from 'shared/components';
import {
  sendErrorReport,
  formatAmountValue,
  mapCurrencySymbol
} from 'shared/helpers';
import { getCompanySilent } from 'src/company/actions';
import { editStandardSubscription } from 'src/billing/actions';
import './styles.scss';

const getSubscriptionCycle = plan => {
  if (!plan) {
    return undefined;
  }
  if (plan === 'standard_monthly') {
    return 'monthly';
  }
  if (plan === 'standard_yearly') {
    return 'yearly';
  }
  return undefined;
};

const getTotal = (selectedPlan, selectedDiscount, selectedTax) => {
  let unitPlanPrice = 0;
  let unitDiscount = 0;
  let unitTax = 0;

  if (!selectedPlan) {
    return 0;
  }

  unitPlanPrice = formatAmountValue(selectedPlan.amount);
  if (selectedDiscount) {
    unitDiscount =
      Number(selectedDiscount.amount_off) ||
      (Number(unitPlanPrice) *
        Number(get(selectedDiscount, 'percent_off') || 0)) /
        100;
  }
  if (selectedTax) {
    unitTax =
      ((Number(unitPlanPrice) - unitDiscount) *
        Number(get(selectedTax, 'percentage') || 0)) /
      100;
  }
  const total = Number(unitPlanPrice) - unitDiscount + unitTax;
  return total.toFixed(2);
};

const StandardSubscriptionForm = ({
  closeCb,
  companyDetails,
  coupons,
  hasSubscription,
  pricingPlans,
  taxRates,
  title
}) => {
  const dispatch = useDispatch();

  const companyPricingPlan =
    get(companyDetails, 'upcoming_invoice.plan_id') || '';
  const companyCouponCode =
    get(companyDetails, 'upcoming_invoice.discounts[0].coupon.id') ||
    get(companyDetails, 'payment_coupon_code') ||
    '';
  const companyTaxRate = get(companyDetails, 'stripe_tax_id') || '';

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setIsDirtyFormAlertDisplayed] = useState(
    false
  );
  const [pricingPlan, setPricingPlan] = useState(companyPricingPlan);
  const [pricingPlanError, setPricingPlanError] = useState('');
  const [hasDiscount, setHasDiscount] = useState(!!companyCouponCode);
  const [discount, setDiscount] = useState(companyCouponCode);
  const [discountError, setDiscountError] = useState('');
  const [hasTaxRate, setHasTaxRate] = useState(!!companyTaxRate);
  const [taxRate, setTaxRate] = useState(companyTaxRate);
  const [taxRateError, setTaxRateError] = useState('');

  const validatePlan = () => {
    if (!pricingPlan) {
      setPricingPlanError('This field is required');
      return false;
    }
    return true;
  };

  const validateDiscount = () => {
    if (!hasDiscount) {
      return true;
    }
    if (!discount) {
      setDiscountError('This field is required');
      return false;
    }
    return true;
  };

  const validateTaxRate = () => {
    if (!hasTaxRate) {
      return true;
    }
    if (!taxRate) {
      setTaxRateError('This field is required');
      return false;
    }
    return true;
  };

  const isFormValid = () => {
    const isPlanValid = validatePlan();
    const isDiscountValid = validateDiscount();
    const isTaxRateValid = validateTaxRate();
    return isPlanValid && isDiscountValid && isTaxRateValid;
  };

  const updateBillingInfo = () => {
    const cycle = getSubscriptionCycle(pricingPlan);
    const data = {
      subscription_cycle: hasSubscription ? undefined : cycle,
      coupon: discount || '',
      tax_rate: taxRate || ''
    };
    const companyId = get(companyDetails, 'id');

    editStandardSubscription(companyId, data)
      .then(() => {
        dispatch(getCompanySilent(companyId));
        Notification(
          'success',
          hasSubscription
            ? __('Subscription successfully updated')
            : __('Billing info successfully updated')
        );
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot update subscription', data);
        Notification(
          'error',
          __('Error occured'),
          hasSubscription
            ? __('We could not update the subscription.')
            : __('We could not update the billing info.')
        );
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    const isValid = isFormValid();
    if (!isValid || loading) {
      return false;
    }

    setLoading(true);
    updateBillingInfo();
    return true;
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return setIsDirtyFormAlertDisplayed(true);
  };

  const selectedPricingPlan = pricingPlans.find(p => p.id === pricingPlan);
  const selectedCoupon = coupons.find(c => c.id === discount);
  const selectedTaxRate = taxRates.find(t => t.id === taxRate);
  const totalPrice = getTotal(
    selectedPricingPlan,
    selectedCoupon,
    selectedTaxRate
  );

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={title}
      disabled={loading}
      size="sm"
    >
      <div className="StandardSubscriptionForm">
        <form className="StandardSubscriptionForm-form" onSubmit={handleSubmit}>
          <div>
            <Label text={__('Pricing plan')} inputId="pricing-plan" />
            <Selector
              options={pricingPlans}
              value={pricingPlan}
              disabled={hasSubscription}
              handleChange={val => {
                setDirty(true);
                setPricingPlanError('');
                setPricingPlan(val);
              }}
              valueKey="id"
              getOptionLabel={o =>
                `${o.nickname} - ${formatAmountValue(
                  o.amount
                )} ${mapCurrencySymbol(o.currency)} (${o.interval})`
              }
              getOptionValue={o => o.id}
            />
            <InputErrorMessage text={pricingPlanError} />
          </div>
          <div>
            <Checkbox
              label={__('Discount')}
              handleChange={val => {
                setDirty(true);
                setDiscountError('');
                setDiscount('');
                setHasDiscount(val);
              }}
              checked={hasDiscount}
              inputId="discount"
            />
            {hasDiscount && (
              <div>
                <Selector
                  options={coupons}
                  value={discount}
                  handleChange={val => {
                    setDirty(true);
                    setDiscount(val);
                  }}
                  valueKey="id"
                  getOptionLabel={o => `${o.name}`}
                  getOptionValue={o => o.id}
                />
                <InputErrorMessage text={discountError} />
              </div>
            )}
          </div>
          <div className="tax-rate-wrapper">
            <Checkbox
              label={__('Tax rate')}
              handleChange={val => {
                setDirty(true);
                setTaxRate('');
                setTaxRateError('');
                setHasTaxRate(val);
              }}
              checked={hasTaxRate}
              inputId="tax-rate"
            />
            {hasTaxRate && (
              <div>
                <Selector
                  options={taxRates}
                  value={taxRate}
                  handleChange={val => {
                    setDirty(true);
                    setTaxRate(val);
                  }}
                  valueKey="id"
                  getOptionLabel={o =>
                    `${o.display_name} ${o.jurisdiction} - ${o.percentage}%`
                  }
                  getOptionValue={o => o.id}
                />
                <InputErrorMessage text={taxRateError} />
              </div>
            )}
          </div>
          <div className="total">
            <span>Total:</span>
            &nbsp;
            <span>{`${totalPrice} ${mapCurrencySymbol(
              get(selectedPricingPlan, 'currency') || ''
            )}`}</span>
          </div>
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setIsDirtyFormAlertDisplayed(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </Modal>
  );
};

StandardSubscriptionForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyDetails: PropTypes.object.isRequired,
  coupons: PropTypes.array.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  pricingPlans: PropTypes.array.isRequired,
  taxRates: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

export default StandardSubscriptionForm;
