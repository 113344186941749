import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';
import { get } from 'lodash';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import {
  ContentLoader,
  NotFound,
  TopHeader,
  Forbidden
} from 'shared/components';
import { getUserCompanies, initUser } from 'src/account/actions';
import CompanyList from 'src/company/components/CompanyList';
import CompanySelector from 'src/company/components/CompanySelector';
import ProfileContainer from 'src/user/ProfileContainer';
import ConsentForm from '../ConsentForm';
import UserPermissionsCheck from '../UserPermissionsCheck';

const AppContainer = () => {
  const location = useLocation();
  const { pathname } = location;

  const history = useHistory();

  const dispatch = useDispatch();
  const auth = useAuth();
  const userConsent = get(auth, 'user.profile.platform_acquired_consent');
  const userID = get(auth, 'user.profile.platform_user_id');
  const isStaff = get(auth, 'user.profile.platform_is_staff');

  const activeCompany = useSelector(state => get(state, 'company.details.id'));
  const companies = useSelector(state => get(state, 'account.companies'));
  const companiesLoading = useSelector(state =>
    get(state, 'account.companiesLoading')
  );

  const handleUserInit = useCallback(() => {
    initUser()
      .then(() => {
        if (!userID) {
          auth.signinSilent();
        }
      })
      .catch(() => {
        auth.signinRedirect();
      });
  }, [auth, userID]);

  useEffect(() => {
    handleUserInit();
  }, [handleUserInit]);

  useEffect(() => {
    if (userID) {
      dispatch(getUserCompanies());
    }
  }, [userID, auth.isAuthenticated]);

  useEffect(() => {
    if (
      !companiesLoading &&
      pathname === '/' &&
      companies &&
      companies.length
    ) {
      const firstCompany = activeCompany || get(companies, '[0].id');

      if (companies.length && firstCompany && !isStaff) {
        history.push(`/${firstCompany}`);
      }
    }
  }, [companiesLoading, activeCompany, companies, auth, pathname]);

  if (!userConsent && !isStaff && userID) {
    return (
      <ConsentForm userId={userID} refetchUser={() => auth.signinSilent()} />
    );
  }

  if (companiesLoading) {
    return <ContentLoader text="Getting your organizations" />;
  }

  if (!companies.length) {
    return (
      <div className="AppContainer">
        <TopHeader>
          <CompanySelector />
        </TopHeader>
        <Forbidden text={__('Your account has been disabled')} />
      </div>
    );
  }

  return (
    <div className="AppContainer">
      <Route
        path="/"
        render={() => (
          <TopHeader>
            <CompanySelector />
          </TopHeader>
        )}
      />
      <Switch>
        <Route path="/" exact component={CompanyList} />
        <Route path="/profile" component={ProfileContainer} />
        <Route path="/:companyId" component={UserPermissionsCheck} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default AppContainer;
