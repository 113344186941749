import * as actionTypes from './actionTypes';

const initialState = {
  loading: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.getUsersInit:
      return Object.assign({}, state, {
        loading: true
      });
    case actionTypes.getUsersSuccess:
      return Object.assign({}, state, {
        loading: false,
        list: action.payload
      });
    case actionTypes.getUsersError:
      return Object.assign({}, state, {
        loading: false
      });
    case actionTypes.clearUsers:
      return Object.assign({}, state, {
        list: undefined
      });
    default:
      return state;
  }
}
