import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { isStaffUser, isUserCompanyAdmin } from 'shared/helpers';

const AuthorizedElement = ({ children, level }) => {
  const account = useSelector(state => get(state, 'account'));
  const companyId = useSelector(state => get(state, 'company.details.id'));

  const isAdmin = isUserCompanyAdmin(companyId);
  const isStaff = isStaffUser();
  const isStaffDisabled = get(account, 'disableStaff');

  if (level === 'admin' && !isStaff && !isAdmin) {
    return null;
  }
  if (level === 'staff' && (!isStaff || isStaffDisabled)) {
    return null;
  }

  return children;
};

AuthorizedElement.propTypes = {
  children: PropTypes.element.isRequired,
  level: PropTypes.string
};

AuthorizedElement.defaultProps = {
  level: 'user'
};

export default AuthorizedElement;
