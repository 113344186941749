import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get } from 'lodash';
import { abbreviateNumber } from 'js-abbreviation-number';
import {
  AreaChart,
  Area,
  ReferenceLine,
  Label,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import {
  Button,
  ChartTooltip,
  ContentLoader,
  NoGraphData,
  Notification
} from 'shared/components';
import { APIdateFormat } from 'shared/constants';
import {
  formatAnalyticsData,
  sendErrorReport,
  filenameFromHeadersData,
  downloadResource
} from 'shared/helpers';
import {
  getHourlyFeatureConsumptionAnalytics,
  getDailyFeatureConsumptionAnalytics,
  exportFeatureConsumptionAnalytics
} from 'src/license/actions';
import FeatureConsumptionEventsList from '../FeatureConsumptionEventsList';
import './styles.scss';

// ts__lt, ts__lte, ts__gt, ts__gte, action__in=consumption_add,consumption_reset, order_by=ts, license__in | feature__in, ts__date__gte
const FeatureConsumptionChart = ({
  feature,
  companyID,
  dateFrom,
  dateTo,
  height,
  label,
  params,
  title,
  subtitle
}) => {
  const allowUnlimited = get(feature, 'allow_unlimited_consumptions');
  const maxConsumption = get(feature, 'max_consumption');
  const maxOverages = get(feature, 'allow_overages')
    ? get(feature, 'max_overages')
    : null;

  const featureID = get(feature, 'id');
  const [isLoading, setLoading] = useState(false);
  const [isExportLoading, setExportLoading] = useState(false);
  const [data, setData] = useState(null);
  const [isDaily, setDaily] = useState(true);
  const [showEventsList, setEventsListDisplay] = useState(false);

  const getEndpointParams = () => {
    const from = dateFrom.format(APIdateFormat);
    const to = dateTo.format(APIdateFormat);
    const duration = moment.duration(dateTo.diff(dateFrom)).asDays();
    const gteParam = `&ts__date__gte=${from}`;
    const lteParam = `&ts__date__lte=${to}`;
    const objectParam = `&license_feature__in=${featureID}`;

    return {
      from,
      to,
      duration,
      gteParam,
      lteParam,
      objectParam
    };
  };

  const fetchDailyData = parameters => {
    getDailyFeatureConsumptionAnalytics(parameters)
      .then(res => {
        const events = get(res, 'data') || [];
        setData(events);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchHourlyData = parameters => {
    getHourlyFeatureConsumptionAnalytics(parameters)
      .then(res => {
        const events = get(res, 'data') || [];
        setData(events);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getData = useCallback(() => {
    const { duration, gteParam, lteParam, objectParam } = getEndpointParams();

    const shouldDisplayDaily = duration > 7;
    setLoading(true);
    setData([]);
    setDaily(shouldDisplayDaily);

    const endpointParams = `?company=${companyID}${gteParam}${lteParam}&order_by=ts${objectParam}${params}`;

    if (shouldDisplayDaily) {
      fetchDailyData(endpointParams);
    } else {
      fetchHourlyData(endpointParams);
    }
  }, [companyID, dateFrom, dateTo]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDataExport = () => {
    const { gteParam, lteParam, objectParam } = getEndpointParams();

    const endpointParams = `?company=${companyID}${gteParam}${lteParam}&order_by=ts${objectParam}${params}`;

    setExportLoading(true);
    exportFeatureConsumptionAnalytics(endpointParams)
      .then(response => {
        const filename = filenameFromHeadersData(response.headers);
        downloadResource(response, filename);
        setExportLoading(false);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot export data', data);
        Notification('error', __('Export data failed'));
        setExportLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div
        className="FeatureConsumptionChart BaseChart loading"
        style={{ height: `${height}px`, maxHeight: `${height}px` }}
      >
        <ContentLoader text={`${label} ${__('loading')}`} />
      </div>
    );
  }

  if (!data || !data.length) {
    return (
      <div className="FeatureConsumptionChart">
        <NoGraphData
          maxHeight={height}
          minHeight={height}
          text={`${title}: ${__('No consumptions data')}`}
          description={__('Data is refreshed every 24 hours')}
          label={label}
          subtitle={subtitle}
        />
      </div>
    );
  }

  const overageLimit = maxConsumption + maxOverages;
  const unifiedDateTimeData = formatAnalyticsData(data, isDaily);

  const formattedData = unifiedDateTimeData.map(fd => {
    const diff = fd.total_consumptions - maxConsumption;
    const cons = diff > 0 ? maxConsumption : fd.total_consumptions;
    const over = diff > 0 ? diff : 0;
    return {
      ...fd,
      cons,
      over
    };
  });

  const showOverageLimit = maxOverages !== null && maxOverages !== 0;

  return (
    <div className="FeatureConsumptionChart BaseChart">
      {title && (
        <>
          <div className="FeatureConsumptionChart-heading BaseChart-heading">
            <div className="title">{title}</div>
            {subtitle && <div className="subtitle">{subtitle}</div>}
          </div>
          <div className="FeatureConsumptionChart-actions">
            <Button size="sm" onClick={() => setEventsListDisplay(true)}>
              {__('View consumption events')}
            </Button>
            <Button
              size="sm"
              onClick={handleDataExport}
              loading={isExportLoading}
              disabled={isExportLoading}
            >
              {__('Export')}
            </Button>
          </div>
        </>
      )}
      <div className="FeatureConsumptionChart-chart">
        <ResponsiveContainer width="100%" height={height}>
          <AreaChart
            data={formattedData}
            margin={{
              top: 15,
              right: 20,
              bottom: 5,
              left: -20
            }}
          >
            <defs>
              <linearGradient
                id={`consumptions-fill-${featureID}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="15%" stopColor="#13b0fc" stopOpacity={0.6} />
                <stop offset="95%" stopColor="#13b0fc" stopOpacity={0.1} />
              </linearGradient>
              <linearGradient
                id={`overages-fill-${featureID}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="15%" stopColor="#ee5253" stopOpacity={0.6} />
                <stop offset="95%" stopColor="#ee5253" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <Area
              connectNulls
              name={label}
              type="step"
              fillOpacity={1}
              stackId="a"
              dataKey="cons"
              fill={`url(#consumptions-fill-${featureID})`}
            />
            <Area
              connectNulls
              name={label}
              type="step"
              fillOpacity={1}
              stackId="a"
              dataKey="over"
              fill={`url(#overages-fill-${featureID})`}
            />
            {!allowUnlimited && (
              <ReferenceLine
                y={maxConsumption}
                stroke="#13b0fc"
                strokeDasharray="3 3"
              >
                <Label
                  value={__('Max consumptions')}
                  position="insideTopRight"
                />
              </ReferenceLine>
            )}
            {showOverageLimit && !allowUnlimited && (
              <ReferenceLine
                y={overageLimit}
                stroke="#ee5253"
                strokeDasharray="3 3"
              >
                <Label
                  value={__('Max overage limit')}
                  position="insideTopRight"
                />
              </ReferenceLine>
            )}
            {formattedData
              .filter(fd => fd.action === 'consumption_reset')
              .map(fd => (
                <ReferenceLine
                  x={fd.ts}
                  label=""
                  stroke="#419a848a"
                  strokeWidth={5}
                  key={fd.ts}
                >
                  <Label value={__('Reset')} position="top" />
                </ReferenceLine>
              ))}
            <YAxis
              stroke="#07364c"
              tickFormatter={v => abbreviateNumber(v, 1)}
              tick={{ fontSize: 10 }}
              // domain={['dataMin', 'dataMax']}
              domain={[0, showOverageLimit ? overageLimit : 'dataMax']}
            />
            <XAxis
              stroke="#07364c"
              dataKey="ts"
              type="number"
              domain={['dataMin', 'dataMax']}
              interval="preserveStartEnd"
              scale="time"
              tickFormatter={ts =>
                isDaily
                  ? moment(ts).format('MM/DD/YYYY')
                  : moment(ts).format('MM/DD/YYYY HH:mm')
              }
              tick={{ fontSize: 10 }}
            />
            <Tooltip
              content={
                <ChartTooltip
                  isDaily={isDaily}
                  tooltipLabel={label}
                  isConsumption
                />
              }
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      {showEventsList && (
        <FeatureConsumptionEventsList
          companyID={companyID}
          closeCb={() => setEventsListDisplay(false)}
          title={title}
          subtitle={subtitle}
          isOpen={showEventsList}
          params={getEndpointParams()}
        />
      )}
    </div>
  );
};

FeatureConsumptionChart.propTypes = {
  companyID: PropTypes.number.isRequired,
  feature: PropTypes.object.isRequired,
  dateFrom: PropTypes.object.isRequired,
  dateTo: PropTypes.object.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  params: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

FeatureConsumptionChart.defaultProps = {
  height: 300,
  label: '',
  params: '',
  title: '',
  subtitle: '',
  resetPeriod: null
};

export default FeatureConsumptionChart;
