import React from 'react';
import PropTypes from 'prop-types';
import { DayPicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './styles.scss';

const DatePicker = ({ dateFormat, selectDate, ...rest }) => (
  <div className="DatePicker">
    <DayPicker
      hideKeyboardShortcutsPanel
      numberOfMonths={1}
      onDayClick={val => selectDate(val.format(dateFormat))}
      {...rest}
    />
  </div>
);

DatePicker.propTypes = {
  dateFormat: PropTypes.string,
  selectDate: PropTypes.func.isRequired
};

DatePicker.defaultProps = {
  dateFormat: 'YYYY-MM-DD'
};

export default DatePicker;
