import React, { Component } from 'react';
import { DateRangePicker as Picker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { Button, Dropdown, IconCalendar } from 'shared/components';
import PropTypes from 'prop-types';
import { preselect, displayFormat } from './config';
import './styles.scss';
import { displayDate, calculateNewFormat } from './helpers';

class DateRangePicker extends Component {
  constructor(props) {
    super(props);

    const { initialStartDate, initialEndDate } = this.props;

    this.state = {
      startDate: initialStartDate || null,
      customStartDate: initialStartDate || null,
      endDate: initialEndDate || null,
      customEndDate: initialEndDate || null,
      focusedInput: 'startDate',
      showDatePicker: false,
      showOptions: false,
      timeRangeText: '',
      activePreselect: ''
    };

    this.optionsRef = React.createRef();
  }

  onFocusChange = focusedInput => {
    if (!focusedInput) return;
    this.setState({ focusedInput });
  };

  setToday = () => {
    this.setState(
      {
        startDate: moment(),
        customStartDate: moment(),
        endDate: moment(),
        customEndDate: moment(),
        timeRangeText: __('Today'),
        showDatePicker: false,
        showOptions: false,
        activePreselect: preselect.today
      },
      () => {
        const { startDate, endDate } = this.state;
        const { handleChange } = this.props;
        handleChange([startDate, endDate]);
      }
    );
  };

  setYesterday = () => {
    this.setState(
      {
        startDate: moment().subtract(1, 'days'),
        customStartDate: moment().subtract(1, 'days'),
        endDate: moment().subtract(1, 'days'),
        customEndDate: moment().subtract(1, 'days'),
        timeRangeText: __('Yesterday'),
        showDatePicker: false,
        showOptions: false,
        activePreselect: preselect.yesterday
      },
      () => {
        const { startDate, endDate } = this.state;
        const { handleChange } = this.props;
        handleChange([startDate, endDate]);
      }
    );
  };

  setLastWeek = () => {
    this.setState(
      {
        startDate: moment()
          .startOf('week')
          .subtract(7, 'days'),
        customStartDate: moment()
          .startOf('week')
          .subtract(7, 'days'),
        endDate: moment()
          .endOf('week')
          .subtract(1, 'weeks'),
        customEndDate: moment()
          .endOf('week')
          .subtract(1, 'weeks'),
        timeRangeText: __('Previous Week'),
        showDatePicker: false,
        showOptions: false,
        activePreselect: preselect.last_week
      },
      () => {
        const { startDate, endDate } = this.state;
        const { handleChange } = this.props;
        handleChange([startDate, endDate]);
      }
    );
  };

  setLast7Days = () => {
    this.setState(
      {
        startDate: moment().subtract(7, 'days'),
        customStartDate: moment().subtract(7, 'days'),
        endDate: moment(),
        customEndDate: moment(),
        timeRangeText: __('Last 7 Days'),
        showDatePicker: false,
        showOptions: false,
        activePreselect: preselect.last_7_days
      },
      () => {
        const { startDate, endDate } = this.state;
        const { handleChange } = this.props;
        handleChange([startDate, endDate]);
      }
    );
  };

  setThisWeek = () => {
    this.setState(
      {
        startDate: moment().startOf('week'),
        customStartDate: moment().startOf('week'),
        endDate: moment().endOf('week'),
        customEndDate: moment().endOf('week'),
        timeRangeText: __('This Week'),
        showDatePicker: false,
        showOptions: false,
        activePreselect: preselect.this_week
      },
      () => {
        const { startDate, endDate } = this.state;
        const { handleChange } = this.props;
        handleChange([startDate, endDate]);
      }
    );
  };

  setLastMonth = () => {
    this.setState(
      {
        startDate: moment()
          .subtract(1, 'month')
          .startOf('month'),
        customStartDate: moment()
          .subtract(1, 'month')
          .startOf('month'),
        endDate: moment()
          .subtract(1, 'month')
          .endOf('month'),
        customEndDate: moment()
          .subtract(1, 'month')
          .endOf('month'),
        timeRangeText: __('Previous Month'),
        showDatePicker: false,
        showOptions: false,
        activePreselect: preselect.last_month
      },
      () => {
        const { startDate, endDate } = this.state;
        const { handleChange } = this.props;
        handleChange([startDate, endDate]);
      }
    );
  };

  setLast30Days = () => {
    this.setState(
      {
        startDate: moment().subtract(30, 'days'),
        customStartDate: moment().subtract(30, 'days'),
        endDate: moment(),
        customEndDate: moment(),
        timeRangeText: __('Last 30 Days'),
        showDatePicker: false,
        showOptions: false,
        activePreselect: preselect.last_30_days
      },
      () => {
        const { startDate, endDate } = this.state;
        const { handleChange } = this.props;
        handleChange([startDate, endDate]);
      }
    );
  };

  setThisMonth = () => {
    this.setState(
      {
        startDate: moment().startOf('month'),
        customStartDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
        customEndDate: moment().endOf('month'),
        timeRangeText: __('This Month'),
        showDatePicker: false,
        showOptions: false,
        activePreselect: preselect.this_month
      },
      () => {
        const { startDate, endDate } = this.state;
        const { handleChange } = this.props;
        handleChange([startDate, endDate]);
      }
    );
  };

  setAllTime = () => {
    this.setState(
      {
        startDate: moment()
          .subtract(5, 'year')
          .startOf('year'),
        customStartDate: moment()
          .subtract(1, 'year')
          .startOf('year'),
        endDate: moment(),
        customEndDate: moment(),
        timeRangeText: __('All Time'),
        showDatePicker: false,
        showOptions: false,
        activePreselect: preselect.all_time
      },
      () => {
        const { startDate, endDate } = this.state;
        const { handleChange } = this.props;
        handleChange([startDate, endDate]);
      }
    );
  };

  setCustomTime = ({ startDate, endDate }) => {
    this.setState({
      customStartDate: startDate,
      customEndDate: endDate
    });
  };

  handleCustomTimeConfirm = () => {
    const {
      customStartDate,
      customEndDate,
      showDatePicker,
      showOptions
    } = this.state;
    const { handleChange } = this.props;
    this.setState(
      {
        timeRangeText: '',
        showDatePicker: !showDatePicker,
        showOptions: !showOptions,
        startDate: customStartDate,
        endDate: customEndDate,
        activePreselect: preselect.custom
      },
      () => {
        handleChange([customStartDate, customEndDate]);
      }
    );
  };

  showOptions = () => {
    this.setState({
      showOptions: true
    });
  };

  showDatePicker = () => {
    const { startDate, endDate, showDatePicker } = this.state;
    this.setState({
      showDatePicker: !showDatePicker,
      customStartDate: startDate,
      customEndDate: endDate
    });
  };

  closeAll = () => {
    this.setState({
      customStartDate: null,
      showDatePicker: false,
      showOptions: false,
      customEndDate: null,
      focusedInput: 'startDate'
    });
  };

  displayStartDate = () => {
    const { startDate, endDate } = this.state;
    const { initialStartDate } = this.props;
    const newFormat = calculateNewFormat(startDate, endDate, displayFormat);
    return displayDate(startDate, initialStartDate, newFormat);
  };

  displayEndDate = () => {
    const { endDate } = this.state;
    const { initialEndDate } = this.props;
    return displayDate(endDate, initialEndDate, displayFormat);
  };

  render() {
    const {
      startDate,
      customStartDate,
      endDate,
      customEndDate,
      focusedInput,
      showDatePicker,
      showOptions,
      timeRangeText,
      activePreselect
    } = this.state;
    const { initialStartDate, initialEndDate, disabled, ...rest } = this.props;
    const { handleChange, ...restProps } = rest;
    const display = showDatePicker ? 'block' : 'none';
    const displayOptions = showOptions
      ? { display: 'flex', flexDirection: 'column' }
      : { display: 'none' };

    return (
      <div className={`DatePicker ${disabled ? 'disabled' : ''}`}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className="date-show-cont"
          onClick={this.showOptions}
          role="button"
          tabIndex="0"
        >
          <IconCalendar />
          <div className="date-show">
            {timeRangeText ? (
              <div>{timeRangeText}</div>
            ) : (
              <div>
                <span>
                  {/* startDate is required for Picker, so using it here to suppress eslint */}
                  {this.displayStartDate() || !!startDate}
                </span>
                <span className="divider">-</span>
                <span>
                  {/* same with endDate */}
                  {this.displayEndDate() || !!endDate}
                </span>
              </div>
            )}
          </div>
        </div>
        {showOptions && (
          <Dropdown ref={this.optionsRef} close={this.closeAll}>
            <div className="dropdown-inner">
              <div className="date-options-cont" style={displayOptions}>
                <button
                  className={
                    activePreselect === preselect.today && !showDatePicker
                      ? 'active'
                      : ''
                  }
                  type="button"
                  onClick={this.setToday}
                >
                  {__('Today')}
                </button>
                <button
                  className={
                    activePreselect === preselect.yesterday && !showDatePicker
                      ? 'active'
                      : ''
                  }
                  type="button"
                  onClick={this.setYesterday}
                >
                  {__('Yesterday')}
                </button>
                <button
                  className={
                    activePreselect === preselect.this_week && !showDatePicker
                      ? 'active'
                      : ''
                  }
                  type="button"
                  onClick={this.setThisWeek}
                >
                  {__('This Week')}
                </button>
                <button
                  className={
                    activePreselect === preselect.last_week && !showDatePicker
                      ? 'active'
                      : ''
                  }
                  type="button"
                  onClick={this.setLastWeek}
                >
                  {__('Previous Week')}
                </button>
                <button
                  className={
                    activePreselect === preselect.last_7_days && !showDatePicker
                      ? 'active'
                      : ''
                  }
                  type="button"
                  onClick={this.setLast7Days}
                >
                  {__('Last 7 Days')}
                </button>
                <button
                  className={
                    activePreselect === preselect.last_month && !showDatePicker
                      ? 'active'
                      : ''
                  }
                  type="button"
                  onClick={this.setLastMonth}
                >
                  {__('Previous Month')}
                </button>
                <button
                  className={
                    activePreselect === preselect.last_30_days &&
                    !showDatePicker
                      ? 'active'
                      : ''
                  }
                  type="button"
                  onClick={this.setLast30Days}
                >
                  {__('Last 30 Days')}
                </button>
                <button
                  className={
                    activePreselect === preselect.this_month && !showDatePicker
                      ? 'active'
                      : ''
                  }
                  type="button"
                  onClick={this.setThisMonth}
                >
                  {__('This Month')}
                </button>
                <button
                  className={
                    activePreselect === preselect.all_time && !showDatePicker
                      ? 'active'
                      : ''
                  }
                  type="button"
                  onClick={this.setAllTime}
                >
                  {__('All Time')}
                </button>
                <button
                  className={
                    showDatePicker || activePreselect === preselect.custom
                      ? 'active'
                      : ''
                  }
                  type="button"
                  onClick={this.showDatePicker}
                >
                  {__('Custom')}
                </button>
              </div>
              <div className="date-picker-cont" style={{ display }}>
                <Picker
                  startDate={customStartDate}
                  startDateId="startDateId"
                  endDate={customEndDate}
                  endDateId="endDateId"
                  onDatesChange={this.setCustomTime}
                  focusedInput={focusedInput}
                  onFocusChange={this.onFocusChange}
                  enableOutsideDays
                  isOutsideRange={() => false}
                  {...restProps}
                  keepOpenOnDateSelect
                />
                <Button theme="success" onClick={this.handleCustomTimeConfirm}>
                  {__('Confirm')}
                </Button>
              </div>
            </div>
          </Dropdown>
        )}
      </div>
    );
  }
}

DateRangePicker.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  initialStartDate: PropTypes.instanceOf(moment),
  initialEndDate: PropTypes.instanceOf(moment)
};

DateRangePicker.defaultProps = {
  disabled: false,
  initialStartDate: null,
  initialEndDate: null
};

export default DateRangePicker;
