import { get } from 'lodash';
import { displayFeatureList } from 'shared/helpers';

export const mapLicenseFeaturesFromProduct = (features, product) => {
  if (!features || !product) {
    return [];
  }

  const productFeatures = get(product, 'product_features') || [];

  if (Array.isArray(features)) {
    const list = features.map(id => {
      const feat = productFeatures.filter(feature => feature.id === id);
      return get(feat, '[0]');
    });
    return list.filter(Boolean);
  }

  return [];
};

export const mapFeaturesToTable = (
  features,
  productId,
  products,
  fallback = '-'
) => {
  if (!features || !productId || !products) {
    return fallback;
  }

  if (Array.isArray(products)) {
    const cellProduct = products.filter(product => product.id === productId);
    const featureList = mapLicenseFeaturesFromProduct(
      features,
      get(cellProduct, '[0]')
    );

    return displayFeatureList(featureList);
  }

  return fallback;
};

export const transformSort = (sortObject = []) => {
  const formattedSortObject = sortObject.map(sortObj => {
    if (sortObj.id === 'license_key') {
      return {
        id: 'customLicenseSort',
        desc: sortObj.desc
      };
    }
    if (sortObj.id === 'customer.labels') {
      return {
        id: 'customer__clabels_all',
        desc: sortObj.desc
      };
    }
    if (sortObj.id === 'customer.customer_account.name') {
      return {
        id: 'customer__account__name',
        desc: sortObj.desc
      };
    }
    return sortObj;
  });

  const fieldsToTransform = [
    'customer.email',
    'customer.company_name',
    'customer.last_name',
    'customer.reference',
    'product.product_name',
    'product.id'
  ];
  const rewriteMap = {
    product: 'product.product_name'
  };
  return formattedSortObject.map(s => {
    const field = rewriteMap[s.id] || s.id;
    if (fieldsToTransform.indexOf(field) >= 0) {
      return {
        id: field.split('.').join('__'),
        desc: s.desc
      };
    }
    return s;
  });
};

export const transformSearchFilter = searchBy => {
  if (searchBy === 'license_users.true_email') {
    return searchBy.split('.').join('__');
  }
  if (searchBy === 'store_id') {
    return 'order__store_id';
  }
  return searchBy;
};

export const getProductFilter = (productOptions, productID) => {
  if (!productID || productID === 'all') {
    return productOptions[0];
  }
  const product = productOptions.find(p => p.value === productID);
  return product;
};
