import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  ConfirmationPopup,
  IconDelete,
  Notification,
  Page,
  List
} from 'shared/components';
import {
  sendErrorReport,
  displayValue,
  mapCurrencySymbol,
  formatAmountValue,
  capitalizeFirstLetter
} from 'shared/helpers';
import { getCoupons, deleteCoupon } from 'src/billing/actions';
import CouponForm from './CouponForm';
import './styles.scss';

const DiscountSettingsSettings = () => {
  const dispatch = useDispatch();
  const coupons = useSelector(state => get(state, 'billing.coupons'));
  const loadingCoupons = useSelector(state =>
    get(state, 'billing.loadingCoupons')
  );
  const companyID = useSelector(state => get(state, 'company.details.id'));

  const [deletingCoupon, setDeletingCoupon] = useState(false);
  const [isFormDisplayed, setFormDisplay] = useState(false);
  const [couponToDelete, setCouponToDelete] = useState(null);
  const [
    isCouponDeleteConfirmationDisplayed,
    setCouponDeleteConfirmationDisplayed
  ] = useState(false);

  const handleCouponDelete = coupon => {
    const couponId = get(coupon, 'id');
    setDeletingCoupon(true);

    deleteCoupon(couponId, companyID)
      .then(() => {
        dispatch(getCoupons(companyID));
        Notification('success', __('Coupon deleted'));
        setCouponDeleteConfirmationDisplayed(false);
        setCouponToDelete(null);
        setDeletingCoupon(false);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete coupon');
        Notification(
          'error',
          __('Error occured'),
          __('We could not delete the coupon.')
        );
        setDeletingCoupon(false);
      });
  };

  return (
    <Page title={__('Coupons')}>
      <div className="DiscountSettingsSettings">
        <div className="DiscountSettingsSettings-actions">
          <Button size="sm" theme="info" onClick={() => setFormDisplay(true)}>
            {__('Create new coupon')}
          </Button>
        </div>
        <List
          columns={[
            {
              accessor: 'name',
              Header: __('Name'),
              Cell: cellInfo => displayValue(cellInfo.value)
            },
            {
              accessor: 'duration',
              Header: __('Duration'),
              Cell: cellInfo =>
                displayValue(capitalizeFirstLetter(cellInfo.value))
            },
            {
              accessor: 'duration_in_months',
              Header: __('Duration in months'),
              Cell: cellInfo => displayValue(cellInfo.value)
            },
            {
              accessor: 'percent_off',
              Header: __('Percent off'),
              Cell: cellInfo => {
                const percent = get(cellInfo, 'value', '');
                if (!percent) {
                  return '-';
                }
                return `${percent} %`;
              }
            },
            {
              accessor: 'amount_off',
              Header: __('Amount off'),
              Cell: cellInfo => displayValue(formatAmountValue(cellInfo.value))
            },
            {
              accessor: 'currency',
              Header: __('Currency'),
              Cell: cellInfo => displayValue(mapCurrencySymbol(cellInfo.value))
            },
            {
              Cell: rowData => (
                <Button
                  className="edit-button"
                  onClick={() => {
                    setCouponToDelete(rowData.original);
                    setCouponDeleteConfirmationDisplayed(true);
                  }}
                  type="button"
                >
                  <IconDelete height="16px" width="16px" color="#ee5253" />
                </Button>
              ),
              maxWidth: 50
            }
          ]}
          data={coupons}
          loading={loadingCoupons}
          minRows={2}
          pageSize={20}
          defaultPageSize={10}
        />
      </div>
      {isFormDisplayed && (
        <CouponForm
          title={__('Create new coupon')}
          closeCb={() => setFormDisplay(false)}
          confirmCb={() => setFormDisplay(false)}
          fetchCoupons={() => dispatch(getCoupons(companyID))}
        />
      )}
      {isCouponDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setCouponToDelete(null);
            setCouponDeleteConfirmationDisplayed(false);
          }}
          confirmCb={() => handleCouponDelete(couponToDelete)}
          title={`${__('Are you sure you want to delete coupon:')} ${get(
            couponToDelete,
            'name'
          )}?`}
          confirmText={__('Delete')}
          theme="error"
          warning
          disabled={deletingCoupon}
        />
      )}
    </Page>
  );
};

export default DiscountSettingsSettings;
