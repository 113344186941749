import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { sendErrorReport, parseMetadata } from 'shared/helpers';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
  TextArea,
  PhoneNumberInput
} from 'shared/components';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
  validateCustomerAccountCode,
  debouncedValidateCustomerAccountCode,
  validateEmail,
  validateJSON,
  debouncedValidateJSON
} from 'shared/validation';
import {
  updateCustomerAccount,
  createCustomerAccount
} from 'src/customer/actions';
import './styles.scss';

const CustomerAccountForm = ({
  closeCb,
  companyID,
  customerAccount,
  confirmCb
}) => {
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const isEnterprisePlan = get(companyDetails, 'plan_type') === 'enterprise';
  const initialCode = get(customerAccount, 'code');
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [name, setName] = useState({
    value: get(customerAccount, 'name') || '',
    error: ''
  });
  const [code, setCode] = useState({
    value: get(customerAccount, 'code') || '',
    error: '',
    loading: false
  });
  const [description, setDescription] = useState({
    value: get(customerAccount, 'description') || '',
    error: ''
  });
  const [reference, setReference] = useState({
    value: get(customerAccount, 'reference') || '',
    error: ''
  });
  const [email, setEmail] = useState({
    value: get(customerAccount, 'email') || '',
    error: '',
    loading: false
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: get(customerAccount, 'phone') || '',
    error: ''
  });
  const [address, setAddress] = useState({
    value: get(customerAccount, 'address') || '',
    error: ''
  });
  const [metadata, setMetadata] = useState(
    parseMetadata(get(customerAccount, 'metadata'))
  );
  const [metadataError, setMetadataError] = useState('');

  const validateValue = async (val, cb) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(prev => ({ ...prev, error: errors }));
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit customer account form value', {
        value: val
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validateEmailValue = async (val, cb) => {
    // email is not required
    if (!val) {
      return true;
    }
    setLoading(true);
    let errors;
    try {
      errors = await validateEmail(val);
      cb(prev => ({ ...prev, error: errors }));
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit customer account form value', {
        value: val
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validateCode = async (val, cb) => {
    setLoading(true);
    if (initialCode && val === initialCode) {
      return true;
    }
    let errors;
    try {
      errors = await validateCustomerAccountCode(val, companyID);
      cb(prev => ({ ...prev, error: errors }));
    } catch (err) {
      sendErrorReport(err, 'Cannot validate customer account code', {
        value: val
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validateMetadata = async val => {
    setLoading(true);
    let errors;
    try {
      errors = await validateJSON(metadata);
      setMetadataError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit customer form value', {
        value: val
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validateFormData = async () => {
    const isNameValid = await validateValue(name.value, setName);
    const isCodeValid = await validateCode(code.value, setCode);
    const isEmailValid = await validateEmailValue(email.value, setEmail);
    const metadataValid = await validateMetadata();

    return isNameValid && isCodeValid && isEmailValid && metadataValid;
  };

  const isFormValid = async () => {
    const isFormDataValid = await validateFormData();
    return isFormDataValid;
  };

  const editCustomerAccount = (customerAccountID, data) => {
    updateCustomerAccount(companyID, customerAccountID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        confirmCb();
        closeCb();
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot edit customer company details', data);
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const addCustomerAccount = data => {
    const dataWithCompany = { ...data, company: companyID };
    createCustomerAccount(companyID, dataWithCompany)
      .then(res => {
        const id = get(res, 'data.id');
        Notification('success', __('Changes saved successfully'));
        history.push(`/${companyID}/customers/accounts/${id}`);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot add customer account', data);
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const getCode = () => {
    if (!initialCode) {
      return get(code, 'value');
    }

    if (initialCode && get(code, 'value') !== initialCode) {
      return get(code, 'value');
    }

    return undefined;
  };

  const handleSubmit = async () => {
    if (!isDirty) {
      closeCb();
      return false;
    }
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setLoading(true);
    const customerID = get(customerAccount, 'id');
    const data = {
      name: get(name, 'value'),
      code: getCode(),
      reference: get(reference, 'value'),
      description: get(description, 'value'),
      email: get(email, 'value'),
      phone: get(phoneNumber, 'value'),
      address: get(address, 'value'),
      metadata: metadata ? JSON.parse(metadata) : {}
    };

    if (customerAccount) {
      editCustomerAccount(customerID, data);
    } else {
      addCustomerAccount(data);
    }
    return true;
  };

  const handleClose = () => {
    if (!isDirty) {
      return closeCb();
    }
    return setDirtyFormAlertDisplay(true);
  };

  const title = customerAccount
    ? `${__('Edit customer account')} - ${get(customerAccount, 'name')}`
    : __('Add customer account');

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading || email.loading}
      title={title}
      size="md"
    >
      <div className="CustomerAccountForm">
        <form onSubmit={handleSubmit}>
          <div className="form-inner">
            <div className="left">
              <div>
                <Label text={__('Name')} inputId="customer-comp-name" />
                <TextInput
                  id="customer-comp-firstname"
                  value={get(name, 'value')}
                  error={get(name, 'error')}
                  handleChange={val => {
                    setDirty(true);
                    setName(prev => ({ ...prev, value: val }));
                    debouncedValidateRequiredValue(val).then(err =>
                      setName(prev => ({ ...prev, error: err }))
                    );
                  }}
                />
              </div>
              <div className="form-column right-input">
                <Label text={__('Code')} inputId="code" />
                <TextInput
                  id="code"
                  value={get(code, 'value')}
                  error={get(code, 'error')}
                  loading={get(code, 'loading')}
                  disabled={!!initialCode}
                  handleChange={val => {
                    setDirty(true);
                    setCode(prev => ({ ...prev, value: val, loading: true }));
                    if (initialCode && val !== initialCode) {
                      debouncedValidateCustomerAccountCode(val, companyID).then(
                        err =>
                          setCode(prev => ({
                            ...prev,
                            error: err,
                            loading: false
                          }))
                      );
                    } else {
                      setCode(prev => ({ ...prev, loading: false }));
                    }
                  }}
                  placeholder={__('Eg. QQ')}
                />
              </div>
              <div>
                <Label text={__('Email')} inputId="customer-comp-email" />
                <TextInput
                  id="customer-comp-email"
                  value={get(email, 'value')}
                  error={get(email, 'error')}
                  loading={get(email, 'loading')}
                  handleChange={val => {
                    setDirty(true);
                    setEmail(prev => ({ ...prev, value: val, loading: false }));
                  }}
                />
              </div>
              <div>
                <Label
                  text={__('Reference')}
                  inputId="customer-comp-reference"
                />
                <TextInput
                  id="customer-comp-reference"
                  value={get(reference, 'value')}
                  error={get(reference, 'error')}
                  handleChange={val => {
                    setDirty(true);
                    setReference(prev => ({ ...prev, value: val }));
                  }}
                />
              </div>
            </div>
            <div className="divider active" />
            <div className="right">
              <div>
                <Label text={__('Address')} inputId="customer-comp-address" />
                <TextInput
                  id="customer-comp-address"
                  value={get(address, 'value')}
                  error={get(address, 'error')}
                  handleChange={val => {
                    setDirty(true);
                    setAddress(prev => ({ ...prev, value: val }));
                  }}
                />
              </div>
              <div>
                <Label
                  text={__('Phone Number')}
                  inputId="customer-comp-phonenumber"
                />
                <PhoneNumberInput
                  id="customer-comp-phonenumber"
                  value={get(phoneNumber, 'value')}
                  error={get(phoneNumber, 'error')}
                  handleChange={val => {
                    setDirty(true);
                    setPhoneNumber(prev => ({ ...prev, value: val }));
                  }}
                />
              </div>
              <div>
                <Label
                  text={__('Description')}
                  inputId="customer-comp-description"
                />
                <TextArea
                  id="customer-comp-description"
                  rows="4"
                  value={get(description, 'value')}
                  error={get(description, 'error')}
                  handleChange={val => {
                    setDirty(true);
                    setDescription(prev => ({ ...prev, value: val }));
                  }}
                />
              </div>
              <div>
                <Label inputId="metadata-input" text={__('Metadata JSON')} />
                <TextArea
                  handleChange={val => {
                    setDirty(true);
                    setMetadata(val);
                    debouncedValidateJSON(val).then(err =>
                      setMetadataError(err)
                    );
                  }}
                  id="metadata-input"
                  type="metadata"
                  value={metadata}
                  error={metadataError}
                  rows="4"
                  disabled={!isEnterprisePlan}
                />
              </div>
            </div>
          </div>
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={isDirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </Modal>
  );
};

CustomerAccountForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  customerAccount: PropTypes.object,
  confirmCb: PropTypes.func.isRequired
};

CustomerAccountForm.defaultProps = {
  customerAccount: null
};

export default CustomerAccountForm;
