import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { getUserFromStorage } from 'src/keycloak/authUser';
import { useAuth } from 'react-oidc-context';
import { sendErrorReport } from 'shared/helpers';
import {
  Button,
  Label,
  Notification,
  Page,
  TextInput
} from 'shared/components';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue
} from 'shared/validation';
import { updateUserData } from 'src/user/actions';

const EditProfileContainer = () => {
  const auth = useAuth();
  const companies = useSelector(state => get(state, 'account.companies'));
  const firstCompanyID = get(companies, '[0].id');

  const user = getUserFromStorage();
  const userId = get(user, 'profile.platform_user_id');

  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(
    get(user, 'profile.given_name') || ''
  );
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState(
    get(user, 'profile.family_name') || ''
  );
  const [lastNameError, setLastNameError] = useState('');

  const validateValue = async (val, cb) => {
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate 2FA passcode');
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const isFormValid = async () => {
    const isFirstNameValid = validateValue(firstName, setFirstNameError);
    const isLastNameValid = validateValue(lastName, setLastNameError);
    return isFirstNameValid && isLastNameValid;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || loading) {
      return false;
    }

    setLoading(true);
    const userData = { first_name: firstName, last_name: lastName };

    updateUserData(userId, userData, firstCompanyID)
      .then(() => {
        setLoading(false);
        setDirty(false);
        auth.signinSilent();
        Notification('success', __('Profile updated'));
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot edit user profile', userData);
        setLoading(false);
        Notification(
          'fail',
          __('Error occured'),
          __('Your changes were not saved')
        );
      });
    return true;
  };

  return (
    <Page title={__('Edit profile')}>
      <Prompt
        message={__(
          'By leaving this form you will lose all progress. Are you sure you want to leave?'
        )}
        when={dirty}
      />
      <form onSubmit={handleSubmit}>
        <Label inputId="firstname" text={__('First Name')} />
        <TextInput
          id="firstname"
          handleChange={val => {
            setDirty(true);
            setFirstName(val);
            debouncedValidateRequiredValue(val).then(err =>
              setFirstNameError(err)
            );
          }}
          value={firstName}
          error={firstNameError}
          disabled={loading}
        />
        <Label inputId="lastname" text={__('Last Name')} />
        <TextInput
          id="lastname"
          handleChange={val => {
            setDirty(true);
            setLastName(val);
            debouncedValidateRequiredValue(val).then(err =>
              setLastNameError(err)
            );
          }}
          value={lastName}
          error={lastNameError}
          disabled={loading}
        />
        <Button theme="success" type="submit" disabled={loading}>
          {__('Confirm change')}
        </Button>
      </form>
    </Page>
  );
};

export default EditProfileContainer;
