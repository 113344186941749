import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { displayValue, sendErrorReport } from 'shared/helpers';
import {
  Checkbox,
  DescriptionTable,
  ConfirmationPopup,
  Notification,
  Label,
  NumberInput
} from 'shared/components';
import { addLicenseUser } from 'src/license/actions';
import { addOrderManager, removeOrderManager } from 'src/order/actions';
import { validateRequiredNumber } from 'shared/validation';
import './styles.scss';

const ExistingUserForm = ({
  closeCb,
  confirmCb,
  existingUser,
  licenseID,
  companyID,
  orderID,
  initialMaxActivations,
  initalIsLicenseManager
}) => {
  const initialManagerValue =
    get(existingUser, 'is_license_manager') || initalIsLicenseManager;
  const existingUserID = get(existingUser, 'id');
  const existingUserEmail = get(existingUser, 'true_email');

  const [loading, setLoading] = useState(false);
  const [isLicenseManager, setLicenseManager] = useState(initialManagerValue);
  const [maxActivations, setMaxActivations] = useState(
    initialMaxActivations || 1
  );
  const [maxActivationsError, setMaxActivationsError] = useState('');

  const validateMaxActivations = async val => {
    setLoading(true);
    let errors;

    try {
      errors = await validateRequiredNumber(val);
      setLoading(false);
      setMaxActivationsError(errors);
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate max activations', { value: val });
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const handleMaxActivationsChange = val => {
    setMaxActivations(val);
    validateMaxActivations(val);
  };

  const isFormValid = async () => {
    const isMaxActivationsValid = await validateMaxActivations(maxActivations);
    return isMaxActivationsValid;
  };

  const setAsManager = () => {
    addOrderManager(orderID, companyID, { email: existingUserEmail })
      .then(() => confirmCb())
      .catch(err => {
        sendErrorReport(err, 'Cannot set license user as manager', {
          value: existingUserEmail
        });
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const removeAsManager = () => {
    removeOrderManager(orderID, companyID, existingUserID)
      .then(() => confirmCb())
      .catch(err => {
        sendErrorReport(err, 'Cannot remove license user as manager', {
          value: existingUserEmail
        });
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const changeManagerStatusIfNeeded = () => {
    if (initialManagerValue === isLicenseManager) {
      confirmCb();
      return true;
    }
    if (isLicenseManager) {
      setAsManager();
    } else {
      removeAsManager();
    }
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || loading) {
      return false;
    }

    setLoading(true);

    const data = {
      email: existingUserEmail,
      max_activations: Number(maxActivations) || 1
    };

    addLicenseUser(licenseID, data, companyID)
      .then(() => changeManagerStatusIfNeeded())
      .catch(err => {
        sendErrorReport(err, 'Cannot add license user', data);
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
    return true;
  };

  return (
    <ConfirmationPopup
      confirmCb={handleSubmit}
      closeCb={closeCb}
      disabled={loading}
      title={__('User with this email already exist')}
      warning
    >
      <div className="ExistingUserForm">
        <div className="ExistingUserForm-message">
          {__('Do you want to assign the license to existing user?')}
        </div>
        <DescriptionTable
          details={[
            {
              label: __('Email'),
              value: displayValue(existingUserEmail)
            },
            {
              label: __('First name'),
              value: displayValue(get(existingUser, 'first_name'))
            },
            {
              label: __('Last name'),
              value: displayValue(get(existingUser, 'last_name'))
            },
            {
              label: __('Phone number'),
              value: displayValue(get(existingUser, 'phone_number'))
            }
          ]}
        />
        <div className="section-row">
          <Label text={__('Max Activations')} inputId="max-activations-input" />
          <NumberInput
            handleChange={handleMaxActivationsChange}
            value={maxActivations}
            error={maxActivationsError}
            min="1"
            max="2147483647"
            id="max-activations-input"
          />
        </div>
        <div className="license-manager-input">
          <Checkbox
            label={__('Is license manager')}
            inputId="license-manager"
            checked={isLicenseManager}
            disabled={loading}
            handleChange={val => setLicenseManager(val)}
          />
        </div>
      </div>
    </ConfirmationPopup>
  );
};

ExistingUserForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  confirmCb: PropTypes.func.isRequired,
  existingUser: PropTypes.object.isRequired,
  licenseID: PropTypes.number.isRequired,
  orderID: PropTypes.number.isRequired,
  initialMaxActivations: PropTypes.number.isRequired,
  initalIsLicenseManager: PropTypes.bool.isRequired
};

export default ExistingUserForm;
