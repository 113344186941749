import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  DirtyFormAlert,
  Label,
  Modal,
  TextInput,
  Notification,
  NumberInput,
  Selector
} from 'shared/components';
import { currencyOptions } from 'shared/constants';
import { sendErrorReport, getLSEnterpriseProductId } from 'shared/helpers';
import { createPricingPlan } from 'src/billing/actions';
import './styles.scss';

const PricingPlanForm = ({ closeCb, confirmCb, fetchPricingPlans, title }) => {
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const stripeIntervalOptions = [
    { label: __('Monthly'), value: 'month' },
    { label: __('Yearly'), value: 'year' }
  ];
  const productId = getLSEnterpriseProductId();

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState('');
  const [currency, setCurrency] = useState(get(currencyOptions, '[0].value'));
  const [interval, setInterval] = useState(
    get(stripeIntervalOptions, '[0].value')
  );

  const validatePlanName = val => {
    if (!val) {
      setNameError(__('This field is required'));
      return false;
    }
    setNameError('');
    return true;
  };

  const validatePlanAmount = val => {
    if (!val) {
      setAmountError(__('This field is required'));
      return false;
    }
    setAmountError('');
    return true;
  };

  const handleNameChange = val => {
    setDirty(true);
    setName(val);
    validatePlanName(val);
  };

  const handleAmountChange = val => {
    setDirty(true);
    setAmount(val);
    validatePlanAmount(val);
  };

  const handleCurrencyChange = val => {
    setDirty(true);
    setCurrency(val);
  };

  const handleIntervalChange = val => {
    setDirty(true);
    setInterval(val);
  };

  const isFormValid = () => {
    const isNameValid = validatePlanName(name);
    const isAmountValid = validatePlanAmount(amount);
    return isNameValid && isAmountValid;
  };

  const handleSubmit = () => {
    if (!isFormValid() || loading) {
      return false;
    }

    setLoading(true);
    const data = {
      nickname: name,
      amount: Number(amount) * 100,
      interval,
      currency,
      product: productId
    };

    createPricingPlan(data, companyID)
      .then(() => {
        fetchPricingPlans();
        Notification('success', __('Pricing plan created'));
        confirmCb();
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot create pricing plan', data);
        Notification(
          'error',
          __('Error occured'),
          __('We could not create the pricing plan.')
        );
        setLoading(false);
      });
    return true;
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return setDirtyFormAlertDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={title}
      disabled={loading}
      size="sm"
    >
      <div className="PricingPlanForm">
        <form className="PricingPlanForm-form" onSubmit={handleSubmit}>
          <div>
            <Label text={__('Name')} inputId="name" />
            <TextInput
              id="name"
              value={name}
              error={nameError}
              handleChange={handleNameChange}
              disabled={loading}
            />
          </div>
          <div>
            <Label text={__('Amount')} inputId="amount" />
            <NumberInput
              id="amount"
              value={amount}
              error={amountError}
              handleChange={handleAmountChange}
              min="1"
              max="10000"
              disabled={loading}
            />
          </div>
          <div>
            <Label text={__('Currency')} inputId="currency" />
            <Selector
              options={currencyOptions}
              value={currency}
              isDisabled={loading}
              handleChange={handleCurrencyChange}
            />
          </div>
          <div>
            <Label text={__('Billing cycle')} inputId="interval" />
            <Selector
              options={stripeIntervalOptions}
              value={interval}
              isDisabled={loading}
              handleChange={handleIntervalChange}
            />
          </div>
        </form>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

PricingPlanForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  fetchPricingPlans: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

PricingPlanForm.defaultProps = {
  pricingPlanToEdit: null
};

export default PricingPlanForm;
