import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Button,
  DescriptionTable,
  Notification,
  PermissionMissingNotificationTitle,
  ContentLoader
} from 'shared/components';
import { userPermissions } from 'shared/constants';
import {
  displayValue,
  getCustomerTaxOptions,
  checkUserPermission,
  sendErrorReport
} from 'shared/helpers';
import { getCompanyCustomerDetails } from 'src/company/actions';
import BillingDetailsForm from '../BillingDetailsForm';
import TaxDetailsForm from '../TaxDetailsForm';
import './styles.scss';

const BillingDetails = ({ companyDetails }) => {
  const canManageBilling = checkUserPermission(userPermissions.billing_write);
  const companyID = get(companyDetails, 'id');

  const [isLoading, setLoading] = useState(true);
  const [
    isBillingDetailsFormDisplayed,
    setBillingDetailsFormDisplay
  ] = useState(false);
  const [isTaxDetailsFormDisplayed, setTaxDetailsFormDisplay] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(null);

  const refreshCustomerDetails = useCallback(() => {
    getCompanyCustomerDetails(companyID)
      .then(res => {
        const details = get(res, 'data');
        setCustomerDetails(details);
        setLoading(false);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot get customer details');
        setLoading(false);
      });
  }, [companyID]);

  const handleManageBillingClick = cb => {
    if (!canManageBilling) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle
          permission={userPermissions.billing_write}
        />,
        __('Contact you account admin for support.')
      );
      return false;
    }
    cb(true);
    return true;
  };

  useEffect(() => {
    refreshCustomerDetails();
  }, [refreshCustomerDetails]);

  if (isLoading) {
    return (
      <div className="BillingDetails">
        <h2>{__('Billing details')}</h2>
        <ContentLoader />
      </div>
    );
  }

  const customerTaxes = getCustomerTaxOptions(
    get(customerDetails, 'taxes') || []
  );

  return (
    <div className="BillingDetails">
      <h2>{__('Billing details')}</h2>
      <Button
        size="sm"
        theme="info"
        onClick={() => handleManageBillingClick(setBillingDetailsFormDisplay)}
      >
        {__('Change billing details')}
      </Button>
      <DescriptionTable
        details={[
          {
            label: __('Name'),
            value: displayValue(get(customerDetails, 'name'))
          },
          {
            label: __('Email'),
            value: displayValue(get(customerDetails, 'email'))
          },
          {
            label: __('Description'),
            value: displayValue(get(customerDetails, 'description'))
          },
          {
            label: __('Address line 1'),
            value: displayValue(get(customerDetails, 'address.line1'))
          },
          {
            label: __('Address line 2'),
            value: displayValue(get(customerDetails, 'address.line2'))
          },
          {
            label: __('Postal code'),
            value: displayValue(get(customerDetails, 'address.postal_code'))
          },
          {
            label: __('City'),
            value: displayValue(get(customerDetails, 'address.city'))
          },
          {
            label: __('State'),
            value: displayValue(get(customerDetails, 'address.state'))
          },
          {
            label: __('Country'),
            value: displayValue(get(customerDetails, 'address.country'))
          }
        ]}
      />
      <h2>{__('Tax details')}</h2>
      <Button
        size="sm"
        theme="info"
        onClick={() => handleManageBillingClick(setTaxDetailsFormDisplay)}
      >
        {__('Change tax details')}
      </Button>
      <DescriptionTable
        details={customerTaxes.map(ct => ({
          label: get(ct, 'label'),
          value: get(ct, 'number')
        }))}
      />
      {isBillingDetailsFormDisplayed && (
        <BillingDetailsForm
          companyID={get(companyDetails, 'id')}
          customerDetails={customerDetails}
          closeCb={() => setBillingDetailsFormDisplay(false)}
          refreshCustomer={refreshCustomerDetails}
        />
      )}
      {isTaxDetailsFormDisplayed && (
        <TaxDetailsForm
          companyID={get(companyDetails, 'id')}
          customerDetails={customerDetails}
          closeCb={() => setTaxDetailsFormDisplay(false)}
        />
      )}
    </div>
  );
};

BillingDetails.propTypes = {
  companyDetails: PropTypes.object.isRequired
};

export default BillingDetails;
