import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { sendErrorReport, parseMetadata } from 'shared/helpers';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextArea
} from 'shared/components';
import { validateJSON, debouncedValidateJSON } from 'shared/validation';
import { patchLicense } from 'src/license/actions';
import './styles.scss';

const EditBundleLicenseForm = ({ closeCb, license, confirmCb }) => {
  // todo_metadata replace this check with company feature
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const companyID = get(companyDetails, 'id');
  const isEnterprisePlan = get(companyDetails, 'plan_type') === 'enterprise';

  const licenseID = get(license, 'id');

  const [isLoading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [metadata, setMetadata] = useState(
    parseMetadata(get(license, 'metadata'))
  );
  const [metadataError, setMetadataError] = useState('');

  const validateMetadata = async val => {
    setLoading(true);
    let errors;
    try {
      errors = await validateJSON(metadata);
      setMetadataError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit customer form value', {
        value: val
      });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validateFormData = async () => {
    const metadataValid = await validateMetadata();
    return metadataValid;
  };

  const isFormValid = async () => {
    const isFormDataValid = await validateFormData();
    return isFormDataValid;
  };

  const editBundleLicense = data => {
    patchLicense(licenseID, data, companyID)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        confirmCb();
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot edit customer details', data);
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const handleSubmit = async () => {
    if (!dirty) {
      closeCb();
      return false;
    }
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setLoading(true);
    const data = {
      metadata: metadata ? JSON.parse(metadata) : {}
    };

    editBundleLicense(data);
    return true;
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return setDirtyFormAlertDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title={__('Edit bundle license')}
      size="sn"
    >
      <div className="EditBundleLicenseForm">
        <form onSubmit={handleSubmit}>
          <div className="form-inner">
            <div>
              <Label inputId="metadata-input" text={__('Metadata JSON')} />
              <TextArea
                handleChange={val => {
                  setDirty(true);
                  setMetadata(val);
                  debouncedValidateJSON(val).then(err => setMetadataError(err));
                }}
                id="metadata-input"
                type="metadata"
                value={metadata}
                error={metadataError}
                rows="4"
                disabled={!isEnterprisePlan}
              />
            </div>
          </div>
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </Modal>
  );
};

EditBundleLicenseForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  license: PropTypes.object.isRequired,
  confirmCb: PropTypes.func.isRequired
};

export default EditBundleLicenseForm;
