import React from 'react';
import { Subtitle } from 'shared/components';
import { ReactComponent as AccessDeniedImg } from './access_denied.svg';
import './styles.scss';

const MissingPermissions = () => (
  <div className="MissingPermissions">
    <div className="wrapper">
      <div className="MissingPermissions-image">
        <AccessDeniedImg />
      </div>
      <Subtitle text={__('Access Restricted')} />
      <p>
        {__(
          'Your account does not have the required permissions to access this company. Please contact your company admin for access.'
        )}
      </p>
    </div>
  </div>
);

export default MissingPermissions;
