/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { errorMsg } from 'shared/constants';
import {
  sendErrorReport,
  formatEventsToSelector,
  isAllowedEventRecipient,
  hasCustomEventData,
  mapProductsToSelector
} from 'shared/helpers';
import {
  Checkbox,
  CheckboxSelector,
  DirtyFormAlert,
  InputErrorMessage,
  Label,
  Modal,
  MultiEmailInput,
  Notification,
  Selector,
  TextInput
} from 'shared/components';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue
} from 'shared/validation';
import {
  createNotificationPolicy,
  updateNotificationPolicy
} from 'src/notifications/actions';
import './styles.scss';

const getInitialEvent = (policy, events) => {
  const { event } = policy;
  const foundEvent = events.find(e => e.data.code === event.code);
  return foundEvent;
};

const getInitialCustomEmails = notificationPolicy => {
  if (!notificationPolicy) return {};
  return {
    emailError: '',
    value: '',
    emails: notificationPolicy.notify_custom_emails.map(ce => ce.email)
  };
};

const getInitialCompanyUsers = (notificationPolicy, users) => {
  if (!notificationPolicy) return [];
  const list = [];
  notificationPolicy.notify_users.forEach(nu => {
    const user = users.find(u => u.id === nu);
    if (user) {
      list.push({
        value: user.id,
        label: user.email,
        data: user
      });
    }
  });
  return list;
};

const getInitialDataValues = (notificationPolicy, value) => {
  if (!notificationPolicy) return '';
  const { data } = notificationPolicy;

  if (!data[value]) {
    return '';
  }
  return data[value].join(',');
};

const NotificationPolicyForm = ({
  closeCb,
  refetchData,
  companyID,
  notificationPolicy
}) => {
  const products = useSelector(state => get(state, 'products.list'));
  const productOptions = mapProductsToSelector(products, 'id');
  const events = useSelector(state => get(state, 'notifications.events'));
  const completeEventsOptions = formatEventsToSelector(events);
  const eventsOptions = formatEventsToSelector(events);
  const users = useSelector(state => get(state, 'users.list') || []);
  const activeUsers = users.filter(u => u.is_active);
  const companyUserOptions = activeUsers.map(u => ({
    value: u.id,
    label: u.email,
    data: u
  }));

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);

  const getInitialProduct = policy => {
    if (!policy) return null;
    const { product } = policy;
    if (!product) {
      return null;
    }
    const findProduct = products.find(p => p.id === product);
    if (!findProduct) {
      return null;
    }

    return {
      value: findProduct.id,
      label: findProduct.product_name
    };
  };

  const [allowProductSelect, setAllowProductSelect] = useState(
    !!get(notificationPolicy, 'product') || false
  );
  const [selectedProduct, setSelectedProduct] = useState(
    getInitialProduct(notificationPolicy)
  );

  const initialEvent = notificationPolicy
    ? getInitialEvent(notificationPolicy, completeEventsOptions)
    : get(eventsOptions, '[0]');

  // form state
  const [selectedEvent, setSelectedEvent] = useState(initialEvent);
  const [notifyCustomer, setNotifyCustomer] = useState(
    get(notificationPolicy, 'notify_customer') || false
  );
  const [notifyLicenseUsers, setNotifyLicenseUsers] = useState(
    get(notificationPolicy, 'notify_license_users') || false
  );
  const [notifyLicenseManagers, setNotifyLicenseManagers] = useState(
    get(notificationPolicy, 'notify_license_managers') || false
  );
  const [customEmails, setCustomEmails] = useState(
    getInitialCustomEmails(notificationPolicy)
  );
  const [selectedCompanyUsers, setSelectedCompanyUsers] = useState(
    getInitialCompanyUsers(notificationPolicy, users)
  );
  const [checkDays, setCheckDays] = useState(
    getInitialDataValues(notificationPolicy, 'check_days')
  );
  const [checkDaysError, setCheckDaysError] = useState('');
  const [usersLeft, setUsersLeft] = useState(
    getInitialDataValues(notificationPolicy, 'users_left')
  );
  const [usersLeftError, setUsersLeftError] = useState('');
  const [name, setName] = useState(get(notificationPolicy, 'name') || '');
  const [nameError, setNameError] = useState('');

  const handleEventChange = val => {
    setDirty(true);
    const selected = eventsOptions.find(option => option.value === val);
    setNotifyCustomer(get(selected, 'data.notify_customer'));
    setNotifyLicenseUsers(get(selected, 'data.notify_license_users'));
    setNotifyLicenseManagers(get(selected, 'data.notify_license_managers'));
    setSelectedEvent(selected);
  };

  const handleProductSelect = val => {
    const selectedP = products.find(p => get(p, 'id') === val);
    setDirty(true);
    // reset state values
    const newSelectedProduct = {
      label: get(selectedP, 'product_name'),
      value: get(selectedP, 'id')
    };
    setSelectedProduct(newSelectedProduct);
  };

  const handleEmailSubmit = val => {
    setDirty(true);
    setCustomEmails(val);
  };

  const validateValue = async (val, cb) => {
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate payment method form value', {
        value: val
      });
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const validateCustomEmails = () => {
    if (customEmails.value) {
      setCustomEmails({
        ...customEmails,
        validationError: `${get(errorMsg, 'unsubmittedEmail')} (${get(
          customEmails,
          'value'
        )})`
      });
      return false;
    }
    return true;
  };

  const validateCustomData = () => {
    const hasCustomData = hasCustomEventData(selectedEvent);
    if (!hasCustomData) {
      return true;
    }

    const hasCheckDays = hasCustomEventData(selectedEvent, 'check_days');
    if (!hasCheckDays) {
      return true;
    }

    if (hasCheckDays) {
      const checkDaysItems = checkDays.trim().split(',');
      const areValidNumbers = checkDaysItems
        .map(i => Number(i))
        .every(i => typeof i === 'number' && !!i);

      if (areValidNumbers) {
        return true;
      }
      setCheckDaysError(errorMsg.invalidData);
      return false;
    }

    const hasUsersLeft = hasCustomEventData(selectedEvent, 'users_left');
    if (!hasUsersLeft) {
      return true;
    }

    if (hasUsersLeft) {
      const usersLeftItems = usersLeft.trim().split(',');
      const areValidNumbers = usersLeftItems
        .map(i => Number(i))
        .every(i => typeof i === 'number' && !!i);

      if (areValidNumbers) {
        return true;
      }
      setUsersLeftError(errorMsg.invalidData);
      return false;
    }
    return true;
  };

  const isFormValid = async () => {
    const isNameValid = await validateValue(name, setNameError);
    const areCustomEmailsValid = await validateCustomEmails();
    const isCustomDataValid = await validateCustomData();
    return isNameValid && areCustomEmailsValid && isCustomDataValid;
  };

  const createPolicy = async data => {
    const createData = { ...data, is_active: true };
    try {
      await createNotificationPolicy(companyID, createData);
      Notification(
        'success',
        __('Changes saved successfully'),
        __('Notification policy created')
      );
      refetchData();
      closeCb();
    } catch (err) {
      sendErrorReport(err, 'Cannot create notification policy', data);
      setLoading(false);
      Notification(
        'error',
        __('Your changes were not saved'),
        __('There was an error while saving your changes')
      );
    }
  };

  const updatePolicy = async data => {
    const updateData = {
      ...data,
      is_active: get(notificationPolicy, 'is_active')
    };
    const policyID = get(notificationPolicy, 'id');
    try {
      await updateNotificationPolicy(policyID, companyID, updateData);
      Notification(
        'success',
        __('Changes saved successfully'),
        __('Notification policy updated')
      );
      refetchData();
      closeCb();
    } catch (err) {
      sendErrorReport(err, 'Cannot update notification policy', data);
      setLoading(false);
      Notification(
        'error',
        __('Your changes were not saved'),
        __('There was an error while saving your changes')
      );
    }
  };

  const createCustomData = () => {
    const data = {};
    if (
      selectedEvent.data.code === 'license_will_expire' ||
      selectedEvent.data.code === 'trial_will_expire' ||
      selectedEvent.data.code === 'maintenance_will_expire' ||
      selectedEvent.data.code === 'feature_will_expire'
    ) {
      const checkDaysArr = checkDays.split(',').map(i => Number(i));
      data.check_days = checkDaysArr;
    }
    if (selectedEvent.data.code === 'will_assign_max_license_users') {
      const usersLeftArr = usersLeft.split(',').map(i => Number(i));
      data.users_left = usersLeftArr;
    }
    return data;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (isLoading || !isValid) {
      return false;
    }

    setLoading(true);
    const data = {
      event: get(selectedEvent, 'value'),
      product: allowProductSelect ? get(selectedProduct, 'value') : null,
      company: companyID,
      name,
      notify_customer: notifyCustomer,
      notify_license_users: notifyLicenseUsers,
      notify_license_managers: notifyLicenseManagers,
      notify_custom_emails: customEmails.emails
        ? customEmails.emails.map(ce => ({ email: ce }))
        : [],
      notify_users: selectedCompanyUsers.map(scu => scu.value),
      data: createCustomData()
    };

    if (!notificationPolicy) {
      createPolicy(data);
    } else {
      updatePolicy(data);
    }
    return true;
  };

  const handleClose = () => {
    if (!isDirty) {
      return closeCb();
    }
    return setDirtyFormAlertDisplay(true);
  };

  const title = notificationPolicy
    ? __('Edit notification policy')
    : __('Add notification policy');

  const getSelectorHeading = (values = []) => {
    if (!Array.isArray(values) || !values.length) return '';
    if (values.length === 1) {
      return get(values, '[0].label');
    }
    const sorted = sortBy(values, 'value');
    return `${get(sorted, '[0].label')} (+${values.length - 1})`;
  };

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      title={title}
      size="sm"
    >
      <div className="NotificationPolicyForm">
        <div className="form-row">
          <Label inputId="events-selector" text={__('Event')} />
          <Selector
            options={notificationPolicy ? completeEventsOptions : eventsOptions}
            value={selectedEvent.value}
            handleChange={handleEventChange}
            disabled={!!notificationPolicy}
          />
        </div>
        <div className="form-row">
          <Label text={__('Policy Name')} />
          <TextInput
            disabled={isLoading}
            id="policy-name"
            type="text"
            value={name}
            error={nameError}
            handleChange={val => {
              setDirty(true);
              setName(val);
              debouncedValidateRequiredValue(val).then(err =>
                setNameError(err)
              );
            }}
          />
        </div>
        <div className="form-row">
          {selectedEvent.singleProduct && (
            <Checkbox
              label={__('Send for a single product only')}
              checked={allowProductSelect}
              inputId="allow-product-checkbox"
              handleChange={val => {
                setDirty(true);
                setAllowProductSelect(val);
                setSelectedProduct({
                  label: get(productOptions, '[0].label'),
                  value: get(productOptions, '[0].value')
                });
              }}
            />
          )}
        </div>
        {allowProductSelect && (
          <div className="form-row product-select">
            <Label inputId="product-input" text={__('Select product')} />
            <Selector
              options={productOptions}
              handleChange={handleProductSelect}
              value={get(selectedProduct, 'value')}
              inputId="product-input"
            />
          </div>
        )}
        {isAllowedEventRecipient(selectedEvent, 'customer') && (
          <div className="form-row">
            <Checkbox
              label={__('Notify customer')}
              checked={notifyCustomer}
              inputId="notify-customers-checkbox"
              handleChange={val => {
                setDirty(true);
                setNotifyCustomer(val);
              }}
            />
          </div>
        )}
        {isAllowedEventRecipient(selectedEvent, 'license_managers') && (
          <div className="form-row">
            <Checkbox
              label={__('Notify license managers')}
              checked={notifyLicenseManagers}
              inputId="notify-managers-checkbox"
              handleChange={val => {
                setDirty(true);
                setNotifyLicenseManagers(val);
              }}
            />
          </div>
        )}
        {isAllowedEventRecipient(selectedEvent, 'license_users') && (
          <div className="form-row">
            <Checkbox
              label={__('Notify license users')}
              checked={notifyLicenseUsers}
              inputId="notify-users-checkbox"
              handleChange={val => {
                setDirty(true);
                setNotifyLicenseUsers(val);
              }}
            />
          </div>
        )}
        {isAllowedEventRecipient(selectedEvent, 'custom_emails') && (
          <div className="form-row">
            <Label text={__('Custom recipients')} inputId="emails-input" />
            <MultiEmailInput
              emails={get(customEmails, 'emails')}
              onEmailSubmit={val => handleEmailSubmit(val)}
              disabled={isLoading}
            />
            <InputErrorMessage
              text={get(customEmails, 'validationError') || ''}
            />
          </div>
        )}
        {isAllowedEventRecipient(selectedEvent, 'company_users') && (
          <div className="form-row">
            <Label text={__('Set company users as recipients')} />
            <CheckboxSelector
              text={getSelectorHeading(selectedCompanyUsers)}
              options={sortBy(companyUserOptions, 'value')}
              value={selectedCompanyUsers}
              onChangeCallback={val => {
                setDirty(true);
                setSelectedCompanyUsers(val);
              }}
              onMenuClose={() => {}}
              disabled={isLoading}
            />
          </div>
        )}
        {hasCustomEventData(selectedEvent, 'check_days') && (
          <div className="form-row">
            <Label
              text={__('Check days')}
              description={__(
                'Define how many days before license expiration the notification will be sent. You can define multiple days spearated by comma. For example: "1,3,5" would mean that notification will be sent for every license which is going to expire in one, three or five days from now.'
              )}
            />
            <TextInput
              placeholder="Eq. 1,3,5"
              disabled={isLoading}
              id="check-days"
              type="text"
              value={checkDays}
              error={checkDaysError}
              handleChange={val => {
                setDirty(true);
                setCheckDays(val);
                setCheckDaysError('');
              }}
            />
          </div>
        )}
        {hasCustomEventData(selectedEvent, 'users_left') && (
          <div className="form-row">
            <Label
              text={__('Remaining user seats')}
              description={__(
                'Notificiation will be sent when there is a certain number of user slots free before reaching limit. You can define multiple numbers spearated by comma. For example: "1,3,5" would mean that notification will be sent for every license where there is a 1, 3 or 5 free user seats left.'
              )}
            />
            <TextInput
              placeholder="Eq. 1,3,5"
              disabled={isLoading}
              id="check-users_left"
              type="text"
              value={usersLeft}
              error={usersLeftError}
              handleChange={val => {
                setDirty(true);
                setUsersLeft(val);
                setUsersLeftError('');
              }}
            />
          </div>
        )}
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

NotificationPolicyForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  notificationPolicy: PropTypes.object,
  refetchData: PropTypes.func.isRequired,
  companyID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};

NotificationPolicyForm.defaultProps = {
  notificationPolicy: null
};

export default NotificationPolicyForm;
