import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { DateRangePicker, Subtitle, BaseChart } from 'shared/components';
import { APIdateFormat } from 'shared/constants';
import { changeDateRange } from 'src/analytics/actions';
import { MonthlyRateLimitBar } from 'src/analytics/components';
import './styles.scss';

const ActivityAnalytics = React.memo(({ activeCompany }) => {
  const dispatch = useDispatch();
  const defaultFrom = moment().startOf('month');
  const defaultTo = moment().endOf('month');

  const from = useSelector(state =>
    get(state, 'analytics.dateRange.date_from')
  );
  const to = useSelector(state => get(state, 'analytics.dateRange.date_to'));

  const dateFrom = from ? moment(from) : defaultFrom;
  const dateTo = to ? moment(to) : defaultTo;

  const handleDateChange = dates => {
    dispatch(
      changeDateRange({
        date_from: dates[0].format(APIdateFormat),
        date_to: dates[1].format(APIdateFormat)
      })
    );
  };

  return (
    <div className="ActivityAnalytics">
      <div className="ActivityAnalytics-heading">
        <Subtitle text={__('API Usage')} />
        <DateRangePicker
          handleChange={handleDateChange}
          initialStartDate={dateFrom}
          initialEndDate={dateTo}
          minimumNights={0}
        />
      </div>
      <div className="ActivityAnalytics-main">
        <MonthlyRateLimitBar activeCompany={activeCompany} />
        <BaseChart
          companyID={activeCompany}
          dateFrom={dateFrom}
          dateTo={dateTo}
          label={__('License API')}
          title={__('Total License API calls')}
          displayTotal
        />
      </div>
    </div>
  );
});

ActivityAnalytics.propTypes = {
  activeCompany: PropTypes.number.isRequired
};

export default ActivityAnalytics;
