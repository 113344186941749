import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';
import {
  Checkbox,
  DateInput,
  InputErrorMessage,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  Selector,
  RadioBtn
} from 'shared/components';
import {
  sendErrorReport,
  formatAmountValue,
  mapCurrencySymbol,
  getUpcomingInvoiceDate
} from 'shared/helpers';
import { getCompanySilent } from 'src/company/actions';
import { scheduleSubscriptionUpdate } from 'src/billing/actions';
import './styles.scss';

const getTotal = (selectedPlan, selectedDiscount, selectedTax) => {
  let unitPlanPrice = 0;
  let unitDiscount = 0;
  let unitTax = 0;

  if (!selectedPlan) {
    return 0;
  }

  unitPlanPrice = formatAmountValue(selectedPlan.amount);
  if (selectedDiscount) {
    unitDiscount =
      Number(selectedDiscount.amount_off) ||
      (Number(unitPlanPrice) *
        Number(get(selectedDiscount, 'percent_off') || 0)) /
        100;
  }
  if (selectedTax) {
    unitTax =
      ((Number(unitPlanPrice) - unitDiscount) *
        Number(get(selectedTax, 'percentage') || 0)) /
      100;
  }
  const total = Number(unitPlanPrice) - unitDiscount + unitTax;
  return total.toFixed(2);
};

const ScheduledUpdateForm = ({
  closeCb,
  companyDetails,
  coupons,
  pricingPlans,
  taxRates,
  title
}) => {
  const dispatch = useDispatch();

  const companyID = get(companyDetails, 'id') || '';
  const companyPricingPlan =
    get(companyDetails, 'upcoming_invoice.plan_id') || '';
  const companyCouponCode =
    get(companyDetails, 'upcoming_invoice.discounts[0].coupon.id') ||
    get(companyDetails, 'payment_coupon_code') ||
    '';
  const companyTaxRate = get(companyDetails, 'stripe_tax_id') || '';
  const invoiceBillingDate = getUpcomingInvoiceDate(companyDetails);
  const companyBillingDate = invoiceBillingDate
    ? moment(invoiceBillingDate).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD');

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setIsDirtyFormAlertDisplayed] = useState(
    false
  );
  const [pricingPlan, setPricingPlan] = useState(companyPricingPlan);
  const [pricingPlanError, setPricingPlanError] = useState('');
  const [billingDate, setBillingDate] = useState(companyBillingDate);
  const [billingDateError, setBillingDateError] = useState('');
  const [hasDiscount, setHasDiscount] = useState(!!companyCouponCode);
  const [discount, setDiscount] = useState(companyCouponCode);
  const [discountError, setDiscountError] = useState('');
  const [hasTaxRate, setHasTaxRate] = useState(!!companyTaxRate);
  const [taxRate, setTaxRate] = useState(companyTaxRate);
  const [taxRateError, setTaxRateError] = useState('');
  const [selectedUpdateDateType, setSelectedUpdateDateType] = useState(
    'end-of-current-period'
  );

  const handleBillingDateSelect = val => {
    setDirty(true);
    if (moment(val).isBefore(moment().subtract(1, 'days'))) {
      setBillingDateError(__('Only future dates can be selected'));
    } else {
      setBillingDate(moment(val).format('YYYY-MM-DD'));
      setBillingDateError('');
    }
  };

  const validateDate = () => {
    if (selectedUpdateDateType === 'end-of-current-period') {
      return true;
    }
    if (!billingDate) {
      setBillingDateError(__('This field is required'));
      return false;
    }
    if (moment(billingDate).isBefore(moment().subtract(1, 'days'))) {
      setBillingDateError(__('Only future dates can be selected'));
      return false;
    }
    return true;
  };

  const validateDiscount = () => {
    if (!hasDiscount) {
      return true;
    }
    if (!discount) {
      setDiscountError('This field is required');
      return false;
    }
    return true;
  };

  const validateTaxRate = () => {
    if (!hasTaxRate) {
      return true;
    }
    if (!taxRate) {
      setTaxRateError('This field is required');
      return false;
    }
    return true;
  };

  const isFormValid = () => {
    const isDateValid = validateDate();
    const isDiscountValid = validateDiscount();
    const isTaxRateValid = validateTaxRate();
    return isDateValid && isDiscountValid && isTaxRateValid;
  };

  const handleSubmit = () => {
    const isValid = isFormValid();
    if (!isValid || loading) {
      return false;
    }

    setLoading(true);
    const data = {
      start_date:
        selectedUpdateDateType === 'end-of-current-period'
          ? undefined
          : moment(billingDate).format('YYYY-MM-DD'),
      plan: pricingPlan,
      coupon: discount || undefined,
      tax_rate: taxRate || undefined
    };

    scheduleSubscriptionUpdate(companyID, data)
      .then(() => {
        setLoading(false);
        dispatch(getCompanySilent(companyID));
        Notification('success', __('Scheduled update created successfully'));
        closeCb();
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot create scheduled update', data);
        setLoading(false);
        Notification(
          'error',
          __('Error occured'),
          __('Your changes were not saved')
        );
      });
    return true;
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return setIsDirtyFormAlertDisplayed(true);
  };

  const selectedPricingPlan = pricingPlans.find(p => p.id === pricingPlan);
  const selectedCoupon = coupons.find(c => c.id === discount);
  const selectedTaxRate = taxRates.find(t => t.id === taxRate);
  const totalPrice = getTotal(
    selectedPricingPlan,
    selectedCoupon,
    selectedTaxRate
  );

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={title}
      disabled={loading}
      size="sm"
    >
      <div className="ScheduledUpdateForm">
        <form className="ScheduledUpdateForm-form" onSubmit={handleSubmit}>
          <div className="scheduled-date-container">
            <Label text={__('Scheduled update date:')} inputId="update-date" />
            <div>
              <RadioBtn
                name="update-date-select"
                inputId="end-of-current-period"
                label={`${__('End of the current period')} ${billingDate}`}
                value="end-of-current-period"
                checked={selectedUpdateDateType === 'end-of-current-period'}
                handleChange={val => {
                  setBillingDate(companyBillingDate);
                  setSelectedUpdateDateType(val);
                }}
              />
            </div>
            <div>
              <RadioBtn
                name="update-date-select"
                inputId="custom-date"
                label={__('Custom date:')}
                value="custom-date"
                checked={selectedUpdateDateType === 'custom-date'}
                handleChange={val => {
                  setBillingDate(companyBillingDate);
                  setSelectedUpdateDateType(val);
                }}
              />
            </div>
            <div>
              <DateInput
                handleChange={val => handleBillingDateSelect(val)}
                value={billingDate}
                error={billingDateError}
                id="update-date"
                disabled={selectedUpdateDateType === 'end-of-current-period'}
              />
            </div>
          </div>
          <div>
            <Label text={__('Pricing plan')} inputId="pricing-plan" />
            <Selector
              options={pricingPlans}
              value={pricingPlan}
              handleChange={val => {
                setDirty(true);
                setPricingPlanError('');
                setPricingPlan(val);
              }}
              valueKey="id"
              getOptionLabel={o =>
                `${o.nickname} - ${formatAmountValue(
                  o.amount
                )} ${mapCurrencySymbol(o.currency)} (${o.interval})`
              }
              getOptionValue={o => o.id}
            />
            <InputErrorMessage text={pricingPlanError} />
          </div>
          <div>
            <Checkbox
              label={__('Discount')}
              handleChange={val => {
                setDirty(true);
                setDiscountError('');
                setDiscount('');
                setHasDiscount(val);
              }}
              checked={hasDiscount}
              inputId="discount"
            />
            {hasDiscount && (
              <div>
                <Selector
                  options={coupons}
                  value={discount}
                  handleChange={val => {
                    setDirty(true);
                    setDiscount(val);
                  }}
                  valueKey="id"
                  getOptionLabel={o => `${o.name}`}
                  getOptionValue={o => o.id}
                />
                <InputErrorMessage text={discountError} />
              </div>
            )}
          </div>
          <div className="tax-rate-wrapper">
            <Checkbox
              label={__('Tax rate')}
              handleChange={val => {
                setDirty(true);
                setTaxRate('');
                setTaxRateError('');
                setHasTaxRate(val);
              }}
              checked={hasTaxRate}
              inputId="tax-rate"
            />
            {hasTaxRate && (
              <div>
                <Selector
                  options={taxRates}
                  value={taxRate}
                  handleChange={val => {
                    setDirty(true);
                    setTaxRate(val);
                  }}
                  valueKey="id"
                  getOptionLabel={o =>
                    `${o.display_name} ${o.jurisdiction} - ${o.percentage}%`
                  }
                  getOptionValue={o => o.id}
                />
                <InputErrorMessage text={taxRateError} />
              </div>
            )}
          </div>
          <div className="total">
            {__('Total:')}
            &nbsp;
            <span>{`${totalPrice} ${mapCurrencySymbol(
              get(selectedPricingPlan, 'currency') || ''
            )}`}</span>
          </div>
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setIsDirtyFormAlertDisplayed(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </Modal>
  );
};

ScheduledUpdateForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyDetails: PropTypes.object.isRequired,
  coupons: PropTypes.array.isRequired,
  pricingPlans: PropTypes.array.isRequired,
  taxRates: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

export default ScheduledUpdateForm;
