import React from 'react';
import { IconInfo, Tooltip } from 'shared/components';

const NextInvoiceLabel = () => (
  <div className="DescriptionTable-label">
    <span>{__('Next invoice date')}</span>
    <Tooltip
      content={__(
        'The next date your LicenseSpring bill is due and when we will attempt to charge your payment method.'
      )}
      active
    >
      <span className="icon-cont">
        <IconInfo height="12px" width="12px" />
      </span>
    </Tooltip>
  </div>
);

export default NextInvoiceLabel;
