import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { DescriptionTable } from 'shared/components';
import {
  displayValue,
  capitalizeFirstLetter,
  getSelectedBillingCycleLabel,
  getSelectedPricingPlan,
  displayCouponName,
  displayTaxRate
} from 'shared/helpers';
import {
  showBillingAmount,
  showNextInvoiceDateValue,
  showUnitAmount,
  showTaxAmount
} from '../../helpers';
import NextInvoiceLabel from '../NextInvoiceLabel';

const PaymentDetails = () => {
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const pricingPlans = useSelector(state => get(state, 'billing.pricingPlans'));
  const standardPricingPlans = useSelector(state =>
    get(state, 'billing.standardPricingPlans')
  );
  const taxRates = useSelector(state => get(state, 'billing.taxRates'));
  const coupons = useSelector(state => get(state, 'billing.coupons'));

  // todo upcoming invoice separate endpoint
  const pricingPlan = getSelectedPricingPlan(
    get(companyDetails, 'upcoming_invoice.plan_id'),
    pricingPlans
  );
  const couponName = displayCouponName(
    get(companyDetails, 'upcoming_invoice.discounts[0].coupon.id') ||
      get(companyDetails, 'payment_coupon_code'),
    coupons
  );
  const taxRate = displayTaxRate(
    get(companyDetails, 'stripe_tax_id'),
    taxRates
  );

  return (
    <div className="PaymentDetails">
      <h2>{__('Payment info')}</h2>
      <DescriptionTable
        details={[
          {
            label: __('Plan type'),
            value: displayValue(
              capitalizeFirstLetter(get(companyDetails, 'plan_type'))
            )
          },
          {
            label:
              get(companyDetails, 'plan_type') === 'standard'
                ? __('Billing cycle')
                : null,
            value: displayValue(
              getSelectedBillingCycleLabel(
                get(companyDetails, 'subscription_cycle')
              )
            )
          },
          {
            label:
              get(companyDetails, 'plan_type') === 'enterprise'
                ? __('Billing cycle')
                : null,
            value: capitalizeFirstLetter(get(pricingPlan, 'interval')) || '-'
          },
          {
            label: __('Amount'),
            value: showUnitAmount(
              companyDetails,
              pricingPlans,
              standardPricingPlans
            )
          },
          {
            label: get(companyDetails, 'upcoming_invoice.discounts[0].coupon')
              ? __('Discount')
              : null,
            value: couponName
          },
          {
            label: get(companyDetails, 'stripe_tax_id') ? __('Tax rate') : null,
            value: taxRate
          },
          {
            label: get(companyDetails, 'upcoming_invoice.tax')
              ? __('Tax amount')
              : null,
            value: showTaxAmount(companyDetails)
          },
          {
            label: __('Total'),
            value: showBillingAmount(companyDetails)
          },
          {
            label: NextInvoiceLabel(),
            value: showNextInvoiceDateValue(companyDetails)
          }
        ]}
      />
    </div>
  );
};

export default PaymentDetails;
