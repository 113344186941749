import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { errorMsg, validationTimeDefault } from 'shared/constants';

export const validatePassword = val =>
  new Promise(resolve => {
    let error = '';

    if (!val) {
      error = errorMsg.required;
      resolve(error);
    }

    if (val.length < 8) {
      error = errorMsg.passCharNum;
      resolve(error);
    }

    resolve(error);
  });

export const debouncedValidatePassword = AwesomeDebouncePromise(
  validatePassword,
  validationTimeDefault
);
