import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { sendErrorReport } from 'shared/helpers';
import {
  Checkbox,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
  NumberInput
} from 'shared/components';
import {
  validateRequiredNumber,
  debouncedValidateRequiredNumber
} from 'shared/validation';
import { setLicenseUserActivations } from 'src/license/actions';
import './styles.scss';

const LicenseUserMaxActivations = ({
  closeCb,
  user,
  licenseID,
  companyID,
  refetchLicense
}) => {
  const userID = get(user, 'id');
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);
  // form state
  const [maxActivations, setMaxActivations] = useState(
    get(user, 'max_activations') || 1
  );
  const [maxActivationsError, setMaxActivationsError] = useState('');
  const [allowUnlimitedActivations, setAllowUnlimitedActivations] = useState(
    get(user, 'allow_unlimited_activations') || false
  );

  const validateMaxActivations = async val => {
    setLoading(true);
    let errors;

    try {
      errors = await validateRequiredNumber(val);
      setLoading(false);
      setMaxActivationsError(errors);
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate max activations', { value: val });
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const handleMaxActivationsChange = val => {
    setDirty(true);
    setMaxActivations(val);
    debouncedValidateRequiredNumber(val).then(err =>
      setMaxActivationsError(err)
    );
  };

  const handleAllowUnlimitedActivations = val => {
    setDirty(true);
    setAllowUnlimitedActivations(val);
    setMaxActivations(1);
    setMaxActivationsError('');
  };

  const isFormValid = async () => {
    const areMaxActivationsValid = await validateMaxActivations(maxActivations);
    return areMaxActivationsValid;
  };

  const setTotalActivations = () => {
    setLoading(true);
    const data = {
      [userID]: {
        max_activations: maxActivations,
        allow_unlimited_activations: allowUnlimitedActivations
      }
    };
    setLicenseUserActivations(licenseID, companyID, data)
      .then(() => {
        refetchLicense();
        closeCb();
        Notification('success', __('Changes saved successfully'), '');
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot set license user max activations');
        setLoading(false);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
      });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setDirty(false);
    setLoading(true);
    setTotalActivations();
    return true;
  };

  const handleClose = () => {
    if (!isDirty) {
      return closeCb();
    }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      size="sm"
      title={__('Set max activations')}
    >
      <form className="LicenseUserMaxActivations" onSubmit={handleSubmit}>
        <div className="form-inner">
          <div className="form-row">
            <Label text={__('Email')} inputId="email" />
            <TextInput
              id="email"
              value={get(user, 'true_email') || ''}
              disabled
              handleChange={() => {}}
            />
          </div>
          <div className="form-row">
            <Label
              text={__('Max Activations')}
              inputId="max-activations-input"
            />
            <NumberInput
              handleChange={handleMaxActivationsChange}
              value={maxActivations}
              error={maxActivationsError}
              disabled={isLoading || allowUnlimitedActivations}
              min="1"
              max="2147483647"
              id="max-activations-input"
            />
          </div>
          <div className="form-row">
            <Checkbox
              label={__('Allow unlimited activations')}
              inputId="allow-unlimited-activations-checkbox"
              checked={allowUnlimitedActivations}
              handleChange={handleAllowUnlimitedActivations}
            />
          </div>
        </div>
        <button type="submit" style={{ visibility: 'hidden' }} />
      </form>
      {isDirtyFormDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

LicenseUserMaxActivations.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  licenseID: PropTypes.number.isRequired,
  refetchLicense: PropTypes.func.isRequired
};

export default LicenseUserMaxActivations;
