export const runStatusFilter = (products, filters) => {
  const { selectedFilterStatus } = filters;
  if (selectedFilterStatus.value === 'all') {
    return products;
  }
  const isStatus = selectedFilterStatus.value === 'Active';
  const result = products.filter(p => p.active === isStatus);
  return result;
};

export const runTrialFilter = (products, filters) => {
  const { selectedTrialFilter } = filters;
  if (selectedTrialFilter.value === 'all') {
    return runStatusFilter(products, filters);
  }
  const allowTrial = selectedTrialFilter.value === 'Allow trial';
  const result = products.filter(p => p.allow_trial === allowTrial);
  return runStatusFilter(result, filters);
};

export const runAuthMethodFilter = (products, filters) => {
  const { selectedAuthFilterMethod } = filters;
  if (selectedAuthFilterMethod.value === 'all') {
    return runTrialFilter(products, filters);
  }
  const result = products.filter(
    p => p.authorization_method === selectedAuthFilterMethod.value
  );
  return runTrialFilter(result, filters);
};

export const getFilteredList = (products, filters) =>
  runAuthMethodFilter(products, filters);
