import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';
import {
  DateInput,
  DirtyFormAlert,
  DescriptionTable,
  InputErrorMessage,
  Label,
  Modal,
  Notification,
  NumberInput,
  RadioBtn
} from 'shared/components';
import { formatDate } from 'shared/helpers';
import { defaultDateTimeFormat } from 'shared/constants';
import {
  validateRequiredNumber,
  debouncedValidateRequiredNumber
} from 'shared/validation';
import { getCompanySuccess, changeTrialDays } from 'src/company/actions';
import { extendTrial, patchCompanyTrialDays } from 'src/billing/actions';
import './styles.scss';

const ChangeTrialForm = ({
  closeCb,
  companyId,
  extend,
  passedTrialDays,
  title,
  trialDays,
  trialStart
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setIsDirtyFormAlertDisplayed] = useState(
    false
  );
  const [selectedTrialInput, setSelectedTrialInput] = useState('days');
  const [trialDate, setTrialDate] = useState(
    moment()
      .add(trialDays, 'days')
      .format('YYYY-MM-DD')
  );
  const [trial, setTrial] = useState(trialDays);
  const [trialError, setTrialError] = useState('');
  const [trialDateError, setTrialDateError] = useState('');
  const [remainingDaysError, setRemainingDaysError] = useState('');

  const validateRemainingDays = val => {
    if (extend) {
      return true;
    }
    const remaining = Number(val) - passedTrialDays;
    if (remaining < 0) {
      setRemainingDaysError(
        __('The remaining days cannot be smaller than zero.')
      );
      return false;
    }
    setRemainingDaysError('');
    return true;
  };

  const handleTrialChange = val => {
    setDirty(true);
    setTrial(val);
    validateRemainingDays(val);
    return debouncedValidateRequiredNumber(val).then(err => setTrialError(err));
  };

  const handleSelecteTrialInput = val => {
    setDirty(true);
    setSelectedTrialInput(val);
    setTrial(trialDays);
    setTrialDateError('');
    setTrialError('');
    setTrialDate(
      moment()
        .add(trialDays, 'days')
        .format('YYYY-MM-DD')
    );
  };

  const handleTrialEndDateSelect = val => {
    setDirty(true);
    if (moment(val).isBefore(moment())) {
      setTrialDateError(__('Only future dates can be selected'));
    } else {
      setTrialDate(moment(val).format('YYYY-MM-DD'));
      setTrialDateError('');
      const newTrialDays = moment(val).diff(moment(trialStart), 'days') || 0;
      setTrial(newTrialDays);
      validateRemainingDays(newTrialDays);
    }
  };

  const validateTrialDays = () => {
    let isValid = false;
    setLoading(true);
    if (selectedTrialInput === 'days') {
      return validateRequiredNumber(trial).then(d => {
        setTrialError(d);
        setLoading(false);
        isValid = !d;
        return isValid;
      });
    }

    if (selectedTrialInput === 'date') {
      const isPastDay = moment(trialDate).isBefore(moment());
      if (isPastDay) {
        setTrialDateError(__('Only future dates can be selected'));
        isValid = false;
        setLoading(false);
      } else {
        isValid = true;
        setLoading(false);
      }
    }
    return isValid;
  };

  const isFormValid = async () => {
    const areTrialDaysValid = await validateTrialDays();
    const areRemainingDaysValid = await validateRemainingDays(trial);
    return !!areTrialDaysValid && !!areRemainingDaysValid;
  };

  const handleSubmit = async () => {
    const isValid = await isFormValid();
    if (!isValid || loading) {
      return false;
    }

    setLoading(true);

    if (extend) {
      // use extend endpoint
      extendTrial(trial, companyId)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          const trialDaysRes = get(res, 'data.trial_days');
          dispatch(changeTrialDays(trialDaysRes));
          closeCb();
          Notification('success', __('Trial period started'));
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      // patch company trial_days
      patchCompanyTrialDays(trial, companyId)
        .then(res => {
          dispatch(getCompanySuccess(res.data));
          closeCb();
          Notification('success', __('Trial days changed'));
        })
        .catch(() => {
          setLoading(false);
        });
    }
    return true;
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return setIsDirtyFormAlertDisplayed(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={title}
      disabled={loading}
      size="sm"
    >
      <div className="ChangeTrialForm">
        <div className="ChangeTrialForm-radiobtns">
          <RadioBtn
            name="trial-input-select"
            inputId="trial-days"
            label={__('Input trial days')}
            value="days"
            checked={selectedTrialInput === 'days'}
            handleChange={handleSelecteTrialInput}
          />
          <RadioBtn
            name="trial-input-select"
            inputId="trial-date"
            label={__('Select trial end date')}
            value="date"
            checked={selectedTrialInput === 'date'}
            handleChange={handleSelecteTrialInput}
          />
          {selectedTrialInput === 'days' && (
            <div className="row input-cont">
              <Label inputId="trial-input" text={__('Trial days')} />
              <NumberInput
                disabled={loading}
                handleChange={val => handleTrialChange(val)}
                id="trial-input"
                min="1"
                max="10000"
                error={trialError}
                value={trial}
              />
            </div>
          )}
          {selectedTrialInput === 'date' && (
            <div>
              <DateInput
                handleChange={val => handleTrialEndDateSelect(val)}
                value={trialDate}
                id="trial-date-input"
              />
              <InputErrorMessage text={trialDateError} />
            </div>
          )}
        </div>
        {!extend && (
          <div className="ChangeTrialForm-remaining">
            <DescriptionTable
              details={[
                {
                  label: __('Trial start'),
                  value: formatDate(trialStart, defaultDateTimeFormat)
                },
                {
                  label: __('Remaining trial days'),
                  value: Number(trial) - passedTrialDays
                }
              ]}
            />
            <InputErrorMessage text={remainingDaysError} />
          </div>
        )}
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setIsDirtyFormAlertDisplayed(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </Modal>
  );
};

ChangeTrialForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  extend: PropTypes.bool,
  passedTrialDays: PropTypes.number,
  title: PropTypes.string.isRequired,
  trialDays: PropTypes.number,
  trialStart: PropTypes.string
};

ChangeTrialForm.defaultProps = {
  extend: false,
  passedTrialDays: 0,
  trialDays: 15,
  trialStart: ''
};

export default ChangeTrialForm;
