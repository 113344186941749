import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import TagManager from 'react-gtm-module';
import i18n from 'src/i18n';
import { ContentLoader, CustomPrompt, IsAuthorized } from 'shared/components';
import AppContainer from './app/AppContainer';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

/* eslint-disable-next-line new-cap, no-unused-vars */
const appLang = new i18n('en');

const App = () => {
  const auth = useAuth();
  const env = process.env.REACT_APP_ENV;

  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  useEffect(
    () =>
      auth.events.addAccessTokenExpiring(() => {
        auth.signinRedirect();
      }),
    [auth.events, auth.signinRedirect]
  );

  const getEnvTitle = () => {
    if (env === 'localhost') {
      return 'LicenseSpring Platform | LOCAL';
    }
    if (env === 'development') {
      return 'LicenseSpring Platform | DEV';
    }
    if (env === 'staging') {
      return 'LicenseSpring Platform | STAGING';
    }
    return 'LicenseSpring Platform';
  };

  if (env !== 'localhost' && !!process.env.REACT_APP_GTM_ID) {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
      auth: process.env.REACT_APP_GTM_AUTH,
      preview: process.env.REACT_APP_GTM_PREVIEW
    });
  }

  if (auth.isLoading) {
    return <ContentLoader text="" />;
  }

  return (
    <div className="App">
      <Helmet>
        <title>{getEnvTitle()}</title>
      </Helmet>
      <Router
        getUserConfirmation={(message, callback) =>
          CustomPrompt(__(message), callback, __('Warning'))
        }
      >
        <Switch>
          <Route path="/login" render={() => <Redirect to="/" />} />
          <Route
            path="/"
            render={() => (
              <IsAuthorized>
                <AppContainer />
              </IsAuthorized>
            )}
          />
        </Switch>
      </Router>
      <ToastContainer
        autoClose={3000}
        closeButton={false}
        toastClassName="Notification-container"
      />
    </div>
  );
};

export default App;
