import React from 'react';
import PropTypes from 'prop-types';
import { Subtitle } from 'shared/components';
import { userPermissionLabels } from 'shared/constants';
import { ReactComponent as AccessDeniedImg } from './access_denied.svg';
import './styles.scss';

const PermissionDenied = ({ permission }) => (
  <div className="PermissionDenied">
    <Subtitle text={__('You are not authorized to view this section')} />
    <div className="PermissionDenied-main">
      <div className="label">{`${__('Missing permission')}:`}</div>
      <div className="value">{userPermissionLabels[permission]}</div>
    </div>
    <div className="PermissionDenied-image">
      <AccessDeniedImg />
    </div>
    <p className="PermissionDenied-support">
      {__('Contact you account admin for support.')}
    </p>
  </div>
);

PermissionDenied.propTypes = {
  permission: PropTypes.string.isRequired
};

export default PermissionDenied;
