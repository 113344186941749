import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Button,
  ClipboardRow,
  ConfirmationPopup,
  ContentLoader,
  Label,
  Notification,
  PermissionMissingNotificationTitle,
  TextInput
} from 'shared/components';
import {
  sendErrorReport,
  isFeatureEnabled,
  isFeatureAvailable,
  checkUserPermission
} from 'shared/helpers';
import { platformFeatures, userPermissions } from 'shared/constants';
import {
  fetchAirGapPublicKey,
  generateAirGapKeyPair
} from 'src/account/actions';
import './styles.scss';

const AirGap = () => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write);
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const companyID = get(companyDetails, 'id');
  const allowsAirGapped =
    isFeatureEnabled(platformFeatures.extra_air_gapped) &&
    isFeatureAvailable(platformFeatures.extra_air_gapped);

  // air_gapped keys
  const [
    isAirGapRegenerateIntentDisplayed,
    setAirGapRegenerateIntentDisplay
  ] = useState(false);
  const [
    isAirGapRegenerateWarningDisplayed,
    setAirGapRegenerateWarningDisplay
  ] = useState(false);
  const [airGapKeysGenerating, setAirGapKeysGenerating] = useState(false);
  const [airGapKeysLoading, setAirGapKeysLoading] = useState(true);
  const [airGapPublicKey, setAirGapPublicKey] = useState(null);

  const getPublicAirGapKey = useCallback(() => {
    fetchAirGapPublicKey(companyID)
      .then(res => {
        const key = get(res, 'data.public_key') || null;
        setAirGapPublicKey(key);
        setAirGapKeysLoading(false);
        setAirGapKeysGenerating(false);
        setAirGapRegenerateIntentDisplay(false);
        setAirGapRegenerateWarningDisplay(false);
      })
      .catch(() => {
        setAirGapKeysLoading(false);
        setAirGapKeysGenerating(false);
      });
  }, [companyID]);

  const generateKeyPair = useCallback(() => {
    setAirGapKeysGenerating(true);
    generateAirGapKeyPair(companyID)
      .then(() => {
        getPublicAirGapKey();
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot generate air-gapped key pair');
        setAirGapKeysGenerating(false);
        Notification('error', __('Error occured'));
      });
  }, [companyID]);

  useEffect(() => {
    if (allowsAirGapped) {
      getPublicAirGapKey();
    }
  }, [allowsAirGapped, getPublicAirGapKey]);

  const handleManageSettingsClick = cb => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle
          permission={userPermissions.settings_write}
        />,
        __('Contact you account admin for support.')
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <div className="AirGap">
      {allowsAirGapped && (
        <div className="row air-gapped">
          <h3>{__('Air-gapped licensing keys')}</h3>
          <Button
            size="sm"
            theme="info"
            loading={airGapKeysGenerating}
            disabled={airGapKeysLoading}
            onClick={() =>
              handleManageSettingsClick(() => {
                if (!airGapPublicKey) {
                  generateKeyPair();
                } else {
                  setAirGapRegenerateIntentDisplay(true);
                }
              })
            }
          >
            {__('Generate new key pair')}
          </Button>
          {airGapKeysLoading ? (
            <div className="row air-gap-loading">
              <ContentLoader />
            </div>
          ) : (
            <div className="air-gap-keys">
              {airGapPublicKey ? (
                <>
                  <div className="row">
                    <Label inputId="air-gap-private" text={__('Private key')} />
                    <div className="text-input-cont">
                      <TextInput
                        id="air-gap-private"
                        handleChange={() => {}}
                        value="***********************************************************"
                        disabled
                      />
                    </div>
                  </div>
                  <ClipboardRow
                    value={airGapPublicKey}
                    isTextArea
                    label={__('Public key')}
                    id="air-gap-public-input"
                  />
                </>
              ) : (
                <div className="row air-gap-no-key">
                  {__('No key pair generated')}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {isAirGapRegenerateIntentDisplayed && (
        <ConfirmationPopup
          closeCb={() => setAirGapRegenerateIntentDisplay(false)}
          confirmCb={() => setAirGapRegenerateWarningDisplay(true)}
          title={__(
            'Are you sure you want to generate new air-gapped licensing key pair?'
          )}
          confirmText={__('Confirm')}
          theme="error"
          disabled={airGapKeysGenerating}
        >
          {__(
            'This action cannot be undone. If you have embeded existing public key in SDK, it will stop working.'
          )}
        </ConfirmationPopup>
      )}
      {isAirGapRegenerateWarningDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setAirGapRegenerateIntentDisplay(false);
            setAirGapRegenerateWarningDisplay(false);
          }}
          confirmCb={generateKeyPair}
          title={__(
            'Please, confirm that you really want to generate new key pair.'
          )}
          confirmText={__('Confirm')}
          theme="error"
          warning
          disabled={airGapKeysGenerating}
        />
      )}
    </div>
  );
};

export default AirGap;
