import { get } from 'lodash';
import { billingCycles } from 'shared/constants';

export const getValue = (val, key) => {
  if (val) {
    return { [key]: val };
  }
  return null;
};

export const getCycle = val => {
  if (val) {
    const selected = billingCycles.find(c => c.value === val);
    return selected;
  }
  return get(billingCycles, '[0]');
};
