import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shared/components';
import './styles.scss';

const EnterprisePlanTypeBox = ({ isCurrent }) => (
  <article
    className={`EnterprisePlanTypeBox PlanTypeBox ${
      isCurrent ? 'current' : ''
    }`}
  >
    <div className="PlanTypeBox-title">{__('Enterprise')}</div>
    <p className="PlanTypeBox-description">
      {__(
        'For projects used by a large audience, or for organizations with sophisticated licensing requirements'
      )}
    </p>
    <div className="PlanTypeBox-price enterprise">
      {__('Please contact us regarding the price')}
    </div>
    <div className="PlanTypeBox-btn">
      {isCurrent ? (
        <Button
          onClick={() => {}}
          theme="success"
          disabled={isCurrent}
          size="lg"
        >
          {__('Upgrade to Enterprise')}
        </Button>
      ) : (
        <a
          className="upgrade-link"
          href="https://licensespring.com/get-started/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {__('Upgrade to Enterprise')}
        </a>
      )}
    </div>
    <ul className="PlanTypeBox-features">
      <li>
        <span>{__('Unlimited')}</span>
        {__('monthly active users')}
      </li>
      <li>
        <span>{__('Unlimited')}</span>
        {__('products')}
      </li>
      <li>
        <span>{__('All')}</span>
        {__('development environments')}
      </li>
      <li className="checkmark">{__('Node-Locking')}</li>
      <li className="checkmark">{__('Key or User-Based')}</li>
      <li className="checkmark">{__('Offline Licensing')}</li>
      <li className="checkmark">{__('Product Versioning')}</li>
      <li className="checkmark">{__('Unlimited MALs')}</li>
      <li className="checkmark">{__('Custom Authentication')}</li>
      <li className="checkmark">{__('Floating Licenses')}</li>
      <li className="checkmark">{__('White Label APIs')}</li>
    </ul>
  </article>
);

EnterprisePlanTypeBox.propTypes = {
  isCurrent: PropTypes.bool.isRequired
};

export default EnterprisePlanTypeBox;
