import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { Button, IconDisabled } from 'shared/components';
import './styles.scss';

const DirtyFormAlert = ({
  dirty,
  confirmText,
  disabled,
  title,
  theme,
  children,
  closeAlert,
  closeCb,
  ...props
}) => {
  if (!dirty) {
    closeCb();
    return null;
  }

  return createPortal(
    <div className="DirtyFormAlert" {...props}>
      <div className="DirtyFormAlert-inner">
        <div className="DirtyFormAlert-header">{title}</div>
        <div className="DirtyFormAlert-body">{children}</div>
        <div className="DirtyFormAlert-footer">
          <Button onClick={closeCb} theme={theme} disabled={disabled}>
            {__(confirmText)}
          </Button>
          <Button onClick={closeAlert} theme="link">
            {__('Cancel')}
          </Button>
          <button
            type="button"
            className="DirtyFormAlert-dismiss"
            onClick={closeAlert}
          >
            <IconDisabled height="16px" />
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

DirtyFormAlert.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]),
  closeCb: PropTypes.func.isRequired,
  closeAlert: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  disabled: PropTypes.bool,
  theme: PropTypes.oneOf([
    'default',
    'info',
    'warning',
    'success',
    'error',
    'link'
  ]),
  title: PropTypes.string
};

DirtyFormAlert.defaultProps = {
  children: '',
  confirmText: 'Discard changes',
  disabled: false,
  theme: 'error',
  title: 'Are you sure you want to discard your changes?'
};

export default DirtyFormAlert;
