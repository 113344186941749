import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { get } from 'lodash';
import moment from 'moment';
import {
  Modal,
  Label,
  DateRangePicker,
  Notification,
  Notice,
  TextInput
} from 'shared/components';
import { validateEmail, debouncedValidateEmail } from 'shared/validation';
import { sendErrorReport } from 'shared/helpers';
import { generateAccountsReport } from 'src/company/actions';
import './styles.scss';

// const APIdateFormat = 'YYYY-MM-DD';
const defaultFrom = moment().subtract(30, 'days');
const defaultTo = moment();

const GenerateReportForm = ({ closeCb }) => {
  const [isLoading, setLoading] = useState(false);
  const [from, setFrom] = useState(defaultFrom);
  const [to, setTo] = useState(defaultTo);
  const [email, setEmail] = useState('edmon@licensespring.com');
  const [emailError, setEmailError] = useState('');

  const handleEmailValidation = async () => {
    setLoading(true);
    let errors;
    try {
      errors = await validateEmail(email);
      setEmailError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new user email', { value: email });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const isFormValid = async () => {
    const isEmailValid = await handleEmailValidation();
    return isEmailValid;
  };

  const exportData = async () => {
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setLoading(true);
    const data = {
      send_to_emails: [email]
    };

    generateAccountsReport(data)
      .then(() => {
        Notification(
          'success',
          __('Report will be sent to the email when generated.'),
          __('Generating report can take up to 15 minutes.')
        );
        closeCb();
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot export data', data);
        Notification('error', __('Export data failed'));
        setLoading(false);
      });
    return true;
  };

  const isDateRangeValid = !!from && !!to && from.isSameOrBefore(to);
  const isFormSubmittable = isDateRangeValid && !isLoading;

  return (
    <Modal
      title={__('Generate report')}
      closeCb={closeCb}
      size="sm"
      confirmCb={exportData}
      disabled={!isFormSubmittable}
    >
      <div className="GenerateReportForm">
        {false && (
          <div className="row">
            <Label text={__('Select date range for the data export')} />
            <DateRangePicker
              handleChange={dates => {
                setFrom(dates[0]);
                setTo(dates[1]);
              }}
              initialStartDate={defaultFrom}
              initialEndDate={defaultTo}
            />
          </div>
        )}
        <div className="row">
          <Label
            text={__('Enter the email where report is going to be sent')}
          />
          <TextInput
            disabled={isLoading}
            handleChange={val => {
              setEmail(val);
              debouncedValidateEmail(val).then(err => setEmailError(err));
            }}
            id="custom-email"
            type="email"
            value={email}
            error={emailError}
          />
        </div>
        <Notice size="sm">
          {__(
            'Api calls count, limit and used percentage fields will be calculated for the current month and the rest of the fields for the total amount of the time.'
          )}
        </Notice>
      </div>
    </Modal>
  );
};

GenerateReportForm.propTypes = {
  closeCb: PropTypes.func.isRequired
};

export default GenerateReportForm;
