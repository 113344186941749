import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { sendErrorReport } from 'shared/helpers';
import {
  ContentLoader,
  Label,
  Modal,
  Notification,
  Selector
} from 'shared/components';
import { fetchMgmgtApiKeys, initHubSpotIntegration } from 'src/account/actions';
import { addIntegration, updateIntegration } from 'src/company/actions';
import './styles.scss';

const HubSpotInit = ({ closeCb, hubSpotIntegration }) => {
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const companyID = get(companyDetails, 'id');

  const [isLoading, setLoading] = useState(false);
  const [isMgmtKeysLoading, setMgmtKeysLoading] = useState(true);
  const [mgmtKeys, setMgmtKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null);

  const getMgmtKeys = useCallback(() => {
    fetchMgmgtApiKeys(companyID)
      .then(res => {
        const data = get(res, 'data') || [];
        const keysList = data
          .filter(k => !k.read_only && !k.revoked)
          .filter(k => k.management_api_key);
        setMgmtKeys(keysList);
        setMgmtKeysLoading(false);
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot get mgmt api keys');
        setMgmtKeysLoading(false);
      });
  }, [companyID]);

  useEffect(() => {
    getMgmtKeys();
  }, [getMgmtKeys]);

  const createIntegration = data => {
    const baseUrl = window.appConfig.integrationServiceBaseUrl;
    initHubSpotIntegration(baseUrl, data)
      .then(res => {
        const redirectLink = get(res, 'data.link');
        window.location.replace(redirectLink);
      })
      .catch(err => {
        sendErrorReport(err);
        Notification(
          'error',
          __('Your changes were not saved.'),
          __('There was an error in communication with integration server.')
        );
        setLoading(false);
      });
  };

  const editIntegrationInLS = (integrationData, data) => {
    const integrationID = get(hubSpotIntegration, 'id');
    const patchData = {
      ...integrationData,
      is_active: true,
      data
    };
    updateIntegration(integrationID, companyID, patchData)
      .then(() => {
        createIntegration(data);
      })
      .catch(err => {
        sendErrorReport(err);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
        setLoading(false);
      });
  };

  const saveIntegrationInLS = (integrationData, data) => {
    addIntegration(companyID, integrationData)
      .then(() => {
        createIntegration(data);
      })
      .catch(err => {
        sendErrorReport(err);
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        );
        setLoading(false);
      });
  };

  const handleSubmit = async () => {
    if (isLoading) {
      return false;
    }

    if (!selectedKey) {
      Notification('error', __('Management API key is required'));
      return false;
    }

    setLoading(true);

    const data = {
      licenseSpringApiKey: selectedKey
    };

    const integrationData = {
      code: 'hubspot',
      is_active: false,
      is_verified: false,
      data: {
        licenseSpringApiKey: selectedKey
      }
    };

    if (!hubSpotIntegration) {
      saveIntegrationInLS(integrationData, data);
    } else {
      editIntegrationInLS(integrationData, data);
    }
    return true;
  };

  const handleClose = () => {
    closeCb();
  };

  if (isMgmtKeysLoading) {
    return (
      <Modal
        closeCb={handleClose}
        confirmCb={() => {}}
        disabled={isMgmtKeysLoading}
        title={__('Activate HubSpot integration')}
      >
        <div className="HubSpotInit">
          <ContentLoader text="" />
        </div>
      </Modal>
    );
  }

  const mgtmApiKeyOptions = mgmtKeys.map(k => ({
    value: k.management_api_key,
    label: k.management_api_key
  }));

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title={__('Activate HubSpot integration')}
    >
      <div className="HubSpotInit">
        <div>
          <Label
            inputid="mgmt-api-key-select"
            text={__('LicenseSpring Management API key')}
            description={__('Key has to be active and not read_only')}
          />
          <Selector
            options={mgtmApiKeyOptions}
            value={selectedKey}
            handleChange={val => {
              setSelectedKey(val);
            }}
            blurInputOnSelect
            isDisabled={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

HubSpotInit.propTypes = {
  closeCb: PropTypes.func.isRequired,
  hubSpotIntegration: PropTypes.object
};

HubSpotInit.defaultProps = {
  hubSpotIntegration: null
};

export default HubSpotInit;
