import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { Label, Page, Selector, Notice } from 'shared/components';
import { mapProductsToSelector } from 'shared/helpers';
import { AnalyticsDatePicker, AnalyticsProduct } from '../components';

const ProductsAnalytics = React.memo(() => {
  const products = useSelector(state => get(state, 'products.list'));
  const productsForSelector = mapProductsToSelector(products);
  const sidebarSize = useSelector(state => get(state, 'account.sidebarSize'));
  const maxWidth = sidebarSize === 'lg' ? '1050px' : '1440px';

  const [selectedProduct, setSelectedProduct] = useState({
    label: get(get(products, '[0]'), 'product_name'),
    value: get(get(products, '[0]'), 'product_name'),
    data: get(products, '[0]')
  });

  const handleProductSelect = val => {
    const selectedP = products.find(p => get(p, 'product_name') === val);
    setSelectedProduct({
      label: get(selectedP, 'product_name'),
      value: get(selectedP, 'product_name'),
      data: selectedP
    });
  };

  return (
    <Page title={__('Analytics')}>
      <div style={{ maxWidth }} className="ProductsAnalytics">
        <AnalyticsDatePicker title={__('Products')} />
        <Notice size="sm">{__('Data is aggregated every 24 hours')}</Notice>
        <div className="ProductsAnalytics-selector">
          <div className="section-row">
            <Label inputId="product-input" text={__('Product Name')} />
            <Selector
              options={productsForSelector}
              handleChange={handleProductSelect}
              value={get(selectedProduct, 'value')}
              inputId="product-input"
            />
          </div>
        </div>
        <AnalyticsProduct product={selectedProduct} />
      </div>
    </Page>
  );
});

export default ProductsAnalytics;
