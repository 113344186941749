import React, { useEffect } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { ContentLoader, MissingPermissions } from 'shared/components';
import { isStaffUser, isUserCompanyAdmin } from 'shared/helpers';
import { getUserPermissions } from 'src/company/actions';
import { changeActiveCompany } from 'src/account/actions';
import { getUserFromStorage } from 'src/keycloak/authUser';
import CompanyContainer from 'src/company/CompanyContainer';

const UserPermissionsCheck = () => {
  const { companyId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const isStaff = isStaffUser();
  const isAdmin = isUserCompanyAdmin(Number(companyId));

  const user = getUserFromStorage();
  const userID = get(user, 'profile.platform_user_id');

  const userPermissions =
    useSelector(state => get(state, 'company.userPermissions')) || [];
  const loadingUserPermissions = useSelector(state =>
    get(state, 'company.loadingUserPermissions')
  );

  useEffect(() => {
    if (companyId) {
      dispatch(changeActiveCompany({ id: Number(companyId) }));
      dispatch(getUserPermissions(userID, companyId));
    }
  }, [companyId, userID]);

  if (loadingUserPermissions) {
    return (
      <div style={{ paddingTop: '80px' }}>
        <ContentLoader />
      </div>
    );
  }

  const hasPermissions = !!userPermissions && userPermissions.length;

  if (!hasPermissions && !isStaff && !isAdmin) {
    return <MissingPermissions />;
  }

  return <CompanyContainer location={location} />;
};

export default UserPermissionsCheck;
