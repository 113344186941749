import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { Dropdown, IconUser, DebugSwitcher } from 'shared/components';
import './styles.scss';

const ProfileNavigation = () => {
  const auth = useAuth();
  const popupRef = useRef();

  const [isMenuDisplayed, setMenuDisplay] = useState(false);

  return (
    <div className="ProfileNavigation">
      <div className="profile-label">
        <div
          className="sidebar-item"
          onClick={() => setMenuDisplay(!isMenuDisplayed)}
          role="presentation"
        >
          <IconUser color="#fff" />
        </div>
      </div>
      {isMenuDisplayed && (
        <Dropdown ref={popupRef} close={() => setMenuDisplay(!isMenuDisplayed)}>
          <ul className="links">
            <DebugSwitcher />
            <li>
              <NavLink
                className="li-content"
                onClick={() => setMenuDisplay(false)}
                to="/profile"
              >
                {__('My profile')}
              </NavLink>
            </li>
            <li>
              <button
                className="li-content"
                onClick={() => auth.signoutRedirect()}
                type="button"
              >
                {__('Logout')}
              </button>
            </li>
          </ul>
        </Dropdown>
      )}
    </div>
  );
};

export default ProfileNavigation;
