import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Notice = ({ children, size, title, theme }) => (
  <div className={`Notice Notice-theme--${theme} Notice-size--${size}`}>
    {title && <div className="Notice-title">{title}</div>}
    {children && <div className="Notice-body">{children}</div>}
  </div>
);

Notice.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element
  ]),
  size: PropTypes.oneOf(['lg', 'md', 'sm']),
  theme: PropTypes.oneOf(['success', 'warning', 'info', 'error', 'default']),
  title: PropTypes.string
};

Notice.defaultProps = {
  children: null,
  size: 'md',
  theme: 'info',
  title: null
};

export default Notice;
