import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import {
  DescriptionTable,
  IconDelete,
  IconEdit,
  List,
  SelectedCustomFieldsTags,
  SelectedFeaturesTags
} from 'shared/components';
import {
  displayValue,
  capitalizeFirstLetter,
  displayMaxOveragesValue,
  formatDate
} from 'shared/helpers';
import { licenseTypes, defaultDateFormat } from 'shared/constants';

const SelectedBundlesTable = ({
  deleteBundleFromList,
  handleBundleEdit,
  selectedBundles,
  showActionBtns
}) => {
  const products = useSelector(state => get(state, 'products.list'));
  const [tableExpanded, setTableExpanded] = useState({});

  return (
    <div className="SelectedBundlesTable">
      <List
        data={selectedBundles || []}
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={row => {
          const policies = get(row, 'original.selected_bundle_policies') || [];
          const sortedPolicies = sortBy(policies, 'product');
          return (
            <div className="SubComponent">
              {sortedPolicies.map(p => {
                const product = products.find(
                  prod => prod.id === get(p, 'product')
                );
                const productName = get(product, 'product_name');
                const productAuthMethod =
                  get(product, 'authorization_method') || '';
                const isUserBasedProduct = productAuthMethod === 'user';
                const isTimeLimited =
                  get(p, 'default_license_type') === licenseTypes.time_limited;
                const isSubscription =
                  get(p, 'default_license_type') === licenseTypes.subscription;
                const isConsumption =
                  get(p, 'default_license_type') === licenseTypes.consumption;
                const isTrial = get(p, 'allow_trial');
                const trialDays = get(p, 'trial_days');
                const trialValidity = `${trialDays} ${'days'}`;
                const policyFeatures =
                  get(p, 'license_product_feature_templates') || [];
                const policyCFs =
                  get(p, 'license_custom_field_templates') || [];

                const shouldShowFeaturesTab = policyFeatures.length > 0;
                const shouldShowCustomFieldsTab = policyCFs.length > 0;

                return (
                  <div className="policy-row">
                    <DescriptionTable
                      details={[
                        {
                          label: __('Product name'),
                          value: displayValue(productName)
                        },
                        {
                          label: __('License type'),
                          value: displayValue(get(p, 'default_license_type'))
                        },
                        {
                          label: __('Max activations'),
                          value: get(p, 'allow_unlimited_activations')
                            ? __('Unlimited')
                            : displayValue(get(p, 'max_activations'))
                        },
                        {
                          label: isUserBasedProduct
                            ? __('Max license users')
                            : null,
                          value:
                            get(p, 'unlimited_max_license_users') ||
                            get(p, 'max_license_users') === 0
                              ? __('Unlimited')
                              : displayValue(get(p, 'max_license_users'))
                        },
                        {
                          label:
                            isTimeLimited || isSubscription
                              ? __('Valid duration')
                              : null,
                          value: displayValue(get(p, 'valid_duration'))
                        },
                        {
                          label: __('Is trial'),
                          value: isTrial ? __('Yes') : __('No')
                        },
                        {
                          label: isTrial ? __('Trial days') : null,
                          value: trialValidity
                        },
                        // show consumption values
                        {
                          label: isConsumption ? __('Max consumptions') : null,
                          value: get(p, 'allow_unlimited_consumptions')
                            ? __('Unlimited')
                            : displayValue(get(p, 'max_consumptions'))
                        },
                        {
                          label: isConsumption ? __('Allow overages') : null,
                          value: get(p, 'allow_overages') ? __('Yes') : __('No')
                        },
                        {
                          label: isConsumption ? __('Max overages') : null,
                          value: displayMaxOveragesValue(p)
                        },
                        {
                          label: isConsumption ? __('Reset consumption') : null,
                          value: get(p, 'reset_consumption')
                            ? __('Yes')
                            : __('No')
                        },
                        {
                          label: isConsumption
                            ? __('Consumption period')
                            : null,
                          value: displayValue(
                            capitalizeFirstLetter(get(p, 'consumption_period'))
                          )
                        },
                        // show everything else
                        {
                          label:
                            get(p, 'enable_maintenance_period') &&
                            !get(p, 'maintenance_period')
                              ? __('Maintenance duration')
                              : null,
                          value: displayValue(get(p, 'maintenance_duration'))
                        },
                        {
                          label:
                            get(p, 'enable_maintenance_period') &&
                            get(p, 'maintenance_period')
                              ? __('Maintenance End Date')
                              : null,
                          value: formatDate(
                            get(p, 'maintenance_period'),
                            defaultDateFormat
                          )
                        },
                        {
                          label: get(p, 'is_floating')
                            ? __('Offline floating license')
                            : null,
                          value: get(p, 'is_floating') ? __('Yes') : __('No')
                        },
                        {
                          label: get(p, 'is_floating_cloud')
                            ? __('Is floating cloud')
                            : null,
                          value: get(p, 'is_floating_cloud')
                            ? __('Yes')
                            : __('No')
                        },
                        {
                          label:
                            get(p, 'is_floating') || get(p, 'is_floating_cloud')
                              ? __('Max simultaneous license users')
                              : null,
                          value: displayValue(get(p, 'floating_users'))
                        },
                        {
                          label:
                            get(p, 'is_floating') || get(p, 'is_floating_cloud')
                              ? __('Floating timeout')
                              : null,
                          value: `${displayValue(
                            get(p, 'floating_timeout')
                          )} min`
                        },
                        {
                          label: get(p, 'can_borrow') ? __('Can borrow') : null,
                          value: get(p, 'can_borrow') ? __('Yes') : __('No')
                        },
                        {
                          label: get(p, 'can_borrow')
                            ? __('Max borrow time')
                            : null,
                          value: `${displayValue(
                            get(p, 'max_borrow_time')
                          )} ${__('hours')}`
                        },
                        {
                          label: get(p, 'max_transfers')
                            ? __('Device transfer limit')
                            : null,
                          value: displayValue(get(p, 'max_transfers'))
                        },
                        {
                          label: __('Prevent virtual machine'),
                          value: get(p, 'prevent_vm') ? __('Yes') : __('No')
                        },
                        {
                          label: shouldShowFeaturesTab
                            ? __('Product features')
                            : null,
                          // value: <SelectedFeaturesTags features={get(p, 'license_product_feature_templates')} />,
                          value: (
                            <SelectedFeaturesTags features={policyFeatures} />
                          )
                        },
                        {
                          label: shouldShowCustomFieldsTab
                            ? __('Custom fields')
                            : null,
                          // value: <SelectedCustomFieldsTags cFields={get(selectedProductPolicy, 'license_custom_field_templates')} />,
                          value: (
                            <SelectedCustomFieldsTags cFields={policyCFs} />
                          )
                        }
                      ]}
                    />
                  </div>
                );
              })}
            </div>
          );
        }}
        columns={[
          {
            expander: true,
            Header: __('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none'
            }
          },
          {
            accessor: 'bundle.data.product_name',
            Header: __('Bundle name'),
            className: 'text-center',
            headerClassName: 'text-center'
          },
          {
            accessor: 'bundle.data.short_code',
            Header: __('Bundle code'),
            className: 'text-center',
            headerClassName: 'text-center'
          },
          {
            className: 'text-center',
            Cell: cellData => (
              <button
                className="edit-button"
                onClick={() => handleBundleEdit(cellData.index)}
                type="button"
              >
                <IconEdit height="20px" width="20px" />
              </button>
            ),
            width: 50,
            show: showActionBtns
          },
          {
            className: 'text-center',
            Cell: cellData => (
              <button
                className="delete-button"
                onClick={() => deleteBundleFromList(cellData.index)}
                type="button"
              >
                <IconDelete height="20px" width="20px" color="#ee5253" />
              </button>
            ),
            width: 50,
            show: showActionBtns
          }
        ]}
        showPagination={false}
        minRows={2}
        noDataText={__('Add bundle to the list')}
      />
    </div>
  );
};

SelectedBundlesTable.propTypes = {
  deleteBundleFromList: PropTypes.func,
  handleBundleEdit: PropTypes.func,
  selectedBundles: PropTypes.array,
  showActionBtns: PropTypes.bool
};

SelectedBundlesTable.defaultProps = {
  deleteBundleFromList: () => {},
  handleBundleEdit: () => {},
  selectedBundles: [],
  showActionBtns: true
};

export default SelectedBundlesTable;
