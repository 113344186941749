import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'shared/components';

const UnauthorizeNotice = ({ children, noTitle }) => (
  <Page
    showBreadcrumbs={false}
    title={noTitle ? '' : __('You are not authorized to view this section')}
  >
    <>{children}</>
  </Page>
);

UnauthorizeNotice.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  noTitle: PropTypes.bool
};

UnauthorizeNotice.defaultProps = {
  children: null,
  noTitle: false
};

export default UnauthorizeNotice;
