import React from 'react';
import PropTypes from 'prop-types';
import { TabPanel } from 'react-tabs';
import {
  // Button,
  IconLock
} from 'shared/components';
import './styles.scss';

const TabContent = ({ children, isAvailable, ...rest }) => {
  if (!isAvailable) {
    return (
      <TabPanel {...rest}>
        <div className="TabContent-not-available">
          <IconLock width="40px" height="40px" />
          <div className="TabContent-not-available-description">
            {__('This feature is not available in your current plan type')}
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://licensespring.com/pricing"
          >
            {__('Compare plan types')}
          </a>
          {/* <Button
            theme="info"
            size="lg"
          >
            {__('Compare plan types')}
          </Button> */}
        </div>
      </TabPanel>
    );
  }

  return <TabPanel {...rest}>{children}</TabPanel>;
};

TabContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isAvailable: PropTypes.bool
};

TabContent.defaultProps = {
  children: null,
  isAvailable: true
};

TabContent.tabsRole = 'TabPanel';

export default TabContent;
